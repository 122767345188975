<!-- search-route-component -->
<div *ngIf="true" style="padding:0.5rem;">
    <h1 class="hidden">{{'MAPS.SEARCH-ROUTE.TITLE' | translate}}</h1>

    <!-- search-routes-title -->
    <div style="display:flex; padding:0.5rem; width:100%;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.SEARCH-ROUTE.TITLE' | translate}}</span>
        <!-- <h1 style="margin-top:0.3rem; margin-left:0.0rem; margin-bottom:0.0rem; font-size:x-large;">
            {{'MAPS.SEARCH-ROUTE.TITLE' | translate}}
        </h1> -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onBackToMapClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <!-- progress-spinner -->
    <div *ngIf="isLoadingData" style="text-align: center;">
        <span>{{'MAPS.SEARCH-ROUTE.LOADING-ROUTES_MSG' | translate}}</span>
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"
            style="margin-left:120px;"></mat-progress-spinner>
    </div>

    <!-- search -->
    <ng-container *ngIf="!isLoadingData">
        <div *ngIf="showSearchFilter || !foundRoutes" style="padding:0.5rem; background-color:lemonchiffon;">

            <!-- search area -->
            <span style="margin-top:1.0rem; font-size:large">{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_TITLE' |
                translate}}</span>
            <!-- select a region -->
            <div *ngIf="true" style="margin-top:0.5rem;display:flex;flex-wrap:wrap;flex-direction:row;">
                <mat-form-field appearance="fill" style="min-width:150px;width:250px;">
                    <mat-label>{{'MAPS.SEARCH-ROUTE.SEARCH-REGION_SELECT_LBL' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedRegionId" (change)="onSelectRegionChange($event)">
                        <mat-option *ngFor="let region of regions" [value]="region.id"
                            (click)="onSelectRegionChange($event)">{{region.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <span style="margin:0.5rem;"></span>
                <mat-form-field appearance="fill" style="min-width:150px;width:250px;">
                    <mat-label>{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_LBL' | translate}}</mat-label>
                    <input matInput id="regionInput" enterkeyhint="search" [matAutocomplete]="auto"
                        placeholder="{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_PLH' | translate}}"
                        (keyup)="onSearchRegionTextKeyUp($event)" [(ngModel)]="searchRegionText">
                    <mat-autocomplete id="regionAutocomplete" #auto="matAutocomplete"
                        (optionSelected)="onFoundRegionSelected($event)">
                        <mat-option *ngFor="let region of foundRefRegions" [value]="region.name">
                            {{region.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <img *ngIf="selectedRegionId"
                    style="margin-left:0.5rem; cursor:pointer; width:24px; height:24px; flex-shrink:0;"
                    src="./assets/icons/close.svg" title="{{'MAPS.GEN.ALL_T' | translate}}"
                    (click)="onClearRegionClick()">
                <img style="margin-left:0.0rem;margin-right:0.5rem; cursor:pointer; width:24px; height:24px; height:24px; flex-shrink:0;"
                    src="./assets/icons/information-variant.svg" title="{{'MAPS.GEN.HELP_ICON_T' | translate}}"
                    (click)="onHelpRegionClick()">
            </div>
            <!-- show region select info -->
            <div>
                <div *ngIf="true">
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG0' | translate}}</span><br>
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG01' | translate}}</span><br>
                </div>
                <div *ngIf="showHelpSelectRegion">
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG1' | translate}}</span><br>
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG2' | translate}}</span><br>
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG21' | translate}}</span><br>
                    <span>{{'MAPS.SEARCH-ROUTE.SEARCH-AREA_HELP_MSG3' | translate}}</span><br>
                </div>
            </div>
            <hr>

            <!-- movetype -->
            <div style="margin-top:1.0rem;">
                <span style="font-size:large">{{'MAPS.SEARCH-ROUTE.MOVING-PROFILE_TITLE' | translate}}:</span>
            </div>
            <div style="margin-top:0.0rem; margin-left:0.0rem; padding-top:0.2rem;">
                <img [class.CursorPointer]="true" style="margin-left:0.0rem;"
                    [style.background-color]="searchFilter.moveType==='bike' ? 'greenyellow' : ''"
                    src="./assets/icons/bike.svg" title="{{'MAPS.RPL.DLG.BYBIKE_T' | translate}}" alt="symbol"
                    (click)="onSelectMoveType('bike')">
                <!-- <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='ebike' ? 'greenyellow' : ''"
                    src="./assets/icons/bicycle-electric.svg" title="{{'MAPS.RPL.DLG.BYEBIKE_T' | translate}}"
                    alt="symbol" (click)="onSelectMoveType('ebike')"> -->
                <!-- <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='bike-road' ? 'greenyellow' : ''"
                    src="./assets/icons/bike-road.svg" title="{{'MAPS.RPL.DLG.BYBIKEROAD_T' | translate}}" alt="symbol"
                    (click)="onSelectMoveType('bike-road')"> -->
                <!-- <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='mtb' ? 'greenyellow' : ''"
                    src="./assets/icons/bike-mtb.svg" title="{{'MAPS.RPL.DLG.BYMTB_T' | translate}}" alt="symbol"
                    (click)="onSelectMoveType('mtb')"> -->
                <!-- <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='walking' ? 'greenyellow' : ''"
                    src="./assets/icons/walking.svg" title="{{'MAPS.RPL.DLG.WALKING_T' | translate}}" alt="symbol"
                    (click)="onSelectMoveType('walking')"> -->
                <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='hiking' ? 'greenyellow' : ''"
                    src="./assets/icons/hiking.svg" width="24px" title="{{'MAPS.RPL.DLG.HIKING_T' | translate}}"
                    alt="symbol" (click)="onSelectMoveType('hiking')">
                <!-- <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                    [style.background-color]="searchFilter.moveType==='car' ? 'greenyellow' : ''"
                    src="./assets/icons/car-hatchback.svg" title="{{'MAPS.RPL.DLG.BYCAR_T' | translate}}" alt="symbol"
                    (click)="onSelectMoveType('car')"> -->
                <span (click)="onSelectMoveType('all')" style="margin-left:1.0rem; cursor:pointer; font-size:x-large;"
                    [style.background-color]="!searchFilter.moveType ? 'greenyellow' : ''">{{'MAPS.GEN.ALL_T'
                    | translate}}
                </span>
            </div>

            <!-- search-text in route name -->
            <div style="margin-top:1.0rem;">
                <span style="font-size:large">{{'MAPS.SEARCH-ROUTE.TEXT-SEARCH_TITLE' | translate}}:</span>
            </div>
            <div>
                <input #searchInput type="text" class="input-search" enterkeyhint="search"
                    placeholder="{{'MAPS.SEARCH-ROUTE.SEARCH_INPUT_PLH' | translate}}"
                    [(ngModel)]="searchStringRouteName" (keyup)="onSearchTextKeyUp($event)">
                <img style="margin-left:1.0rem; cursor:pointer; width:24px; height:24px;"
                    src="./assets/icons/information-variant.svg" title="{{'MAPS.GEN.HELP_ICON_T' | translate}}"
                    (click)="onHelpTextSearchClick()">
                <img *ngIf="searchStringRouteName" style="margin-left:1.0rem; cursor:pointer; width:24px; height:24px;"
                    src="./assets/icons/close.svg" title="{{'MAPS.GEN.ALL_T' | translate}}"
                    (click)="onClearTextStringClick()">
                <br>
                <div *ngIf="showHelpTextSearch">
                    <span>{{'MAPS.SEARCH-ROUTE.TEXT-SEARCH_HELP_MSG' | translate}}</span>
                </div>
            </div>

            <!-- route-type -->
            <div style="margin-top:1.0rem;">
                <span style="font-size:large">{{'MAPS.SEARCH-ROUTE.ROUTE-TYPE_TITLE' | translate}}:</span>
            </div>
            <div style="margin-top:0.0rem;">
                <mat-radio-group [value]="routeType">
                    <mat-radio-button value="normal" (click)="onSelectRouteType('normal')">
                        {{'MAPS.SEARCH-ROUTE.ROUTE-TYPE-AB_T' | translate}}
                    </mat-radio-button>
                    <mat-radio-button style="margin-left:1.0rem;" value="round" (click)="onSelectRouteType('round')">
                        {{'MAPS.SEARCH-ROUTE.ROUTE-TYPE-ROUND_T' | translate}}</mat-radio-button>
                    <mat-radio-button style="margin-left:1.0rem;" value="all"
                        (click)="onSelectRouteType('all')">{{'MAPS.GEN.ALL_T' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- route-length -->
            <div style="margin-top:1.0rem;">
                <span style="font-size:large">{{'MAPS.SEARCH-ROUTE.MAX-ROUTE-LENGTH_T' | translate}}:</span>
                <label style="margin-left:1.0rem;">{{selectedMaxRouteLength}} km</label>
            </div>
            <mat-slider style="width:220px;" [thumbLabel]="true" [min]="minRouteLength" [max]="maxRouteLength"
                [step]="stepRouteLength" [(ngModel)]="selectedMaxRouteLength">
                <input matSliderThumb />
            </mat-slider>
            <span style="margin-left:0.5rem;">{{maxRouteLength}} km</span>

            <!-- categories -->
            <div style="margin-top:1.0rem;" style="cursor:pointer" (click)="onShowCategoriesClick()">
                <img *ngIf="!this.showCategories" src="./assets/icons/chevron-down.svg">
                <img *ngIf="this.showCategories" src="./assets/icons/chevron-up.svg">
                <span style="margin-left:0.5rem; font-size:large">
                    {{'MAPS.SEARCH-ROUTE.CATEGORIES_TITLE' | translate}}:</span>
            </div>
            <ng-container *ngIf="showCategories">
                <div style="display:flex;">
                    <div style="min-width:8rem;">
                        <div *ngFor="let cat of categoryNames; let i=index;">
                            <ng-container *ngIf="categoryIds[i]<100">
                                <mat-checkbox [(ngModel)]="categoryChecked[i]" (click)="onCheckClicked($event, i)">
                                    {{cat}}</mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                    <div style="margin-left:0.5rem;">
                        <div *ngFor="let cat of categoryNames; let i=index;">
                            <ng-container *ngIf="categoryIds[i]>100 && categoryIds[i]<1000">
                                <mat-checkbox [(ngModel)]="categoryChecked[i]" (click)="onCheckClicked($event, i)">
                                    {{cat}}</mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div style="min-width:8rem;">
                    <div *ngFor="let cat of categoryNames; let i=index;">
                        <ng-container *ngIf="categoryIds[i]>1000">
                            <mat-checkbox [(ngModel)]="categoryChecked[i]" (click)="onCheckClicked($event, i)">
                                {{cat}}</mat-checkbox>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <!-- only in current map-extent -->
            <hr>
            <div style="margin-top:0.8rem;">
                <mat-checkbox [ngModel]="searchFilter.inBbox" [disabled]="inBboxDisabled"
                    (change)="onCheckInBoxChanged($event)">
                    {{'MAPS.SEARCH-ROUTE.ONLY-IN-MAP-EXTENT' | translate}}</mat-checkbox>
            </div>

            <!-- start search -->
            <hr>
            <div *ngIf="loggedInUser && loggedInUser.mapPriv" style=" margin-top:0.8rem;">
                <!-- approved -->
                <mat-radio-group [value]="approved">
                    <mat-radio-button [value]=0 (click)="setApproved(0)">
                        Not approved routes</mat-radio-button>
                    <mat-radio-button style="margin-left:1.0rem;" [value]=1 (click)="setApproved(1)">
                        Only approved routes</mat-radio-button><br>
                    <span style="margin-top:0.5rem;"></span>
                    <mat-radio-button [value]=-1 (click)="setApproved(-1)">
                        Approved or not approved routes</mat-radio-button>
                </mat-radio-group>
            </div>

            <div style=" margin-top:0.8rem; margin-right:1.0rem; text-align: right;">
                <button mat-raised-button color="primary" title="{{'MAPS.SRCH.DLG.SEARCH_ICON_T' | translate}}"
                    (click)="onSearchClick()">
                    <img style="cursor:pointer; width:24px; height:24px;" src="./assets/icons/magnify.svg">
                    {{'MAPS.SEARCH-ROUTE.START-SEARCH_BUTTON_T' | translate}}
                </button>

            </div>
        </div>
    </ng-container>


    <!-- presented route -->
    <ng-container *ngIf="isPresentedRoute">
        <div style="margin-top:0.5rem; text-align:center;">
            <!-- search other routes    -->
            <button *ngIf="foundRoutes" style="margin-left:1.0rem; margin-right:0.5rem;" mat-raised-button
                color="accent" (click)="onSearchOtherRoutesClick()">{{'MAPS.SEARCH-ROUTE.SEARCH-OTHER-ROUTES_BTN_T' |
                translate}}</button>
        </div>
    </ng-container>

    <!-- found-routes -->
    <ng-container *ngIf="foundRoutes && foundRoutes.length  && !isPresentedRoute">
        <div style="text-align:center;">
            <span style="font-size:x-large; ">{{foundRoutes.length}} {{'MAPS.SEARCH-ROUTE.FOUND_MSG' |
                translate}}</span>
        </div>
        <div style="margin-top:0.5rem; text-align:center;">
            <!-- draw all routes -->
            <button *ngIf="allRoutesLoaded" style="margin-left:1.0rem; margin-right:0.5rem;" mat-raised-button
                color="primary" (click)="onDrawAllRoutesClick()">
                {{'MAPS.SEARCH-ROUTE.DRAW-ALL-ROUTES_BTN_T' | translate}}</button>
            <!-- new search     -->
            <button *ngIf="foundRoutes" style="margin-left:1.0rem; margin-right:0.5rem;" mat-raised-button
                color="accent" (click)="onNewSearchClick()">{{'MAPS.SEARCH-ROUTE.NEW-SEARCH_BTN_T' |
                translate}}</button>
        </div>
    </ng-container>

    <!-- present all found routes -->
    <div #routesHeader *ngFor="let route of foundRoutes; let i=index">
        <div id="route{{i}}">
            <app-present-route-component [uiType]="uiType" [languageCode]="languageCode" [loggedInUser]="loggedInUser"
                [routeIndex]="i" [route]="route" [open]="isPresentedRoute" (drawPublicRoute)="onDrawPublicRoute(route)"
                (activatePublicRoute)="onActivatePublicRoute(route)" (removePublicRoute)="onRemovePublicRoute(route)">
            </app-present-route-component>
        </div>
    </div>

    <!-- no routes found -->
    <ng-container *ngIf="noRouteFound">
        <!-- not found-message -->
        <div style="margin:1.0rem; padding:1.0rem; background-color:lightgrey;">
            <span style="color:red;  font-size:x-large">{{'MAPS.SEARCH-ROUTE.NO-ROUTES-FOUND_MSG' |
                translate}}</span><br><br>
            <span style="font-size:large">{{'MAPS.SEARCH-ROUTE.CHANGE-FILTER_MSG' | translate}}</span>
        </div>
        <!-- API-Error -->
        <div *ngIf="apiPostErrorText" style="margin:0.5rem; color:crimson; max-width:360px">
            <hr style=" margin-top:0.3rem; margin-bottom:0.5rem;">
            <span style="margin-top:0.5rem; font-size:x-large;">
                {{'MAPS.SEARCH-ROUTE.SEARCH-ERR_HTTP' | translate}}</span><br>
            <span style="margin-top:0.5rem; font-size:medium;">{{apiPostErrorText}}</span>
        </div>
        <!-- new search -->
        <div *ngIf="noRouteFound && foundRoutes" style="margin:1.0rem; float:right;">
            <button mat-raised-button color="accent" (click)="onNewSearchClick()">
                {{'MAPS.SEARCH-ROUTE.NEW-SEARCH_BTN_T' | translate}}</button>
        </div>
    </ng-container>

</div>