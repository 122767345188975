import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnChanges, AfterViewInit, ɵsetAllowDuplicateNgModuleIdsForTest } from "@angular/core";
import { DomSanitizer, SafeValue, Title } from "@angular/platform-browser";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, NavigationStart, ParamMap, Params, Router } from "@angular/router";
import { Location, PlatformLocation } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SwPush } from "@angular/service-worker";
import { MatIconRegistry } from "@angular/material/icon";
import { MatDialog } from "@angular/material/dialog";

// import "ol/ol.css";
import Map from "ol/Map";
import OSM, { ATTRIBUTION } from "ol/source/OSM";
import BingMaps from "ol/source/BingMaps.js";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VectorTileSource from "ol/source/VectorTile";
import TileJSONSource from "ol/source/TileJSON";
import XYZ from "ol/source/XYZ.js";
import Geolocation from "ol/Geolocation";
import GeoJSON from "ol/format/GeoJSON";
import GPX from "ol/format/GPX";
import KML from "ol/format/KML";
import View from "ol/View";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import MultiLineString from "ol/geom/MultiLineString";
import { toLonLat, fromLonLat, transform } from "ol/proj";
import Draw from "ol/interaction/Draw";
import Select from "ol/interaction/Select";
import PointerInteraction from 'ol/interaction/Pointer.js';
import Overlay from "ol/Overlay";
import { Fill, Stroke, Text, Style, Circle, Icon, } from "ol/style";
import { Control, Rotate } from "ol/control";
import { Translate, } from "ol/interaction";
import { TranslateEvent, } from "ol/interaction/Translate";
import Collection from "ol/Collection";
import { extend as Extend, createEmpty, isEmpty, getWidth, getHeight } from "ol/extent.js";
import { getDistance } from "ol/sphere";
import { getLength } from "ol/sphere.js";

import { LngLat } from "src/app/models/lnglat";
import { Track, TrackPoint, WayPoint, } from "src/app/models/track";
import { convertTrackToGPXData } from "src/app/utils/utils-track";
import { MapPlaceL } from "src/app/models/mapplace";
import {
  EnumGlobalStatusCode, LiveTrackPosition, LiveTracking, MapPublicRoute, MapTour, RefRegion, UsageLogMaps, User
} from "src/dto.generated/api";
import { EnumActionType, EnumAppType } from "src/dto.generated/api";
import { RockItAuthenticationService } from "src/app/shell/rockit-authentication.service";
import { calculateCompassHeading, createApiError, createUid, getDeviceTypeName, getIP, hasPointer, IIP, isMobileDevice, isTouchDevice, } from "src/app/utils/utils";
import { GlobalService } from "src/app/services/global.service";
import { RoPoint, RoPolyline } from "src/app/models/geometry";
import { calculateDistance, calculatePolylineLength, calculatePolylinePointIntersectionAt } from "src/app/utils/utils-geometry";
import { RouteStepResult } from "src/app/models/routestep-result";
import { LazyLoadStylesheetService } from "src/app/services/lazy-load-stylesheet.service";
import { SearchFilter } from "src/app/models/search-parameter";
import { GeneralSettings, RouteFormatOptions, RoutePlannerOptions } from "src/app/models/map-settings";
import {
  convertMapPlaceToUrlString, createBaseLayer, createGeoLocationLayer, createGpxRouteLayer,
  createGpxWaypointLayer, createHotelStyle, createMap, createMarkerLayer, createMousePositionControl, createPoisLayer,
  createRestoStyle, createRouteLayer, createSearchPointLayer, createSupermarketStyle, createTrackingPointLayer,
  createTrackingRouteLayer, createView, createWayPointLayer, MainControl, PoisControl, parseUrlMapPlace,
  createSavedPlacesLocalyLayer, createSavedRoutesLocalyLayer, createSavedRoutesServerLayer, createOverlayLayer, createRouteArrowLayer,
  createRoutePoisLayer, createRoutePointerLayer, parseUrlPoi, convertPoiToUrlString,
  createPoiStyle, createLiveTrackingLayer, createLiveTrackingEndPointStyle, createLiveTrackingPathStyle, createColorArray,
  createPublicRoutesLayer, createSavedPlacesServerLayer,
  getTransformedViewExtent,
  createMapExtentLayer,
} from "../maps-utils/utils-maps-ol-map";

import { doORSCalculateRoute, doORSCalculateRoundRoute, doORSReverseGeocode, doORSElevation } from "../maps-utils/utils-maps-ors";
import { getSteepnessColor, getSteepnessName, getSurfaceColor, getSurfaceName, getWaytypeColor, getWaytypeName } from "src/app/module-maps/show-details/show-route/show-route-utils";
import { PRoute } from "src/app/models/p-route";
import {
  localLoadGeneralSettings, localLoadLiveTrackingMe, localLoadLiveTrackings, localLoadNotifications, localLoadRouteFormatSettings, localLoadRoutePlannerSettings,
  localLoadSavedPlaces, localLoadSavedRoutes, localLoadTestUser, localLoadTourData, localLoadUserId, localStoreLiveTrackingMe, localStoreLiveTrackings, localStoreNotifications,
  localStoreSavedRoutes,
  localStoreTestUser,
  localStoreTourData,
  localStoreUserId
} from "../maps-utils/utils-maps-local-storage";
import { SavedPlaceCategory, SavedRouteCategory } from "src/app/models/saved-item-categories";
import { cloneMapPlace, cloneMapPlaces } from "../maps-utils/utils-mapplace";
import {
  combineLRouteAndPubRoute, convertLRouteToSRoute, convertPubRouteToLRoute, convertSRouteToLRoute, createDefaultWayPoints,
  createPRoute, createWayPointAtCurrentLocation, getMovetypeIconSource,
  getRouteMoveTypeText,
  getRouteNameForLanguage,
  getRouteTypeName
} from "../maps-utils/utils-route";
import { GeneralPopupComponent } from "src/app/global-components/popup-general/popup-general.component";
import { RouteNamePopupComponent } from "../popup-route-name/popup-route-name.component";
import { MissingLocatorPopupComponent } from "../popup-missing-locator/popup-missing-locator.component";
import {
  getOsmMapSource,
  setBingmapsAerialLabelsMapLayer, setBingmapsAerialMapLayer, setCycleOsmMapLayer, setGoogleMapsMapLayer, setHybridMapLayer,
  setNoMapLayer,
  setOsmMapLayer, setOutdoorMapLayer, setSatelliteMapLayer, setStreetsMapLayer, setTopoMapLayer,
  setWaymarkedTrailsCyclingOverlayLayer, setWaymarkedTrailsHikingOverlayLayer, setWaymarkedTrailsMtbOverlayLayer
} from "src/app/utils/utils-ol-map";
import { OsmLayerConfig, OsmLayerConfigCategory } from "src/app/models/pois-layers-config";
import { createPoisLayerConfigBicycle, getPoiIconSource } from "../maps-utils/utils-pois";

import * as uuid from "uuid";
import { environment } from "src/environments/environment";
import { Color } from "ol/color";
import { RockItCommandSenderService } from "src/app/shell/rockit-command-sender.service";
import {
  convertLRouteCatToSRouteCat, convertSRouteCatToLRouteCat,
  createDefaultSavedRouteCategoryRemember, getColorFromIndex
} from "../maps-utils/utils-route-categories";
import { MapSearchRouteFilter } from "src/app/models/search-route-filter";
import { getAvailableRegions, getAvailableRegionsDevelop } from "../maps-utils/utils-regions";
import { LineStyle } from "src/app/models/line-style";
import { showLocalNotificationShareLocation } from "../maps-utils/utils-notifications";
import { convertLPlaceCatToSPlaceCat, convertSPlaceCatToLPlaceCat, createDefaultSavedPlaceCategoryRemember } from "../maps-utils/utils-place-categories";
import { convertLPlaceToSPlace, convertSPlaceToLPlace } from "../maps-utils/utils-place";
import { TourData } from "src/app/models/tour-data";
import { ApiError } from "src/app/models/api-error";
import { decodeUrlParamRouteColor, decodeUrlParamRouteStyle, decodeUrlParamWaypointSize, decodeUrlParamWaypointStyle } from "src/app/utils/utils-url-decode";
import { convertMapTourToTourData, convertTourDataToMapTour, initTourData } from "../maps-utils/utils-tour";

@Component({
  selector: "app-maps-component",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.scss"]
})
export class MapsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  // subscriptions
  private isUserSignedInSubscription: Subscription;
  private paramSubscription: any;
  public destroyed = new Subject<void>();

  // user /usageLog
  public appVersion: string;
  public isUserSignedIn: Observable<boolean>;
  public loggedInUser: User;
  public userLanguage: string;
  public userId: number;
  public isTestUser: boolean;
  public isUserAdmin: boolean;
  // private userIP: IIP;
  public sessionId: string;
  private startedAt: Date;
  private referrer: string;

  // url
  public paramVersion: string;
  private paramPartnerId: string;
  public baseUrl = "planner?";

  // device-type
  public isMobileDevice: boolean;
  public isTouchDevice: boolean;
  public hasPointer: boolean;
  public currentDeviceType = "H-P";
  public uiType: string;
  public isOnline: boolean;

  // device-orientation
  public hasDeviceOrientation: boolean;
  public orientationAbsolute: boolean;
  public orientationAlpha: number;
  public orientationBeta: number;
  public orientationGamma: number;
  public compassHeading: number;

  // map
  public map: Map;
  public mapRef: Map;
  public mapExtent: Extend;
  public currentMapExtent: Extend;
  public basemapLayer: TileLayer<any>;
  public tileSource: any;
  public serverMapSource: VectorTileSource;
  public indexedDbMapSource: VectorTileSource;
  public ovlmapLayer: TileLayer<any>;
  public layers: any;
  public view: View;
  public mapPadding: number;
  public mapPaddingS = 60; // 60 pixel for uiType='S'
  public mapPaddingL = 120; // 100 pixel for uiType='L'
  public selectedFeature: Feature<any>;
  public dragFeature: Feature<any>;
  public featureDragged: boolean;

  public lastMapClickPosition: number[];
  public lonLat: LngLat = null;
  public longitude: number = null;
  public latitude: number = null;
  public zoom: number = null;
  public zoomed: boolean;
  public isExtentInUrl: boolean;

  // indexedDb
  public indexedDb: IDBDatabase;
  public isActiveOfflineMap: boolean;

  // geolocation-layer
  public geoLocationLayer: VectorLayer<any>;
  public locationFeature: Feature<any>;
  public geoLocationSource: VectorSource<any>;

  // route-planner-layer
  public routePlannerRouteLayer: VectorLayer<any>;
  public routePlannerRouteArrowLayer: VectorLayer<any>;
  public routePlannerRouteArrowSource: VectorSource<any>;
  public routePlannerWayPointsLayer: VectorLayer<any>;
  public routePlannerWayPointsSource: VectorSource<any>;
  public routePlannerWayPointsStyle: Style;
  public routePoisLayer: VectorLayer<any>;
  public routePoisSource: VectorSource<any>;
  public routePointerLayer: VectorLayer<any>;
  public routePointerSource: VectorSource<any>;

  // search-points-layer
  public searchPointsLayer: VectorLayer<any>;
  public searchPointsSource: VectorSource<any>;
  public searchStyle: Style;

  // markers-layer
  public markerPointsLayer: VectorLayer<any>;
  public markerPointsSource: VectorSource<any>;
  public markerStyle: Style;

  // saved-places-layer
  public savedPlacesLocalyLayer: VectorLayer<any>;
  public savedPlacesLocalySource: VectorSource<any>;
  public savedPlaceLocalyStyle: Style;
  public savedPlacesServerLayer: VectorLayer<any>;
  public savedPlacesServerSource: VectorSource<any>;
  public savedPlaceServerStyle: Style;

  // saved-routes-layer
  public savedRoutesLocalyLayer: VectorLayer<any>;
  public savedRoutesLocalySource: VectorSource<any>;
  public savedRouteLocalyStyle: Style;
  public savedRoutesServerLayer: VectorLayer<any>;
  public savedRoutesServerSource: VectorSource<any>;
  public savedRouteServerStyle: Style;

  // public-routes-layer
  public publicRoutesLayer: VectorLayer<any>;
  public publicRoutesSource: VectorSource<any>;
  public publicRoutesStyle: Style;

  // gpxRoute-layer
  public gpxRouteLayer: any;
  public gpxRouteSource: any;
  public gpxWaypointLayer: any;
  public gpxWaypointSource: any;

  // tracking-layer
  public trackingLayer: any;
  public trackingSource: any;
  public trackingStyle: any;
  public trackingLocationLayer: any;
  public trackingLocationSource: any;
  public trackingLocationStyle: Style;

  // live-tracking-layer
  public liveTrackingLayer: any;
  public liveTrackingSource: any;

  // map-extent-layer
  public mapExtentLayer: any;
  public mapExtentSource: any;

  // pois-layer
  public poisLayer: VectorLayer<any>;
  public poisSource: VectorSource<any>;
  public restoStyle: Style[];
  public hotelStyle: Style[];
  public supermarketStyle: Style[];
  public drinkingWaterStyle: Style;

  // map-controls
  public mainControl: any;
  public buttonZoomToMyPosition: any;
  public buttonNavigation: any;
  public buttonZoomToMapData: any;
  public buttonLockScreen: any;
  public MenuControl: Control;
  public poisControl: any;
  public buttonShowRestos: any;
  public isShowRestosActive: boolean;
  public buttonShowHotels: any;
  public isShowHotelsActive: boolean;
  public buttonShowSupermarkets: any;
  public isShowSupermarketsActive: boolean;
  public buttonShowPois: any;
  public isShowPoisActive: boolean;
  public MousePositionControl: Control;
  public showMousePosition: boolean;

  // overlays
  public hoverRouteOverlay: Overlay;

  // interactions
  public waypointDragger: Translate;

  // geo-location
  public showGeolocationInfo: boolean;
  public geolocation: Geolocation;
  public geolocationError: string;
  public currentPositionMapCoord: LngLat;
  public currentPositionLngLat: LngLat;
  public dialogRefMissingLocator: any;
  public factorHeading = 180 / Math.PI;


  // selected map-items
  public selectedMapItemType: string;
  public selectedMapPoint: MapPlaceL;
  public selectedMapRoute: PRoute;
  public selectedLiveTracking: LiveTracking;
  public selectedSaveType: string;

  // saved items
  public isVisibleSavedItemsDialog = false;
  public saveType: string;
  // saved places
  public mySavedPlacesLocaly = new Array<SavedPlaceCategory>();
  public mySavedPlacesServer = new Array<SavedPlaceCategory>();
  public savedPlaceName: string;
  // saved routes
  public mySavedRoutesLocaly = new Array<SavedRouteCategory>();
  public mySavedRoutesServer = new Array<SavedRouteCategory>();
  public isVisibleSavedRouteDetails: boolean;
  public currentRouteForDetails: PRoute;
  // public mySavedToursServer = new Array<MapTour>();

  // search-route
  public searchRouteFilter: MapSearchRouteFilter;
  public searchRegions: RefRegion[];

  // public routes
  public publicRoutes: MapPublicRoute[];
  public publicRoutesCat: SavedRouteCategory;

  // presented route
  public isVisiblePresentedRouteDialog: boolean;
  public presentedRoute: MapPublicRoute;
  public scrolledRouteIndex: number;

  // publish route
  public pubRoute: MapPublicRoute;
  public isCurrentRoutePublic: boolean;
  public isCurrentRouteIdPublished: boolean;
  public isVisiblePublishRouteDialog = false;


  // search route
  public isVisibleSearchRoutesDialog: boolean;

  // route-planner
  public isVisibleRoutePlanner = false;
  public currentRoute: PRoute;
  public plannerPhase: number;            // phase of waypoint definition (0...no wp, 1...start, 2...end, 3...start+end)
  public isStartPointDefined: boolean;
  public isEndPointDefined: boolean;
  public selectedWayPointIndex: number;   // way-point-index for setting active waypoint in edit-waypoints
  public selectedWayPointForMapIndex: number;  // waypoint-index for setting position on map (-1 .. no waypoint selected)
  public savedWayPointIndex: number;
  public isWayPointStartPoint: boolean; // way-points-popup start-point
  public isWayPointEndPoint: boolean;   // // way-points-popup end-point
  public wayPointPositionOnMap: LngLat; // set a new postion for selected waypoint (edit-waypoints-component)
  public geojsonRoute: any; // local copy
  public orsPostErrorText: string; // - RP
  public fileUrlRoute: SafeValue; // - RP
  public routeDistance: number;   // m
  public routeDuration: number;   // sec
  public routeDurationHour: number;
  public routeDurationMinute: number;
  public canDeleteSelectedWayPoint: boolean;
  public createNewPointOnMap: boolean;
  public drawRouteStyle: string;
  public drawRouteColor: string;
  public drawWaypointStyle: string;
  public drawWaypointSize: number;
  public drawSteepness: string;
  public steepnessExtra: any;
  public steepnessExtraT4Y: any;
  public saveRoutePopupTitle: string;
  public saveRouteMsg1Text: string;
  public saveRouteMsg2Text: string;
  public iconMoveTypeSource: string;

  // route-details
  public isVisibleRouteDetails: boolean;
  public routeDetailsBackToMap: boolean;

  // navigation
  public doAutoMapMove: boolean;
  public automaticMapMove = false;
  public doAutoMapRotation: boolean;
  public automaticMapRotation = false;
  public mapCoordinatesRotationOld: number[];
  public routePolyline: RoPolyline;
  public isNavigationStarted = false;
  public isNaviInfoOnMapVisible = false;
  public isArrivalInfoOnMapVisible = false;
  public mapCoordinatesNavigationOld: number[];
  public mapCoordinatesTourStatisticsOld: number[];
  public geolocationAltiduteTourStatisticsOld: number;
  public geolocationTimeTourStatisticsOld: Date;
  public calculatedRoutePolylineLength: number;
  public factorRealToPolylineLength: number;
  public startTime: Date;
  public currentDistance: number;   // m
  public totalVelocity: number;     // m/s
  public calculatedDoneDistance: number;    // m
  public remainingDistance: number;   // m
  public calculatedDurationFromStart: number;  // sec
  public remainingDuration: number;   // sec
  public remainingDurationHour;
  public remainingDurationMinute;
  public estimatedArrivalTime: Date;
  public maxDistanceFromRoute: number;  // m
  public isOffRoute: boolean;
  public isAtStartPoint: boolean;
  public isAtEndPoint: boolean;
  public playSoundIfOffRoute: boolean;
  public playSoundIfOnRoute: boolean;
  public currentRouteStepResult: RouteStepResult;
  public currentRouteStep: any;
  public nextRouteStep: any;
  public currentRouteStepIconSource: string;
  public currentRouteStepInstructionA: string;
  public currentRouteStepInstructionB: string;
  public currentRouteStepInstructionC: string;
  public listOffRoutePositions = new Array<any>();
  public navigationWakeLock: any;
  public currentHeading = 0.0;
  public currentCompassHeading = 0.0;
  public speedCheck: number;
  public updateArrowCheck: boolean;

  // hover-route
  public doneHoverRouteDistance: number;
  public remainingHoverRouteDistance: number;

  // search
  public isVisibleSearchDialog: boolean;
  public searchText: string;
  public searchParameter = {} as SearchFilter;
  public searchPoint: MapPlaceL;
  public foundRefPlaces: MapPlaceL[];
  public createSearchPointOnMap: boolean;

  // markers
  public markerName: string;
  public markerPoints = new Array<MapPlaceL>();

  // load-gpx-route
  public isVisibleGPXRouteDialog = false;
  public gpxFileName: string;
  public gpxFileLoaded = false;
  public activeGpxTrack: Track;

  // gpx-route-details
  public isVisibleGpxRouteDetails: boolean;
  public gpxRouteDetailsBackToMap: boolean;

  // record tour / tracking
  public isVisibleTrackingDialog = false;
  public currentTourData: TourData;
  public tourDataTimer: any;
  public trackIntervall = 25.0;
  public trackingWakeLock: any;
  public continueTrackingPopupTitle: string;


  // map-selection
  public isVisibleMapSelection = false;
  public mapSource = "OSM";
  public ovlmapSource = "";

  // settings
  public isVisibleSettings = false;
  public routePlannerSettings: RoutePlannerOptions;
  public defaultPlannerSettings: boolean;
  public generalSettings: GeneralSettings;
  public routeFormatSettings: RouteFormatOptions;
  public roundRouteLengthBike = 50000;
  public roundRouteLengthFoot = 10000;
  public useWakeLock: boolean;

  // manage-maps
  public isVisibleManageMaps = false;

  // pois
  public poisLayerConfig: OsmLayerConfigCategory[];
  public poisDrinkingWater = true;
  public poisBicycleParking: boolean;
  public poisBicycleRepairStation: boolean;

  // live-tracking
  public isOpenLiveTrackinMeMenu: boolean;
  public newLiveTrackingMe: LiveTracking;
  public newLiveTrackingMeDuration: string;
  public newLiveTrackingMeHours: number;
  public newLiveTrackingMeMinutes: number;
  public newLiveTrackingMePermanent: boolean;
  public newLiveTrackingMeName: string;
  public newLiveTrackingMeStoreWholeRoute: boolean;
  public currentLiveTrackingMe: LiveTracking;
  public hasLiveTrackingMePopupChanged: boolean;
  public lastLiveTrackingMePosition: LiveTrackPosition;
  public liveTrackings: LiveTracking[];
  public liveTrackingInterval: any;
  public liveTrackingUpdateInterval: any;
  public liveTrackingWakeLock: any;
  public debugLiveTrackingOff: boolean;

  // notifications
  public notifications: any;

  // share
  public shareRouteSubjectText: string;
  public shareRouteMsg1Text: string;
  public shareRouteMsg2Text: string;
  public sharePlaceSubjectText: string;
  // public sharePlaceMsg1Text: string;
  public sharePlaceMsg2Text: string;
  public shareLocationSubjectText: string;
  public shareLocationMsg2Text: string;
  public shareLiveTrackingSubjectText: string;
  public shareLiveTrackingMsg2Text: string;
  public shareLiveTrackingKeySubjectText: string;
  public shareLiveTrackingKeyMsg2Text: string;

  // help
  public isVisibleHelp = false;
  public isVisibleInfos = false;

  // admin
  public isVisibleAdmin = false;

  // translated-text
  private textWaypoint: string;
  private textStart: string;
  private textEnd: string;
  private textNewPointTitle: string;
  private textElevationPopupTitle: string;
  private textNamePopupTitle: string;
  private textRouteNamePopupTitle: string;
  private textRouteNamePopupInputPlh: string;
  private textRoutePopupSurfaceText: string;
  private textRoutePopupWaytypeText: string;
  private textRoutePopupInclineText: string;
  private textRoutePopupDeclineText: string;

  // diverse
  public mapCoordinates: number[];
  public atan2: number;
  public deltaX: number;
  public deltaY: number;
  public rotation: number;
  public colorArray: Color[];

  // dialog-visibility
  public isActiveDialog: boolean;
  public isVisibleMainDialog = true;
  public isVisibleMap: boolean;
  public hidePoisControl: boolean;
  public isScreenLocked: boolean;

  public directionFeature: Feature<any>;
  public arrowFeature: Feature<any>;

  // progress-spinner
  public isVisibleProgressSpinner: boolean;

  // ol-loaded
  public isOlLoaded: boolean;

  // error
  public errorMessageTitle: string;
  public errorMessage: string;
  public errorMessageHelp: string;
  public errorMessageHelpOffline: string;
  public isErrorMessagePopupVisible = false;
  public errorMessageOffline = false;

  // OSM-data
  public elements: { id: number, lat: number, lon: number, name: string, tags: any }[];

  // map-popup
  public isFeaturePopupSearchPointVisible = false;
  public isFeaturePopupPOIVisible = false;
  public isFeaturePopupMarkerVisible = false;
  public isFeaturePopupSavedPlaceVisible = false;
  public isFeaturePopupWPVisible = false;
  public isFeaturePopupRoutePOIVisible = false;
  public isFeaturePopupGPXPointVisible = false;
  public isFeaturePopupRouteVisible = false;
  public isFeaturePopupRouteSegmentVisible = false;
  public isFeaturePopupSavedRouteVisible = false;
  public isFeaturePopupPubRouteVisible = false;
  public isFeaturePopupGPXRouteVisible = false;
  public isFeaturePopupGeolocationVisible = false;
  public isFeaturePopupLiveTrackingVisible = false;
  public isFeaturePopupTrackVisible = false;
  public isFeaturePopupMapExtentVisible = false;

  public isNewPointPopupVisible = false;
  public showTagsInPopups = false;
  public currentFeatureTags: any;
  public isNamePopupVisible = false;
  public isLiveTrackingMePopupVisible = false;
  public isHoverRoutePopupVisible = false;
  public isPoisFilterPopupVisible = false;
  public isNeedToLoginPopupVisible = false;
  public objectType: string;
  public currentPopupMode: string;
  public isElevationPopupVisible: boolean;

  // elevation
  public currentElevation: number;

  // test
  public showLog: boolean;
  public debugLog = new Array<string>();
  public testTrackingPositions: LiveTrackPosition[];

  // @ViewChild("mapPageId") pageElement: ElementRef;
  @ViewChild("container") containerElement: ElementRef;
  @ViewChild("content") contentElement: ElementRef;
  @ViewChild("contenttags") contentTagsElement: ElementRef;
  @ViewChild("map") mapElement: ElementRef;
  @ViewChild("hoverroutepopupcontainer") hoverRoutePopupContainerElement: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private commandSenderService: RockItCommandSenderService,
    private globalService: GlobalService,
    private lazyLoadStylesheetService: LazyLoadStylesheetService,
    private titleService: Title,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    // private platformLocation: PlatformLocation,
    private socialAuthService: RockItAuthenticationService,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private swPush: SwPush,

  ) {
    this.isUserSignedIn = socialAuthService.userId$.pipe(map(authState => authState != null));

    this.isUserSignedInSubscription = this.isUserSignedIn
      .subscribe(isUserSignedIn => {
        if (isUserSignedIn) {
          this.getLoggedInUser();
          this.loadSavedPlacesFromServer();
          this.loadSavedRoutesFromServer();
        } else {
          this.loggedInUser = null;
        }
      });

    // observe current device-type
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.WebPortrait,
      Breakpoints.WebLandscape,
    ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        // console.log("Maps:query-result", result);
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            // console.log("Maps:query", query);
            this.currentDeviceType = getDeviceTypeName(query);
            console.log("Maps:constructor-currentDeviceType", this.currentDeviceType);
            this.updateDeviceDependency();
            console.log("Maps:constructor-deviceType+uiType", this.currentDeviceType.toString() + "/" + this.uiType.toString());
            if (this.map) {
              this.updateDialogStatus();
              if (this.uiType === "L") {
                this.showMap();
              }
              if (this.uiType === "S") {
                if (this.isActiveDialog) { this.hideMap(); }
              }
              setTimeout(() => { this.map.updateSize(); });
            }
          }
        }
      });

    // appVersion
    this.appVersion = environment.releaseVersion;

    // showLog
    this.showLog = false;
    if (environment.loginWithDevUser) { this.showLog = true; }
    this.commandSenderService.setShowLog(this.showLog);

    // disable back-button-event
    const onBackButtonEvent = (e: PopStateEvent) => {
      if (this.showLog) { console.log("Maps:constructor:onBackButtonEvent-e", e); }
      e.preventDefault();
    };
    window.addEventListener('popstate', onBackButtonEvent);


    this.matIconRegistry.addSvgIcon("menu", sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/menu.svg"));
    // this.matIconRegistry.addSvgIcon("map-marker-plus", sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/map-marker-plus.svg"));
    // this.matIconRegistry.addSvgIcon("map-marker-minus", sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/map-marker-minus.svg"));
    // this.matIconRegistry.addSvgIcon("delete", sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/delete.svg"));

    // translate
    translate.addLangs(["en", "de"]); // funktioniert nicht zum definieren der gültigen sprachen
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use("en");

    // isMobileDevice
    this.isMobileDevice = isMobileDevice();
    // isTouchDevice
    this.isTouchDevice = isTouchDevice();
    // has pointer
    this.hasPointer = hasPointer();
    // this.hasPointer = false;

    // isOnline
    // this.isOnline = navigator.onLine;
    // if (this.showLog) { console.log("Maps:constructor-navigator.onLine", navigator.onLine); }
    this.isOnline = this.commandSenderService.getIsOnline();
    // this.isOnline = false;
    if (this.showLog) { console.log("Maps:constructor-isOnline", this.isOnline); }

    // router-events
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const targetUrl = event.url;
        if (this.showLog) { console.log("------------------------------->Maps:constructor-navigationStart-event", event); }
      }
    });

  }

  public async ngOnInit() {
    if (this.showLog) { console.log("Maps:ngOnInit"); }

    // userLanguage from navigator
    if (!this.userLanguage) {
      this.userLanguage = this.translate.getBrowserLang();
      // this.userLanguage = this.translate.currentLang;
      // console.log("Maps:ngOnInit-userLanguage", this.userLanguage);
      if (this.userLanguage !== "de" && this.userLanguage !== "en") {
        const languages = navigator.languages;
        // console.log("Maps:ngOnInit-languages", languages);
        if (languages) {
          let found = false;
          for (const language of languages) {
            const lang = language.substring(0, 2).toLowerCase();
            // console.log("Maps:ngOnInit-lang", lang);
            if (lang == "de" || lang == "en") {
              this.userLanguage = lang;
              found = true;
              break;
            }
            if (!found) { this.userLanguage = "en"; }
          }
        }
      }
    }
    // set language for i18n
    // this.userLanguage = "en";  // for tests
    // this.userLanguage = "pl";  // for tests other languages
    // console.log("Maps:ngOnInit-userLanguage", this.userLanguage);
    document.documentElement.lang = this.userLanguage;
    this.translate.use(this.userLanguage);
    this.setUserLanguageText(this.userLanguage);

    // title
    let title = "Route planner & navigation | Outdoor app";
    if (this.userLanguage === "de") { title = "Routenplaner & Navigation | Outdoor-App"; }
    title += " | Trip4You-Maps";
    this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    let description = "Route planner for outdoor activities, app for easy route planning, navigation, recording tours, creating and using GPX routes";
    if (this.userLanguage === "de") {
      description = "Routenplaner für Outdoor-Aktivitäten, App zum einfachen Planen von Routen, Navigieren, Touren aufzeichnen, GPX-Routen erstellen und verwenden";
    }
    metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);

    // device-orientation
    this.startDeviceOrientationListener();

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        if (this.showLog) { console.log("Maps:ngOnInit-visibilityState", document.visibilityState); }
        // this.debugLog.push("visibilityState: " + document.visibilityState.toString());
        // this.debugLog.push("trackingWakeLock: " + isActive.toString());
        // wake-locks
        if (this.navigationWakeLock) { this.activateNavigationWakeLock(); }
        if (this.currentTourData.isTourStarted) {
          this.activateTrackingWakeLock();
          this.currentTourData.numGaps++;
        }
        if (this.liveTrackingWakeLock) { this.activateLiveTrackingWakeLock(); }
        this.checkIfNetworkAccessOk();
      }
      // update loggedInUser due to possible timetime
      this.socialAuthService.updateUserId();
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // get texts
    // text route-name-popup
    this.translate.get("MAPS.N-POPUP.ROUTE-NAME_TITLE").subscribe((text: string) => { this.textRouteNamePopupTitle = text; });
    this.translate.get("MAPS.N-POPUP.ROUTE-NAME_PLH").subscribe((text: string) => { this.textRouteNamePopupInputPlh = text; });
    this.translate.get("MAPS.F-POPUP.ROUTE-SURFACE_TEXT").subscribe((text: string) => { this.textRoutePopupSurfaceText = text; });
    this.translate.get("MAPS.F-POPUP.ROUTE-WAYTYPE_TEXT").subscribe((text: string) => { this.textRoutePopupWaytypeText = text; });
    this.translate.get("MAPS.F-POPUP.ROUTE-INCLINE_TEXT").subscribe((text: string) => { this.textRoutePopupInclineText = text; });
    this.translate.get("MAPS.F-POPUP.ROUTE-DECLINE_TEXT").subscribe((text: string) => { this.textRoutePopupDeclineText = text; });
    this.translate.get("MAPS.SHARE.ROUTE-SUBJECT_TEXT").subscribe((text: string) => { this.shareRouteSubjectText = text; });
    // this.translate.get("MAPS.SHARE.ROUTE-MSG1_TEXT").subscribe((text: string) => { this.shareRouteMsg1Text = text; });
    this.translate.get("MAPS.SHARE.ROUTE-MSG2_TEXT").subscribe((text: string) => { this.shareRouteMsg2Text = text; });
    this.translate.get("MAPS.SHARE.PLACE-SUBJECT_TEXT").subscribe((text: string) => { this.sharePlaceSubjectText = text; });
    this.translate.get("MAPS.SHARE.PLACE-MSG2_TEXT").subscribe((text: string) => { this.sharePlaceMsg2Text = text; });
    this.translate.get("MAPS.SHARE.LOCATION-SUBJECT_TEXT").subscribe((text: string) => { this.shareLocationSubjectText = text; });
    this.translate.get("MAPS.SHARE.LOCATION-MSG2_TEXT").subscribe((text: string) => { this.shareLocationMsg2Text = text; });
    this.translate.get("MAPS.SHARE.LIVE-TRACKING-SUBJECT_TEXT").subscribe((text: string) => { this.shareLiveTrackingSubjectText = text; });
    this.translate.get("MAPS.SHARE.LIVE-TRACKING-MSG2_TEXT").subscribe((text: string) => { this.shareLiveTrackingMsg2Text = text; });
    // tslint:disable-next-line:max-line-length
    this.translate.get("MAPS.SHARE.LIVE-TRACKING-KEY-SUBJECT_TEXT").subscribe((text: string) => { this.shareLiveTrackingKeySubjectText = text; });
    this.translate.get("MAPS.SHARE.LIVE-TRACKING-KEY-MSG2_TEXT").subscribe((text: string) => { this.shareLiveTrackingKeyMsg2Text = text; });
    this.translate.get("MAPS.SAVEROUTE-POPUP.TITLE").subscribe((text: string) => { this.saveRoutePopupTitle = text; });
    this.translate.get("MAPS.SAVEROUTE-POPUP.MSG1_TEXT").subscribe((text: string) => { this.saveRouteMsg1Text = text; });
    this.translate.get("MAPS.SAVEROUTE-POPUP.MSG2_TEXT").subscribe((text: string) => { this.saveRouteMsg2Text = text; });

    // referrer
    // this.referrer = document.referrer;
    // console.log("Maps:ngOnInit-referrer", this.referrer);

    // map-data
    // start with center to Graz
    this.longitude = 15.4376;
    this.latitude = 47.0739;
    this.zoom = 4;
    this.maxDistanceFromRoute = 50.0;
    this.selectedWayPointForMapIndex = -1;

    // url-parameter
    this.paramSubscription = this.route.queryParamMap.subscribe((parammap) => this.onRouteQueryParamsChanged(parammap));

    // load ol-css
    // this.lazyLoadStylesheetService.loadOlCSS().subscribe(_ => {
    //   // console.log("Maps:ngOnInit-ol.css is loaded!");
    //   this.isOlLoaded = true;
    // });
    // this.isOlLoaded = true;

    // load-local-storage-data
    this.loadLocalStorageData();

    // init new route if not existent
    if (!this.currentRoute) {
      this.initRoute();
    }
    if (!this.drawRouteStyle) { this.drawRouteStyle = "solid"; }
    if (!this.drawRouteColor) { this.drawRouteColor = "cyan"; }
    if (!this.drawWaypointStyle) { this.drawWaypointStyle = "std"; }
    if (!this.drawWaypointSize) { this.drawWaypointSize = 12; }

    // icon moveType
    let moveType = this.currentRoute.plannerOptions.moveType;
    if (this.defaultPlannerSettings) { moveType = ""; }
    this.iconMoveTypeSource = getMovetypeIconSource(moveType);

    // search-parameter
    this.searchParameter.focusCurrentPosition = true;

    // show notifications
    this.showLocalNotificationsAtStart();

    // user-IP
    // this.userIP = await getIP(this.http);
    let info = "";
    if (this.userId) { info = this.userId.toString(); }
    const isGooglebot = /googlebot/i.test(navigator.userAgent);
    if (isGooglebot) { info += "GoogleBot" }
    const isBingbot = /bingbot/i.test(navigator.userAgent);
    if (isBingbot) { info += "BingBot" }
    const isDuckduckgobot = /DuckDuckBot/i.test(navigator.userAgent);
    if (isDuckduckgobot) { info += "DuckDuckGoBot" }
    this.createUsageLog(EnumActionType.init, info);

    // query-file-handling api
    this.queryFileHandlingApi();

    this.createNewPointOnMap = true;
    this.createSearchPointOnMap = false;

    //neu laden verhindern
    window.addEventListener('beforeunload', (e) => {
      // if (this.showLog) { console.log("Maps:onNgInit:beforeunload-e", e); }
      this.saveCurrentDataToLocalStorage();
      const isAuthActive = this.socialAuthService.isAuthActive;
      // if (this.showLog) { console.log("Maps:onNgInit-beforeunload-isAuthActive", isAuthActive); }
      if (!isAuthActive) { e.preventDefault(); }
    });



    // test userAgent
    // console.log("Maps:ngOnInit-userAgent", navigator.userAgent);


    if (this.showLog) { console.log("Maps:+++++++++++++++++++++++++++++++++ngOnInit-end!"); }
  }

  public async ngAfterViewInit() {
    // console.log("Maps:ngAfterViewInit");
    // setTimeout(() => { this.map.updateSize(); });
    this.scrollToMenu();
    if (this.presentedRoute) { this.zoomToPublicRoute(this.presentedRoute); }
  }

  public async ngOnChanges(changes: SimpleChanges) {
    // console.log("Maps:onChanges-changes", changes);
  }

  public async onRouteQueryParamsChanged(paramMap: ParamMap) {
    if (this.showLog) { console.log("Maps:onRouteQueryParamsChanged-paramMap", paramMap); }

    // load-local-storage-data
    this.loadLocalStorageData();
    // init new Route
    this.initRoute();
    // clear markers
    if (this.map) {
      this.markerPoints.splice(0);
      this.markerPointsSource.clear();
    }

    // login-redir
    if (paramMap.has("redir")) {
      const redirId = paramMap.get("redir");
      // console.log("Maps:onRouteParamsChanged-redirId", redirId);
      const localStorage = window.localStorage;
      const redirValue = localStorage.getItem("t4y-maps_redir");
      // console.log("Maps:onRouteParamsChanged-redirValue", redirValue);
      const items = redirValue.split("=>");
      if (items.length > 1) {
        const redirIdSaved = items[0];
        // console.log("Maps:onRouteParamsChanged-redirIdSaved", redirIdSaved);
        if (redirIdSaved === redirId) {
          const redirUrl = items[1];
          // console.log("Maps:onRouteParamsChanged-redirUrl", redirUrl);
          if (redirUrl.includes("param") || redirUrl.includes("planner")) {
            this.router.navigateByUrl(redirUrl);
          }
          if (redirUrl.includes("route")) {
            const items = redirUrl.split("/");
            // console.log("Maps:onRouteParamsChanged-items", items);
            if (items[1] === "route") {
              const languageCode = items[2];
              const routeId = items[3];
              const activate = false;
              this.userLanguage = languageCode;
              this.translate.use(this.userLanguage);
              this.setUserLanguageText(this.userLanguage);
              const success = await this.loadPublicRouteById(routeId, languageCode);
              if (success) {
                if (activate) {
                  setTimeout(() => {
                    this.activatePublicRoute(this.presentedRoute);
                  }, 500);
                }
                this.activateMap();
                setTimeout(() => {
                  this.zoomToPublicRoute(this.presentedRoute);
                }, 500);
              }
            }
          }
        }
      }
      // return;
    }
    // route
    // get route-params
    await this.getRouteParams();
    if (this.presentedRoute) {
      console.log("Maps:onRouteQueryParamsChanged-presentedRoute", this.presentedRoute);
      await this.activateMap();
      this.redrawPublicRoutes();
      this.openSearchRoutesDialog();
      this.zoomToPublicRoute(this.presentedRoute);
      return;
    }

    // version
    this.paramVersion = "2.0";
    if (paramMap.has("ver")) {
      this.paramVersion = paramMap.get("ver");
    }
    // if(this.debugLog) {console.log("Maps:onRouteParamsChanged-version", this.paramVersion);}
    // partnerId
    if (paramMap.has("partnerId")) {
      this.paramPartnerId = paramMap.get("partnerId");
      // console.log("Maps:onRouteParamsChanged-partnerId", this.paramPartnerId);
    }

    // Version 1.0
    if (this.paramVersion === "1.0") {
      this.routeQueryParamsChanged_Ver200(paramMap);
      return;
    }
    // Version 2.0
    if (this.paramVersion === "2.0") {
      this.routeQueryParamsChanged_Ver200(paramMap);
      return;
    }
    // default:ver 2.0
    this.routeQueryParamsChanged_Ver200(paramMap);
    // console.log("Maps:onRouteParamsChanged-end!");
  }
  public async routeQueryParamsChanged_Ver200(paramMap: ParamMap) {
    if (this.showLog) { console.log("Maps:routeQueryParamsChanged_Ver200-paramMap", paramMap); }
    // map-extent&zoom
    this.isExtentInUrl = false;
    if (paramMap.has("@")) {
      const extentString = paramMap.get("@");
      const items = extentString.split(",");
      // console.log("Maps:routeQueryParamsChanged_Ver200-items", items);
      const lonString = items[0];
      const latString = items[1];
      let zoomString = items[2];
      if (zoomString.endsWith("z")) { zoomString = zoomString.slice(0, zoomString.length - 1); }
      this.longitude = Number(lonString);
      this.latitude = Number(latString);
      this.zoom = Number(zoomString);
      this.isExtentInUrl = true;
      // console.log("Maps:routeQueryParamsChanged_Ver200-latitude", this.latitude);
      // console.log("Maps:routeQueryParamsChanged_Ver200-zoom", this.zoom);
    }
    // marker-param
    if (paramMap.has("markers")) {
      const markerPointsString = paramMap.get("markers");
      this.markerPoints = new Array<MapPlaceL>();
      // console.log("Maps:onRouteParamsChanged-markerPointString", markerPointsString);
      const items = markerPointsString.split(";");
      for (const item of items) {
        if (item === "") { continue; }
        const flagPoint = parseUrlPoi(item);
        // console.log("Maps:routeQueryParamsChanged:markers-flagPoint", flagPoint);
        flagPoint.type = "marker";
        this.markerPoints.push(flagPoint);
      }
    }
    // live-tracking-key
    if (paramMap.has("tracking-key")) {
      const trackingKey = paramMap.get("tracking-key");
      this.markerPoints = new Array<MapPlaceL>();
      // console.log("Maps:routeQueryParamsChanged:trackingKey", trackingKey);
      let liveTracking = {} as LiveTracking;
      liveTracking.trackingId = trackingKey;
      this.isOnline = this.commandSenderService.getIsOnline();
      if (!this.isOnline) { return; }
      // load liveTracking from server
      const result = await this.globalService.getLiveTracking(liveTracking.trackingId);
      // add live-tracking to array
      if (result.status === EnumGlobalStatusCode.Success) {
        liveTracking = result.liveTracking;
        if (!this.liveTrackings) { this.liveTrackings = new Array<LiveTracking>(); }
        this.liveTrackings.push(liveTracking);
        const trackPositions = new Array<LiveTrackPosition>();
        (liveTracking as any).trackPositions = trackPositions;
        localStoreLiveTrackings(this.liveTrackings);
        this.updateLiveTrackings();
        this.addLiveTracking(liveTracking);
        const info = "id:" + liveTracking.id + ";userId:" + liveTracking.userId + ";trackingId:" + liveTracking.trackingId;
        this.createUsageLog(EnumActionType.activate_live_tracking_by_url, info);
      }
    }
    // route-style
    if (paramMap.has("routestyle")) {
      const paramString = paramMap.get("routestyle");
      this.drawRouteStyle = decodeUrlParamRouteStyle(paramString);
      this.drawRouteColor = decodeUrlParamRouteColor(paramString);
      // console.log("Maps:onRouteParamsChanged-routestyle", paramString);
      // const params = paramString.split("|");
      // let routeStyle = "solid";
      // if (params.length > 0) { routeStyle = params[0]; }
      // if (routeStyle === "solid") {
      //   this.drawRouteStyle = routeStyle;
      //   if (params.length > 1) {
      //     const color = params[1];
      //     if (color) { this.drawRouteColor = color; }
      //   }
      //   // console.log("Maps:onRouteParamsChanged-drawRouteColor", this.drawRouteColor);
      // }
      // if (routeStyle === "transparent") {
      //   this.drawRouteStyle = routeStyle;
      // }
      // if (routeStyle === "surface-type") {
      //   this.drawRouteStyle = routeStyle;
      // }
      // if (routeStyle === "way-type") {
      //   this.drawRouteStyle = routeStyle;
      // }
      // if (routeStyle === "steepness") {
      //   this.drawRouteStyle = routeStyle;
      // }
      // if (routeStyle === "style-g") {
      //   this.drawRouteStyle = routeStyle;
      // }
      // if (routeStyle === "style-t") {
      //   this.drawRouteStyle = routeStyle;
      // }
    }
    // waypoint-style
    if (paramMap.has("waypointstyle")) {
      const paramString = paramMap.get("waypointstyle");
      // console.log("Maps:onRouteParamsChanged-waypointstyle", paramString);
      this.drawWaypointStyle = decodeUrlParamWaypointStyle(paramString);
      this.drawWaypointSize = decodeUrlParamWaypointSize(paramString);
      // const params = paramString.split("|");
      // let waypointStyle = "std";
      // if (params.length > 0) { waypointStyle = params[0]; }
      // if (waypointStyle === "std") {
      //   this.drawWaypointStyle = waypointStyle;
      //   if (params.length > 1) {
      //     const size = Number(params[1]);
      //     if (size) { this.drawWaypointSize = size; }
      //   }
      // }
      // if (waypointStyle === "hide") {
      //   this.drawWaypointStyle = waypointStyle;
      // }
      // if (waypointStyle === "start") {
      //   this.drawWaypointStyle = waypointStyle;
      // }
      // if (waypointStyle === "end") {
      //   this.drawWaypointStyle = waypointStyle;
      // }
    }
    // pois-control
    if (paramMap.has("no-pois")) {
      // console.log("Maps:onRouteParamsChanged-no-pois");
      this.hidePoisControl = true;
    }
    // create current-route out of params
    this.initRoute();
    // route-id
    if (paramMap.has("id")) {
      this.currentRoute.routeId = paramMap.get("id");
    }
    // route-name
    if (paramMap.has("name")) {
      this.currentRoute.name = paramMap.get("name");
    }
    // route-params
    if (paramMap.has("movetype")) {
      const routeWayPoints = new Array<MapPlaceL>();
      // movetype
      let moveType = paramMap.get("movetype");
      // console.log("Maps:onRouteParamsChanged-moveType", moveType);
      if (moveType === "hike") { moveType = "hiking"; }
      this.iconMoveTypeSource = getMovetypeIconSource(moveType);
      this.currentRoute.plannerOptions.moveType = moveType;
      // start-point
      if (paramMap.has("start")) {
        const startString = paramMap.get("start");
        // console.log("Maps:onRouteParamsChanged-startString", startString);
        const startPoint = parseUrlMapPlace(startString);
        // console.log("Maps:onRouteParamsChanged-startPoint", startPoint);
        if (startPoint) {
          startPoint.type = "wp";
          if (startPoint.name === "$current") {
            startPoint.label = "Current location";
            if (this.userLanguage === "de") { startPoint.label = "Aktueller Standort"; }
            startPoint.type = "$current";
            startPoint.coordLon = undefined;
            startPoint.coordLat = undefined;
          }
          routeWayPoints.push(startPoint);
        } else {
          const wp = {} as MapPlaceL;
          routeWayPoints.push(wp);
        }
      }
      // way-points
      if (paramMap.has("wp")) {
        const wpCoords = paramMap.get("wp");
        // console.log("Maps:onRouteParamsChanged-wpString", wpCoords);
        const items = wpCoords.split(";");
        for (const item of items) {
          if (item === "") { continue; }
          const point = parseUrlMapPlace(item);
          // console.log("Maps:onRouteParamsChanged-point", point);
          if (point) {
            point.type = "wp";
            if (point.name === "$current") {
              point.label = "Current location";
              if (this.userLanguage === "de") { point.label = "Aktueller Standort"; }
              point.type = "$current";
            }
            routeWayPoints.push(point);
          }
        }
      }
      // end-point
      if (paramMap.has("end")) {
        const endString = paramMap.get("end");
        // console.log("Maps:onRouteParamsChanged-endString", endString);
        const endPoint = parseUrlMapPlace(endString);
        // console.log("Maps:onRouteParamsChanged-endPoint", endPoint);
        if (endPoint) {
          // console.log("Maps:onRouteParamsChanged-endPoint", endPoint);
          endPoint.type = "wp";
          if (endPoint.name === "$current") {
            endPoint.label = "Current location";
            if (this.userLanguage === "de") { endPoint.label = "Aktueller Standort"; }
            endPoint.type = "$current";
          }
          routeWayPoints.push(endPoint);
        } else {
          const wp = {} as MapPlaceL;
          routeWayPoints.push(wp);
        }
      }
      // console.log("Maps:onRouteParamsChanged-routeWayPoints1", this.routeWayPoints);
      // backtostart (old:roundtrip)
      if (paramMap.has("backtostart")) {
        const backtostartString = paramMap.get("backtostart");
        // console.log("Maps:onRouteParamsChanged-backtostartString", backtostartString);
        this.currentRoute.backToStart = false;
        if (backtostartString === "true") { this.currentRoute.backToStart = true; }
      }
      // if (paramMap.has("roundtrip")) {
      //   const roundTripString = paramMap.get("roundtrip");
      //   this.currentRoute.backToStart = false;
      //   if (roundTripString === "true") { this.currentRoute.backToStart = true; }
      // }
      // roundroute
      if (paramMap.has("roundroute")) {
        this.currentRoute.roundRoute = true;
        const roundRouteString = paramMap.get("roundroute");
        const options = roundRouteString.split("|");
        const length = Number(options[0]);
        const points = Number(options[1]);
        const seed = Number(options[2]);
        this.currentRoute.plannerOptions.roundRouteLength = length;
        this.currentRoute.plannerOptions.roundRoutePoints = points;
        this.currentRoute.plannerOptions.roundRouteSeed = seed;
      }
      this.currentRoute.wayPoints = routeWayPoints;
      // console.log("Maps:onRouteParamsChanged-routeWayPoints2", this.routeWayPoints);
      this.updatePlannerPhase();
      this.createNewPointOnMap = true;
    }
    // pois
    if (paramMap.has("pois")) {
      const poisString = paramMap.get("pois");
      const poiStringArray = poisString.split(",");
      for (const poiString of poiStringArray) {
        const poi = parseUrlPoi(poiString);
        if (poi) { this.currentRoute.pois.push(poi); }
      }
    }
    // help-parameter
    if (paramMap.has("help")) {
      const help = paramMap.get("help");
      // console.log("Maps:onRouteParamsChanged-help", help);
      if (help === "de") { this.userLanguage = "de"; }
      if (help !== "de") { this.userLanguage = "en"; }
      // console.log("Maps:onRouteQueryParamsChanged-userLanguage", this.userLanguage);
      this.isVisibleHelp = true;
    }
    // console.log("Maps:routeQueryParamsChanged_Ver200-end");

    // activate map
    await this.activateMap();
    // if (this.presentedRoute) { this.zoomToPublicRoute(this.presentedRoute); }

  }

  private async activateMap() {
    // console.log("Maps:activateMap");
    this.initMap();
    this.redrawSavedPlacesLocaly();
    this.redrawSavedRoutesLocaly();
    // console.log("Maps:activateMap-draw");
    this.drawMarkers();
    // calculate-route
    if (!this.currentRoute.roundRoute) {
      if (this.currentRoute.wayPoints.length >= 2) { await this.calculateNormalRoute(); }
      if (this.currentRoute.wayPoints.length < 2) {
        await this.redrawWayPoints();
      }
    }
    if (this.currentRoute.roundRoute) {
      if (this.currentRoute.wayPoints.length === 1) { await this.calculateRoundRoute(); }
    }
    this.drawRoutePois();
    // console.log("Maps:activateMap-latitude", this.latitude);
    // console.log("Maps:activateMap-zoom", this.zoom);
    if (this.isExtentInUrl) { this.setCenter(); }
    if (!this.isExtentInUrl) { this.zoomToMapData(); }
    // redraw track
    if (this.currentTourData) {
      this.redrawTrack();
      this.redrawTrackLocations();
      this.trackingLayer.setVisible(this.currentTourData.showTrack);
      this.trackingLocationLayer.setVisible(this.currentTourData.showTrack);
    }
    // console.log("Maps:activateMap-end!");
  }

  public ngOnDestroy(): void {
    // console.log("Maps:ngOnDestroy");
    this.destroyed.next();
    this.destroyed.complete();
    if (this.map) { this.map.setTarget(null); }
    this.map = undefined;
  }

  // get loggedInUser
  public async getLoggedInUser() {
    this.isNeedToLoginPopupVisible = false;
    this.loggedInUser = await this.globalService.getLoggedInUser();
    if (this.showLog) { console.log("Maps:getLoggedInUser-loggedInUser", this.loggedInUser); }
    // if (!this.loggedInUser) { return; }
    if (this.loggedInUser && this.loggedInUser.id === 1) {
      this.isTestUser = true;
      this.isUserAdmin = true;
      // §todo remove test
      // this.registerPeriodicSync();
    }
    // if (this.loggedInUser && this.loggedInUser.id === 15) {
    //   this.isTestUser = true;
    // }
    localStoreUserId(this.loggedInUser.id);
    localStoreTestUser(this.isTestUser);
  }

  public queryFileHandlingApi() {
    if ("launchQueue" in window && "LaunchParams" in window) {
      // console.log("Maps:queryFileHandlingApi-launchQueue is available", window);
      (window as any).launchQueue.setConsumer(async (launchParams) => {
        // console.log("Maps:queryFileHandlingApi-launchParams", launchParams);
        if (!launchParams.files.length) {
          // Nothing to do when the queue is empty.
          return;
        }
        for (const fileHandle of launchParams.files) {
          // Handle the file.
          // console.log("Maps:queryFileHandlingApi-fileHandle", fileHandle);
          const gpxName = fileHandle.name;
          // console.log("Maps:queryFileHandlingApi-gpxName", gpxName);
          const file = await fileHandle.getFile();
          // console.log("Maps:queryFileHandlingApi-file", file);
          this.initMap();
          const name = file.name;
          if (name.endsWith(".gpx")) {
            this.loadGpxFile(file);
            // return;
          }
          if (name.endsWith(".kml")) {
            this.loadKmlFile(file);
            // return;
          }
          this.onGPXRouteClick();
        }
      });
    }
  }

  private async getRouteParams() {
    if (this.showLog) { console.log("Maps:getRouteParams-route", this.route); }
    let languageCode: string;
    let routeId: string;
    if (this.route.snapshot.params) {
      const params = this.route.snapshot.params;
      // routeId
      if (params.routeId) {
        routeId = params.routeId;
      }
      // languageCode
      if (params.languageCode) {
        languageCode = params.languageCode;
        if (languageCode !== "de") { languageCode = "en"; }
        this.userLanguage = languageCode;
        this.translate.use(this.userLanguage);
        this.setUserLanguageText(this.userLanguage);
        if (this.showLog) { console.log("Maps:getRouteParams-userLanguage", this.userLanguage); }
      }
    }
    const queryParams = this.route.snapshot.queryParams;
    // console.log("Maps:getRouteParams-queryParams", queryParams);
    const queryParamRouteStyle = queryParams["routestyle"];
    if (queryParamRouteStyle) {
      this.drawRouteStyle = decodeUrlParamRouteStyle(queryParamRouteStyle);
      this.drawRouteColor = decodeUrlParamRouteColor(queryParamRouteStyle);
    }
    const queryParamWaypointStyle = queryParams["waypointstyle"];
    if (queryParamWaypointStyle) {
      this.drawWaypointStyle = decodeUrlParamWaypointStyle(queryParamWaypointStyle);
      this.drawWaypointSize = decodeUrlParamWaypointSize(queryParamWaypointStyle);
    }
    const queryParamActivate = queryParams["activate"];
    // console.log("Maps:getRouteParams-queryParamActivate", queryParamActivate);
    if (routeId) {
      const success = await this.loadPublicRouteById(routeId, languageCode);
      if (success) {
        if (queryParamActivate === "true") {
          setTimeout(() => {
            this.activatePublicRoute(this.presentedRoute);
          }, 500);
        }
        setTimeout(() => {
          this.zoomToPublicRoute(this.presentedRoute);
        }, 500);
      }
      const info = "routeId:" + routeId + ":" + this.presentedRoute.name;
      this.createUsageLog(EnumActionType.public_route_by_url, info);
    }
  }

  private async loadPublicRouteById(routeId: string, languageCode: string) {
    const success = await this.showPublicRouteById(routeId, languageCode);
    if (success) { return true; }
    this.publicRoutes = new Array<MapPublicRoute>();
    this.presentedRoute = {} as MapPublicRoute;
    this.presentedRoute.routeId = routeId;
    this.publicRoutes.push(this.presentedRoute);
    const url = "/route/" + languageCode + "/" + routeId;
    this.location.go(url);
    return false;
  }

  private async showPublicRouteById(routeId: string, languageCode: string) {
    if (this.showLog) { console.log("Maps:showPublicRouteById-routeId", routeId); }
    const result = await this.globalService.getPublicRouteByRouteId(routeId);
    if (result.status === EnumGlobalStatusCode.ObjectNotInDb) {
      console.log("Maps:showPublicRouteById-Route not found in Database");
      return false;
    };
    if (result.status !== EnumGlobalStatusCode.Success) { return false; }
    this.presentedRoute = result.route;
    this.publicRoutes = new Array<MapPublicRoute>();
    this.changePageTitleAndDescriptionToRoute(this.presentedRoute);
    if (this.presentedRoute) {
      this.publicRoutes.push(this.presentedRoute);
      const url = "/route/" + languageCode + "/" + routeId;
      this.location.go(url);
      return true;
    }
  }
  private changePageTitleAndDescriptionToRoute(route) {
    if (this.showLog) { console.log("Maps:changePageTitleAndDescriptionToRoute-route", route); }
    const plannerOptions = JSON.parse(route.plannerOptions);
    const moveType = plannerOptions.moveType;
    const moveTypeText = getRouteMoveTypeText(moveType, this.userLanguage);
    if (this.showLog) { console.log("Maps:changePageTitleAndDescriptionToRoute-userLanguage", this.userLanguage); }
    let title: string;
    if (this.userLanguage === "de") { title = route.nameDe; }
    if (this.userLanguage !== "en") { title = route.nameEn; }
    if (!title && route.nameDe) { title = route.nameDe; }
    if (!title && route.nameEn) { title = route.nameEn; }
    if (this.showLog) { console.log("Maps:changePageTitleAndDescriptionToRoute-title", title); }
    title += " | " + moveTypeText + " | " + "Trip4YouMaps";
    this.titleService.setTitle(title);
    let description = "Detailed route information about " + moveTypeText + ": " + route.name;
    if (this.userLanguage === "de") {
      description = "Detaillierte Routeninformation über " + moveTypeText + ": " + route.name;
    }
    const metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);
  }

  // save current-data when unloaded
  private saveCurrentDataToLocalStorage() {
    // tour-data
    localStoreTourData(this.currentTourData);
  }

  private loadLocalStorageData() {
    if (typeof (Storage) === "undefined") { return; }
    if (this.generalSettings) { return; }
    // console.log("Maps:loadLocalStorageData");

    try {
      // userId
      if (!this.loggedInUser) {
        this.userId = localLoadUserId();
      }
      // test-user
      this.isTestUser = localLoadTestUser();
    } catch (e) {
      console.error("Error during loading userId :", e.message);
    }

    try {
      // general settings
      this.generalSettings = localLoadGeneralSettings();
      this.doAutoMapMove = this.generalSettings.autoMapMove;
      this.doAutoMapRotation = this.generalSettings.autoMapRotation;
      this.showGeolocationInfo = this.generalSettings.showLocatorInfo;
      this.showMousePosition = this.generalSettings.showMousePosition;
      this.useWakeLock = this.generalSettings.useWakeLock;

      // route planner settings
      this.routePlannerSettings = localLoadRoutePlannerSettings();
      if (this.routePlannerSettings.moveType === "") {
        this.routePlannerSettings.moveType = "ebike";
        this.defaultPlannerSettings = true;
      }

      // route-format settings
      this.routeFormatSettings = localLoadRouteFormatSettings();
      // console.log("Maps:loadLocalStorageData-routeFormatSettings", this.routeFormatSettings);
      this.drawRouteStyle = this.routeFormatSettings.drawRouteStyle;
      this.drawRouteColor = this.routeFormatSettings.drawRouteColor;
      this.drawWaypointStyle = this.routeFormatSettings.drawWaypointStyle;
      this.drawWaypointSize = this.routeFormatSettings.drawWaypointSize;
      this.drawSteepness = this.routeFormatSettings.drawSteepness;

    } catch (e) {
      console.error("Error during loading settings :", e.message);
    }

    try {
      // saved-places
      this.mySavedPlacesLocaly = localLoadSavedPlaces(this.userLanguage);
      // console.log("Maps:loadLocalStorageData-mySavedPlaces", this.mySavedPlaces);
      // saved-routes
      this.mySavedRoutesLocaly = localLoadSavedRoutes(this.userLanguage);
      // console.log("Maps:loadLocalStorageData-mySavedRoutes", this.mySavedRoutes);

      // live-trackings
      this.liveTrackings = localLoadLiveTrackings(this.userLanguage);
      this.updateLiveTrackings();
      if (this.liveTrackings) {
        for (const tracking of this.liveTrackings) {
          this.addLiveTracking(tracking);
        }
      }
      this.currentLiveTrackingMe = localLoadLiveTrackingMe(this.userLanguage);
    } catch (e) {
      console.error("Error during loading saved routes and places :", e.message);
    }

    // current tourData
    try {
      this.currentTourData = localLoadTourData();
      // console.log("Maps:loadLocalStorageData-currentTourData", this.currentTourData);
      if (true) {
        if (this.currentTourData.isTourStarted) {
          // console.log("Maps:loadLocalStorageData-tourStarted", this.currentTourData.isTourStarted);
          this.createContinueTrackingPopup(this.userLanguage);
        }
      }
    } catch (e) {
      console.error("Error during loading tour data :", e.message);
    }
    // console.log("Maps:loadLocalStorageData-end");
  }
  private async loadSavedPlacesFromServer() {
    // console.log("Maps:loadSavedplacesFromServer");
    this.mySavedPlacesServer.splice(0);
    // load place-categories
    const result1 = await this.globalService.getPlaceCategories();
    if (result1.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result1, "getPlaceCategories");
      this.showApiError(apiErr);
      return;
    }
    const placeCategories = result1.placeCategories;
    if (placeCategories.length > 0) {
      for (const serverCat of placeCategories) {
        const lCat = convertSPlaceCatToLPlaceCat(serverCat);
        // console.log("Maps:loadSavedPlacesFromServer-lCat", lCat);
        this.mySavedPlacesServer.push(lCat);
      }
      // sort categories
      this.mySavedPlacesServer.sort((a, b) => {
        if (a.order > b.order) { return 1; }
        if (a.order < b.order) { return -1; }
      });
    }
    // load placess
    const result2 = await this.globalService.getPlaces();
    if (result2.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result2, "getPlaces");
      this.showApiError(apiErr);
      return;
    }
    const savedPlaces = result2.places;
    if (savedPlaces) {
      for (const sPlace of savedPlaces) {
        const cat = this.findPlaceCategoryServer(sPlace.categoryId);
        const lPlace = convertSPlaceToLPlace(sPlace);
        cat.placeItems.push(lPlace);
      }
    }
    // sort places
    for (const cat of this.mySavedPlacesServer) {
      cat.placeItems.sort((a, b) => {
        if (a.order > b.order) { return 1; }
        if (a.order < b.order) { return -1; }
      });
    }
    if (this.map) { this.redrawSavedPlacesServer(); }
  }
  private async loadSavedRoutesFromServer() {
    // console.log("Maps:loadSavedRoutesFromServer");
    this.mySavedRoutesServer.splice(0);
    // load route-categories
    const result1 = await this.globalService.getRouteCategories();
    if (result1.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result1, "getRouteCategories");
      this.showApiError(apiErr);
      return;
    }
    const routeCategories = result1.routeCategories;
    if (routeCategories.length > 0) {
      for (const serverCat of routeCategories) {
        const lCat = convertSRouteCatToLRouteCat(serverCat);
        // console.log("Maps:loadSavedRoutesFromServer-lCat", lCat);
        this.mySavedRoutesServer.push(lCat);
      }
      // sort categories
      this.mySavedRoutesServer.sort((a, b) => {
        if (a.order > b.order) { return 1; }
        if (a.order < b.order) { return -1; }
      });
    }
    // load routes
    const result2 = await this.globalService.getRoutes();
    if (result2.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result2, "getRoutes");
      this.showApiError(apiErr);
      return;
    }
    const savedRoutes = result2.routes;
    if (savedRoutes) {
      for (const route of savedRoutes) {
        const cat = this.findRouteCategoryServer(route.categoryId);
        const pRoute = convertSRouteToLRoute(route);
        cat.routeItems.push(pRoute);
      }
    }
    // sort routes
    for (const cat of this.mySavedRoutesServer) {
      cat.routeItems.sort((a, b) => {
        if (a.order > b.order) { return 1; }
        if (a.order < b.order) { return -1; }
      });
    }
    if (this.map) { this.redrawSavedRoutesServer(); }
  }
  private findRouteCategoryServer(categoryId: number) {
    for (const xCat of this.mySavedRoutesServer) {
      if (xCat.categoryId === categoryId) { return xCat; }
    }
    for (const xCat of this.mySavedRoutesServer) {
      if (xCat.type === "$remember") { return xCat; }
    }
    return undefined;
  }
  private findPlaceCategoryServer(categoryId: number) {
    for (const xCat of this.mySavedPlacesServer) {
      if (xCat.categoryId === categoryId) { return xCat; }
    }
    for (const xCat of this.mySavedPlacesServer) {
      if (xCat.type === "$remember") { return xCat; }
    }
    return undefined;
  }
  private setUserLanguageText(lang: string) {

    if (lang === "de") {
      this.textWaypoint = "Wegpunkt";
      this.textStart = "Start";
      this.textEnd = "Ziel";
      this.textNewPointTitle = "Wähle eine Funktion";
      this.textElevationPopupTitle = "Geländehöhe"
      this.textNamePopupTitle = "Gib einen Namen an";
      this.errorMessageHelpOffline = "Prüfe die Verbindung, du bist vielleicht offline. Klicke auf das Offline-Symbol in der Hauptmenüleiste"
    }
    if (lang !== "de") {
      this.textWaypoint = "Waypoint";
      this.textStart = "Start";
      this.textEnd = "End";
      this.textNewPointTitle = "Select a feature";
      this.textElevationPopupTitle = "Elevation"
      this.textNamePopupTitle = "Give a name";
      this.errorMessageHelpOffline = "Check the connection, you may be offline. Click on the offline symbol in the main menu bar"
    }
  }

  private scrollToMenu() {
    // this.pageElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  private updateDeviceDependency() {
    this.uiType = "L";
    this.mapPadding = this.mapPaddingL;
    if (this.currentDeviceType === "H-P" || this.currentDeviceType === "T-P") {
      this.uiType = "S";
      this.mapPadding = this.mapPaddingS;
    }
    if (this.currentDeviceType === "H-L") { this.mapPadding = this.mapPaddingS; }
  }
  private closeAllDialogs() {
    this.isVisibleSearchDialog = false;
    this.isVisibleRoutePlanner = false;
    this.isVisibleRouteDetails = false;
    this.isVisibleGPXRouteDialog = false;
    this.isVisibleGpxRouteDetails = false;
    this.isVisibleTrackingDialog = false;
    // this.isVisibleTrackDetails = false;
    this.isVisibleSavedItemsDialog = false;
    this.isVisibleSavedRouteDetails = false;
    this.isVisibleSearchRoutesDialog = false;
    this.isVisiblePresentedRouteDialog = false;
    this.isVisibleMapSelection = false;
    this.isVisibleSettings = false;
    this.isVisibleManageMaps = false;
    this.isVisibleHelp = false;
  }
  private updateDialogStatus() {
    this.isActiveDialog = false;
    if (this.isVisibleSearchDialog) { this.isActiveDialog = true; }
    if (this.isVisibleRoutePlanner) { this.isActiveDialog = true; }
    if (this.isVisiblePublishRouteDialog) { this.isActiveDialog = true; }
    if (this.isVisibleRouteDetails) { this.isActiveDialog = true; }
    if (this.isVisibleGPXRouteDialog) { this.isActiveDialog = true; }
    if (this.isVisibleGpxRouteDetails) { this.isActiveDialog = true; }
    if (this.isVisibleTrackingDialog) { this.isActiveDialog = true; }
    // if (this.isVisibleTrackDetails) { this.isActiveDialog = true; }
    if (this.isVisibleSavedItemsDialog) { this.isActiveDialog = true; }
    if (this.isVisibleSavedRouteDetails) { this.isActiveDialog = true; }
    if (this.isVisibleSearchRoutesDialog) { this.isActiveDialog = true; }
    if (this.isVisiblePresentedRouteDialog) { this.isActiveDialog = true; }
    if (this.isVisibleMapSelection) { this.isActiveDialog = true; }
    if (this.isVisibleSettings) { this.isActiveDialog = true; }
    if (this.isVisibleManageMaps) { this.isActiveDialog = true; }
    if (this.isVisibleHelp) { this.isActiveDialog = true; }
    if (this.isVisibleInfos) { this.isActiveDialog = true; }
    if (this.isVisibleAdmin) { this.isActiveDialog = true; }
    this.isVisibleMainDialog = !this.isActiveDialog;
  }

  private startDeviceOrientationListener() {
    if (window.DeviceOrientationEvent) { this.hasDeviceOrientation = true; }
    // console.log("Maps:startOrientationListener-hasDeviceOrientation", this.hasDeviceOrientation);

    window.addEventListener("deviceorientationabsolute", (event: DeviceOrientationEvent) => {
      // console.log("Test-----------:handleOrientationEvent-event:", event);
      this.orientationAbsolute = event.absolute;
      this.orientationAlpha = event.alpha;
      this.orientationBeta = event.beta;
      this.orientationGamma = event.gamma;
      if (event.alpha) {
        this.compassHeading = calculateCompassHeading(event.alpha, event.beta, event.gamma);
        if (this.arrowFeature && !this.isNavigationStarted) {
          this.currentHeading = 2.0 * Math.PI - this.orientationAlpha * Math.PI / 180.0;
          // this.currentCompassHeading = this.compassHeading * Math.PI / 180.0;
          this.createOrientationArrow(this.directionFeature, this.arrowFeature, this.currentHeading);
          // if (this.loggedInUser && this.loggedInUser.id === 1) {
          if (this.generalSettings.autoMapRotation2) {
            const rotation = this.currentHeading * -1.0;
            this.map.getView().setRotation(rotation);
          }
        }
      }
    }, true);
    // console.log("Maps:startOrientationAbsoluteListener:started");
  }


  // map-functions -----------------------------------------------------------------------------------------------------------------------
  public hideMap() {
    // console.log("Maps:hideMap-isVisibleMap", this.isVisibleMap);
    this.isVisibleMap = false;
    const mapContainerDiv = document.getElementById("mapContainer");
    mapContainerDiv.style.visibility = "hidden";
  }
  public showMap() {
    // console.log("Maps:showMap-isVisibleMap", this.isVisibleMap);
    this.isVisibleMap = true;
    const mapContainerDiv = document.getElementById("mapContainer");
    mapContainerDiv.style.visibility = "visible";
    // const sizeMap = this.map.getSize();
    // console.log("Maps:showMap-sizeMap", sizeMap);
  }
  // map - controls
  private switchOnOffMousePositionControl(on: boolean) {
    const element = (this.MousePositionControl as any).element;
    // console.log("Maps:setVisibilitySettingsDialog-element", element);
    const style = element.style;
    if (!on) { style.visibility = "hidden"; }
    if (on) { style.visibility = "visible"; }
    // style.color = "cornflowerblue";
  }
  public onLockScreenClick() {
    this.isScreenLocked = !this.isScreenLocked;
    // console.log("Maps:onLockScreenClick-isScreenLocked", this.isScreenLocked);
    let text = "";
    if (this.isScreenLocked) {
      this.waypointDragger.setActive(false);
      this.buttonLockScreen.style.backgroundColor = "yellow";
      this.buttonLockScreen.innerHTML = "<img width='30px'src='./assets/icons/lock-open-outline.svg'alt='symbol'>";
      text = "Allow map clicks";
      if (this.userLanguage === "de") { text = "Klicks auf Karte erlauben"; }
    } else {
      this.waypointDragger.setActive(true);
      this.closeAllPopups();
      this.buttonLockScreen.style.backgroundColor = "";
      this.buttonLockScreen.innerHTML = "<img width='30px'src='./assets/icons/lock-outline.svg'alt='symbol'>";
      text = "Prevent map clicks";
      if (this.userLanguage === "de") { text = "Klicks auf Karte verhindern"; }
    }
    this.buttonLockScreen.title = text;
  }

  public startMovingDistanceBar(event: PointerEvent) {
    console.log("Maps:startMovingDistanceBar-event", event);

  }

  // main-Dialog
  public onMenuButtonClick() {
    // console.log("Maps:onMainButtonClick");
  }
  public onMainSearchInputClick() {
    // console.log("Maps:onMainSearchInputClick-uiType", this.uiType);
    this.searchParameter.text = this.searchText;
    this.openSearchDialog();
    this.createUsageLog(EnumActionType.search_click, "");
  }
  public onMainRoutePlannerClick() {
    if (this.geojsonRoute || !this.selectedMapPoint) {
      this.onRoutePlannerClick();
      return;
    }
    if (this.selectedMapPoint) {
      this.onPlanNewRouteToSelectedPlaceClick();
      return;
    }
  }
  public showMainDialog() {
    // const mainDialogDiv = document.getElementById("mainDialog");
    // console.log("Maps:showMainDialog-mainDialogDiv", mainDialogDiv);
    // mainDialogDiv.style.visibility = "visible";
    this.isVisibleMainDialog = true;
  }
  public hideMainDialog() {
    // const mainDialogDiv = document.getElementById("mainDialog");
    // mainDialogDiv.style.visibility = "hidden";
    this.isVisibleMainDialog = false;
  }

  // map places -------------------------------------------------------------------------------------------------
  public async onShareSelectedPlaceClick() {
    // console.log("Maps:onShareSelectedPlaceClick-selectedMapPoint", this.selectedMapPoint);
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    this.isFeaturePopupLiveTrackingVisible = false;
    this.isFeaturePopupGeolocationVisible = false;
    const place = this.selectedMapPoint;
    this.sharePlace(place);
  }
  public sharePlace(place: MapPlaceL) {
    const flagsUrl = this.convertPlaceAsMarkerToUrl(place);
    const url = this.baseUrl + "ver=2.0&" + flagsUrl;
    const sharedData: ShareData = { url };
    sharedData.title = this.sharePlaceSubjectText + place.name;
    // sharedData.text = this.sharePlaceMsg1Text + "\r\r" + this.sharePlaceMsg2Text;
    sharedData.text = this.sharePlaceMsg2Text;
    navigator.share(sharedData);
  }
  public onAddSelectedPlaceToSavedPlacesClick(saveType: string) {
    // console.log("Maps:onAddSelectedPlaceToSavedPlacesClick", this.selectedMapPoint);
    this.closeAllPopups();
    this.isFeaturePopupLiveTrackingVisible = false;
    this.saveType = saveType;
    this.objectType = "favorite";
    this.savedPlaceName = this.selectedMapPoint.name;
    this.createObjectNameMapPopup();
  }
  public onAddSelectedMapPlaceToSavedPlaces() {
    // console.log("Maps:onAddSelectedMapPlaceToSavedPlaces", this.selectedMapPoint);
    this.isNamePopupVisible = false;
    this.selectedMapPoint.name = this.savedPlaceName;
    if (!this.savedPlaceName) { this.selectedMapPoint.name = "?"; }
    this.selectedMapPoint.id = createUid();

    if (this.saveType === "local") { this.onAddSelectedMapPlaceToSavedPlacesLocaly(); }
    if (this.saveType === "server") { this.onAddSelectedMapPlaceToSavedPlacesServer(); }
  }
  public onAddSelectedMapPlaceToSavedPlacesLocaly() {
    // console.log("Maps:onAddSelectedMapPlaceToSavedPlacesLocaly-selectedMapPoint", this.selectedMapPoint);
    let catIndex: number;
    for (const placeCat of this.mySavedPlacesLocaly) {
      if (placeCat.type === "$remember") { catIndex = this.mySavedPlacesLocaly.indexOf(placeCat); }
    }
    if (catIndex === undefined) { return; }
    this.mySavedPlacesLocaly[catIndex].placeItems.push(this.selectedMapPoint);
    this.redrawSavedPlacesLocaly();
    if (this.selectedMapPoint.type === "searchpoint") { this.onDeleteSearchPoint(); }
    this.storeSavedPlacesToLocalStorage();
  }
  public async onAddSelectedMapPlaceToSavedPlacesServer() {
    // console.log("Maps:onAddSelectedMapPlaceToSavedPlacesServer-selectedMapPoint", this.selectedMapPoint);
    let catIndex: number;
    for (const placeCat of this.mySavedPlacesServer) {
      if (placeCat.type === "$remember") { catIndex = this.mySavedPlacesServer.indexOf(placeCat); }
    }
    if (catIndex === undefined) {
      const lCat = createDefaultSavedPlaceCategoryRemember(this.userLanguage);
      const sCat = convertLPlaceCatToSPlaceCat(lCat);
      const result = await this.globalService.addPlaceCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = createApiError(result, "addPlaceCategory");
        this.showApiError(apiErr);
        return;
      }
      const addedCat = result.addedPlaceCategory;
      lCat.categoryId = addedCat.id;
      this.mySavedPlacesServer.push(lCat);
      catIndex = this.mySavedPlacesServer.indexOf(lCat);
    }
    const lPlace = this.selectedMapPoint;
    const sPlace = convertLPlaceToSPlace(lPlace, catIndex);
    sPlace.id = undefined;

    // console.log("Maps:onAddSelectedMapPlaceToSavedPlacesServer-sPlace", sPlace);
    const result = await this.globalService.addPlace(sPlace);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "addPlace");
      this.showApiError(apiErr);
      return;
    }
    const addedPlace = result.addedPlace;
    lPlace.id = addedPlace.id;
    lPlace.order = this.mySavedPlacesServer[catIndex].placeItems.length;
    // console.log("Maps:onAddSelectedMapPlaceToSavedPlacesServer-sPlace", sPlace);
    // console.log("Maps:onAddSelectedMapPlaceToSavedPlacesServer-lPlace", lPlace);
    this.mySavedPlacesServer[catIndex].placeItems.push(lPlace);
    this.redrawSavedPlacesServer();
    if (this.selectedMapPoint.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  private storeSavedPlacesToLocalStorage() {
    const value = JSON.stringify(this.mySavedPlacesLocaly);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_mySavedPlaces", value);
  }

  public onSelectedPlaceToMarkerClick() {
    // console.log("Maps:onSelectedPlaceToMarkerClick", this.selectedMapPoint);
    this.closeAllPopups();
    this.isFeaturePopupSearchPointVisible = false;
    this.objectType = "marker";
    this.markerName = this.selectedMapPoint.name;
    this.createObjectNameMapPopup();
  }
  public onConvertPlaceToMarker() {
    this.isNamePopupVisible = false;
    this.selectedMapPoint.type = "marker";
    if (!this.markerName) { this.markerName = "?"; }
    this.selectedMapPoint.name = this.markerName;
    this.markerPoints.push(this.selectedMapPoint);
    this.selectedMapPoint = {} as MapPlaceL;
    this.searchPointsSource.clear();
    this.drawMarkers();
    this.updateUrl();
    this.createUsageLog(EnumActionType.add_marker, "idOSM:" + this.selectedMapPoint.idOSM);
  }
  public async onPlanNewRouteFromCurrentPointClick() {
    // console.log("Maps:onPlanRouteFromSelectedPointClick");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    let place = {} as MapPlaceL;
    place.coordLon = this.longitude;
    place.coordLat = this.latitude;
    place.name = "?";
    this.isVisibleProgressSpinner = true;
    const result = await doORSReverseGeocode(this.http, this.lonLat);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.HttpError, result);
      // this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    const foundPlaces = result;
    if (foundPlaces && foundPlaces.length > 0) {
      if (foundPlaces[0].distance < 0.1) {
        place = foundPlaces[0];
        place.coordLon = this.longitude;
        place.coordLat = this.latitude;
      }
    }
    // create new route
    const moveType = this.currentRoute.plannerOptions.moveType;
    this.currentRoute = createPRoute();
    this.currentRoute.plannerOptions.moveType = moveType;
    this.planRouteFromMapPlace(place);
  }
  public async onPlanNewRouteToCurrentPointClick() {
    // console.log("Maps:onPlanRouteToSelectedPointClick");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    let place = {} as MapPlaceL;
    place.coordLon = this.longitude;
    place.coordLat = this.latitude;
    place.name = "?";
    this.isVisibleProgressSpinner = true;
    const result = await doORSReverseGeocode(this.http, this.lonLat);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.HttpError, result);
      // this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    const foundPlaces = result;
    if (foundPlaces && foundPlaces.length > 0) {
      if (foundPlaces[0].distance < 0.1) {
        place = foundPlaces[0];
        place.coordLon = this.longitude;
        place.coordLat = this.latitude;
      }
    }
    // create new route
    const moveType = this.currentRoute.plannerOptions.moveType;
    this.currentRoute = createPRoute();
    this.currentRoute.plannerOptions.moveType = moveType;
    this.planRouteToMapPlace(place);
    const info = "rev-geocode:" + this.lonLat.lng.toString() + "," + this.lonLat.lat.toString();
    this.createUsageLog(EnumActionType.call_ors_reversegeocode, info);
  }
  public async onPlanNewRouteFromSelectedPlaceClick() {
    console.log("Maps:onPlanRouteFromSelectedPlaceClick-selectedMapPoint", this.selectedMapPoint);
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    if (this.isVisibleSearchDialog) { this.onSearchBack(); }
    if (this.isVisibleSavedItemsDialog) { this.onSavedItemsBack(); }
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    const place = this.selectedMapPoint;
    // create new route
    const moveType = this.currentRoute.plannerOptions.moveType;
    this.currentRoute = createPRoute();
    this.currentRoute.plannerOptions.moveType = moveType;
    this.planRouteFromMapPlace(place);
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  public async onPlanNewRouteToSelectedPlaceClick() {
    console.log("Maps:onPlanRouteToSelectedPlaceClick-selectedMapPoint", this.selectedMapPoint);
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    if (this.isVisibleSearchDialog) { this.onSearchBack(); }
    if (this.isVisibleSavedItemsDialog) { this.onSavedItemsBack(); }
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    const place = this.selectedMapPoint;
    // create new route
    const moveType = this.currentRoute.plannerOptions.moveType;
    this.currentRoute = createPRoute();
    this.currentRoute.plannerOptions.moveType = moveType;
    this.planRouteToMapPlace(place);
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  public async onSetSelectedMapPlaceToStartPositionClick() {
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    const place = this.selectedMapPoint;
    this.setMapPlaceToStartPosition(place);
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  public async onSetSelectedMapPlaceToEndPositionClick() {
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    const place = this.selectedMapPoint;
    this.setMapPlaceToEndPosition(place);
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  private planRouteFromMapPlace(place: MapPlaceL) {
    const moveType = this.currentRoute.plannerOptions.moveType;
    console.log("Maps:planRouteFromPlace-place", place);
    // this.currentRoute = createPRoute();
    // this.currentRoute.plannerOptions.moveType = moveType;
    // start-point
    const startPoint = {} as MapPlaceL;
    startPoint.coordLon = place.coordLon;
    startPoint.coordLat = place.coordLat;
    startPoint.name = place.name;
    this.currentRoute.wayPoints.push(startPoint);
    this.isStartPointDefined = true;
    // end-point
    const endPoint = {} as MapPlaceL;
    this.currentRoute.wayPoints.push(endPoint);
    this.isEndPointDefined = false;
    // console.log("Maps:planNewRouteFromPlace-currentRoute", this.currentRoute);
    // console.log("Maps:planNewRouteFromPlace-isEndPointDefined", this.isEndPointDefined);
    this.calculateRoute();
    this.updateUrl();
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  private planRouteToMapPlace(place: MapPlaceL) {
    const moveType = this.currentRoute.plannerOptions.moveType;
    console.log("Maps:planRouteToPlace-place", place);
    // this.currentRoute = createPRoute();
    // this.currentRoute.plannerOptions.moveType = moveType;
    // start-way-point
    const startPoint = this.createWaypointToCurrentLocation();
    if (!startPoint) { return; }
    this.currentRoute.wayPoints.push(startPoint);
    this.isStartPointDefined = true;
    // end-point
    const endPoint = {} as MapPlaceL;
    endPoint.coordLon = place.coordLon;
    endPoint.coordLat = place.coordLat;
    endPoint.name = place.name;
    this.currentRoute.wayPoints.push(endPoint);
    this.isEndPointDefined = true;
    // this.onAutoSetRoutePlannerDialog();
    this.calculateRoute();
    this.updateUrl();
    if (place.type === "searchpoint") { this.onDeleteSearchPoint(); }
  }
  public async setMapPlaceToStartPosition(place: MapPlaceL) {
    const wayPoints = this.currentRoute.wayPoints;
    wayPoints[0] = place;
    this.calculateRoute();
    this.updateUrl();
  }
  public async setMapPlaceToEndPosition(place: MapPlaceL) {
    const wayPoints = this.currentRoute.wayPoints;
    wayPoints[wayPoints.length - 1] = place;
    this.calculateRoute();
    this.updateUrl();
  }

  private createWaypointToCurrentLocation() {
    if (!this.currentPositionLngLat) { return undefined; }
    const wp = {} as MapPlaceL;
    wp.coordLon = this.currentPositionLngLat.lng;
    wp.coordLat = this.currentPositionLngLat.lat;
    wp.type = "$current";
    wp.name = "Current location";
    if (this.userLanguage === "de") { wp.name = "Aktueller Standort"; }
    return wp;
  }
  public onAddSelectedMapPlaceToCurrentRouteClick() {
    console.log("Maps:onAddSelectedMapPlaceToCurrentRouteClick-selectedMapPoint", this.selectedMapPoint);
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    this.addMapPlaceToCurrentRoute(this.selectedMapPoint);
  }
  private addMapPlaceToCurrentRoute(place: MapPlaceL) {
    this.createUsageLog(EnumActionType.add_route_poi, "idOSM:" + place.idOSM);
    if (place.type === "searchpoint") {
      place.type = "poi";
      // place.cat = "searchpoint";
      this.currentRoute.pois.push(place);
      this.drawRoutePois();
      this.clearSearchPoints();
      this.searchPoint = undefined;
      this.selectedMapPoint = undefined;
      return;
    }
    if (place.type === "markerpoint") {
      place.type = "poi";
      this.currentRoute.pois.push(place);
      this.drawRoutePois();
      this.clearSearchPoints();
      this.selectedMapPoint = undefined;
      return;
    }
    if (place.type === "savedplace-l" || place.type === "savedplace-s") {
      place.type = "poi";
      this.currentRoute.pois.push(place);
      this.drawRoutePois();
      return;
    }
    this.currentRoute.pois.push(place);
    this.drawRoutePois();
    this.updateUrl();
    // console.log("Maps:addPlaceToCurrentRoute-currentRoute", this.currentRoute);
  }
  public onRemoveMapPlaceFromCurrentRouteClick() {
    // console.log("Maps:onRemoveMapPlaceFromCurrentRouteClick-selectedMapPoint", this.selectedMapPoint);
    if (!this.selectedMapPoint) { return; }
    this.closeAllPopups();
    this.removePlaceFromCurrentRoute(this.selectedMapPoint);
  }
  private removePlaceFromCurrentRoute(place: MapPlaceL) {
    // console.log("Maps:removeMapPlaceFromCurrentRoute-place", place);
    const index = place.id;
    this.currentRoute.pois.splice(index, 1);
    this.drawRoutePois();
    this.updateUrl();
    // console.log("Maps:removePlaceFromCurrentRoute-currentRoute", this.currentRoute);
  }
  public onRemoveAllRoutePoisClick() {
    this.closeAllPopups();
    this.currentRoute.pois.splice(0);
    this.drawRoutePois();
    this.updateUrl();
  }
  public async onPlanRoundRouteFromSelectedPointClick() {
    // console.log("Maps:onPlanRoundRouteFromSelectedPointClick");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    let place = {} as MapPlaceL;
    place.coordLon = this.longitude;
    place.coordLat = this.latitude;
    place.name = "?";
    this.isVisibleProgressSpinner = true;
    const result = await doORSReverseGeocode(this.http, this.lonLat);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    const foundPlaces = result;
    if (foundPlaces && foundPlaces.length > 0) {
      if (foundPlaces[0].distance < 0.1) {
        place = foundPlaces[0];
        place.coordLon = this.longitude;
        place.coordLat = this.latitude;
      }
    }
    this.planNewRoundRouteFromPlace(place);

    const info = "rev-geocode:" + this.lonLat.lng.toString() + "," + this.lonLat.lat.toString();
    this.createUsageLog(EnumActionType.call_ors_reversegeocode, info);
  }
  private planNewRoundRouteFromPlace(place: MapPlaceL) {
    // console.log("Maps:planNewRoundRouteFromPlace-place", place);
    const moveType = this.currentRoute.plannerOptions.moveType;
    this.currentRoute = createPRoute();
    this.currentRoute.roundRoute = true;
    this.currentRoute.plannerOptions.moveType = moveType;
    // start-way-point
    const startPoint = {} as MapPlaceL;
    startPoint.coordLon = place.coordLon;
    startPoint.coordLat = place.coordLat;
    startPoint.name = place.name;
    this.currentRoute.wayPoints.splice(0);
    this.currentRoute.pois.splice(0);
    this.currentRoute.wayPoints.push(startPoint);
    this.onAutoSetRoutePlannerDialog();
    if (this.drawRouteStyle === "surface-type" || this.drawRouteStyle === "way-type") { this.drawRouteStyle = "solid"; }
    this.calculateRoundRoute();
    this.updateUrl();
  }


  // search-Dialog --------------------------------------------------------------------------------------------------
  public async openSearchDialog() {
    // console.log("Maps:openSearchDialog");
    this.mapRef = this.map;    // set mapRef for using map-extent as search option
    this.closeAllDialogs();
    this.checkIfNetworkAccessOk();
    if (!this.isOnline) {
      this.createErrorMapPopup();
      return;
    }
    this.hideMainDialog();
    if (this.uiType === "S") {
      this.currentMapExtent = getTransformedViewExtent(this.map);
      this.hideMap();
    }
    this.showSearchDialog();
    setTimeout(() => { this.map.updateSize(); });
  }
  public showSearchDialog() {
    this.isVisibleSearchDialog = true;
    this.isActiveDialog = true;
  }
  public hideSearchDialog() {
    this.isVisibleSearchDialog = false;
  }
  public onSearchBack() {
    // console.log("Maps:onSearchBack");
    this.hideSearchDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onSelectSearchResult(mapPlace: MapPlaceL) {
    // console.log("Maps:onSelectSearchResult-mapPlace", mapPlace);
    const selectedRefPlace = mapPlace;
    this.searchText = mapPlace.name;
    this.onSearchBack();
    this.onNewSearchPoint(selectedRefPlace);
    this.selectedMapPoint = ({} as MapPlaceL);
    this.selectedMapPoint.id = mapPlace.id;
    this.selectedMapPoint.idOSM = mapPlace.idOSM;
    this.selectedMapPoint.name = mapPlace.name;
    this.selectedMapPoint.coordLon = mapPlace.coordLon;
    this.selectedMapPoint.coordLat = mapPlace.coordLat;
    this.selectedMapPoint.type = "searchpoint";
  }

  public onRouteToSearchResult(mapPlace: MapPlaceL) {
    // console.log("Maps:onRouteToSearchResult-mapPlace", mapPlace);
    const selectedRefPlace = mapPlace;
    this.searchText = mapPlace.name;
    this.onSearchBack();
    this.onNewSearchPoint(selectedRefPlace);
    this.selectedMapPoint = ({} as MapPlaceL);
    this.selectedMapPoint.id = mapPlace.id;
    this.selectedMapPoint.idOSM = mapPlace.idOSM;
    this.selectedMapPoint.name = mapPlace.name;
    this.selectedMapPoint.coordLon = mapPlace.coordLon;
    this.selectedMapPoint.coordLat = mapPlace.coordLat;
    this.selectedMapPoint.type = "searchpoint";
    this.onPlanNewRouteToSelectedPlaceClick();
  }

  public onNewSearchPoint(selectedRefPlace: MapPlaceL) {
    // console.log("Maps:onNewSearchPoint-selectedRefPlace", selectedRefPlace);
    this.drawSearchPoint(this.searchPointsSource, selectedRefPlace);
    const coord = {} as LngLat;
    coord.lng = selectedRefPlace.coordLon;
    coord.lat = selectedRefPlace.coordLat;
    const view = this.map.getView();
    // view.setCenter(fromLonLat([coord.lng, coord.lat], "EPSG:3857", "EPSG:4326"));
    view.setCenter(fromLonLat([coord.lng, coord.lat], "EPSG:3857"));
  }

  public onCreateSearchPointOnMap() {
    this.createSearchPointOnMap = true;
    this.mapElement.nativeElement.style.cursor = "crosshair";
    if (this.uiType === "S") { this.onSearchBack(); }
  }
  public onDeleteSearchPoint() {
    // console.log("Maps:onDeleteSearchPoint-id", id);
    this.clearSearchPoints();
    this.searchPoint = undefined;
    this.selectedMapPoint = undefined;
    this.closeAllPopups();
  }
  public clearSearchPoints() {
    this.searchPointsSource.clear();
  }

  // markers -------------------------------------------------------------------------------------------------------------
  public newMarkerPointOnMap() {
    // console.log("Maps:newMarkerPointOnMap");
    this.isNewPointPopupVisible = false;
    const markerPoint = {} as MapPlaceL;
    markerPoint.type = "marker";
    markerPoint.id = -1;
    markerPoint.coordLon = this.lonLat.lng;
    markerPoint.coordLat = this.lonLat.lat;
    this.selectedMapPoint = markerPoint;
    this.markerName = "";
    this.objectType = "marker";
    this.createObjectNameMapPopup();
  }
  public onRemoveMarker(place: MapPlaceL) {
    // console.log("Maps:onRemoveMarker-place", place);
    this.markerPoints.splice(place.id, 1);
    this.isFeaturePopupMarkerVisible = false;
    this.updateUrl();
    this.drawMarkers();
  }
  private drawMarkers() {
    // console.log("Maps:drawMarkers-markerPoints", this.markerPoints);
    if (!this.markerPoints) { return; }
    this.markerPointsSource.clear();
    for (const markerPoint of this.markerPoints) {
      const id = this.markerPoints.indexOf(markerPoint);
      markerPoint.id = id;
      this.drawMarker(this.markerPointsSource, markerPoint);
    }
  }
  public drawMarker(source: VectorSource<any>, place: MapPlaceL) {

    // coordinates
    const coords = {} as LngLat;
    coords.lng = place.coordLon;
    coords.lat = place.coordLat;

    // create feature
    const markerFeature = new Feature({
      geometry: new Point(transform([coords.lng, coords.lat], "EPSG:4326", "EPSG:3857")),
      id: place.id,
      idOSM: place.idOSM,
      type: place.type,
      cat: place.cat,
      // lon: coords.lng,
      // lat: coords.lat,
      name: place.name,
    });

    if (!place.cat) { place.cat = "marker"; }
    // style marker
    if (place.cat === "marker") {
      const markerStyle = new Style({
        image: new Icon({
          anchor: [0.25, 28],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.95,
          src: "./assets/icons/flag-variant_dodgerblue.svg",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        text: new Text({
          text: place.name,
          offsetX: 0,
          offsetY: 10,
        }),
      });
      markerFeature.setStyle(markerStyle);
    }

    if (place.cat === "loc-single" || place.cat === "loc-track") {
      const locatorStyle = new Style({
        image: new Icon({
          scale: 2,
          anchor: [0.5, 22],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.95,
          src: "./assets/icons/map-marker-red.svg",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        text: new Text({
          text: place.name,
          offsetX: 0,
          offsetY: 10,
        }),
      });
      markerFeature.setStyle(locatorStyle);
    }



    // console.log("Maps:drawMarker-markerFeature", markerFeature);
    source.addFeature(markerFeature);
  }

  // saved places ------------------------------------------------------------------------------------------------------------------------
  public newSavePointOnMap(saveType: string) {
    // console.log("Maps:newSavePlaceOnMap-saveType", saveType);
    this.isNewPointPopupVisible = false;
    const savePoint = {} as MapPlaceL;
    if (saveType === "local") { savePoint.type = "savedplace-l"; }
    if (saveType === "server") { savePoint.type = "savedplace-s"; }
    savePoint.id = -1;
    savePoint.coordLon = this.lonLat.lng;
    savePoint.coordLat = this.lonLat.lat;
    this.selectedMapPoint = savePoint;
    this.savedPlaceName = "";
    this.objectType = "favorite";
    this.saveType = saveType;
    this.createObjectNameMapPopup();
  }

  // menu-functions ----------------------------------------------------------------------------------------------------------------------
  // route-planner -----------------------------------------------------------------------------------------------------------------------
  public async onRoutePlannerClick() {
    // console.log("Maps:onRoutePlannerClick-currentRoute", this.currentRoute);
    // clear spinner
    this.isVisibleProgressSpinner = false;
    // if startpoint not defined, set startpoint to current location
    // createDefaultWayPoints(this.currentPositionLngLat);
    const wpStart = this.currentRoute.wayPoints[0];
    if (!wpStart.coordLon && this.currentPositionLngLat) {
      const wpX = createWayPointAtCurrentLocation(this.currentPositionLngLat, this.userLanguage);
      this.currentRoute.wayPoints[0] = wpX;
    }
    this.mapRef = this.map;     // set mapRef for using map-extent as search option
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showRoutePlannerDialog();
    setTimeout(() => { this.map.updateSize(); });
    this.createUsageLog(EnumActionType.planner_click, "");
  }
  public async showRoutePlannerDialog() {
    // variables for publish route
    this.isCurrentRoutePublic = this.testCurrentRouteIsPublic();
    if (this.pubRoute && this.currentRoute.routeId !== this.pubRoute.routeId) { this.pubRoute = undefined; }
    if (!this.pubRoute) { this.pubRoute = {} as MapPublicRoute; }
    this.testCurrentRouteIdIsPublished();

    this.isVisibleRoutePlanner = true;
    this.isActiveDialog = true;
  }
  public hideRoutePlannerDialog() {
    this.isVisibleRoutePlanner = false;
  }
  private initRoute() {
    this.plannerPhase = 0;
    this.currentRoute = createPRoute();
    this.currentRoute.wayPoints = createDefaultWayPoints();
    this.currentRoute.plannerOptions.moveType = this.routePlannerSettings.moveType;
  }
  private updatePlannerPhase() {
    this.plannerPhase = 0;
    const wayPoints = this.currentRoute.wayPoints;
    this.isStartPointDefined = false;
    if (wayPoints[0].coordLon) { this.isStartPointDefined = true; }
    this.isEndPointDefined = false;
    const lastIndex = wayPoints.length - 1;
    if (wayPoints[lastIndex].coordLon) { this.isEndPointDefined = true; }
    if (this.isStartPointDefined) { this.plannerPhase = 1; }
    if (this.isEndPointDefined) { this.plannerPhase = 2; }
    if (this.isStartPointDefined && this.isEndPointDefined) { this.plannerPhase = 3; }
    // console.log("Maps:updatePlannerPhase-plannerPhase", this.plannerPhase);
  }
  private testCurrentRouteIsPublic() {
    // console.log("Maps:testCurrentRouteIsPublic-testCurrentRouteIsPublic", this.pubRoute);
    // test if route is public
    let isCurrentRoutePublic = true;
    if (!this.pubRoute) { isCurrentRoutePublic = false; }
    if (this.pubRoute) {
      if (this.pubRoute.routeId !== this.currentRoute.routeId) { isCurrentRoutePublic = false; }
      if (this.pubRoute.id !== this.currentRoute.id) { isCurrentRoutePublic = false; }
      if (!this.pubRoute.createdAt) { isCurrentRoutePublic = false; }
    }
    return isCurrentRoutePublic;
  }
  private async testCurrentRouteIdIsPublished() {
    // test, if routeId is already published
    if (this.loggedInUser) {
      this.isCurrentRouteIdPublished = false;
      const result = await this.globalService.existsPublicRoute(this.currentRoute.routeId);
      if (result.status === EnumGlobalStatusCode.Success) {
        this.isCurrentRouteIdPublished = result.existent;
        return;
      }
      if (result.status === EnumGlobalStatusCode.UserNotLoggedin) {
        this.loggedInUser = undefined;
      }
    }
  }

  public onRoutePlannerBack() {
    // console.log("Maps:onRoutePlannerBack");
    this.hideRoutePlannerDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  public async onRouteNameKeyUp(event: KeyboardEvent) {
    // console.log("Maps:onRouteNameKeyUp", event);
    if (event.key === "Enter") {
      this.updateUrl();
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public onRouteNameBlur() {
    // console.log("Maps:onRouteNameBlur-routeName", this.currentRoute.name);
    this.updateUrl();
  }
  public onSelectedWayPointForMapChange(index: number) {
    // console.log("Maps:onSelectedWayPointForMapChange-index", index);
    this.mapElement.nativeElement.style.cursor = "";
    this.selectedWayPointForMapIndex = index;
    this.savedWayPointIndex = index;
    this.isWayPointStartPoint = index === 0;
    this.isWayPointEndPoint = false;
    if (index === this.currentRoute.wayPoints.length - 1 && !this.currentRoute.backToStart) { this.isWayPointEndPoint = true; }
    if (this.currentRoute.roundRoute) { this.isWayPointEndPoint = false; }
    if (index === -1) { return; }
    if (this.uiType === "S") { this.onRoutePlannerBack(); }
  }
  public onDeleteWayPoint(id: number) {
    // console.log("Maps:onDeleteWayPoint-id", id);
    // console.log("Maps:onDeleteWayPoint-routeWayPoints", this.currentRoute.wayPoints);
    this.currentRoute.wayPoints.splice(id, 1);
    this.onAutoSetRoutePlannerDialog();
    this.isFeaturePopupWPVisible = false;
    this.calculateRoute();
    this.updateUrl();
  }
  public async setStartWayPointToMapPosition() {
    // console.log("Maps:setStartWayPointToMapPosition");
    this.isNewPointPopupVisible = false;
    this.closeAllDialogs();
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    this.wayPointPositionOnMap = this.lonLat;
    // this.createWayPointOnMap = false;
    this.setWayPointIndexToMapPosition(0);
    this.updatePlannerPhase();
  }
  public async setSelectedWayPointToMapPosition() {
    // console.log("Maps:setSelectedWayPointToMapPosition");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    this.wayPointPositionOnMap = this.lonLat;
    const index = this.selectedWayPointForMapIndex;
    this.setWayPointIndexToMapPosition(index);
    this.updatePlannerPhase();
  }
  public async setEndWayPointToMapPosition() {
    // console.log("Maps:setEndWayPointToMapPosition");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    this.wayPointPositionOnMap = this.lonLat;
    const index = this.currentRoute.wayPoints.length - 1;
    this.setWayPointIndexToMapPosition(index);
    this.updatePlannerPhase();
  }
  public async setAdditionalWayPointToMapPosition() {
    // console.log("Maps:setAdditionalWayPointToMapPosition");
    this.isNewPointPopupVisible = false;
    if (this.uiType === "L") {
      this.showRoutePlannerDialog();
      this.hideMainDialog();
    }
    this.wayPointPositionOnMap = this.lonLat;

    const indexLast = this.currentRoute.wayPoints.length - 1;
    let add = false;
    if (this.currentRoute.wayPoints[indexLast].coordLon) { add = true; }

    // insert after end-point
    if (add) {
      const wp = {} as MapPlaceL;
      wp.type = "waypoint";
      wp.name = "";
      this.currentRoute.wayPoints.push(wp);
      const index = this.currentRoute.wayPoints.length - 1;
      this.setWayPointIndexToMapPosition(index);
    }
    // insert before of end-point
    if (!add) {
      // save end-point
      const wpLast = this.currentRoute.wayPoints[indexLast];
      // remove end-point from array
      this.currentRoute.wayPoints.splice(indexLast, 1);
      const wp = {} as MapPlaceL;
      wp.type = "waypoint";
      wp.name = "";
      // add new point
      this.currentRoute.wayPoints.push(wp);
      const index = this.currentRoute.wayPoints.length - 1;
      this.setWayPointIndexToMapPosition(index);
      this.currentRoute.wayPoints.push(wpLast);
    }
    this.updatePlannerPhase();
  }
  private async setWayPointIndexToMapPosition(index: number) {
    // console.log("Maps:setWayPointIndexToMapPosition-index", index);
    this.currentRoute.wayPoints[index].coordLon = this.lonLat.lng;
    this.currentRoute.wayPoints[index].coordLat = this.lonLat.lat;
    this.currentRoute.wayPoints[index].name = this.textWaypoint;
    this.isVisibleProgressSpinner = true;
    const result = await doORSReverseGeocode(this.http, this.lonLat);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.HttpError, result);
      // this.checkIfNetworkAccessOk();
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    const foundPlaces = result;
    if (foundPlaces && foundPlaces.length > 0) {
      if (foundPlaces[0].distance < 0.1) { this.currentRoute.wayPoints[index] = foundPlaces[0]; }
      this.currentRoute.wayPoints[index].coordLon = this.lonLat.lng;
      this.currentRoute.wayPoints[index].coordLat = this.lonLat.lat;
    }
    this.onAutoSetRoutePlannerDialog();
    this.calculateRoute();
    this.updateUrl();
    this.selectedWayPointForMapIndex = -1;
    this.redrawWayPoints();

    const info = "rev-geocode:" + this.lonLat.lng.toString() + "," + this.lonLat.lat.toString();
    this.createUsageLog(EnumActionType.call_ors_reversegeocode, info);
  }

  public onSetRoutePlannerMoveType(moveType: string) {
    // console.log("Maps:onSetRoutePlannerMoveType-moveType", moveType);
    this.currentRoute.plannerOptions.moveType = moveType;
    this.iconMoveTypeSource = getMovetypeIconSource(moveType);
    // this.onAutoSetRoutePlannerDialog();
    this.calculateRoute();
    this.updateUrl();
  }
  public onBackToStartChange() {
    // console.log("Maps:onRoundTripChange-backToStart", this.currentRoute.backToStart);
    // this.onAutoSetRoutePlannerDialog();
    this.calculateRoute();
    this.updateUrl();
  }
  public onAutoSetRoutePlannerDialog() {
    // console.log("Maps:onAutoSetRoutePlannerDialog-routeWayPoints", this.currentRoute.wayPoints);
    if (this.uiType === "S") {
      this.onRoutePlannerBack();
    }
    if (this.uiType === "L" && !this.isVisibleRoutePlanner) {
      // this.onRoutePlannerClick();
    }
  }

  public onRecalculateRoute(updateUrl: boolean) {
    // console.log("Maps:onRecalculateRoute-currentRoute", this.currentRoute);
    this.calculateRoute();
    if (updateUrl) { this.updateUrl(); }
    // this.onAutoSetRoutePlannerDialog();
  }
  public async calculateRoute() {
    if (!this.currentRoute.roundRoute) { await this.calculateNormalRoute(); }
    if (this.currentRoute.roundRoute) { await this.calculateRoundRoute(); }
  }
  public async calculateNormalRoute() {
    // console.log("Maps:calculateNormalRoute-currentRoute", this.currentRoute);
    // test, if minimum 2 waypoints with corrd exist
    let countWayPoints = 0;
    for (const wp of this.currentRoute.wayPoints) {
      if (wp.coordLon) { countWayPoints++; }
    }
    if (countWayPoints < 2) {
      this.geojsonRoute = undefined;
      const routeSource = this.routePlannerRouteLayer.getSource();
      if (routeSource) { routeSource.clear(); }
      this.redrawWayPoints();
      return;
    }
    const plannerSettings = {} as RoutePlannerOptions;
    plannerSettings.moveType = this.currentRoute.plannerOptions.moveType;
    plannerSettings.avoidSteps = this.currentRoute.plannerOptions.avoidSteps;
    plannerSettings.avoidTollways = this.currentRoute.plannerOptions.avoidTollways;
    plannerSettings.avoidHighways = this.currentRoute.plannerOptions.avoidHighways;
    this.isVisibleProgressSpinner = true;
    const result = await doORSCalculateRoute(this.http, this.currentRoute.wayPoints, this.currentRoute.backToStart, plannerSettings, this.userLanguage);
    this.isVisibleProgressSpinner = false;
    // console.log("Maps:calculateNormalRoute-result", result);
    if (result.type === "http-error") {
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("calculate route", EnumGlobalStatusCode.HttpError, result);
      // this.checkIfNetworkAccessOk();
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.error.error.message;
      this.showORSRequestError("calculate route", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    this.geojsonRoute = result;
    this.currentRoute.geojsonRoute = this.geojsonRoute;
    if (this.showLog) { console.log("Maps:onCalculateNormalRoute-geojsonRoute", this.geojsonRoute); }

    // route calculated
    this.orsPostErrorText = "";
    const feature = this.geojsonRoute.features[0];
    this.routeDistance = Math.round(feature.properties.summary.distance);
    this.routeDuration = Math.round(feature.properties.summary.duration);
    this.routeDurationHour = Math.floor(this.routeDuration / 3600);
    this.routeDurationMinute = Math.floor(this.routeDuration / 60 - this.routeDurationHour * 60);
    const currentTime = new Date(Date.now());
    this.estimatedArrivalTime = new Date(currentTime.getTime() + this.routeDuration * 1000);
    this.routePolyline = this.getRoutePolyline();

    // update missing extra
    this.updateRouteExtras(feature);

    // draw route
    this.drawPlannedRoute();

    const info = this.convertRouteToUrl();
    this.createUsageLog(EnumActionType.call_ors_direction, info);
  }
  public async calculateRoundRoute() {
    // console.log("Maps:calculateRoundRoute-currentRoute", this.currentRoute);
    // test, if minimum 1 waypoints with corrd exist
    let countWayPoints = 0;
    for (const wp of this.currentRoute.wayPoints) {
      if (wp.coordLon) { countWayPoints++; }
    }
    if (countWayPoints < 1) {
      this.geojsonRoute = undefined;
      const routeSource = this.routePlannerRouteLayer.getSource();
      if (routeSource) { routeSource.clear(); }
      this.redrawWayPoints();
      return;
    }
    // default options
    if (!this.currentRoute.plannerOptions.roundRouteLength) {
      let roundRouteLength = this.roundRouteLengthBike;
      if (this.currentRoute.plannerOptions.moveType === "walking") { roundRouteLength = this.roundRouteLengthFoot; }
      if (this.currentRoute.plannerOptions.moveType === "hiking") { roundRouteLength = this.roundRouteLengthFoot; }
      this.currentRoute.plannerOptions.roundRouteLength = roundRouteLength;
      this.currentRoute.plannerOptions.roundRoutePoints = 5;
      this.currentRoute.plannerOptions.roundRouteSeed = 1;
    }
    const settings = {} as RoutePlannerOptions;
    settings.moveType = this.currentRoute.plannerOptions.moveType;
    settings.avoidSteps = this.currentRoute.plannerOptions.avoidSteps;
    settings.avoidTollways = this.currentRoute.plannerOptions.avoidTollways;
    settings.avoidHighways = this.currentRoute.plannerOptions.avoidHighways;
    settings.roundRouteLength = this.currentRoute.plannerOptions.roundRouteLength;
    settings.roundRouteLength = this.currentRoute.plannerOptions.roundRouteLength;
    settings.roundRoutePoints = this.currentRoute.plannerOptions.roundRoutePoints;
    settings.roundRouteSeed = this.currentRoute.plannerOptions.roundRouteSeed;
    this.isVisibleProgressSpinner = true;
    const result = await doORSCalculateRoundRoute(this.http, this.currentRoute.wayPoints, settings, this.userLanguage);
    this.isVisibleProgressSpinner = false;
    // console.log("utils_map_ors:calculateRoundRoute-result", result);
    if (result.type === "http-error") {
      // console.log("utils_map_ors:calculateRoundRoute-http-post-error", result);
      if (this.userLanguage === "de") { this.orsPostErrorText = "Internetverbindung prüfen!"; } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      this.showORSRequestError("calculate round route", EnumGlobalStatusCode.HttpError, result);
      // this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.error.error.message;
      this.showORSRequestError("calculate round route", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    this.geojsonRoute = result;
    this.currentRoute.geojsonRoute = this.geojsonRoute;
    if (this.showLog) { console.log("Maps:onCalculateRoundRoute-geojsonRoute", this.geojsonRoute); }

    // route calculated
    this.orsPostErrorText = "";
    const feature = this.geojsonRoute.features[0];
    this.routeDistance = Math.round(feature.properties.summary.distance);
    this.routeDuration = Math.round(feature.properties.summary.duration);
    this.routeDurationHour = Math.floor(this.routeDuration / 3600);
    this.routeDurationMinute = Math.floor(this.routeDuration / 60 - this.routeDurationHour * 60);
    const currentTime = new Date(Date.now());
    this.estimatedArrivalTime = new Date(currentTime.getTime() + this.routeDuration * 1000);
    this.routePolyline = this.getRoutePolyline();

    // update missing extra
    this.updateRouteExtras(feature);

    // draw-route
    this.drawPlannedRoute();

    const info = this.convertRouteToUrl();
    this.createUsageLog(EnumActionType.call_ors_direction, info);
  }
  private updateRouteExtras(feature: any) {
    // console.log("Maps:updateRouteExtras-feature", feature);
    if (!feature.properties.extras) {
      (feature.properties as any).extras = {};
    }
    const extras = feature.properties.extras;
    if (!extras.surface) { (extras as any).surface = undefined; }
    if (!extras.waytypes) { (extras as any).waytypes = undefined; }
    if (!extras.steepness) { (extras as any).steepness = undefined; }
    this.steepnessExtraT4Y = undefined;
    this.setSteepnessExtra();
  }

  public onClearRouteClick(doUpdateUrl: boolean) {
    // console.log("Maps:onClearRouteClick-doUpdateUrl", doUpdateUrl);
    this.plannerPhase = 0;
    this.selectedWayPointIndex = -1;
    this.selectedWayPointForMapIndex = -1;
    this.isWayPointStartPoint = false;
    this.isWayPointEndPoint = false;
    this.routePolyline = undefined;
    this.orsPostErrorText = undefined;

    // save current options
    const options = this.currentRoute.plannerOptions;
    const currentRoute = createPRoute();
    // currentRoute.wayPoints = createDefaultWayPoints(undefined);
    currentRoute.wayPoints = createDefaultWayPoints();
    // console.log("Maps:onClearRouteClick-routeWayPoints", this.currentRoute.wayPoints);
    currentRoute.plannerOptions = options;
    this.currentRoute = currentRoute;
    // console.log("Maps:onClearRouteClick-currentRoute", this.currentRoute);
    this.updatePlannerPhase();
    this.updateUrl();

    this.routePlannerWayPointsLayer.getSource().clear();
    if (this.geojsonRoute) {
      this.routePlannerRouteLayer.getSource().clear();
      this.routePlannerRouteArrowLayer.getSource().clear();
      this.routePoisSource.clear();
      this.geojsonRoute = undefined;
    }
    this.currentRoute.geojsonRoute = undefined;
  }

  public onReverseRouteClick() {
    // console.log("Maps:onReverseRouteClick");
    this.currentRoute.wayPoints.reverse();
    this.calculateRoute();
    this.updateUrl();
  }
  public onZoomToActiveRouteClick() {
    // console.log("Maps:onZoomToRouteClick");
    if (this.isVisibleRoutePlanner) {
      if (!this.isVisibleMap) {
        this.onRoutePlannerBack();
      }
    }
    setTimeout(() => {
      this.zoomToActiveRoute();
    });
  }
  public zoomToActiveRoute() {
    // console.log("Maps:onZoomToRoute");
    const view = this.map.getView();
    const source = this.routePlannerRouteLayer.getSource();
    // console.log("Maps:onZoomToRouteClick-source", source);
    if (!source) { return; }
    const extent = source.getExtent();
    // console.log("Maps:onZoomToRouteClick-extent", extent);
    if (!Number.isFinite(extent[0])) { return; }
    const paddding = this.mapPadding;
    view.fit(extent, { padding: [paddding, paddding, paddding, paddding] });
    this.zoomed = true;
  }

  private convertActiveRouteToPRoute() {
    console.log("Maps:convertActiveRouteToPRoute-currentRoute", this.currentRoute);
    const route = {} as PRoute;
    route.id = this.currentRoute.id;
    route.routeId = this.currentRoute.routeId;
    // let name = this.currentRoute.name;
    // if (name === "") {
    //   name = "new route";
    //   if (this.userLanguage === "de") { name = "Neue Route"; }
    // }
    // route.name = name;
    route.name = this.currentRoute.name;
    route.nameDe = this.currentRoute.nameDe;
    route.nameEn = this.currentRoute.nameEn;
    const options = {} as RoutePlannerOptions;
    options.moveType = this.currentRoute.plannerOptions.moveType;
    options.avoidHighways = this.currentRoute.plannerOptions.avoidHighways;
    options.avoidTollways = this.currentRoute.plannerOptions.avoidTollways;
    route.plannerOptions = options;
    const wayPoints = cloneMapPlaces(this.currentRoute.wayPoints);
    route.wayPoints = wayPoints;
    const pois = cloneMapPlaces(this.currentRoute.pois);
    route.pois = pois;
    route.backToStart = this.currentRoute.backToStart;
    route.geojsonRoute = this.currentRoute.geojsonRoute;
    return route;
  }

  public onPublishRouteClick() {
    // console.log("Maps:OnPublishRouteClick-currentRoute", this.currentRoute);
    this.closeAllDialogs();
    this.hideMainDialog();
    if (!this.searchRegions) { this.loadAvailableRegions(); }
    if (this.uiType === "S") { this.hideMap(); }
    this.showPublishRouteDialog();
    setTimeout(() => { this.map.updateSize(); });
  }
  public showPublishRouteDialog() {
    const lRoute = this.convertActiveRouteToPRoute();
    // console.log("Maps:showPublishRouteDialog-lRoute", lRoute);
    const pubRoute = combineLRouteAndPubRoute(lRoute, this.pubRoute);
    // console.log("Maps:showPublishRouteDialog-pubRoute", this.pubRoute);
    this.pubRoute = pubRoute;
    this.isVisiblePublishRouteDialog = true;
    this.isActiveDialog = true;
  }
  public hidePublishRouteDialog() {
    this.isVisiblePublishRouteDialog = false;
  }
  public onPublishRouteBack(to: string) {
    // console.log("Maps:onPublishRouteBack-pubRoute", this.pubRoute);
    this.hidePublishRouteDialog();
    this.updateDialogStatus();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  public async onUploadRouteToServer() {
    const result = await this.globalService.addPublicRoute(this.pubRoute);
    // console.log("Maps:onUploadRouteToServer-result", result);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "uploadRoute");
      this.showApiError(apiErr);
      return;
    }
    this.isCurrentRouteIdPublished = true;
    const info = "routeId:" + this.pubRoute.routeId;
    this.createUsageLog(EnumActionType.publish_route, info);
  }


  // search routes
  public onSearchRoutesClick() {
    // console.log("Maps:onSearchRoutesClick");
    this.openSearchRoutesDialog();
    setTimeout(() => { this.map.updateSize(); });
    this.createUsageLog(EnumActionType.search_route_click, "");
  }
  private openSearchRoutesDialog() {
    // console.log("Maps:openSearchRoutesDialog");
    this.mapRef = this.map;    // set mapRef for using map-extent as search filter bbox
    this.closeAllDialogs();
    this.checkIfNetworkAccessOk();
    if (!this.isOnline) {
      this.createErrorMapPopup();
      return;
    }
    if (!this.searchRegions) { this.loadAvailableRegions(); }
    this.initSearchRouteFilter();
    this.hideMainDialog();
    if (this.uiType === "S") {
      this.setSearchFilterBBoxToMapExtent();
      this.hideMap();
    }
    this.showSearchRouteDialog();
  }
  private setSearchFilterBBoxToMapExtent() {
    // console.log("SearchRoute:setSearchFilterBBoxToMapExtent-map", this.map);
    const view = this.map.getView();
    const extent = view.calculateExtent(this.map.getSize());
    // console.log("SearchRoute:setSearchFilterBBoxToMapExtent-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    this.searchRouteFilter.bboxLonFrom = coordLL[0];
    this.searchRouteFilter.bboxLonTo = coordUR[0];
    this.searchRouteFilter.bboxLatFrom = coordLL[1];
    this.searchRouteFilter.bboxLatTo = coordUR[1];
  }

  private initSearchRouteFilter() {
    if (!this.searchRouteFilter) {
      this.searchRouteFilter = {} as MapSearchRouteFilter;
      // this.searchRouteFilter.moveType = this.routePlannerSettings.moveType;
    }
  }
  public showSearchRouteDialog() {
    this.initSearchRouteFilter();
    this.isVisibleSearchRoutesDialog = true;
    this.isActiveDialog = true;
  }
  public hideSearchRouteDialog() {
    this.isVisibleSearchRoutesDialog = false;
  }
  public onSearchRouteBack() {
    this.hideSearchRouteDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onFoundPublicRoutesChanged(foundRoutes: MapPublicRoute[]) {
    // console.log("Maps:onFoundPublicRoutesChanged-foundRoutes", foundRoutes);
    this.publicRoutesSource.clear();
    this.publicRoutes = foundRoutes;
  }
  public onDrawPublicRoutes() {
    // console.log("Maps:onRedrawPublicRoutes");
    this.redrawPublicRoutes();
    if (this.uiType === "S") { this.onSearchRouteBack(); }
    setTimeout(() => {
      this.zoomToMapSource(this.publicRoutesSource);
    });
    const info = "count:" + this.publicRoutes.length;
    this.createUsageLog(EnumActionType.draw_all_public_routes, info);
  }
  public onDrawPublicRoute(pubRoute: MapPublicRoute) {
    console.log("Maps:onDrawPublicRoute-pubRoute", pubRoute);
    this.addDrawPublicRoute(pubRoute);
    if (this.uiType === "S") { this.onSearchRouteBack(); }
    setTimeout(() => {
      this.zoomToPublicRoute(pubRoute);
    });
    const info = "routeId:" + pubRoute.routeId;
    this.createUsageLog(EnumActionType.draw_public_route, info);
  }
  public hidePublicRoute(pubRoute: MapPublicRoute) {
    console.log("Maps:onHidePublicRoute-pubRoute", pubRoute);
    const index = this.publicRoutes.indexOf(pubRoute);
    this.removePublicRouteFromSource(pubRoute);
    if (this.uiType === "S") { this.onSearchRouteBack(); }
  }
  private zoomToPublicRoute(pubRoute: MapPublicRoute) {
    // console.log("Maps:zoomToPublicRoute-pubRoute", pubRoute);
    const coordLL = fromLonLat([pubRoute.bboxLonFrom, pubRoute.bboxLatFrom]);
    const coordUR = fromLonLat([pubRoute.bboxLonTo, pubRoute.bboxLatTo]);
    // console.log("Maps:onSearchRestaurantsClick-coordLL", coordLL);
    // console.log("Maps:onSearchRestaurantsClick-coordUR", coordUR);
    const extent = [coordLL[0], coordLL[1], coordUR[0], coordUR[1]];
    // console.log("Maps:zoomToPublicRoute-extent", extent);
    if (extent[0] === Infinity) { return; }
    const view = this.map.getView();
    const padding = this.mapPadding;
    view.fit(extent, { padding: [padding, padding, padding, padding] });
  }
  public onRemovePublicRoute(pubRoute: MapPublicRoute) {
    this.removePublicRoute(pubRoute);
  }
  private async removePublicRoute(pubRoute: MapPublicRoute) {
    // console.log("Maps:removePublicRoute-pubRoute", pubRoute);
    const result = await this.globalService.deletePublicRoute(pubRoute.id);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "deletePublicRoute");
      this.showApiError(apiErr);
      return;
    }
  }
  public onActivatePublicRouteClick() {
    // console.log("Maps:onActivatePublicRouteClick-selectedMapRoute", this.selectedMapRoute);
    const id = this.selectedMapRoute.id;
    const index = id - 1;
    const pubRoute = this.publicRoutes[index];
    this.closeAllPopups();
    this.activatePublicRoute(pubRoute);
    const info = "routeId:" + pubRoute.routeId;
    this.createUsageLog(EnumActionType.activate_public_route, info);
  }
  public onActivatePublicRoute(pubRoute: MapPublicRoute) {
    if (this.uiType === "S") { this.onSearchRouteBack(); }
    this.activatePublicRoute(pubRoute);
  }
  private activatePublicRoute(pubRoute: MapPublicRoute) {
    // console.log("Maps:onActivatePublicRoute-pubRoute", pubRoute);
    const pRoute = convertPubRouteToLRoute(pubRoute);
    // console.log("Maps:onActivatePublicRoute-pRoute", pRoute);
    if (this.userLanguage === "de") { pRoute.name = pubRoute.nameDe; }
    if (this.userLanguage === "en") { pRoute.name = pubRoute.nameEn; }
    pRoute.name = getRouteNameForLanguage(pRoute, this.userLanguage);
    // console.log("Maps:onActivatePublicRoute-pRoute2", pRoute);
    this.activateSavedRouteServer(pRoute);
    this.pubRoute = pubRoute;
  }
  public onClearPublicRouteClick() {
    const id = this.selectedMapRoute.id;
    const index = id - 1;
    const pubRoute = this.publicRoutes[index];
    this.hidePublicRoute(pubRoute);
    this.isFeaturePopupPubRouteVisible = false;
  }
  public onClearAllPublicRoutesClick() {
    this.publicRoutesSource.clear();
    this.closeAllPopups();
  }

  // route-details
  public onShowRouteDetailsClick() {
    // console.log("Maps:onShowRouteDetailsClick");
    this.closeAllDialogs();
    // console.log("Maps:onShowRouteDetailsClick-feature", feature);
    this.isVisibleRouteDetails = true;
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onShowRouteDetailsPopupClick() {
    // console.log("Maps:onShowRouteDetailsPopupClick");
    this.closeAllDialogs();
    const feature = this.geojsonRoute.features[0];
    if (!feature.properties.extras.steepness) {
      this.updateSteepnessExtra();
      // this.steepnessSummary = feature.properties.extras.steepness.summary;
    }
    this.isVisibleRouteDetails = true;
    this.routeDetailsBackToMap = true;
    this.updateDialogStatus();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.closeAllPopups();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onRemoveRoutePopupClick() {
    console.log("Maps:onRemoveRouteClick");
    this.closeAllPopups();
    this.onClearRouteClick(false);
  }
  public onSaveRouteLocalyPopupClick() {
    this.closeAllPopups();
    this.onSaveRouteLocalyClick();
  }
  public onSaveRouteCloudPopupClick() {
    this.closeAllPopups();
    this.onSaveRouteCloudClick();
  }
  public onPointedIndexChanged(index: number) {
    // console.log("Maps:onPointedIndexChanged-index", index);
    this.routePointerSource.clear();
    if (index === -1) { return; }
    const p = this.routePolyline.points[index];
    // console.log("Maps:onPointedIndexChanged-p", p);
    const feature = new Feature({
      geometry: new Point([p.x, p.y]),
      id: index,
    });
    // console.log("Maps:onPointedIndexChanged-feature", feature);
    this.routePointerSource.addFeature(feature);
  }
  public onInsertWaypointOnRoutePopupClick() {
    // console.log("Maps:onInsertWaypointOnRoutePopupClick-currentRoute", this.currentRoute);
    // calculate distances for all waypoints
    this.calculateWaypointDistances();
    this.closeAllPopups();
    // create new place
    const wpNew = {} as MapPlaceL;
    wpNew.type = "wp";
    wpNew.coordLon = this.longitude;
    wpNew.coordLat = this.latitude;
    wpNew.name = this.textWaypoint;
    const mapCoordinatesNew = transform([wpNew.coordLon, wpNew.coordLat], "EPSG:4326", "EPSG:3857");
    // console.log("Maps:onInsertWaypointOnRoutePopupClick-mapCoordinatesNew", mapCoordinatesNew);
    const distanceNew = this.calculateDoneRouteDistance(mapCoordinatesNew, this.maxDistanceFromRoute * 3.0);
    // console.log("Maps:onInsertWaypointOnRoutePopupClick-distanceNew", distanceNew);
    wpNew.distance = distanceNew;
    this.currentRoute.wayPoints.push(wpNew);
    this.currentRoute.wayPoints.sort((a, b) => {
      if (!a.coordLon || a.distance > b.distance) { return 1; }
      if (a.distance < b.distance || !b.coordLon) { return -1; }
    });
    // console.log("Maps:onInsertWaypointOnRoutePopupClick-wayPoints", this.currentRoute.wayPoints);
    for (const wp of this.currentRoute.wayPoints) {
      wp.id = this.currentRoute.wayPoints.indexOf(wp);
    }
    this.calculateRoute();
    this.redrawWayPoints();
    this.updateUrl();
  }
  public onRouteDetailsBack(to: string) {
    this.isVisibleRouteDetails = false;
    if (this.routeDetailsBackToMap) {
      to = "toMap";
      this.routeDetailsBackToMap = false;
    }
    if (to !== "toMap") { this.onRoutePlannerClick(); }
    if (to === "toMap") { this.onRoutePlannerBack(); }
    this.updateDialogStatus();
  }
  private calculateWaypointDistances() {
    if (!this.factorRealToPolylineLength) {
      this.calculatedRoutePolylineLength = calculatePolylineLength(this.routePolyline);
      // console.log("Maps:startNavigation-calculatedRoutePolylineLength", this.calculatedRoutePolylineLength);
      this.factorRealToPolylineLength = this.routeDistance / this.calculatedRoutePolylineLength;
    }
    for (const wp of this.currentRoute.wayPoints) {
      // console.log("Maps:onInsertWaypointOnRoutePopupClick-wp", wp);
      if (!wp.coordLon) { continue; }
      const mapCoordinates = transform([wp.coordLon, wp.coordLat], "EPSG:4326", "EPSG:3857");
      // console.log("Maps:onInsertWaypointOnRoutePopupClick-mapCoordinates", mapCoordinates);
      let distance = this.calculateDoneRouteDistance(mapCoordinates, this.maxDistanceFromRoute * 2.0);
      const index = this.currentRoute.wayPoints.indexOf(wp);
      // console.log("Maps:onInsertWaypointOnRoutePopupClick-index", index);
      wp.id = index;
      if (index === 0) { distance = 0.0; }
      wp.distance = distance;
    }

  }
  public onShareRouteClick() {
    // console.log("Maps:onShareRouteClick");
    this.isNamePopupVisible = true;
    this.objectType = "route";
    if (this.currentRoute.name) { this.shareRoute(); }
    if (!this.currentRoute.name) { this.createRouteNamePopup("share"); }
  }
  public shareRoute() {
    // console.log("Maps:shareRoute");
    this.isNamePopupVisible = false;
    const verParam = "ver=2.0";
    const routeParam = this.convertRouteToUrl();
    const url = this.baseUrl + verParam + "&" + routeParam;
    const sharedData: ShareData = { url };
    sharedData.title = this.shareRouteSubjectText + this.currentRoute.name;
    // sharedData.text = this.shareRouteMsg1Text + "\r\r" + this.shareRouteMsg2Text;
    sharedData.text = this.shareRouteMsg2Text;
    // console.log("Maps:onShareRouteClick-sharedData", sharedData);
    navigator.share(sharedData);
  }
  public createRouteNamePopup(handlerType: string) {
    // console.log("Maps:createRouteNamePopup");
    if (!this.currentRoute.name) {
      this.currentRoute.name = "my_route";
      if (this.userLanguage === "de") { this.currentRoute.name = "Meine_Route"; }
    }
    const pRoute = {} as PRoute;
    pRoute.name = this.currentRoute.name;
    const dialogRef = this.dialog.open(RouteNamePopupComponent, {
      closeOnNavigation: true,
      data: {
        dlgTitle: this.textRouteNamePopupTitle,
        inputPlaceholder: this.textRouteNamePopupInputPlh,
        route: pRoute,
        buttonOk: true,
        buttonCancel: true,
      },
    });
    dialogRef.afterClosed().subscribe(popupResult => {
      if (popupResult === "ok") {
        this.currentRoute.name = pRoute.name;
        if (handlerType === "share") { this.shareRoute(); }
      }
    });
  }

  // navigation -> automatic map-move & rotation -------------------------------------------------------------------------------------------
  public onNavigationClick() {
    // console.log("Maps:onStartNavigationClick");
    this.isNavigationStarted = !this.isNavigationStarted;
    this.waypointDragger.setActive(!this.isNavigationStarted);
    // console.log("Maps:onStartNavigationClick-isNavigationStarted", this.isNavigationStarted);

    if (this.isNavigationStarted) {
      if (this.isVisibleRoutePlanner) {
        this.hideRoutePlannerDialog();
        this.showMap();
        setTimeout(() => { this.map.updateSize(); });
      }
      this.currentRouteStepInstructionA = "Start";
      this.hideMainDialog();
      this.startNavigation();
      this.switchAutomaticMapMove(this.doAutoMapMove);
      this.switchAutomaticMapRotation(this.doAutoMapRotation);
      // console.log("Maps:onNavigationClick-doAutoMapRotation", this.doAutoMapRotation);

      this.activateNavigationWakeLock();
      this.buttonNavigation.style.backgroundColor = "greenyellow";
      if (!this.geojsonRoute) { return; }
      this.isNaviInfoOnMapVisible = true;
      this.isArrivalInfoOnMapVisible = true;
    }
    if (!this.isNavigationStarted) {
      this.showMainDialog();
      this.switchAutomaticMapMove(false);
      this.switchAutomaticMapRotation(false);
      this.releaseNavigationWakeLock();
      this.buttonNavigation.style.backgroundColor = "";
      this.isNaviInfoOnMapVisible = false;
      this.isArrivalInfoOnMapVisible = false;
    }
    if (this.isNavigationStarted) {
      this.createUsageLog(EnumActionType.start_navi, "");
    } else {
      this.createUsageLog(EnumActionType.stop_navi, "");
    }
  }
  public startNavigation() {
    if (!this.geojsonRoute) { return; }
    // console.log("Maps:startNavigation");
    this.startTime = new Date(Date.now());
    this.calculatedDurationFromStart = 0.0;
    this.calculatedDoneDistance = 0.0;
    this.remainingDistance = this.routeDistance;
    this.remainingDurationHour = this.routeDurationHour;
    this.remainingDurationMinute = this.routeDurationMinute;
    this.estimatedArrivalTime = new Date(this.startTime.getTime() + this.routeDuration * 1000);
    this.isAtStartPoint = false;
    this.isAtEndPoint = false;
    this.isOffRoute = false;
    this.playSoundIfOffRoute = true;
    this.calculatedRoutePolylineLength = calculatePolylineLength(this.routePolyline);
    // console.log("Maps:startNavigation-calculatedRoutePolylineLength", this.calculatedRoutePolylineLength);
    // const x = this.testPolylineLength2(this.routePolyline);
    // console.log("Maps:onRouteCalculate-x", x);
    this.factorRealToPolylineLength = this.routeDistance / this.calculatedRoutePolylineLength;
    if (!this.mapCoordinates) { return; }
    this.onPositionChangedNavigation();
  }
  public onNavigationInfoCloseClick() {
    // console.log("Maps:onNavigationInfoCloseClick");
    if (!this.isArrivalInfoOnMapVisible) {
      this.isNaviInfoOnMapVisible = false;
    }
    if (this.isArrivalInfoOnMapVisible) {
      this.isArrivalInfoOnMapVisible = false;
    }
  }
  private async activateNavigationWakeLock() {
    if (!this.useWakeLock) { return; }
    let wakeLock: any;
    try {
      const extendedNavigator: any = navigator;
      wakeLock = await extendedNavigator.wakeLock.request("screen");
      // console.log("Maps:activateWakeLock", wakeLock);
    } catch (err) {
      // console.log("Maps:WakeLock-Error", `${err.message}`);
    }
    this.navigationWakeLock = wakeLock;
  }
  private releaseNavigationWakeLock() {
    if (!this.navigationWakeLock) { return; }
    this.navigationWakeLock.release();
    this.navigationWakeLock = undefined;
  }

  private playSound(soundName: string) {
    // console.log("Maps:playSound-soundName", soundName);
    // play a sound
    let url = "";
    if (soundName === "wayoff-beep") {
      url = "./assets/sounds/beep-08b.mp3";
    }
    if (soundName === "wayon-beep") {
      url = "./assets/sounds/beep-06.mp3";
    }
    // console.log("Maps:playSound-url", url);
    const audio = new Audio(url);
    audio.play();
    // vibrate
    if (soundName === "wayoff-beep") {
      navigator.vibrate([200, 100, 200]);
    }
    if (soundName === "wayon-beep") {
      navigator.vibrate([200, 100, 200]);
    }
  }
  // automatic map-move
  public switchAutomaticMapMove(onoff: boolean) {
    // console.log("Maps:switchAutomaticMapMove-onoff", onoff);
    this.automaticMapMove = onoff;
  }
  // automatic map-rotation
  public switchAutomaticMapRotation(onoff: boolean) {
    // console.log("Maps:switchAutomaticMapRotation-onoff", onoff);
    this.automaticMapRotation = onoff;
  }
  public onPositionChangedNavigation() {
    // console.log("Maps:onPositionChangedNavigation");
    const currentTime = new Date(Date.now());
    // testen, ob auf start oder end-position
    this.isAtStartPoint = this.isCoordAtRouteStartPoint(this.routePolyline, this.mapCoordinates, this.maxDistanceFromRoute);
    this.isAtEndPoint = this.isCoordAtRouteEndPoint(this.routePolyline, this.mapCoordinates, this.maxDistanceFromRoute);
    if (this.isAtEndPoint) { return; }
    this.deltaX = this.mapCoordinates[0] - this.mapCoordinatesNavigationOld[0];
    this.deltaY = this.mapCoordinates[1] - this.mapCoordinatesNavigationOld[1];
    this.currentDistance = Math.sqrt(this.deltaX * this.deltaX + this.deltaY * this.deltaY);
    // console.log("Maps:onPositionChangedNavigation-lastTime", this.lastTime);
    let minDist = 5.0;
    if (this.currentRoute.plannerOptions.moveType !== "car") { minDist = 5.0; }
    // user is moving
    if (this.currentDistance === 0.0 || this.currentDistance > minDist) {
      this.calculatedDoneDistance = this.calculateDoneRouteDistance(this.mapCoordinates, this.maxDistanceFromRoute);
      // console.log("Maps:onPositionChangedNavigation-calculatedDoneDistance", this.calculatedDoneDistance);
      // user is off the route
      if (isNaN(this.calculatedDoneDistance)) {
        this.isOffRoute = true;
        this.playSoundIfOnRoute = true;
        if (this.isAtStartPoint) { this.isOffRoute = false; }  // notwendig????
        if (this.isOffRoute) {
          if (this.playSoundIfOffRoute) {
            this.playSound("wayoff-beep");
            this.playSoundIfOffRoute = false;
          }
        }
      }
      // user is on the route
      if (!isNaN(this.calculatedDoneDistance)) {
        this.isOffRoute = false;
        this.playSoundIfOffRoute = true;
        if (this.playSoundIfOnRoute) {
          this.playSound("wayon-beep");
          this.playSoundIfOnRoute = false;
        }
        this.calculatedDoneDistance *= this.factorRealToPolylineLength;
        this.currentRouteStepResult = this.calculateActualRouteStepResult(this.calculatedDoneDistance);
        // recalculate remaining duration and arrival-time
        if (this.currentRouteStepResult) {
          this.remainingDistance = Math.floor(this.routeDistance - this.calculatedDoneDistance);
          if (this.remainingDistance < 0.0) { this.remainingDistance = 0.0; }
          this.remainingDuration = this.currentRouteStepResult.durationToEnd;
          // console.log("Maps:onPositionChangedNavigation-remainingDuration-2", this.remainingDuration);
          this.remainingDurationHour = Math.floor(this.remainingDuration / 3600);
          this.remainingDurationMinute = Math.floor(this.remainingDuration / 60 - this.remainingDurationHour * 60);
          this.estimatedArrivalTime = new Date(currentTime.getTime() + this.remainingDuration * 1000);
        }
        // console.log("Maps:onPositionChangedNavigation-estimatedArrivalTime-B", this.estimatedArrivalTime);
        this.currentRouteStepInstructionA = "";
        this.currentRouteStepInstructionB = "";
        this.currentRouteStepInstructionC = "";
        if (this.currentRouteStepResult.index !== -1) {
          this.currentRouteStep = this.currentRouteStepResult.step;
          this.nextRouteStep = this.currentRouteStepResult.nextStep;
          this.currentRouteStepIconSource = "./assets/icons_navi/type" + this.currentRouteStepResult.nextStep.type + ".svg";
          if (this.isAtStartPoint) {
            this.currentRouteStepIconSource = "./assets/icons_navi/type11.svg";
            this.currentRouteStepInstructionC = this.currentRouteStep.name;
          }
          if (!this.isAtStartPoint && this.nextRouteStep) {
            this.currentRouteStepInstructionC = this.nextRouteStep.name;
          }
          if (this.currentRouteStepInstructionC === "-") {
            this.currentRouteStepInstructionC = "";
          }
          // de
          if (this.userLanguage === "de") {
            this.currentRouteStepInstructionA = "In " + Math.round(this.currentRouteStepResult.rest) + "m";
            if (this.currentRouteStepResult.nextStep.type === 7) {
              this.currentRouteStepInstructionB = " Ausfahrt " + this.currentRouteStepResult.nextStep.exit_number;
            }
            if (this.currentRouteStep.type === 11 && (this.isAtStartPoint)) {
              this.currentRouteStepInstructionA = "Starte ";
            }
            if (this.currentRouteStepInstructionC) { this.currentRouteStepInstructionB = " auf "; }
          }
          // en
          if (this.userLanguage !== "de") {
            this.currentRouteStepInstructionA = "In" + Math.round(this.currentRouteStepResult.rest) + "m";
            if (this.currentRouteStepResult.nextStep.type === 7) {
              this.currentRouteStepInstructionB = " exit " + this.currentRouteStepResult.nextStep.exit_number;
            }
            if (this.currentRouteStep.type === 11 && !this.isAtStartPoint) {
              this.currentRouteStepInstructionA = "Start ";
            }
            if (this.currentRouteStepInstructionC) { this.currentRouteStepInstructionB = " on "; }
          }
          // console.log("Maps:change:position-currentRouteStepResult", this.currentRouteStepResult);
        }
      }
      this.mapCoordinatesNavigationOld = this.mapCoordinates;
    }
    // this.lastTime = currentTime;
  }

  // load GPX-route -------------------------------------------------------------------------------------------------
  public async onGPXRouteClick() {
    // console.log("Maps:onGPXRouteClick");
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showGPXRouteDialog();
    setTimeout(() => { this.map.updateSize(); });
    this.createUsageLog(EnumActionType.loadgpx_click, "");
  }
  public showGPXRouteDialog() {
    this.isVisibleGPXRouteDialog = true;
    this.isActiveDialog = true;
  }
  public hideGPXRouteDialog() {
    this.isVisibleGPXRouteDialog = false;
    // this.isActiveDialog = false;
  }
  public onGPXRouteBack() {
    // console.log("Maps:isVisibleLoadTrackDialog");
    this.hideGPXRouteDialog();
    this.showMap();
    // this.showMainDialog();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  // load and draw tracking-layer from gpx
  public onFileChange(event) {
    // console.log("Maps:-----------------------------------onFileChange-event", event);
    if (this.uiType === "S") { this.onGPXRouteBack(); }

    const files = event.target.files;
    if (files.length === 0) { return; }
    const file = files[0];
    // console.log("Maps:-----------------------------------onFileChange-file", file);

    const type = file.type;
    if (type === "application/gpx+xml") {
      this.loadGpxFile(file);
      return;
    }
    if (type === "application/vnd.google-earth.kml+xml") {
      this.loadKmlFile(file);
      return;
    }
    if (type === "") {
      if (file.name.endsWith(".gpx")) {
        this.loadGpxFile(file);
        return;
      }
      if (file.name.endsWith(".kml")) {
        this.loadKmlFile(file);
        return;
      }
    }
  }
  public loadGpxFile(file: any) {
    // console.log("Maps:loadGpxFile-file", file);
    // clear old sources
    if (this.gpxRouteSource) {
      this.gpxRouteSource.clear();
      this.gpxWaypointSource.clear();
    }
    // create new gpxRoute-source
    this.gpxRouteSource = new VectorSource({
      url: URL.createObjectURL(file),
      format: new GPX(),
    });
    // console.log("Maps:onfileChange-gpxRouteSource", this.gpxRouteSource);
    this.gpxRouteLayer.setSource(this.gpxRouteSource);
    this.gpxFileName = file.name;
    setTimeout(() => {
      // const features = this.gpxRouteSource.getFeatures();
      // console.log("Maps:loadKmlFile-features", features);
      this.zoomToGpxRoute();
      this.convertGpxRouteToTrack();
      this.drawGpxWaypoints();
      this.gpxFileLoaded = true;
    }, 500);
  }
  public loadKmlFile(file: any) {
    // console.log("Maps:loadKmlFile-file", file);
    // clear old sources
    if (this.gpxRouteSource) {
      this.gpxRouteSource.clear();
      this.gpxWaypointSource.clear();
    }
    // create new gpxRoute-source
    this.gpxRouteSource = new VectorSource({
      // url: "./assets/data/gpx/example1.gpx",
      url: URL.createObjectURL(file),
      format: new KML(),
    });
    // console.log("Maps:onfileChange-gpxRouteSource", this.gpxRouteSource);
    this.gpxRouteLayer.setSource(this.gpxRouteSource);
    this.gpxFileName = file.name;
    setTimeout(() => {
      // const features = this.gpxRouteSource.getFeatures();
      // console.log("Maps:loadKmlFile-features", features);
      this.zoomToGpxRoute();
      this.convertGpxRouteToTrack();
      this.drawGpxWaypoints();
      this.gpxFileLoaded = true;
    }, 500);

  }
  // public loadXmlFile(file: string) {
  //   // console.log("Maps:loadXmlFile-file", file);
  //   return null;
  // }
  public convertGpxRouteToTrack() {
    // console.log("Maps:convertGpxRouteToTrack-gpxRouteSource", this.gpxRouteSource);
    const features = this.gpxRouteSource.getFeatures();

    this.activeGpxTrack = {} as Track;
    this.activeGpxTrack.name = this.gpxFileName;
    // console.log("Maps:convertGpxRouteToTrack-gpxFileName", this.gpxFileName);
    const trackPoints = Array<TrackPoint>();
    this.activeGpxTrack.trackPoints = trackPoints;
    const wayPoints = Array<WayPoint>();
    this.activeGpxTrack.wayPoints = wayPoints;
    let countMultiLineStrings = 0;
    // this.activeGpxTrack.message = "Features:" + features.length + "\n";
    // console.log("Maps:convertGpxRouteToTrack-features", features);

    if (features && features.length > 0) {
      // this.activeGpxTrack.message += "Before feature loop:" + "\n";
      for (const feature of features) {
        // this.activeGpxTrack.message += "Next feature:" + "\n";
        const geom = feature.getGeometry();
        // console.log("Maps:convertGpxRouteToTrack-geom", geom);
        if (geom instanceof Point) {
          const id = wayPoints.length;
          let name = feature.get("name");
          if (!name) { name = "wp"; }
          const coord = geom.flatCoordinates;
          if (coord.length === 0) { continue; }
          const wp = {} as WayPoint;
          wp.lng = coord[0];
          wp.lat = coord[1];
          wp.name = name;
          wp.id = id;
          if (name === "start") { wp.type = "wps"; }
          if (wp.type !== "wps") { wayPoints.push(wp); }
        }
        // if (constName === "MultiLineString" || constName === "u") {
        if (geom instanceof MultiLineString || geom instanceof LineString) {
          const name = feature.get("name");
          // this.activeGpxTrack.name = name;
          const type = feature.get("type");
          this.activeGpxTrack.type = type;
          const layout = geom.layout;
          this.activeGpxTrack.trackPointsType = layout;
          const coord = geom.flatCoordinates;
          if (layout === "XY") {
            // console.log("Maps:convertGpxRouteToTrack-XYcoord", coord);
            const countPointsXY = coord.length / 2;
            for (let i = 0; i < countPointsXY; i++) {
              const tp = {} as TrackPoint;
              tp.lng = coord[i * 2];
              tp.lat = coord[i * 2 + 1];
              trackPoints.push(tp);
            }
          }
          if (layout === "XYZ") {
            // console.log("Maps:convertGpxRouteToTrack-XYZcoord", coord);
            const countPointsXYZ = coord.length / 3;
            for (let i = 0; i < countPointsXYZ; i++) {
              const tp = {} as TrackPoint;
              tp.lng = coord[i * 3];
              tp.lat = coord[i * 3 + 1];
              tp.ele = coord[i * 3 + 2];
              trackPoints.push(tp);
            }
          }
          if (layout === "XYZM") {
            // console.log("Maps:convertGpxRouteToTrack-XYZcoord", coord);
            const countPointsXYZM = coord.length / 4;
            for (let i = 0; i < countPointsXYZM; i++) {
              const tp = {} as TrackPoint;
              tp.lng = coord[i * 4];
              tp.lat = coord[i * 4 + 1];
              tp.ele = coord[i * 4 + 2];
              const timestamp = coord[i * 4 + 3] * 1000; // sec-> msec
              // console.log("Maps:convertGpxRouteToTrack-timestamp", timestamp);
              tp.timeStamp = new Date(timestamp);
              // console.log("Maps:convertGpxRouteToTrack-tp", tp);
              trackPoints.push(tp);
            }
          }
          if (coord.length !== 0) {
            const wp = {} as WayPoint;
            wp.lng = coord[0];
            wp.lat = coord[1];
            wp.name = name;
            wp.id = wayPoints.length;
            wp.type = "wps";
            if (countMultiLineStrings === 0) { wp.type = "wps"; }
            wayPoints.push(wp);
            countMultiLineStrings++;
          }
        }
      }
    }
    // this.activeGpxTrack.message += "All features processed:" + "\n";
    // const gpxRoutePolyline = this.getGpxRoutePolyLine();
    this.activeGpxTrack.length = this.calculateGpxRouteLength();
    // console.log("Maps:convertGpxRouteToTrack-activeGpxTrack", this.activeGpxTrack);
  }
  private getGpxRoutePolyLine() {
    const routePolyline = {} as RoPolyline;
    routePolyline.points = new Array<RoPoint>();
    for (const trp of this.activeGpxTrack.trackPoints) {
      const p = { x: trp.lng, y: trp.lat } as RoPoint;
      routePolyline.points.push(p);
    }
    // console.log("Maps:getGpxRoutePolyLine-routePolyline", routePolyline);
    return routePolyline;
  }
  public drawGpxWaypoints() {
    // console.log("Maps:drawGpxWayoints");
    for (const wp of this.activeGpxTrack.wayPoints) {
      // console.log("Maps:drawGpxWaypoints-wp", wp);
      this.drawGpxWaypoint(wp);
    }
  }
  public drawGpxWaypoint(wp: WayPoint) {
    const coord = new Array<number>();
    coord.push(wp.lng);
    coord.push(wp.lat);
    const wpFeature = new Feature({
      geometry: new Point(coord),
      id: wp.id,
      name: wp.name
    });

    // start-point
    if (wp.type === "wps") {
      wpFeature.setStyle(
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: "#00FF00",
            }),
          }),
          text: new Text({
            text: wp.name,
          }),
        })
      );
    }
    if (wp.type === "wpx") {
      wpFeature.setStyle(
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: "#008800",
            }),
          }),
          text: new Text({
            text: wp.name,
          }),
        })
      );
    }
    if (wp.type === "wp") {
      wpFeature.setStyle(
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: "#FF00FF",
            }),
          }),
          text: new Text({
            text: wp.name,
          }),
        })
      );
    }
    // console.log("Maps:drawGpxWaypoint-wpFeature", wpFeature);
    this.gpxWaypointSource.addFeature(wpFeature);
  }

  public onZoomToGpxRouteClick() {
    if (this.uiType === "S") { this.onGPXRouteBack(); }
    setTimeout(() => {
      this.zoomToGpxRoute();
    }, 1000);
  }
  private zoomToGpxRoute() {
    const source = this.gpxRouteSource;
    // console.log("Maps:zoomToGpxRoute-source", source);
    const extent = source.getExtent();
    // console.log("Maps:zoomToGpxRoute-extent", extent);
    if (extent[0] === Infinity) { return; }
    const view = this.map.getView();
    const padding = this.mapPadding;
    view.fit(extent, { padding: [padding, padding, padding, padding] });
  }
  public onClearGpxRouteClick() {
    // console.log("Maps:onClearGpxRouteClick");
    // console.log("Maps:onClearGpxRouteClick-gpxRouteSource", this.gpxRouteSource);
    this.gpxRouteSource.clear();
    this.gpxWaypointSource.clear();
    this.activeGpxTrack = {} as Track;
    this.gpxFileLoaded = false;
  }
  public onShowGpxRouteDetailsClick() {
    // console.log("Maps:onShowGpxRouteDetailsClick");
    this.closeAllDialogs();
    this.isVisibleGpxRouteDetails = true;
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onShowGpxRouteDetailsPopupClick() {
    // console.log("Maps:onShowGpxRouteDetailsPopupClick");
    this.closeAllDialogs();
    this.isVisibleGpxRouteDetails = true;
    this.gpxRouteDetailsBackToMap = true;
    this.updateDialogStatus();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.closeAllPopups();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onGpxRouteDetailsBack(to: string) {
    this.isVisibleGpxRouteDetails = false;
    if (this.gpxRouteDetailsBackToMap) {
      to = "toMap";
      this.gpxRouteDetailsBackToMap = false;
    }
    if (to !== "toMap") { this.onGPXRouteClick(); }
    if (to === "toMap") { this.onGPXRouteBack(); }
  }

  // tracking -------------------------------------------------------------------------------------------------
  public async onTrackingClick() {
    // console.log("Maps:onTrackingClick");
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showTrackingDialog();
    setTimeout(() => { this.map.updateSize(); });
    this.createUsageLog(EnumActionType.tracking_click, "");
  }
  public showTrackingDialog() {
    if (!this.currentTourData.moveType && !this.currentTourData.isTourStarted) {
      this.currentTourData.moveType = this.currentRoute.plannerOptions.moveType;
    }
    this.isVisibleTrackingDialog = true;
    this.isActiveDialog = true;
  }
  public hideTrackingDialog() {
    this.isVisibleTrackingDialog = false;
  }
  public onTrackingBack() {
    // console.log("Maps:onTrackingDialog");
    this.hideTrackingDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  public onStartTour() {
    // console.log("Maps:onStartTourClick");
    if (!this.mapCoordinates) { return; }
    this.startTourStatistics();
    this.currentTourData.isTourStarted = true;
    this.activateTrackingWakeLock();
  }
  public onContinueTour() {
    // console.log("Maps:onContinueTourClick");
    if (!this.mapCoordinates) { return; }
    this.startTourTimer();
    this.currentTourData.isTourStarted = true;
  }
  public onStopTour() {
    // console.log("Maps:onStopTourClick");
    this.stopTourStatistics();
    this.currentTourData.isTourStarted = false;
    this.releaseTrackingWakeLock();
  }
  private startTourStatistics() {
    // console.log("Maps:startTourStatistics-currentTourData", this.currentTourData);
    this.initCurrentTourData();
    const now = new Date(Date.now());
    this.currentTourData.tourStartTime = now;
    this.geolocationTimeTourStatisticsOld = now;
    this.mapCoordinatesTourStatisticsOld = this.geolocation.getPosition();
    const altidute = this.geolocation.getAltitude();
    if (altidute) {
      this.geolocationAltiduteTourStatisticsOld = altidute;
      this.currentTourData.currentAltidute = altidute;
      this.currentTourData.minAltidute = altidute;
      this.currentTourData.maxAltidute = altidute;
    }

    // add current location to trackPoints
    if (this.mapCoordinatesTourStatisticsOld) {
      const newTrackPoint = this.createTrackPoint(this.mapCoordinatesTourStatisticsOld, now);
      // §test -> for tests
      let test = true;
      if (environment.production) { test = false; };
      if (test) { newTrackPoint.ele = 1000; }
      if (altidute) { newTrackPoint.ele = altidute; }
      this.currentTourData.trackPoints.push(newTrackPoint);
      this.addTrackMarker(this.mapCoordinatesTourStatisticsOld, "S");
      const trackIsOnRoute = this.isTrackOnCurrentRoute();
      // console.log("Maps:onStartTourClick-trackIsOnRoute", trackIsOnRoute);
      if (trackIsOnRoute) {
        this.currentTourData.routeId = this.currentRoute.routeId;
        this.currentTourData.routeDbId = this.currentRoute.id;
        this.currentTourData.route = JSON.stringify(this.currentRoute);
      }
      // §test -> for tests
      if (test) {
        let tp1 = {} as TrackPoint;
        this.testAddTrackPoint(15.3878, 47.0092, 1010);
        this.testAddTrackPoint(15.3863, 47.0100, 1015);
        this.testAddTrackPoint(15.3837, 47.0116, 1020);
        this.testAddTrackPoint(15.3793, 47.0149, 1025);
        this.testAddTrackPoint(15.3795, 47.0173, 1025);
        this.redrawTrack();
        const trackLength = this.calculateTrackingRouteLength();
        this.currentTourData.trackLength = trackLength;
      }
    }
    this.startTourTimer();
    this.currentTourData.isTourStarted = true;
  }
  private testAddTrackPoint(lng: number, lat: number, ele: number) {
    const tp1 = {} as TrackPoint;
    tp1.lng = lng;
    tp1.lat = lat;
    const now = new Date(Date.now());
    tp1.timeStamp = new Date(now);
    tp1.ele = ele;
    this.mapCoordinates = fromLonLat([tp1.lng, tp1.lat]);
    this.addTrackMarker(this.mapCoordinates, "");
    this.currentTourData.trackPoints.push(tp1);
  }
  private initCurrentTourData() {
    const showTrack = this.currentTourData.showTrack;
    initTourData(this.currentTourData);
    // mapSources & mapLayers
    this.trackingLocationSource.clear();
    this.trackingSource.clear();
    this.trackingLayer.setVisible(this.currentTourData.showTrack);
    this.trackingLocationLayer.setVisible(this.currentTourData.showTrack);
  }
  private isTrackOnCurrentRoute() {
    if (!this.currentRoute.geojsonRoute) return false;
    if (this.currentTourData.trackPoints.length === 0) return false;
    const tpS = this.currentTourData.trackPoints[0];
    const wpS = this.currentRoute.wayPoints[0];
    const dist = calculateDistance(tpS.lng, tpS.lat, wpS.coordLon, wpS.coordLat);
    // console.log("Maps:isTrackOnCurrentRoute-dist", dist);
    if (dist < 100.0) return true;
    return false;
  }


  private startTourTimer() {
    // console.log("Maps:startTourTimer-currentTourData", this.currentTourData);
    const minDistance = this.trackIntervall;
    const minElevationDiff = 5.0;
    const minVelocity = this.getTrackingConstantMinVelocity(this.currentTourData.moveType);
    this.tourDataTimer = setInterval(() => {
      // console.log("Maps:startTourTimer-currentTourData2", this.currentTourData);
      const now = new Date(Date.now());
      // this.debugLog.push("tourTime at:" + now.toString());
      const mapCoordinates = this.geolocation.getPosition();
      // console.log("Maps:startTourTimer-mapCoordinates", mapCoordinates);
      if (!mapCoordinates) { return; }
      // this.debugLog.push("Lat:" + mapCoordinates[0].toString());
      // this.debugLog.push("Lon:" + mapCoordinates[1].toString());
      if (!this.mapCoordinatesTourStatisticsOld) {
        this.mapCoordinatesTourStatisticsOld = {} as number[];
        this.mapCoordinatesTourStatisticsOld[0] = mapCoordinates[0];
        this.mapCoordinatesTourStatisticsOld[1] = mapCoordinates[1];
        this.geolocationTimeTourStatisticsOld = now;
        this.geolocationAltiduteTourStatisticsOld = this.geolocation.getAltitude();
      }
      const deltaX = mapCoordinates[0] - this.mapCoordinatesTourStatisticsOld[0];
      const deltaY = mapCoordinates[1] - this.mapCoordinatesTourStatisticsOld[1];
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      // this.debugLog.push("Tour-Distance0:" + distance.toFixed(2));
      const accuracy = this.geolocation.getAccuracy();
      // this.debugLog.push("Accuracy:" + accuracy.toString());
      const time = (now.getTime() - this.geolocationTimeTourStatisticsOld.getTime()) / 1000;

      let currentVelocity: number;
      let distanceCorrFact: number;
      if (distance > minDistance && distance > accuracy) {
        // this.debugLog.push("Tour-Distance1:" + distance.toFixed(2));
        let timeInMotion = 0;
        const velocity = distance / time;
        if (velocity > minVelocity) {
          timeInMotion += time;
          this.currentTourData.timeInMotion += timeInMotion;
          this.currentTourData.timeInMotionHours = Math.floor(this.currentTourData.timeInMotion / 3600);
          this.currentTourData.timeInMotionMinutes = Math.floor(this.currentTourData.timeInMotion / 60) - this.currentTourData.timeInMotionHours * 60;
        }
        this.currentTourData.doneDistance += distance;
        const newTrackPoint = this.createTrackPoint(mapCoordinates, now);
        this.currentTourData.trackPoints.push(newTrackPoint);
        const count = this.currentTourData.trackPoints.length + 1;
        const name = count.toString();
        this.addTrackMarker(this.mapCoordinates, name);
        this.redrawTrack();
        const trackLength = this.calculateTrackingRouteLength();
        this.currentTourData.trackLength = trackLength;

        distanceCorrFact = trackLength / this.currentTourData.doneDistance;
        if (distanceCorrFact == 0.0) { distanceCorrFact = 1.0; }
        currentVelocity = distance * distanceCorrFact / time * 3.6;
        this.currentTourData.currentVelocity = currentVelocity;
        this.mapCoordinatesTourStatisticsOld = mapCoordinates;
        this.geolocationTimeTourStatisticsOld = now;
      } else {
        // current velocity for short distances
        distanceCorrFact = 1.0;
        if (this.currentTourData.trackLength > 0.0 && this.currentTourData.doneDistance > 0.0) {
          distanceCorrFact = this.currentTourData.trackLength / this.currentTourData.doneDistance;
        }
        currentVelocity = distance * distanceCorrFact / time * 3.6;
        if (distance < accuracy) { currentVelocity = 0.0; }
        this.currentTourData.currentVelocity = currentVelocity;
      }
      const duration = (now.getTime() - this.currentTourData.tourStartTime.getTime()) / 1000;
      this.currentTourData.averageVelocity = this.currentTourData.trackLength / this.currentTourData.timeInMotion * 3.6;
      this.currentTourData.doneDuration = Math.floor(duration);
      this.currentTourData.doneDurationHours = Math.floor(this.currentTourData.doneDuration / 3600);
      this.currentTourData.doneDurationMinutes = Math.floor(this.currentTourData.doneDuration / 60) - this.currentTourData.doneDurationHours * 60;

      // altitudes
      const altidute = this.geolocation.getAltitude();
      let elevationDiff = 0;
      if (altidute && accuracy < 25.0) {
        this.currentTourData.currentAltidute = altidute;
        if (altidute < this.currentTourData.minAltidute) { this.currentTourData.minAltidute = altidute; }
        if (altidute > this.currentTourData.maxAltidute) { this.currentTourData.maxAltidute = altidute; }
        elevationDiff = altidute - this.geolocationAltiduteTourStatisticsOld;
      }
      // ascents-descents
      if (Math.abs(elevationDiff) > minElevationDiff) {
        if (elevationDiff > 0) { this.currentTourData.doneAscents += elevationDiff; }
        if (elevationDiff < 0) { this.currentTourData.doneDescents += elevationDiff; }
        this.geolocationAltiduteTourStatisticsOld = altidute;
      }
      localStoreTourData(this.currentTourData);
      // console.log("Maps:startTourTimer-currentTourData3", this.currentTourData);
    }, 5000);
  }
  public stopTourStatistics() {
    clearInterval(this.tourDataTimer);
    const now = new Date(Date.now());
    this.currentTourData.tourEndTime = now;
    this.currentTourData.isTourStarted = false;
    localStoreTourData(this.currentTourData);
    this.releaseTrackingWakeLock();
  }
  private createTrackPoint(mapCoordinates: number[], timeStamp: Date) {
    const lonlatCoordinates = transform(mapCoordinates, "EPSG:3857", "EPSG:4326");
    const newTrackPoint = {} as TrackPoint;
    newTrackPoint.lng = lonlatCoordinates[0];
    newTrackPoint.lat = lonlatCoordinates[1];
    const elevation = this.geolocation.getAltitude();
    if (elevation) { newTrackPoint.ele = elevation; }
    newTrackPoint.timeStamp = timeStamp;
    return newTrackPoint;
  }

  public onShowTrackChanged(show: boolean) {
    // console.log("Maps:onShowTrackChanged-show", show);
    this.currentTourData.showTrack = show;
    localStoreTourData(this.currentTourData);
    this.trackingLayer.setVisible(show);
    this.trackingLocationLayer.setVisible(show);
  }
  public onTourMoveTypeChanged() {
    localStoreTourData(this.currentTourData);
    if (this.currentTourData.isTourStarted) {
      this.startTourTimer();
    }
  }
  private getTrackingConstantMinVelocity(moveType: string) {
    let minVelocity = 0.2;
    if (moveType === "bike") { minVelocity = 0.3; }
    if (moveType === "ebike") { minVelocity = 0.3; }
    if (moveType === "bike-road") { minVelocity = 0.3; }
    if (moveType === "mtb") { minVelocity = 0.3; }
    if (moveType === "car") { minVelocity = 1.0; }
    return minVelocity;
  }
  private async activateTrackingWakeLock() {
    if (!this.useWakeLock) { return; };
    let wakeLock: any;
    try {
      const extendedNavigator: any = navigator;
      wakeLock = await extendedNavigator.wakeLock.request("screen");
      // console.log("Maps:activateWakeLock", wakeLock);
    } catch (err) {
      // console.log("Maps:WakeLock-Error", `${err.message}`);
    }
    this.trackingWakeLock = wakeLock;
  }
  private releaseTrackingWakeLock() {
    if (!this.trackingWakeLock) { return; }
    this.trackingWakeLock.release();
    this.trackingWakeLock = undefined;
  }

  public onZoomToTrackClick() {
    if (this.uiType === "S") { setTimeout(() => { }, 500); }
    setTimeout(() => {
      this.zoomToTrack();
    });
  }
  private zoomToTrack() {
    // console.log("Maps:onZoomToTrackClick");
    const source = this.trackingSource;
    // console.log("Maps:onZoomToTrackClick-source", source);
    if (this.uiType === "S") { this.onTrackingBack(); }
    const extent = source.getExtent();
    // console.log("Maps:onZoomToLoadedTracksClick-extent", extent);
    if (extent[0] === Infinity) { return; }
    const view = this.map.getView();
    const paddding = this.mapPadding;
    view.fit(extent, { padding: [paddding, paddding, paddding, paddding] });
  }

  private addTrackMarker(mapCoordinates: number[], name: string) {
    // console.log("Maps:addTrackMarker-mapCoordinates", mapCoordinates);
    const iconFeature = new Feature({
      geometry: new Point(mapCoordinates),
      name
    });
    // console.log("TripMapView:addPlaceMarker-iconFeature", iconFeature);
    this.trackingLocationSource.addFeature(iconFeature);
  }
  public onHideTrackRouteClick() {
    this.closeAllPopups();
    this.currentTourData.showTrack = false;
    this.trackingLayer.setVisible(false);
    this.trackingLocationLayer.setVisible(false);
  }
  private redrawTrack() {
    // console.log("Maps:redrawTrack-currentTorData", this.currentTourData);
    this.trackingSource.clear();
    // if (!this.currentTourData.showTrack) { return; }
    if (!this.currentTourData.trackPoints) { return; }
    const points = new Array();
    for (const xPoint of this.currentTourData.trackPoints) {
      // console.log("Maps:drawTrack-xPoint", xPoint);
      // const lonLat: LngLat = {} as LngLat;
      // lonLat.lng = xPoint.lng;
      // lonLat.lat = xPoint.lat;
      const point2 = transform([xPoint.lng, xPoint.lat], "EPSG:4326", "EPSG:3857");
      points.push(point2);
    }
    // console.log("Maps:drawTrack-points", points);
    const lineFeature = new Feature({
      geometry: new LineString(points),
      type: "track",
      name: this.currentTourData.name
    });
    // console.log("TestMaps:drawTrack-lineFeature", lineFeature);
    this.trackingSource.addFeature(lineFeature);
    // this.drawTrackingLocations(points);
  }
  private redrawTrackLocations() {
    // console.log("Maps:drawTrackLocations");
    this.trackingLocationSource.clear();
    // if (!this.currentTourData.showTrack) { return; }
    if (!this.currentTourData.trackPoints) { return; }
    const points = new Array();
    for (const xPoint of this.currentTourData.trackPoints) {
      const point2 = transform([xPoint.lng, xPoint.lat], "EPSG:4326", "EPSG:3857");
      points.push(point2);
    }
    let name = "S";
    let i = 0;
    for (const xPoint of points) {
      i += 1
      if (i > 0) { name = i.toString(); }
      this.addTrackMarker(xPoint, name);
    }
  }
  public async onSaveTour() {
    console.log("Maps:onSaveTour", this.currentTourData);
    const mapTour = convertTourDataToMapTour(this.currentTourData);
    if (!mapTour.averageVelocity || !Number.isFinite(mapTour.averageVelocity)) { mapTour.averageVelocity = 0.0 }
    // console.log("Maps:onSaveTour-mapTour", mapTour);
    if (mapTour.id) {
      // update tour
      const result = await this.globalService.updateTour(mapTour);
      // console.log("Maps:onSaveTour-result", result);
      if (result.status == EnumGlobalStatusCode.ObjectNotInDb) {
        mapTour.id = 0;
      } else {
        if (result.status != EnumGlobalStatusCode.Success) {
          const apiErr = createApiError(result, "updateTour");
          this.showApiError(apiErr);
          return;
        }
      }
    }
    if (!mapTour.id) {
      // add new tour
      const result = await this.globalService.addTour(mapTour);
      // console.log("Maps:onSaveTour-result", result);
      if (result.status != EnumGlobalStatusCode.Success) {
        const apiErr = createApiError(result, "addTour");
        this.showApiError(apiErr);
        return;
      }
      const addedTour = result.addedTour;
      this.currentTourData.dbId = addedTour.id;
      return;
    }
  }

  // saved-items
  public async onSavedItemsClick() {
    // console.log("Maps:onSavedItemsClick");
    this.closeAllDialogs();
    this.checkIfNetworkAccessOk();
    if (!this.isOnline) { this.createErrorMapPopup(); }
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showSavedItemsDialog();
    setTimeout(() => { this.map.updateSize(); });
    this.createUsageLog(EnumActionType.saved_click, "");
  }
  public showSavedItemsDialog() {
    this.isVisibleSavedItemsDialog = true;
    this.isActiveDialog = true;
  }
  public hideSavedItemsDialog() {
    this.isVisibleSavedItemsDialog = false;
    // this.isActiveDialog = false;
  }
  public onSavedItemsBack() {
    this.hideSavedItemsDialog();
    this.showMap();
    // this.showMainDialog();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  // saved-places ---------------------------------------------------------------------------------------------
  public onSaveTypeChange(type: string) {
    // console.log("Maps:onSaveTypeChange-type", type);
    this.saveType = type;
  }
  // saved-places-localy
  public onSavedPlacesChange(places: SavedPlaceCategory[]) {
    this.mySavedPlacesLocaly = places;
  }
  public redrawSavedPlacesLocaly() {
    // console.log("Maps:drawSavedPlaces-savedPlaces", this.mySavedPlaces);
    this.savedPlacesLocalySource.clear();
    if (!this.mySavedPlacesLocaly) { return; }
    for (const cat of this.mySavedPlacesLocaly) {
      if (cat.visible) {
        for (const place of cat.placeItems) {
          // this.drawSavedPlace(this.savedPlacesSource, place, cat.type);
          this.drawSavedPlace(this.savedPlacesLocalySource, place, cat);
        }
      }
    }
    // console.log("Maps:drawSavedPlaces-source", this.savedPlacesSource);
  }
  private drawSavedPlace(source: VectorSource<any>, place: MapPlaceL, cat: SavedPlaceCategory) {
    // console.log("Maps:drawSavedPlace-place", place);
    const placeFeature = new Feature({
      geometry: new Point(transform([place.coordLon, place.coordLat], "EPSG:4326", "EPSG:3857")),
      id: place.id,
      idOSM: place.idOSM,
      lon: place.coordLon,
      lat: place.coordLat,
      name: place.name,
      type: cat.type,
      cat: place.cat,
      tags: place.tags
    });

    let color = cat.pointStyle.strokeColor;
    if (!color) { color = "grey"; }
    placeFeature.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.25, 28],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.95,
          color,
          crossOrigin: "anonymous",
          src: "./assets/icons/flag-variant-outline-test.svg",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        text: new Text({
          text: place.name,
          offsetX: 0,
          offsetY: 10,
        }),
      })
    );
    // console.log("Maps:drawSavedPlace-placeFeature", placeFeature);
    source.addFeature(placeFeature);
  }
  public onDeleteSavedPlaceLocaly(place: MapPlaceL) {
    for (const cat of this.mySavedPlacesLocaly) {
      for (const xplace of cat.placeItems) {
        if (xplace.id === place.id) {
          const index = cat.placeItems.indexOf(xplace);
          cat.placeItems.splice(index, 1);
        }
      }
    }
    this.redrawSavedPlacesLocaly();
    this.closeAllPopups();
  }
  public onMakePlaceCategoryLocalInvisible(place) {
    for (const cat of this.mySavedPlacesLocaly) {
      for (const xplace of cat.placeItems) {
        if (place.id === xplace.id) {
          cat.visible = false;
        }
      }
    }
    this.closeAllPopups();
    this.redrawSavedPlacesLocaly();
  }

  public onDeleteSavedPlaceServer(place: MapPlaceL) {
    for (const cat of this.mySavedPlacesServer) {
      for (const xplace of cat.placeItems) {
        if (xplace.id === place.id) {
          const index = cat.placeItems.indexOf(xplace);
          cat.placeItems.splice(index, 1);
        }
      }
    }
    this.redrawSavedPlacesServer();
    this.closeAllPopups();
  }
  public onMakePlaceCategoryServerInvisible(place) {
    for (const cat of this.mySavedPlacesServer) {
      for (const xplace of cat.placeItems) {
        if (place.id === xplace.id) {
          cat.visible = false;
        }
      }
    }
    this.closeAllPopups();
    this.redrawSavedPlacesServer();
  }

  // saved-places-server
  public redrawSavedPlacesServer() {
    // console.log("Maps:redrawSavedPlacesServer-savedPlacesServer", this.mySavedPlacesServer);
    if (this.savedPlacesServerSource) { this.savedPlacesServerSource.clear(); }
    if (!this.mySavedPlacesServer) { return; }
    for (const cat of this.mySavedPlacesServer) {
      if (cat.visible) {
        for (const place of cat.placeItems) {
          this.drawSavedPlace(this.savedPlacesServerSource, place, cat);
        }
      }
    }
  }

  public onZoomToPlaceClick(place: MapPlaceL) {
    if (this.uiType === "S") { this.onSavedItemsBack(); }
    setTimeout(() => {
      this.zoomToPlace(place);
    });
  }
  public zoomToPlace(place: MapPlaceL) {
    // console.log("Maps:onZoomToPlace-place", place);
    const center = fromLonLat([place.coordLon, place.coordLat]);
    this.view.setCenter(center);
    this.view.setZoom(16);
    this.zoomed = true;
  }

  public onZoomToRouteClick(route: PRoute) {
    // console.log("Maps:onZoomToRouteClick-route", route);
    if (this.uiType === "S") { this.onSavedItemsBack(); }
    setTimeout(() => {
      this.zoomToRoute(route);
    });
  }
  private zoomToRoute(route: PRoute) {
    const bbox = route.geojsonRoute.bbox;
    const coordLL = fromLonLat([bbox[0], bbox[1]]);
    const coordUR = fromLonLat([bbox[3], bbox[4]]);
    // console.log("Maps:onSearchRestaurantsClick-coordLL", coordLL);
    // console.log("Maps:onSearchRestaurantsClick-coordUR", coordUR);
    const extent = [coordLL[0], coordLL[1], coordUR[0], coordUR[1]];
    // console.log("Maps:zoomToPublicRoute-extent", extent);
    if (extent[0] === Infinity) { return; }
    const view = this.map.getView();
    const padding = this.mapPadding;
    view.fit(extent, { padding: [padding, padding, padding, padding] });
    this.zoomed = true;
  }

  // saved-routes ---------------------------------------------------------------------------------------------------
  public onSaveRouteLocalyClick() {
    // console.log("Maps:onSaveRouteLocalyClick-currentRoute", this.currentRoute);
    // create route-id, if not existent
    if (!this.currentRoute.routeId) { this.currentRoute.routeId = createUid().toString(); }
    const route = this.convertActiveRouteToPRoute();
    // console.log("Maps:onSaveRouteClick-route", route);
    // test if existent
    let existent = false;
    for (const list of this.mySavedRoutesLocaly) {
      for (const r of list.routeItems) {
        if (route.routeId === r.routeId) {
          existent = true;
          break;
        }
      }
    }
    if (existent) {
      // console.log("Maps:onSaveRouteClick-existent-route", route);
      this.createSavedRoutePopup(route, "local", this.userLanguage);
    }
    if (!existent) {
      this.addNewSavedRouteLocaly(route);
    }
    const info = "route:" + route.routeId;
    this.createUsageLog(EnumActionType.save_route_device, info);
  }

  private createSavedRoutePopup(route: PRoute, saveType: string, language: string) {
    // console.log("Maps:createSavedRoutePopup-route", route);
    const title = this.saveRoutePopupTitle;
    const dialogRef = this.dialog.open(GeneralPopupComponent, {
      closeOnNavigation: true,
      data: {
        language,
        dlgTitle: title,
        dlgText: "'" + route.name + "'" + this.saveRouteMsg1Text,
        dlgText2: this.saveRouteMsg2Text,
        buttonAdd: true,
        buttonReplace: true,
        buttonCancel: true,
      },
    });
    dialogRef.afterClosed().subscribe(popupResult => {
      // console.log("Maps:createSavedRoutePopup-popupResult", popupResult);
      if (popupResult === "replace") {
        if (saveType === "local") { this.replaceSavedRouteLocaly(route); }
        if (saveType === "server") { this.replaceSavedRouteServer(route); }
      }
      if (popupResult === "add") {
        route.routeId = createUid().toString();
        if (saveType === "local") { this.addNewSavedRouteLocaly(route); }
        if (saveType === "server") { this.addNewSavedRouteServer(route); }
      }
    });
  }

  private replaceSavedRouteLocaly(route: PRoute) {
    // console.log("Maps:replaceSavedRouteLocaly-route", route);
    for (const list of this.mySavedRoutesLocaly) {
      for (const r of list.routeItems) {
        if (route.routeId === r.routeId) {
          const index = list.routeItems.indexOf(r);
          list.routeItems[index] = route;
          localStoreSavedRoutes(this.mySavedRoutesLocaly);
          break;
        }
      }
    }
  }
  private addNewSavedRouteLocaly(route: PRoute) {
    // console.log("Maps:addNewSavedRouteLocaly-route", route);
    for (const list of this.mySavedRoutesLocaly) {
      if (list.type === "$remember") {
        list.routeItems.push(route);
        list.open = true;
        localStoreSavedRoutes(this.mySavedRoutesLocaly);
        break;
      }
    }
  }
  public async onSaveRouteCloudClick() {
    // console.log("Maps:onSaveRouteCloudClick-currentRoute", this.currentRoute);
    // console.log("Maps:onSaveRouteCloudClick-mySavedRoutesServer", this.mySavedRoutesServer);
    // create route-id, if not existent
    if (!this.currentRoute.routeId) { this.currentRoute.routeId = createUid().toString(); }
    // make a copy of currentRoute
    const route = this.convertActiveRouteToPRoute();
    // console.log("Maps:onSaveRouteCloudClick-route", route);
    // test if existent
    let existent = false;
    for (const lCat of this.mySavedRoutesServer) {
      for (const r of lCat.routeItems) {
        if (route.routeId === r.routeId) {
          route.id = r.id;
          existent = true;
          break;
        }
      }
    }
    if (existent) {
      this.createSavedRoutePopup(route, "server", this.userLanguage);
    }
    if (!existent) {
      this.addNewSavedRouteServer(route);
    }
    const info = "route:" + route.routeId;
    this.createUsageLog(EnumActionType.save_route_cloud, info);
  }
  private async replaceSavedRouteServer(lRoute: PRoute) {
    // console.log("Maps:replaceSavedRouteServer-lRoute", lRoute);
    // update route in mySavedRoutesServer
    let catIndex: number;
    let index: number;
    for (const c of this.mySavedRoutesServer) {
      for (const r of c.routeItems) {
        if (r.routeId === lRoute.routeId) {
          catIndex = this.mySavedRoutesServer.indexOf(c);
          index = c.routeItems.indexOf(r);
          this.mySavedRoutesServer[catIndex].routeItems[index] = lRoute;
          break;
        }
        if (index) { break; }
      }
    }
    // update route in server-db
    const cat = this.mySavedRoutesServer[catIndex];
    const sRoute = convertLRouteToSRoute(lRoute, cat.categoryId);
    // console.log("Maps:replaceSavedRouteServer-sRoute", sRoute);
    this.globalService.updateRoute(sRoute);
  }
  private async addNewSavedRouteServer(lRoute: PRoute) {
    // console.log("Maps:addNewSavedRouteServer-lRoute", lRoute);
    // add category remember if not existent
    if (this.mySavedRoutesServer.length === 0) {
      const lCat = createDefaultSavedRouteCategoryRemember(this.userLanguage);
      const sCat = convertLRouteCatToSRouteCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const addedCat = await this.globalService.addRouteCategory(sCat);
      lCat.categoryId = sCat.id;
      this.mySavedRoutesServer.push(lCat);
    }
    let categoryId: number;
    if (this.mySavedRoutesServer.length > 0) {
      for (const cat of this.mySavedRoutesServer) {
        if (cat.type === "$remember") {
          categoryId = cat.categoryId;
          cat.open = true;
        }
      }
    }
    // save route on server
    const sRoute = convertLRouteToSRoute(lRoute, categoryId);
    const result = await this.globalService.addRoute(sRoute);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "addRoute");
      this.showApiError(apiErr);
      return;
    }
    const addedRoute = result.addedRoute;
    if (addedRoute) {
      lRoute.id = addedRoute.id;
      lRoute.saveType = "server";
      // console.log("Maps:convertLRouteToSRoute-mySavedRoutesServer", this.mySavedRoutesServer);
      let catIndex: number;
      for (const cat of this.mySavedRoutesServer) {
        if (cat.categoryId === categoryId) {
          catIndex = this.mySavedRoutesServer.indexOf(cat);
        }
      }
      this.mySavedRoutesServer[catIndex].routeItems.push(lRoute);
    }
  }
  public onShowSavedRouteDetailsClick() {
    const routeId = this.selectedMapRoute.routeId;
    this.closeAllPopups();
    this.routeDetailsBackToMap = true;
    if (this.selectedSaveType === "local") { this.onShowSavedRouteDetailsLocaly(routeId); }
    if (this.selectedSaveType === "server") { this.onShowSavedRouteDetailsServer(routeId); }
    if (this.selectedSaveType === "public") { this.onShowPublicRouteDetails(routeId); }
  }
  public onShowSavedRouteDetailsLocaly(routeId: string) {
    // console.log("Maps:onShowSavedRouteDetails-routeId", routeId);
    const route = this.findSavedRouteLocalyByRouteId(routeId);
    // console.log("Maps:onShowSavedRouteDetailsLocaly-route", route);
    if (!route) { return; }
    this.closeAllDialogs();
    const feature = route.geojsonRoute.features[0];
    this.currentRouteForDetails = route;
    this.isVisibleSavedRouteDetails = true;
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onShowSavedRouteDetailsServer(routeId: string) {
    // console.log("Maps:onShowSavedRouteDetails-routeId", routeId);
    const route = this.findSavedRouteServerByRouteId(routeId);
    // console.log("Maps:onShowSavedRouteDetailsServer-route", route);
    if (!route) { return; }
    this.closeAllDialogs();
    const feature = route.geojsonRoute.features[0];
    this.currentRouteForDetails = route;
    this.isVisibleSavedRouteDetails = true;
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onShowPublicRouteDetails(routeId: string) {
    // console.log("Maps:onShowSavedRouteDetails-routeId", routeId);
    const pubRoute = this.findPublicRouteByRouteId(routeId);
    const route = convertPubRouteToLRoute(pubRoute);
    // console.log("Maps:onShowPublicRouteDetails-route", route);
    if (!route) { return; }
    if (this.userLanguage === "de") { route.name = pubRoute.nameDe; }
    if (this.userLanguage === "en") { route.name = pubRoute.nameEn; }
    this.closeAllDialogs();
    const feature = route.geojsonRoute.features[0];
    this.currentRouteForDetails = route;
    this.isVisibleSavedRouteDetails = true;
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onSavedRouteDetailsBack(to: string) {
    this.isVisibleSavedRouteDetails = false;
    if (this.routeDetailsBackToMap) {
      to = "toMap";
      this.routeDetailsBackToMap = false;
    }
    if (to !== "toMap") { this.onSavedItemsClick(); }
    if (to === "toMap") { this.onSavedItemsBack(); }
    this.updateDialogStatus();
  }

  public onActivateSavedRouteClick() {
    // console.log("Maps:onActivateSavedRouteClick-selectedSaveType", this.selectedSaveType);
    const routeId = this.selectedMapRoute.routeId;
    if (this.selectedSaveType === "local") {
      for (const cat of this.mySavedRoutesLocaly) {
        for (const route of cat.routeItems) {
          if (route.routeId === routeId) {
            this.activateSavedRouteLocaly(route);
            this.pubRoute = undefined;
            this.closeAllPopups();
            return;
          }
        }
      }
    }
    if (this.selectedSaveType === "server") {
      for (const cat of this.mySavedRoutesServer) {
        for (const route of cat.routeItems) {
          if (route.routeId === routeId) {
            this.activateSavedRouteServer(route);
            this.pubRoute = undefined;
            this.closeAllPopups();
            return;
          }
        }
      }
    }
  }

  // saved routes localy
  public redrawSavedRoutesLocaly() {
    // if (this.uiType === "S") { this.onSavedItemsBack(); }
    // console.log("Maps:redrawSavedRoutes-savedRoutes", this.mySavedRoutes);
    this.savedRoutesLocalySource.clear();
    if (!this.mySavedRoutesLocaly) { return; }
    for (const cat of this.mySavedRoutesLocaly) {
      // console.log("Maps:redrawSavedRoutes-cat", cat);
      if (cat.visible) {
        for (const route of cat.routeItems) {
          if (!route.geojsonRoute) { continue; }
          const index = cat.routeItems.indexOf(route);
          this.drawSavedRouteLocaly(this.savedRoutesLocalySource, route, cat, index);
        }
      }
    }
  }
  private drawSavedRouteLocaly(source: VectorSource<any>, route: PRoute, cat: SavedRouteCategory, index: number) {
    // console.log("Maps:drawSavedRoute-route", route);
    const features = new GeoJSON({
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857"
    }).readFeatures(route.geojsonRoute);

    for (const feature of features) {
      feature.set("name", route.name);
      feature.set("routeId", route.routeId);
      feature.set("saveType", "local");
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: cat.lineStyle.lineColor,
            width: 4,
          }),
        })
      );
      source.addFeature(feature);
    }
    // create label feature at start-point
    const color = cat.lineStyle.lineColor;
    const feature0 = route.geojsonRoute.features[0];
    const coords = feature0.geometry.coordinates[0];
    // console.log("Maps:drawSavedRouteLocaly-coords", coords);
    const coordLng = coords[0];
    const coordLat = coords[1];
    const mapCoords = fromLonLat([coordLng, coordLat]);
    const labelFeature = new Feature({
      geometry: new Point([mapCoords[0], mapCoords[1]])
    });
    labelFeature.set("id", route.id);
    labelFeature.set("name", route.name);
    labelFeature.set("routeId", route.routeId);
    labelFeature.set("saveType", "local");
    const labelStyle = new Style({
      image: new Circle({
        radius: 12,
        stroke: new Stroke({
          color: "#000000ff",
          width: 1,
        }),
        fill: new Fill({
          color
        }),
      }),
      text: new Text({
        text: (index + 1).toString(),
        offsetY: 2,
        scale: 1.5,
      }),
    });
    labelFeature.setStyle(labelStyle);
    // console.log("Maps:drawSavedRouteLocaly-labelFeature", labelFeature);
    source.addFeature(labelFeature);

  }
  public activateSavedRouteLocaly(route: PRoute) {
    // console.log("Maps:activateSavedRouteLocaly-route", route);
    this.currentRoute = {} as PRoute;
    this.currentRoute.plannerOptions = {} as RoutePlannerOptions;
    this.currentRoute.routeId = route.routeId;
    this.currentRoute.name = route.name;
    this.currentRoute.plannerOptions.moveType = route.plannerOptions.moveType;
    if (this.currentRoute.plannerOptions.moveType === "car") {
      this.currentRoute.plannerOptions.avoidHighways = route.plannerOptions.avoidHighways;
      this.currentRoute.plannerOptions.avoidTollways = route.plannerOptions.avoidTollways;
    }
    this.currentRoute.wayPoints = cloneMapPlaces(route.wayPoints);
    this.currentRoute.pois = cloneMapPlaces(route.pois);
    this.currentRoute.backToStart = route.backToStart;
    if ((route as any).roundTrip) { this.currentRoute.backToStart = true; } // roundTript was old interface of backToStart
    if (route.roundRoute) {
      this.currentRoute.roundRoute = true;
      this.currentRoute.plannerOptions.roundRouteLength = route.plannerOptions.roundRouteLength;
      this.currentRoute.plannerOptions.roundRoutePoints = route.plannerOptions.roundRoutePoints;
      this.currentRoute.plannerOptions.roundRouteSeed = route.plannerOptions.roundRouteSeed;
    }
    this.iconMoveTypeSource = getMovetypeIconSource(this.currentRoute.plannerOptions.moveType);
    this.currentRoute.geojsonRoute = route.geojsonRoute;
    this.geojsonRoute = route.geojsonRoute;
    if (route.geojsonRoute) {
      this.routePolyline = this.getRoutePolyline();
      this.drawPlannedRoute();
    }
    // update start-endpoint-defined for new-point-popup
    this.isStartPointDefined = false;
    if (this.currentRoute.wayPoints[0].coordLon) { this.isStartPointDefined = true; }
    const indexLastWp = this.currentRoute.wayPoints.length - 1;
    this.isEndPointDefined = false;
    if (this.currentRoute.wayPoints[indexLastWp].coordLon) { this.isEndPointDefined = true; }
    const info = "id=" + route.routeId + ";name=" + route.name;
    this.createUsageLog(EnumActionType.activate_saved_route, info);

    this.drawRoutePois();
    if (!route.geojsonRoute) {
      this.calculateRoute();
    }
    if (this.uiType === "S") { this.onSavedItemsBack(); }
    this.zoomToActiveRoute();
  }

  // saved routes server
  public redrawSavedRoutesServer() {
    // if (this.uiType === "S") { this.onSavedItemsBack(); }
    // console.log("Maps:redrawSavedRoutesServer-mySavedRoutesServer", this.mySavedRoutesServer);
    this.savedRoutesServerSource.clear();
    if (!this.mySavedRoutesServer) { return; }
    for (const cat of this.mySavedRoutesServer) {
      // console.log("Maps:redrawSavedRoutes-cat", cat);
      if (cat.visible) {
        for (const route of cat.routeItems) {
          const index = cat.routeItems.indexOf(route);
          if (!route.geojsonRoute) { continue; }
          this.drawSavedRouteServer(this.savedRoutesServerSource, route, cat, index);
        }
      }
    }
  }
  private drawSavedRouteServer(source: VectorSource<any>, route: PRoute, cat: SavedRouteCategory, index: number) {
    // console.log("Maps:drawSavedRouteServer-route", route);
    const features = new GeoJSON({
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857"
    }).readFeatures(route.geojsonRoute);

    for (const feature of features) {
      feature.set("name", route.name);
      feature.set("routeId", route.routeId);
      feature.set("saveType", "server");
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: cat.lineStyle.lineColor,
            width: 4,
          }),
        })
      );
      source.addFeature(feature);
    }
    // create label feature at start-point
    const color = cat.lineStyle.lineColor;
    const feature0 = route.geojsonRoute.features[0];
    const coords = feature0.geometry.coordinates[0];
    // console.log("Maps:drawSavedRouteLocaly-coords", coords);
    const coordLng = coords[0];
    const coordLat = coords[1];
    const mapCoords = fromLonLat([coordLng, coordLat]);
    const labelFeature = new Feature({
      geometry: new Point([mapCoords[0], mapCoords[1]])
    });
    labelFeature.set("id", route.id);
    labelFeature.set("name", route.name);
    labelFeature.set("routeId", route.routeId);
    labelFeature.set("saveType", "server");
    const labelStyle = new Style({
      image: new Circle({
        radius: 12,
        stroke: new Stroke({
          color: "#000000ff",
          width: 1,
        }),
        fill: new Fill({
          color
        }),
      }),
      text: new Text({
        text: (index + 1).toString(),
        offsetY: 2,
        scale: 1.5,
      }),
    });
    labelFeature.setStyle(labelStyle);
    // console.log("Maps:drawSavedRouteLocaly-labelFeature", labelFeature);
    source.addFeature(labelFeature);

  }
  public activateSavedRouteServer(route: PRoute) {
    // console.log("Maps:activateSavedRouteServer-route", route);
    this.currentRoute = {} as PRoute;
    this.currentRoute.plannerOptions = {} as RoutePlannerOptions;
    this.currentRoute.id = route.id;
    this.currentRoute.routeId = route.routeId;
    this.currentRoute.name = route.name;
    this.currentRoute.nameDe = route.nameDe;
    this.currentRoute.nameEn = route.nameEn;
    this.currentRoute.plannerOptions.moveType = route.plannerOptions.moveType;
    if (this.currentRoute.plannerOptions.moveType === "car") {
      this.currentRoute.plannerOptions.avoidHighways = route.plannerOptions.avoidHighways;
      this.currentRoute.plannerOptions.avoidTollways = route.plannerOptions.avoidTollways;
    }
    this.currentRoute.wayPoints = cloneMapPlaces(route.wayPoints);
    this.currentRoute.pois = cloneMapPlaces(route.pois);
    this.currentRoute.backToStart = route.backToStart;
    if ((route as any).roundTrip) { this.currentRoute.backToStart = true; } // roundTript was old interface of backToStart
    if (route.roundRoute) {
      this.currentRoute.roundRoute = true;
      this.currentRoute.plannerOptions.roundRouteLength = route.plannerOptions.roundRouteLength;
      this.currentRoute.plannerOptions.roundRoutePoints = route.plannerOptions.roundRoutePoints;
      this.currentRoute.plannerOptions.roundRouteSeed = route.plannerOptions.roundRouteSeed;
    }
    this.iconMoveTypeSource = getMovetypeIconSource(this.currentRoute.plannerOptions.moveType);
    this.currentRoute.geojsonRoute = route.geojsonRoute;
    this.geojsonRoute = route.geojsonRoute;
    if (route.geojsonRoute) {
      this.routePolyline = this.getRoutePolyline();
      this.drawPlannedRoute();
    }
    // update start-endpoint-defined for new-point-popup
    this.isStartPointDefined = false;
    if (this.currentRoute.wayPoints[0].coordLon) { this.isStartPointDefined = true; }
    const indexLastWp = this.currentRoute.wayPoints.length - 1;
    this.isEndPointDefined = false;
    if (this.currentRoute.wayPoints[indexLastWp].coordLon) { this.isEndPointDefined = true; }
    const info = "id=" + route.routeId + ";name=" + route.name;
    this.createUsageLog(EnumActionType.activate_saved_route, info);

    this.drawRoutePois();
    if (!route.geojsonRoute) {
      this.calculateRoute();
    }
    if (this.uiType === "S") { this.onSavedItemsBack(); }
    this.zoomToActiveRoute();
    this.updateUrl();
    // console.log("Maps:activateSavedRouteServer-currentRoute", this.currentRoute);
  }
  private findSavedRouteLocalyByRouteId(id: string) {
    for (const cat of this.mySavedRoutesLocaly) {
      for (const route of cat.routeItems) {
        if (route.routeId === id) { return route; }
      }
    }
  }
  private findSavedRouteServerByRouteId(id: string) {
    for (const cat of this.mySavedRoutesServer) {
      for (const route of cat.routeItems) {
        if (route.routeId === id) { return route; }
      }
    }
  }
  private findPublicRouteByRouteId(id: string) {
    for (const pubRoute of this.publicRoutes) {
      if (pubRoute.routeId === id) { return pubRoute; }
    }
  }

  // saved-tours
  public activateSavedTourServer(tour: MapTour) {
    // console.log("Maps:activateSavedTourServer-tour", tour);
    this.onSavedItemsBack();
    this.currentTourData = convertMapTourToTourData(tour);
    this.redrawTrack();
    this.redrawTrackLocations();
    this.zoomToTrack();
    this.onTrackingClick();
    this.onShowTrackChanged(true);
  }

  // public routes
  public redrawPublicRoutes() {
    // console.log("Maps:redrawPublicRoutes-publicRoutes", this.publicRoutes);
    this.initPublicRoutesCat();
    this.publicRoutesSource.clear();
    if (!this.publicRoutes) { return; }
    for (const pubRoute of this.publicRoutes) {
      if (!pubRoute.geojsonRoute) { continue; }
      const index = this.publicRoutes.indexOf(pubRoute);
      const lRoute = convertPubRouteToLRoute(pubRoute);
      lRoute.id = index + 1;
      if (this.userLanguage === "de") { lRoute.name = pubRoute.nameDe; }
      if (this.userLanguage === "en") { lRoute.name = pubRoute.nameEn; }
      this.drawPublicRoute(this.publicRoutesSource, lRoute, this.publicRoutesCat, index);
    }
  }
  private initPublicRoutesCat() {
    if (this.publicRoutesCat) { return; }
    this.publicRoutesCat = {} as SavedRouteCategory;
    this.publicRoutesCat.lineStyle = {} as LineStyle;
    this.publicRoutesCat.lineStyle.lineColor = "#ff000080";
    this.publicRoutesCat.lineStyle.lineWidth = 3;
  }
  public addDrawPublicRoute(pubRoute: MapPublicRoute) {
    console.log("Maps:addDrawPublicRoute-pubRoute", pubRoute);
    this.initPublicRoutesCat();
    const lRoute = convertPubRouteToLRoute(pubRoute);
    lRoute.id = pubRoute.id;
    if (this.userLanguage === "de") { lRoute.name = pubRoute.nameDe; }
    if (this.userLanguage === "en") { lRoute.name = pubRoute.nameEn; }
    this.drawPublicRoute(this.publicRoutesSource, lRoute, this.publicRoutesCat, lRoute.id - 1);
  }
  public removePublicRouteFromSource(pubRoute: MapPublicRoute) {
    // console.log("Maps:removePublicRouteFromSource-pubRoute", pubRoute);
    const features = this.publicRoutesSource.getFeatures();
    // console.log("Maps:removeDrawPublicRoute-features", features);
    for (const feature of features) {
      // console.log("Maps:removeDrawPublicRoute-feature", feature);
      const routeId = feature.get("routeId");
      // console.log("Maps:removeDrawPublicRoute-routeId", routeId);
      if (routeId === pubRoute.routeId) {
        // console.log("Maps:removeDrawPublicRoute-feature", feature);
        this.publicRoutesSource.removeFeature(feature);
        // return;
      }
    }
  }
  private drawPublicRoute(source: VectorSource<any>, route: PRoute, cat: SavedRouteCategory, index: number) {
    // console.log("Maps:drawPublicRoute-route", route);
    const color = getColorFromIndex(index);
    const features = new GeoJSON({
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857"
    }).readFeatures(route.geojsonRoute);

    // create route feature
    for (const feature of features) {
      feature.set("id", route.id);
      feature.set("name", route.name);
      feature.set("routeId", route.routeId);
      feature.set("saveType", "public");
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color,
            width: cat.lineStyle.lineWidth,
          }),
        })
      );
      source.addFeature(feature);
    }
    // create label feature at start-point
    const feature0 = route.geojsonRoute.features[0];
    const coords = feature0.geometry.coordinates[0];
    // console.log("Maps:drawPublicRoute-coords", coords);
    const coordLng = coords[0];
    const coordLat = coords[1];
    const mapCoords = fromLonLat([coordLng, coordLat]);
    const labelFeature = new Feature({
      geometry: new Point([mapCoords[0], mapCoords[1]])
    });
    labelFeature.set("id", route.id);
    labelFeature.set("name", route.name);
    labelFeature.set("routeId", route.routeId);
    labelFeature.set("saveType", "public");
    const labelStyle = new Style({
      image: new Circle({
        radius: 12,
        stroke: new Stroke({
          color: "#000000ff",
          width: 1,
        }),
        fill: new Fill({
          color
        }),
      }),
      text: new Text({
        text: (index + 1).toString(),
        offsetY: 2,
        scale: 1.5,
      }),
    });
    labelFeature.setStyle(labelStyle);
    // console.log("Maps:drawPublicRoute-labelFeature", labelFeature);
    source.addFeature(labelFeature);
  }


  // select map-sources -------------------------------------------------------------------------------------------------
  public async onSelectMapClick() {
    // console.log("Maps:onSelectMapClick");
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showSelectMapDialog();
    setTimeout(() => { this.map.updateSize(); });
  }
  public showSelectMapDialog() {
    this.isVisibleMapSelection = true;
    this.isActiveDialog = true;
  }
  public hideSelectMapDialog() {
    this.isVisibleMapSelection = false;
    this.isActiveDialog = false;
  }
  public onSelectMapBack() {
    this.hideSelectMapDialog();
    this.showMap();
    // this.showMainDialog();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public selectMap(mapType: string) {
    if (mapType === "no") { setNoMapLayer(this.basemapLayer); }
    if (mapType === "OSM") { setOsmMapLayer(this.basemapLayer); }
    if (mapType === "outdoor") { setOutdoorMapLayer(this.basemapLayer); }
    if (mapType === "topo") { setTopoMapLayer(this.basemapLayer); }
    if (mapType === "streets") { setStreetsMapLayer(this.basemapLayer); }
    if (mapType === "satellite") { setSatelliteMapLayer(this.basemapLayer); }
    if (mapType === "hybrid") { setHybridMapLayer(this.basemapLayer); }
    if (mapType === "googlemaps") { setGoogleMapsMapLayer(this.basemapLayer); }
    if (mapType === "cyclosm") { setCycleOsmMapLayer(this.basemapLayer); }
    if (mapType === "bingmaps-aerial") { setBingmapsAerialMapLayer(this.basemapLayer); }
    if (mapType === "bingmaps-aerial-labels") { setBingmapsAerialLabelsMapLayer(this.basemapLayer); }
    if (mapType === "test-map") {
      this.onShowTestSourceClick();
    }
    this.mapSource = mapType;
    // if (this.uiType === "S") { this.onSelectMapBack(); }
    this.onSelectMapBack();
    const info = this.mapSource;
    this.createUsageLog(EnumActionType.selectmap_click, info);
  }
  public selectOverlayMap(mapType: string) {
    // console.log("Maps:selectOverlayMap-mapType", mapType);
    if (mapType === "") {
      this.ovlmapSource = undefined;
      const source = this.ovlmapLayer.setSource(undefined);
      this.onSelectMapBack();
      return;
    }
    this.ovlmapSource = mapType;
    if (mapType === "hiking-red") { setWaymarkedTrailsHikingOverlayLayer(this.ovlmapLayer); }
    if (mapType === "cycling-red") { setWaymarkedTrailsCyclingOverlayLayer(this.ovlmapLayer); }
    if (mapType === "mtb-red") { setWaymarkedTrailsMtbOverlayLayer(this.ovlmapLayer); }
    const info = mapType;
    this.createUsageLog(EnumActionType.selectmap_click, info);
    // if (this.uiType === "S") { this.onSelectMapBack(); }
    this.onSelectMapBack();
  }
  public async onShowTestSourceClick() {
    // console.log("test-Maps:onShowTestSourceClick");

    // google-maps

    const url = "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
    const attributions = "<span>© Google Maps</span>";
    // OpenTopoMap
    // const url = "https://a.tile.opentopomap.org/{z}/{x}/{y}.png";
    // const attributions = "<a href='https://opentopomap.org' target='_blank'> | © OpenTopoMap</a>" +
    //   "<a href='https://creativecommons.org/licenses/by-sa/3.0/' target='_blank'> | CC-BY-SA</a>";

    // waymarked trails
    // const url = "https://tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png"; // MTB-Wegenetz mit Beschriftungen - overlay-Map
    // const url = "https://tile.waymarkedtrails.org/slopes/{z}/{x}/{y}.png";  // loipen+schipisten - overlay-map
    // const attributions = "<a href='https://waymarkedtrails.org' target='_blank'> | © WaymarkedTrails</a>";

    // url: "https://topo.wanderreitkarte.de/topo/{z}/{x}/{y}.png",    // wander-reit-karte - base-map

    // url: "https://tileserver.4umaps.com/{z}/{x}/{y}.png",              // 4umaps - base-map   // !!!! not working
    // url: "http://a.tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png",                               // !!!! not working

    this.tileSource = new XYZ({
      url,
      attributions,
      maxZoom: 19
    });
    this.basemapLayer.setSource(this.tileSource);
  }

  // settings -------------------------------------------------------------------------------------------------
  public async onSettingsClick() {
    // console.log("Maps:onSettings-active", active);
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showSettingsDialog();
    this.createUsageLog(EnumActionType.settings_click, "");
    setTimeout(() => { this.map.updateSize(); });
  }
  public showSettingsDialog() {
    this.isVisibleSettings = true;
    this.isActiveDialog = true;
  }
  public hideSettingsDialog() {
    this.isVisibleSettings = false;
    // this.isActiveDialog = false;
  }
  public onSettingsBack() {
    // console.log("Maps:onSettingsBack");
    this.hideSettingsDialog();
    this.showMap();
    // this.showMainDialog();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  public onGeneralSettingsChange(settings: GeneralSettings) {
    // console.log("Maps:onGeneralSettingsChange-generalSettings", this.generalSettings);
    this.doAutoMapMove = settings.autoMapMove;
    this.doAutoMapRotation = settings.autoMapRotation;
    this.showGeolocationInfo = settings.showLocatorInfo;
    if (this.showMousePosition !== settings.showMousePosition) {
      this.showMousePosition = settings.showMousePosition;
      this.switchOnOffMousePositionControl(this.showMousePosition);
    }
    this.useWakeLock = settings.useWakeLock;
  }
  public onRoutePlannerSettingsChange(routeplannerSettings: RoutePlannerOptions) {
    this.currentRoute.plannerOptions.moveType = this.routePlannerSettings.moveType;
  }
  public onRouteFormatSettingsChange(routeFormatSettings: RouteFormatOptions) {
    // console.log("Maps:onRouteFormatSettingsChange-routeFormatSettings", this.routeFormatSettings);
    if (this.drawSteepness !== this.routeFormatSettings.drawSteepness) {
      this.drawSteepness = this.routeFormatSettings.drawSteepness;
      this.setSteepnessExtra();
    }
    this.drawPlannedRoute();
  }

  // manage maps -------------------------------------------------------------------------------------------------
  public async onManageMapsClick() {
    // console.log("Maps:onManageMapsClick");
    this.mapRef = this.map;    // set mapRef for using map-extent
    this.closeAllDialogs();
    this.hideMainDialog();
    this.calculateCurrentMapExtent(this.uiType);
    if (this.uiType === "S") { this.hideMap(); }
    this.showManageMapsDialog();
    // this.createUsageLog(EnumActionType.settings_click, "");
  }
  public showManageMapsDialog() {
    this.isVisibleManageMaps = true;
    this.isActiveDialog = true;
  }
  public hideManageMapsDialog() {
    this.isVisibleManageMaps = false;
  }
  public onManageMapsBack() {
    // console.log("Maps:onManageMapsBack");
    this.hideManageMapsDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }
  private calculateCurrentMapExtent(uiType: string) {
    if (uiType === "S") {
      const view = this.map.getView();
      this.mapExtent = view.calculateExtent(this.map.getSize());
    } else {
      this.mapExtent = createEmpty();
    }
  }

  public onActivateOfflineMap() {
    // activate offline-map
    if (this.uiType === "S") { this.onManageMapsBack(); }
    this.isActiveOfflineMap = true;
    this.tileSource = this.indexedDbMapSource;
    this.basemapLayer.setSource(this.tileSource);
  }
  public onActivateOnlineMap() {
    // activate online-map
    if (this.uiType === "S") { this.onManageMapsBack(); }
    this.isActiveOfflineMap = false;
    this.tileSource = this.serverMapSource;
    this.basemapLayer.setSource(this.tileSource);
  }
  public onZoomToExtentLonLat(extent: Extend) {
    if (this.uiType === "S") {
      this.onManageMapsBack();
    }
    this.map.updateSize();
    setTimeout(() => {
      this.zoomToExtentLonLat(extent, 0);
    });
  }
  private zoomToExtentLonLat(extent: Extend, padding: number) {
    // console.log("Maps:zoomToExtent-extent", extent);
    const coordLL = fromLonLat([extent[0], extent[1]]);
    const coordUR = fromLonLat([extent[2], extent[3]]);
    const ext = createEmpty();
    ext[0] = coordLL[0];
    ext[1] = coordLL[1];
    ext[2] = coordUR[0];
    ext[3] = coordUR[1];
    const view = this.map.getView();
    view.fit(ext, { padding: [padding, padding, padding, padding] });
  }

  // help -----------------------------------------------------------------------------
  public async onHelpClick() {
    // console.log("Maps:onHelpClick");
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showHelpDialog();
    this.createUsageLog(EnumActionType.help_click, "");
    setTimeout(() => { this.map.updateSize(); });
  }
  public showHelpDialog() {
    this.isVisibleHelp = true;
    this.isActiveDialog = true;
  }
  public hideHelpDialog() {
    this.isVisibleHelp = false;
  }
  public onHelpBack() {
    // console.log("Maps:onHelpBack");
    this.hideHelpDialog();
    this.showMap();
    // this.showMainDialog();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  // Infos --------------------------------------------------------------------------
  public async onInfosClick_save() {
    window.location.href = "/about";
  }
  public async onInfosClick() {
    // console.log("Maps:onInfosClick-active", active);
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showInfosDialog();
    setTimeout(() => { this.map.updateSize(); });
  }
  public showInfosDialog() {
    this.isVisibleInfos = true;
    this.isActiveDialog = true;
  }
  public hideInfosDialog() {
    this.isVisibleInfos = false;
    // this.isActiveDialog = false;
  }
  public onInfosBack() {
    // console.log("Maps:onHelpBack");
    this.hideInfosDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }

  // missing-locator-popup
  public createMissingLocatorPopup(language: string, errorMsg: string) {
    // console.log("Maps:createMissingLocatorPopup");
    this.dialogRefMissingLocator = this.dialog.open(MissingLocatorPopupComponent, {
      closeOnNavigation: true,
      data: {
        language,
        errorMsg,
      },
    });
    this.dialogRefMissingLocator.afterClosed().subscribe(popupResult => {
      if (popupResult === "close") {
      }
    });
  }

  // admin
  public async onAdminClick() {
    // console.log("Maps:onAdminClick-active", active);
    this.closeAllDialogs();
    this.hideMainDialog();
    if (this.uiType === "S") { this.hideMap(); }
    this.showAdminDialog();
    setTimeout(() => { this.map.updateSize(); });
  }
  public showAdminDialog() {
    this.isVisibleAdmin = true;
    this.isActiveDialog = true;
  }
  public hideAdminDialog() {
    this.isVisibleAdmin = false;
  }
  public onAdminBack() {
    this.hideAdminDialog();
    this.showMap();
    this.updateDialogStatus();
    setTimeout(() => { this.map.updateSize(); });
  }


  // diverse functions ---------------------------------------------------------------------------------------------------------------
  private getRoutePolyline() {
    const feature = this.geojsonRoute.features[0];
    const routePolyline = {} as RoPolyline;
    routePolyline.points = new Array<RoPoint>();
    for (const wp of feature.geometry.coordinates) {
      const xy = transform([wp[0], wp[1]], "EPSG:4326", "EPSG:3857");
      const p = { x: xy[0], y: xy[1], z: wp[2] } as RoPoint;
      routePolyline.points.push(p);
    }
    return routePolyline;
  }
  public onDrawRouteStyleChanged(style: string) {
    // console.log("Maps:onDrawRouteStyleChanged-style", style);
    if (style === "steepness") { this.setSteepnessExtra(); }
    this.drawRouteStyle = style;
    this.updateUrl();
    setTimeout(() => { this.drawPlannedRoute(); });
  }
  public drawPlannedRoute() {
    // console.log("Maps:drawPlannedRoute-drawRouteStyle", this.drawRouteStyle);
    if (this.drawRouteStyle === "solid") { this.drawPlannedRoute_normal(); }
    if (this.drawRouteStyle === "transparent") { this.drawPlannedRoute_transparent(); }
    if (this.drawRouteStyle === "surface-type") { this.drawPlannedRoute_surfaceType(); }
    if (this.drawRouteStyle === "way-type") { this.drawPlannedRoute_wayType(); }
    if (this.drawRouteStyle === "steepness") { this.drawPlannedRoute_steepness(); }
    if (this.drawRouteStyle === "style-g") { this.drawPlannedRoute_styleG(); }
    if (this.drawRouteStyle === "style-t") { this.drawPlannedRoute_test(); }
  }
  public drawPlannedRoute_normal() {
    // console.log("Maps:drawPlannedRoute-geojsonRoute", this.geojsonRoute);
    // route
    const routePlannerSource = new VectorSource({
      features: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857"
      }).readFeatures(this.geojsonRoute),
    });
    // console.log("Maps:drawPlannedRoute-routePlannerSource", routePlannerSource);
    // console.log("Maps:drawPlannedRoute-routePlannerLayer", this.routePlannerRouteLayer);
    // console.log("Maps:drawPlannedRoute-drawRouteColor", this.drawRouteColor);
    this.routePlannerRouteLayer.setSource(routePlannerSource);
    if (this.drawRouteColor !== "cyan") {
      const styles = this.routePlannerRouteLayer.getStyle();
      const style = styles[0];
      // console.log("Maps:drawPlannedRoute-style", style);
      const stroke = style.getStroke();
      stroke.setColor(this.drawRouteColor);
      style.setStroke(stroke);
      this.routePlannerRouteLayer.setStyle(styles);
      // console.log("Maps:drawPlannedRoute-routePlannerLayer2", this.routePlannerRouteLayer);
    }
    // draw arrows
    this.drawRouteArrows(this.routePlannerRouteArrowSource);
    // way-points
    this.redrawWayPoints();
  }
  // draw route arrows
  public drawRouteArrows(arrowSource: VectorSource<any>) {
    // console.log("Maps:drawRouteArrows-drawRoteStyle", this.drawRouteStyle);
    if (!this.routePolyline) { return; }
    // clear arrow-source
    arrowSource.clear();
    if (this.drawRouteStyle === "style-g") { return; }
    const minZoom = 10.0;
    if (this.zoom < minZoom) { return; }
    const viewRotation = this.view.getRotation();
    // arrow-distance
    const resolution = this.view.getResolution();
    const zoom = this.view.getZoom();
    const maxDist = zoom * resolution * 5.0;
    // if (this.loggedInUser && this.loggedInUser.id === 1) { arrowLength = length; }
    let dist = 0.0;
    let sumDist = 0.0;
    let lP = this.routePolyline.points[0];
    const locations = new Array<number[]>();
    for (const p of this.routePolyline.points) {
      const index = this.routePolyline.points.indexOf(p);
      if (index === 0) { continue; }
      const dX = p.x - lP.x;
      const dY = p.y - lP.y;
      dist = Math.sqrt(dX * dX + dY * dY);
      sumDist += dist;
      if (sumDist > maxDist) {
        const coordX = p.x - dX * 0.5;
        const coordY = p.y - dY * 0.5;
        const rotation = Math.atan2(dX, dY) + viewRotation;
        this.drawArrowMarker(coordX, coordY, rotation, arrowSource);
        sumDist = 0.0;
      }
      lP = p;
    }
  }
  public drawArrowMarker(coordLng: number, coordLat: number, rotation: number, source: VectorSource<any>) {
    // console.log("Maps:drawArrowMarker");
    // create feature
    const feature = new Feature({
      geometry: new Point([coordLng, coordLat])
    });
    // create arrow-style
    const arrowStyle = new Style({
      image: new Icon({
        // anchor: [0.25, 28],
        // anchorXUnits: "fraction",
        // anchorYUnits: "pixels",
        opacity: 0.95,
        rotation,
        // rotateWithView: true,
        src: "./assets/icons/arrow-up-thin-x.svg",
        // src: "./assets/icons/bike.svg",
      }),
    });
    // set feature-style
    feature.setStyle(arrowStyle);
    // console.log("Maps:drawArrowMarker-feature", feature);
    source.addFeature(feature);
  }

  public drawPlannedRoute_transparent() {
    // console.log("Maps:drawPlannedRoute_transparent-geojsonRoute", this.geojsonRoute);

    const routeSource = new VectorSource({});
    // route
    const locations = new Array<number[]>();
    for (const p of this.routePolyline.points) {
      const index = this.routePolyline.points.indexOf(p);
      const coord = new Array<number>();
      coord.push(p.x, p.y);
      locations.push(coord);
      // this.addTrackMarker(coord, "");
    }
    const id = 1;
    const color = "rgba(0,255,255, 0.3)";
    const polyline = new LineString(locations);
    const polylineFeature = new Feature({
      geometry: polyline,
      id,
    });
    // console.log("Maps:drawPlannedRoute2-polylineFeature", polylineFeature);
    polylineFeature.setStyle(
      new Style({
        stroke: new Stroke({
          color,
          width: 4,
        }),
      }),
    );
    routeSource.addFeature(polylineFeature);
    this.routePlannerRouteLayer.setSource(routeSource);

    // draw arrows
    this.drawRouteArrows(this.routePlannerRouteArrowSource);
    // way-points
    this.redrawWayPoints();
  }
  public drawPlannedRoute_surfaceType() {
    // console.log("Maps:drawPlannedRoute_surfaceType-routePolyLine", this.routePolyline);

    const routeSurfaceSource = new VectorSource({});
    const feature = this.geojsonRoute.features[0];
    // this.steepnessSummary = feature.properties.extras.surface.summary;
    const surfaceSections = feature.properties.extras.surface.values;
    for (const section of surfaceSections) {
      const wpFrom = section[0];
      const wpTo = section[1];
      const id = Number(section[2]);
      const color = getSurfaceColor(id);

      const locations = new Array<number[]>();
      for (const p of this.routePolyline.points) {
        const index = this.routePolyline.points.indexOf(p);
        if (index < wpFrom || index > wpTo) { continue; }
        const coord = new Array<number>();
        coord.push(p.x, p.y);
        locations.push(coord);
      }
      // console.log("Maps: drawPlannedRoute_surfaceType-locations", locations);
      const polyline = new LineString(locations);
      const polylineFeature = new Feature({
        geometry: polyline,
        id,
        cat: "surface"
      });
      polylineFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color,
            width: 4,
          }),
        }),
      );
      routeSurfaceSource.addFeature(polylineFeature);
    }
    // this.routePlannerWayPointsSource.addFeature(polylineFeature);

    this.routePlannerRouteLayer.setSource(routeSurfaceSource);

    // draw arrows
    this.drawRouteArrows(this.routePlannerRouteArrowSource);
    // way-points
    this.redrawWayPoints();
  }
  public drawPlannedRoute_wayType() {
    // console.log("Maps:drawPlannedRoute_wayType-routePolyLine", this.routePolyline);

    const routeWaytypeSource = new VectorSource({});
    const feature = this.geojsonRoute.features[0];
    // console.log("Maps:drawPlannedRoute_wayType-feature", feature);
    // this.steepnessSummary = feature.properties.extras.waytypes.summary;
    const waytypeSections = feature.properties.extras.waytypes.values;
    for (const section of waytypeSections) {
      const wpFrom = section[0];
      const wpTo = section[1];
      const id = Number(section[2]);
      // console.log("Maps:drawPlannedRoute_wayType-id", id);
      const color = getWaytypeColor(id);
      // console.log("Maps:drawPlannedRoute_wayType-color", color);

      const locations = new Array<number[]>();
      for (const p of this.routePolyline.points) {
        const index = this.routePolyline.points.indexOf(p);
        if (index < wpFrom || index > wpTo) { continue; }
        const coord = new Array<number>();
        coord.push(p.x, p.y);
        locations.push(coord);
      }
      const polyline = new LineString(locations);
      const polylineFeature = new Feature({
        geometry: polyline,
        id,
        cat: "waytype"
      });
      // console.log("Maps:drawPlannedRoute2-polylineFeature", polylineFeature);
      polylineFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color,
            width: 4,
          }),
        }),
      );
      // console.log("Maps:drawPlannedRoute_wayType-polylineFeature", polylineFeature);
      routeWaytypeSource.addFeature(polylineFeature);
    }
    // this.routePlannerWayPointsSource.addFeature(polylineFeature);

    this.routePlannerRouteLayer.setSource(routeWaytypeSource);

    // draw arrows
    this.drawRouteArrows(this.routePlannerRouteArrowSource);
    // way-points
    this.redrawWayPoints();
  }
  public drawPlannedRoute_steepness() {
    // console.log("Maps:drawPlannedRoute_steepness_Ori-routePolyLine", this.routePolyline);

    const routeSteepnessSource = new VectorSource({});
    const feature = this.geojsonRoute.features[0];
    // console.log("Maps:drawPlannedRoute_steepness-feature", feature);
    // this.updateSteepnessExtra();
    // this.steepnessSummary = feature.properties.extras.steepness.summary;
    // console.log("Maps:drawPlannedRoute_steepness-feature", feature);
    const steepnessSections = this.steepnessExtra.values;
    for (const section of steepnessSections) {
      const wpFrom = section[0];
      const wpTo = section[1];
      const id = Number(section[2]);
      const color = getSteepnessColor(id);

      const locations = new Array<number[]>();
      for (const p of this.routePolyline.points) {
        const index = this.routePolyline.points.indexOf(p);
        if (index < wpFrom || index > wpTo) { continue; }
        const coord = new Array<number>();
        coord.push(p.x, p.y);
        locations.push(coord);
      }
      const polyline = new LineString(locations);
      const polylineFeature = new Feature({
        geometry: polyline,
        id,
        cat: "steepness"
      });
      // console.log("Maps:drawPlannedRoute2-polylineFeature", polylineFeature);
      polylineFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color,
            width: 4,
          }),
        }),
      );
      routeSteepnessSource.addFeature(polylineFeature);
    }
    // this.routePlannerWayPointsSource.addFeature(polylineFeature);

    this.routePlannerRouteLayer.setSource(routeSteepnessSource);

    // draw arrows
    this.drawRouteArrows(this.routePlannerRouteArrowSource);
    // way-points
    this.redrawWayPoints();
  }

  private updateSteepnessExtra() {
    if (!this.geojsonRoute) { return; }
    this.setSteepnessExtra();
  }
  private setSteepnessExtra() {
    if (!this.geojsonRoute) { return; }
    if (!this.drawSteepness) { this.drawSteepness = this.routeFormatSettings.drawSteepness; }
    if (this.drawSteepness === "ORS") {
      const feature = this.geojsonRoute.features[0];
      this.steepnessExtra = feature.properties.extras.steepness;
      if (!this.steepnessExtra) {
        if (!this.steepnessExtraT4Y) { this.calculateSteepnessExtraT4Y(); }
        this.steepnessExtra = this.steepnessExtraT4Y;
      }
    }
    if (this.drawSteepness === "T4Y") {
      if (!this.steepnessExtraT4Y) { this.calculateSteepnessExtraT4Y(); }
      this.steepnessExtra = this.steepnessExtraT4Y;
    }
  }
  public calculateSteepnessExtraT4Y() {
    // console.log("Maps:calculateSteepnessExtraT4Y-routePolyLine-xxxxxxxxxxxxxxxxxx", this.routePolyline);
    const feature = this.geojsonRoute.features[0];
    // console.log("Maps:calculateSteepnessExtraT4Y-feature", feature);
    const routeLength = feature.properties.summary.distance;

    // init summary
    const summary = new Array<number[]>();
    let cat: number[];
    for (let i = -5; i <= 5; i++) {
      cat = new Array<number>();
      cat.push(i, 0.0, 0.0);
      summary.push(cat);
    }
    const sections = new Array<number[]>();
    let pOld: RoPoint;
    let steepnessCat: number;
    let steepnessCatOld = -999;
    let distance = 0;
    let fromIndex = 0;
    let toIndex: number;
    // console.log("Maps:calculateSteepnessExtraT4Y-routePolyline", this.routePolyline);
    const polylineLength = calculatePolylineLength(this.routePolyline);
    // console.log("Maps:calculateSteepnessExtraT4Y-polylineLength", polylineLength);
    const corrFactor = routeLength / polylineLength;
    // console.log("Maps:calculateSteepnessExtraT4Y-corrFactor", corrFactor);
    for (const p of this.routePolyline.points) {
      // console.log("Maps:calculateSteepnessExtraT4Y-p", p);
      const index = this.routePolyline.points.indexOf(p);
      // console.log("Maps:calculateSteepnessExtraT4Y-index", index);
      if (index === 0) {
        pOld = p;
        fromIndex = 0;
        continue;
      }
      const deltaX = p.x - pOld.x;
      const deltaY = p.y - pOld.y;
      const deltaZ = p.z - pOld.z;
      // const deltaD = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * corrFactor;
      const deltaD = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      // console.log("Maps:calculateSteepnessExtraT4Y-deltaD", deltaD);
      // console.log("Maps:calculateSteepnessExtraT4Y-deltaZ", deltaZ);
      const steepness = deltaZ / deltaD * 100;
      const steepnessAbs = Math.abs(steepness);
      // console.log("Maps:calculateSteepnessExtraT4Y-steepness", steepness);
      steepnessCat = 0;
      if (steepnessAbs >= 1 && steepnessAbs <= 3) { steepnessCat = 1; }
      if (steepnessAbs >= 4 && steepnessAbs <= 6) { steepnessCat = 2; }
      if (steepnessAbs >= 7 && steepnessAbs <= 11) { steepnessCat = 3; }
      if (steepnessAbs >= 12 && steepnessAbs <= 15) { steepnessCat = 4; }
      if (steepnessAbs > 16) { steepnessCat = 5; }
      if (steepness < 0) { steepnessCat *= -1; }
      // console.log("Maps:calculateSteepnessExtraT4Y-steepnessCat", steepnessCat);

      if (steepnessCatOld === -999) { steepnessCatOld = steepnessCat; }
      if (steepnessCat !== steepnessCatOld) {
        toIndex = index - 1;
        for (const c of summary) {
          if (c[0] === steepnessCatOld) { c[1] += distance * corrFactor; }
        }
        const section = new Array<number>();
        section.push(fromIndex);
        section.push(toIndex);
        section.push(steepnessCatOld);
        sections.push(section);
        steepnessCatOld = steepnessCat;
        fromIndex = toIndex;
        distance = 0;
      }
      pOld = p;
      distance += deltaD;
    }
    for (const c of summary) {
      if (c[0] === steepnessCatOld) { c[1] += distance * corrFactor; }
    }
    toIndex = this.routePolyline.points.length - 1;
    const section2 = new Array<number>();
    section2.push(fromIndex);
    section2.push(toIndex);
    section2.push(steepnessCat);
    sections.push(section2);
    // convert to original json
    this.updateSteepnessSummary(summary, sections);
  }
  private updateSteepnessSummary(summary: number[][], sections: number[][]) {
    // console.log("Maps:updateSteepnessSummary-summary", summary);
    let length = 0.0;
    for (const s of summary) {
      if (s[1] > 0.0) { length += s[1]; }
    }
    for (let i = summary.length - 1; i >= 0; --i) {
      const s = summary[i];
      if (s[1] === 0.0) {
        // console.log("Maps:updateSteepnessSummary-s1", s[1]);
        const index = summary.indexOf(s);
        summary.splice(index, 1);
      }
    }
    const steepnessSummary = new Array<any>();
    for (const s of summary) {
      const sx = { value: s[0], distance: s[1], amount: 0.0 };
      steepnessSummary.push(sx);
    }
    // console.log("Maps:updateSteepnessSummary-steepnessSummary", steepnessSummary);
    this.steepnessExtraT4Y = new Object();
    this.steepnessExtraT4Y.summary = steepnessSummary;
    this.steepnessExtraT4Y.values = sections;
  }

  public updateSteepnessExtra_ori() {
    // console.log("Maps:updateSteepnessExtra-routePolyLine-xxxxxxxxxxxxxxxxxx", this.routePolyline);
    const feature = this.geojsonRoute.features[0];
    // console.log("Maps:updateSteepnessExtra-feature", feature);
    if (feature.properties.extras.steepness && feature.properties.extras.steepness.summary) { return; }
    const routeLength = feature.properties.summary.distance;
    // const steepnessSections = feature.properties.extras.steepness.values;
    // console.log("Maps:updateSteepnessExtra-steepnessSections", steepnessSections);

    // init summary
    const summary = new Array<number[]>();
    let cat: number[];
    for (let i = -5; i <= 5; i++) {
      cat = new Array<number>();
      cat.push(i, 0.0, 0.0);
      summary.push(cat);
    }
    const sections = new Array<number[]>();
    let pOld: RoPoint;
    let steepnessCat: number;
    let steepnessCatOld = -999;
    let distance = 0;
    let fromIndex = 0;
    let toIndex: number;
    // console.log("Maps:updateSteepnessExtra-routePolyline", this.routePolyline);
    const polylineLength = calculatePolylineLength(this.routePolyline);
    // console.log("Maps:updateSteepnessExtra-polylineLength", polylineLength);
    const corrFactor = routeLength / polylineLength;
    // console.log("Maps:updateSteepnessExtra-corrFactor", corrFactor);
    for (const p of this.routePolyline.points) {
      // console.log("Maps:updateSteepnessExtra-p", p);
      const index = this.routePolyline.points.indexOf(p);
      // console.log("Maps:updateSteepnessExtra-index", index);
      if (index === 0) {
        pOld = p;
        fromIndex = 0;
        continue;
      }
      const deltaX = p.x - pOld.x;
      const deltaY = p.y - pOld.y;
      const deltaZ = p.z - pOld.z;
      // const deltaD = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * corrFactor;
      const deltaD = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      // console.log("Maps:drawPlannedRoute_steepness-deltaD", deltaD);
      // console.log("Maps:drawPlannedRoute_steepness-deltaZ", deltaZ);
      const steepness = deltaZ / deltaD * 100;
      // const steepness = Math.round(deltaZ / deltaD * 100);
      const steepnessAbs = Math.abs(steepness);
      // console.log("Maps:updateSteepnessExtra-steepness", steepness);
      steepnessCat = 0;
      if (steepnessAbs >= 1 && steepnessAbs <= 3) { steepnessCat = 1; }
      if (steepnessAbs >= 4 && steepnessAbs <= 6) { steepnessCat = 2; }
      if (steepnessAbs >= 7 && steepnessAbs <= 11) { steepnessCat = 3; }
      if (steepnessAbs >= 12 && steepnessAbs <= 15) { steepnessCat = 4; }
      if (steepnessAbs > 16) { steepnessCat = 5; }
      if (steepness < 0) { steepnessCat *= -1; }
      // console.log("Maps:drawPlannedRoute_steepnessCat", steepnessCat);
      // const x = "point:" + index + " steepnessCat:" + steepnessCat;
      // console.log("Maps:updateSteepnessExtra-x", x);

      if (steepnessCatOld === -999) { steepnessCatOld = steepnessCat; }
      if (steepnessCat !== steepnessCatOld) {
        toIndex = index - 1;
        // console.log("Maps:updateSteepnessExtra-fromIndex", fromIndex);
        // console.log("Maps:updateSteepnessExtra-toIndex", toIndex);
        // console.log("Maps:updateSteepnessExtra-distance", distance);
        // const color = getSteepnessColor(steepnessCatOld);
        // console.log("Maps:updateSteepnessExtra-color", color);
        for (const c of summary) {
          if (c[0] === steepnessCatOld) { c[1] += distance * corrFactor; }
        }
        const section = new Array<number>();
        section.push(fromIndex);
        section.push(toIndex);
        section.push(steepnessCatOld);
        sections.push(section);
        steepnessCatOld = steepnessCat;
        fromIndex = toIndex;
        distance = 0;
      }
      pOld = p;
      distance += deltaD;
    }
    // const color2 = getSteepnessColor(steepnessCat);
    // this.addSteepnessToSummary(summary, steepnessCatOld, distance);
    for (const c of summary) {
      if (c[0] === steepnessCatOld) { c[1] += distance * corrFactor; }
    }
    toIndex = this.routePolyline.points.length - 1;
    const section2 = new Array<number>();
    section2.push(fromIndex);
    section2.push(toIndex);
    section2.push(steepnessCat);
    sections.push(section2);
    // convert to original json
    this.updateSteepnessSummary(summary, sections);
    // (feature.properties.extras.steepness as any).values = sections;
    // console.log("Maps:updateSteepnessExtra-steepnessSections-calculated", sections);
  }

  public drawPlannedRoute_styleG() {
    // console.log("Maps:drawPlannedRoute_styleG-geojsonRoute", this.geojsonRoute);
    // const size = roughSizeOfObject(this.geojsonRoute.features[0].properties);
    // console.log("Maps:drawPlannedRoute-size", size);

    const routeSource = new VectorSource({});
    // route
    const locations = new Array<number[]>();
    for (const p of this.routePolyline.points) {
      const index = this.routePolyline.points.indexOf(p);
      const coord = new Array<number>();
      coord.push(p.x, p.y);
      locations.push(coord);
      // this.addTrackMarker(coord, "");
    }
    const id = 1;
    const polyline = new LineString(locations);
    const polylineFeature = new Feature({
      geometry: polyline,
      id,
    });
    // console.log("Maps:drawPlannedRoute_test-polylineFeature", polylineFeature);
    polylineFeature.setStyle([
      new Style({
        stroke: new Stroke({
          color: "black",
          width: 6,
        })
      }),
      new Style({
        stroke: new Stroke({
          color: "dodgerblue",
          width: 4,
        })
      })
    ]);
    routeSource.addFeature(polylineFeature);
    this.routePlannerRouteLayer.setSource(routeSource);

    // way-points
    this.redrawWayPoints();
  }
  public drawPlannedRoute_test() {
    // console.log("Maps:drawPlannedRoute_test-geojsonRoute", this.geojsonRoute);
    // const size = roughSizeOfObject(this.geojsonRoute.features[0].properties);
    // console.log("Maps:drawPlannedRoute-size", size);

    const routeSource = new VectorSource({});
    // route
    const locations = new Array<number[]>();
    for (const p of this.routePolyline.points) {
      const index = this.routePolyline.points.indexOf(p);
      const coord = new Array<number>();
      coord.push(p.x, p.y);
      locations.push(coord);
      // this.addTrackMarker(coord, "");
    }
    const id = 1;
    const color = "dodgerblue";
    const polyline = new LineString(locations);
    const polylineFeature = new Feature({
      geometry: polyline,
      id,
    });
    // console.log("Maps:drawPlannedRoute_test-polylineFeature", polylineFeature);
    polylineFeature.setStyle([
      new Style({
        stroke: new Stroke({
          color: "black",
          width: 6,
        })
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 4,
        })
      })
    ]);
    routeSource.addFeature(polylineFeature);
    this.routePlannerRouteLayer.setSource(routeSource);

    // way-points
    this.redrawWayPoints();
  }

  // draw way-points
  public redrawWayPoints() {
    this.routePlannerWayPointsSource.clear();
    for (const wp of this.currentRoute.wayPoints) {
      // console.log("Maps:drawWayPoints-wp", wp);
      if (wp) {
        if (!wp.coordLon) { continue; }
        if (wp.label === "$no") { continue; }
        const index = this.currentRoute.wayPoints.indexOf(wp);
        let type = 2;
        if (index === this.currentRoute.wayPoints.length - 1 && !this.currentRoute.backToStart) { type = 3; }
        if (index === 0) { type = 1; }
        const name = wp.name;
        const coord = {} as LngLat;
        coord.lng = wp.coordLon;
        coord.lat = wp.coordLat;
        const size = this.drawWaypointSize;
        this.drawWayPoint(this.routePlannerWayPointsSource, index, coord, type, size, name);
      }
    }
  }

  public drawWayPoint(source: VectorSource<any>, index: number, coordinate: LngLat, type: number, size: number, name: string) {
    // console.log("Maps:drawWayPoint-name", name);
    if (!coordinate) { return; }

    // console.log("Maps:drawWayPoint-drawWaypointStyle", this.drawWaypointStyle);
    if (this.drawWaypointStyle === "std") {
      this.drawWayPointStd(this.routePlannerWayPointsSource, index, coordinate, type, size, name);
      return;
    }
    if (this.drawWaypointStyle === "start") {
      this.drawWayPointStartOnly(this.routePlannerWayPointsSource, index, coordinate, type, size);
      return;
    }
    if (this.drawWaypointStyle === "end") {
      this.drawWayPointEndOnly(this.routePlannerWayPointsSource, index, coordinate, type, size, name);
      return;
    }
  }
  public drawWayPointStd(source: VectorSource<any>, index: number, coordinate: LngLat, type: number, size: number, name: string) {
    // console.log("Maps:drawWayPointStd-name", name);
    if (!coordinate) { return; }
    // start-point
    if (type === 1) {
      const startFeature = new Feature({
        geometry: new Point(transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857")),
        id: index,
        type: "wp",
        name,
      });
      startFeature.setStyle(
        new Style({
          image: new Circle({
            radius: size,
            fill: new Fill({
              color: "#00FF00",
            }),
          }),
          text: new Text({
            text: (index + 1).toString(),
            offsetY: 2,
            scale: 1.5,
          }),
        })
      );
      // console.log("Maps:drawWa<Point-startFeature", startFeature);
      source.addFeature(startFeature);
    }
    // additional way-point
    if (type === 2) {
      const wpFeature = new Feature({
        geometry: new Point(transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857")),
        id: index,
        type: "wp",
        name,
      });
      const color = "#CC99FF";
      wpFeature.setStyle(
        new Style({
          image: new Circle({
            radius: size,
            fill: new Fill({
              color,
            }),
          }),
          text: new Text({
            text: (index + 1).toString(),
            // rotateWithView: true,
            offsetY: 2,
            scale: 1.5,
          }),
        })
      );
      // console.log("Maps:drawWa<Point-wpFeature", wpFeature);
      source.addFeature(wpFeature);
    }
    // end-point
    if (type === 3) {
      const endFeature = new Feature({
        geometry: new Point(transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857")),
        id: index,
        type: "wp",
        name,
      });
      endFeature.setStyle(
        new Style({
          image: new Circle({
            radius: size,
            fill: new Fill({
              color: "#FF6666",
            }),
          }),
          text: new Text({
            // text: "end",
            text: (index + 1).toString(),
            offsetY: 2,
            scale: 1.5,
          }),
        })
      );
      // console.log("Maps:drawWa<Point-endFeature", endFeature);
      source.addFeature(endFeature);
    }
  }
  public drawWayPointStartOnly(source: VectorSource<any>, index: number, coordinate: LngLat, type: number, size: number) {
    if (!coordinate) { return; }
    // end-point
    if (type === 1) {
      const endFeature = new Feature({
        geometry: new Point(transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857")),
        id: index,
        name: (index + 1).toString(),
      });
      endFeature.setStyle(
        new Style({
          image: new Circle({
            radius: size,
            fill: new Fill({
              color: "#00FF00",
            }),
          }),
          text: new Text({
            text: "S",
            scale: 1.5,
          }),
        })
      );
      // console.log("Maps:drawWa<Point-startFeature", startFeature);
      source.addFeature(endFeature);
    }
  }
  public drawWayPointEndOnly(source: VectorSource<any>, index: number, coordinate: LngLat, type: number, size: number, name: string) {
    if (!coordinate) { return; }
    // start-point
    if (type === 3) {
      let text = "E";
      if (name && name.length > 0) { text = name; }
      const startFeature = new Feature({
        geometry: new Point(transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857")),
        id: index,
        name: (index + 1).toString(),
      });
      startFeature.setStyle(
        new Style({
          // image: new Circle({
          //   radius: 10,
          //   fill: new Fill({
          //     color: "#FF6666",
          //   }),
          // }),
          image: new Icon({
            scale: 2,
            anchor: [0.5, 24],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            opacity: 0.95,
            src: "./assets/icons/map-marker-red.svg",
          }),
          // text: new Text({
          //   text,
          //   scale: 1.5,
          // }),
          text: new Text({
            text,
            font: "bold 12px serif",
            offsetY: 16,
            scale: 1.5,
          }),
        })
      );
      // console.log("Maps:drawWa<Point-startFeature", startFeature);
      source.addFeature(startFeature);
    }
  }

  // draw route-pois
  public drawRoutePois() {
    this.routePoisSource.clear();
    if (!this.currentRoute.pois) { return; }
    for (const poi of this.currentRoute.pois) {
      // console.log("Maps:drawRoutePois-poi", poi);
      if (poi) {
        if (!poi.coordLon) { continue; }
        if (poi.label === "$no") { continue; }
        const index = this.currentRoute.pois.indexOf(poi);
        const size = this.drawWaypointSize;
        this.drawRoutePoi(this.routePoisSource, index, poi, size);
      }
    }
  }
  private drawRoutePoi(source: VectorSource<any>, index: number, poi: MapPlaceL, size: number) {

    const id = index.toString();
    const idOSM = poi.idOSM;
    const name = poi.name;
    const coord = {} as LngLat;
    coord.lng = poi.coordLon;
    coord.lat = poi.coordLat;
    const type = poi.type;
    const cat = poi.cat;
    const tags = poi.tags;

    const poiFeature = new Feature({
      geometry: new Point(transform([coord.lng, coord.lat], "EPSG:4326", "EPSG:3857")),
      id,
      idOSM,
      lon: coord.lng,
      lat: coord.lat,
      type,
      cat,
      layer: "route-poi",
      name,
      tags
    });

    if (type === "poi") {
      const style = createPoiStyle(cat, name);
      poiFeature.setStyle(style);
    }
    if (type === "searchpoint") {
      const style = createPoiStyle(undefined, name);
      poiFeature.setStyle(style);
    }

    source.addFeature(poiFeature);
  }

  public drawSearchPoint(source: VectorSource<any>, mapPlace: MapPlaceL) {
    // console.log("Maps:drawSearchPoint-mapPlace", mapPlace);

    const id = mapPlace.id;
    const idOSM = mapPlace.idOSM;
    const coord = {} as LngLat;
    coord.lng = mapPlace.coordLon;
    coord.lat = mapPlace.coordLat;
    let name = mapPlace.name;
    const tags = mapPlace.tags;
    if (!name) { name = tags.label; }
    let scale = 1.5;
    if (name === "?") { scale = 2; }

    // search-point
    const searchFeature = new Feature({
      geometry: new Point(transform([coord.lng, coord.lat], "EPSG:4326", "EPSG:3857")),
      id,
      idOSM,
      lon: coord.lng,
      lat: coord.lat,
      name,
      tags
    });
    searchFeature.setStyle(
      new Style({
        image: new Icon({
          scale: 2,
          anchor: [0.5, 24],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.95,
          src: "./assets/icons/map-marker-red.svg",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        text: new Text({
          text: name,
          offsetX: 0,
          offsetY: 10,
          scale
        }),
      })
    );

    // console.log("Maps:drawSearchPoint-searchFeature", searchFeature);
    source.clear();
    source.addFeature(searchFeature);
  }

  // OSM-overpass requests
  public setPoisStatus(type: string, active: boolean) {
    // switch all off
    this.isShowRestosActive = false;
    this.buttonShowRestos.style.backgroundColor = "";
    this.isShowHotelsActive = false;
    this.buttonShowHotels.style.backgroundColor = "";
    this.isShowSupermarketsActive = false;
    this.buttonShowSupermarkets.style.backgroundColor = "";
    this.isShowPoisActive = false;
    this.buttonShowPois.style.backgroundColor = "";
    this.poisSource.clear();

    if (active) {
      if (type === "restos") {
        this.buttonShowRestos.style.backgroundColor = "lightgreen";
        this.isShowRestosActive = true;
      }
      if (type === "hotels") {
        this.buttonShowHotels.style.backgroundColor = "lightgreen";
        this.isShowHotelsActive = true;
      }
      if (type === "supermarkets") {
        this.buttonShowSupermarkets.style.backgroundColor = "lightgreen";
        this.isShowSupermarketsActive = true;
      }
      if (type === "pois") {
        this.buttonShowPois.style.backgroundColor = "lightgreen";
        this.isShowPoisActive = true;
      }
    }
  }
  public async onSearchRestaurantsClick(category: string) {
    // console.log("Maps:onSearchRestaurantsClick");
    this.setPoisStatus("restos", !this.isShowRestosActive);
    if (!this.isShowRestosActive) { return; }
    const zoom = this.view.getZoom();
    // console.log("Maps:onSearchRestaurantsClick-zoom", zoom);
    if (zoom < 10) { this.view.setZoom(10); }
    const extent = this.view.calculateExtent(this.map.getSize());
    // console.log("Maps:onSearchRestaurantsClick-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    // console.log("Maps:onSearchRestaurantsClick-coordLL", coordLL);
    // console.log("Maps:onSearchRestaurantsClick-coordUR", coordUR);
    const bbox = [coordLL[1], coordLL[0], coordUR[1], coordUR[0]];
    // console.log("Maps:onSearchRestaurantsClick-bbox", bbox);
    // const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];" +
    //   "node(" + bbox + ")[amenity=restaurant];out;way(" + bbox + ")[amenity=restaurant];out center;";
    const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];" +
      "node(" + bbox + ")[amenity=restaurant];out;way(" + bbox + ")[amenity=restaurant];out center;";
    // console.log("Maps:onSearchRestaurantsClick-url", url);
    // if (url === "") { return; }
    this.isVisibleProgressSpinner = true;
    const result = await this.http.get<any>(url).toPromise().catch(event => {
      // console.log("Maps:onSearchRestaurantsClick:Error-event", event);
      let result = event;
      if (!result.ok) { result.type = "error" }
      console.log("Maps:onSearchRestaurantsClick:Error-result", result);
      return result;
    });
    // console.log("Maps:onSearchRestaurantsClick-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      this.showOverpassRequestError("search restaurants", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    this.elements = result.elements;
    this.drawOSMElements("resto", this.elements);
    const info = "bbox:" + bbox.toString();
    this.createUsageLog(EnumActionType.search_restos_click, info);
  }
  public async onSearchHotelsClick() {
    // console.log("Maps:onSearchHotelsClick");
    this.setPoisStatus("hotels", !this.isShowHotelsActive);
    if (!this.isShowHotelsActive) { return; }
    const zoom = this.view.getZoom();
    // console.log("Maps:onSearchHotelsClick-zoom", zoom);
    if (zoom < 10) { this.view.setZoom(10); }
    const extent = this.view.calculateExtent(this.map.getSize());
    // console.log("Maps:onSearchHotelsClick-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    // console.log("Maps:onSearchHotelsClick-coordLL", coordLL);
    // console.log("Maps:onSearchHotelsClick-coordUR", coordUR);
    const bbox = [coordLL[1], coordLL[0], coordUR[1], coordUR[0]];
    // console.log("Maps:onSearchHotelsClick-bbox", bbox);
    // const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];node(" + bbox + ")[tourism=hotel];out;";
    // const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];node(" + bbox + ")[tourism=guest_house];out;";
    const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];" +
      "(node(" + bbox + ")[tourism=hotel];node(" + bbox + ")[tourism=guest_house];node(" + bbox + ")[leisure=resort];);out;" +
      "(way(" + bbox + ")[tourism=hotel];way(" + bbox + ")[tourism=guest_house];way(" + bbox + ")[leisure=resort];);out center;";
    // console.log("Maps:onSearchRestaurantsClick-url", url);
    this.isVisibleProgressSpinner = true;
    const result = await this.http.get<any>(url).toPromise().catch(event => {
      // console.log("Maps:onSearchHotelsClick:Error-event", event);
      let result = event;
      if (!result.ok) { result.type = "error" }
      // console.log("Maps:onSearchHotelsClick:Error-result", result);
      return result;
    });
    // console.log("Maps:onSearchHotelsClick-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      this.showOverpassRequestError("search hotels", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    this.elements = result.elements;
    this.drawOSMElements("hotel", this.elements);
    const info = "bbox:" + bbox.toString();
    this.createUsageLog(EnumActionType.search_hotels_click, info);
  }
  public async onSearchSupermarketsClick(category: string) {
    // console.log("Maps:onSearchSupermarketsClick");
    this.setPoisStatus("supermarkets", !this.isShowSupermarketsActive);
    if (!this.isShowSupermarketsActive) { return; }
    const zoom = this.view.getZoom();
    // console.log("Maps:onSearchSupermarketsClick-zoom", zoom);
    if (zoom < 10) { this.view.setZoom(10); }
    const extent = this.view.calculateExtent(this.map.getSize());
    // console.log("Maps:onSearchSupermarketsClick-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    // console.log("Maps:onSearchSupermarketsClick-coordLL", coordLL);
    // console.log("Maps:onSearchSupermarketsClick-coordUR", coordUR);
    const bbox = [coordLL[1], coordLL[0], coordUR[1], coordUR[0]];
    // console.log("Maps:onSearchSupermarketsClick-bbox", bbox);
    const url = "https://www.overpass-api.de/api/interpreter?data=[out:json];" +
      "node(" + bbox + ")[shop=supermarket];out;way(" + bbox + ")[shop=supermarket];out center;";
    // console.log("Maps:onSearchSupermarketsClick-url", url);
    if (url === "") { return; }
    this.isVisibleProgressSpinner = true;
    const result = await this.http.get<any>(url).toPromise().catch(event => {
      // console.log("Maps:onSearchSupermarketsClick:Error-event", event);
      let result = event;
      if (!result.ok) { result.type = "error" }
      // console.log("Maps:onSearchSupermarketsClick:Error-result", result);
      return result;
    });
    // console.log("Maps:onSearchSupermarketsClick-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      this.showOverpassRequestError("search supermarkets", EnumGlobalStatusCode.HttpError, result);

      this.isOnline = false;
      return;
    }
    this.elements = result.elements;
    this.drawOSMElements("supermarket", this.elements);
    const info = "bbox:" + bbox.toString();
    this.createUsageLog(EnumActionType.search_supermarkets_click, info);
  }
  private drawOSMElements(type: string, elements: any[]) {
    for (const element of elements) {
      if (type === "resto") {
        this.poisLayer.setStyle(this.restoStyle);
        this.drawOsmElementResto(element);
      }
      if (type === "hotel") {
        this.drawOsmElementHotel(element);
        this.poisLayer.setStyle(this.hotelStyle);
      }
      if (type === "supermarket") {
        this.drawOsmElementSupermarket(element);
        this.poisLayer.setStyle(this.supermarketStyle);
      }
    }
  }
  private drawOsmElementResto(element: any) {
    // console.log("Maps:addPlaceMarkerOSMElementResto", element);
    let lonlat = null;
    if (element.type === "node") {
      lonlat = [element.lon, element.lat];
    }
    if (element.type === "way") {
      lonlat = [element.center.lon, element.center.lat];
    }
    if (!lonlat) { return; }
    let name = element.tags.name;
    if (element.tags["name:en"]) { name = element.tags["name:en"]; }
    const iconFeature = new Feature({
      geometry: new Point(transform(lonlat, "EPSG:4326", "EPSG:3857")),
      idOSM: element.id,
      type: "poi",
      cat: "resto",
      name,
      tags: element.tags,
    });
    // console.log("Maps:addPlaceMarkerOSMElement-iconFeature", iconFeature);
    this.poisSource.addFeature(iconFeature);
  }
  private drawOsmElementHotel(element: any) {
    // console.log("Maps:addPlaceMarkerOSMElementHotel", element);
    let lonlat = null;
    if (element.type === "node") {
      lonlat = [element.lon, element.lat];
    }
    if (element.type === "way") {
      lonlat = [element.center.lon, element.center.lat];
    }
    if (!lonlat) { return; }
    const iconFeature = new Feature({
      geometry: new Point(transform(lonlat, "EPSG:4326", "EPSG:3857")),
      idOSM: element.id,
      type: "poi",
      cat: "hotel",
      name: element.tags.name,
      tags: element.tags,
    });
    // console.log("Maps:addPlaceMarkerOSMElement-iconFeature", iconFeature);
    this.poisSource.addFeature(iconFeature);
  }
  private drawOsmElementSupermarket(element: any) {
    // console.log("Maps:addPlaceMarkerOSMElementSupermarket", element);
    let lonlat = null;
    if (element.type === "node") {
      lonlat = [element.lon, element.lat];
    }
    if (element.type === "way") {
      lonlat = [element.center.lon, element.center.lat];
    }
    if (!lonlat) { return; }
    const iconFeature = new Feature({
      geometry: new Point(transform(lonlat, "EPSG:4326", "EPSG:3857")),
      idOSM: element.id,
      type: "poi",
      cat: "supermarket",
      name: element.tags.name,
      tags: element.tags,
    });
    // console.log("Maps:addPlaceMarkerOSMElement-iconFeature", iconFeature);
    this.poisSource.addFeature(iconFeature);
  }
  public onPoisLayerCatShowClick(cat: OsmLayerConfigCategory) {
    cat.open = true;
  }
  public onSearchPoisClick() {
    // console.log("Maps:onSearchPoisClick");
    this.setPoisStatus("pois", !this.isShowPoisActive);
    if (!this.isShowPoisActive) { return; }
    if (!this.poisLayerConfig) { this.poisLayerConfig = createPoisLayerConfigBicycle(this.uiType); }
    this.createPoisFilterPopup();
  }
  public async searchPois() {
    // clear popup
    this.isPoisFilterPopupVisible = false;
    // clear pois-source
    this.poisSource.clear();
    // search & draw pois-layers
    for (const plCat of this.poisLayerConfig) {
      for (const pl of plCat.layers) {
        if (pl.visible) { this.searchPoisLayer(pl); }
      }
    }
  }
  public async searchPoisLayer(poisLayer: OsmLayerConfig) {
    const category = poisLayer.id;
    // console.log("Maps:searchPoisLayer-category", category);
    const zoom = this.view.getZoom();
    // console.log("Maps:searchPoisLayer-zoom", zoom);
    if (zoom < 10) { this.view.setZoom(10); }
    const extent = this.view.calculateExtent(this.map.getSize());
    // console.log("Maps:searchPoisLayer-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    const bbox = [coordLL[1], coordLL[0], coordUR[1], coordUR[0]];
    // console.log("Maps:searchPoisLayer-bbox", bbox);
    let url = "";
    if (category) {
      url = "https://www.overpass-api.de/api/interpreter?data=[out:json];" +
        "node(" + bbox + ")" + poisLayer.tags + ";out;way(" + bbox + ")" + poisLayer.tags + ";out center;";
    }
    // console.log("Maps:searchPoisLayer-url", url);
    if (url === "") { return; }
    this.isVisibleProgressSpinner = true;
    const result = await this.http.get<any>(url).toPromise().catch(event => {
      // console.log("Maps:onSearchPoisClick:Error-event", event);
      let result = event;
      if (!result.ok) { result.type = "error" }
      // console.log("Maps:onSearchPoisClick:Error-result", result);
      return result;
    });
    // console.log("Maps:searchPoisLayer-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      this.showOverpassRequestError("search pois", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    this.elements = result.elements;
    // console.log("Maps:searchPoisLayer-before-draw", this.elements);
    this.drawOsmElementLayer(poisLayer, this.elements);
    const info = "category:" + category + ";bbox:" + bbox.toString();
    this.createUsageLog(EnumActionType.search_pois_click, info);
  }
  private drawOsmElementLayer(poisLayer: OsmLayerConfig, elements: any[]) {
    // console.log("Maps:drawOsmElementLayer-elements", elements);
    for (const element of elements) {
      this.drawOsmElementPoi(element, poisLayer);
    }
  }
  private drawOsmElementPoi(element: any, poisLayer: OsmLayerConfig) {
    // console.log("Maps:drawOsmElementPois-poisLayer", poisLayer);
    // console.log("Maps:drawOsmElementPois-element", element);
    // console.log("Maps:drawOsmElementPois-style", style);
    const style = poisLayer.style;
    let lonlat = null;
    if (element.type === "node") {
      lonlat = [element.lon, element.lat];
    }
    if (element.type === "way") {
      lonlat = [element.center.lon, element.center.lat];
    }
    if (!lonlat) { return; }
    const elementFeature = new Feature({
      geometry: new Point(transform(lonlat, "EPSG:4326", "EPSG:3857")),
      idOSM: element.id,
      type: "poi",
      cat: poisLayer.id,
      name: element.tags.name,
      tags: element.tags,
    });
    elementFeature.setStyle(style);
    // console.log("Maps:drawOsmElementPois-iconFeature", elementFeature);
    this.poisSource.addFeature(elementFeature);
  }

  public async searchPoiById(idOSM: string) {
    // console.log("Maps:searchPoiById", idOSM);
    if (!idOSM) { return; }

    let type: string;
    let id: string = idOSM;
    let url = "https://www.overpass-api.de/api/interpreter?data=[out:json];";
    if (idOSM.startsWith("node/")) {
      type = "node";
      id = idOSM.slice(5);
      url += "node(" + id + ");out";
    }
    if (idOSM.startsWith("way/")) {
      type = "way";
      id = idOSM.slice(4);
      url += "way(" + id + ");out center;";
    }
    if (idOSM.startsWith("relation/")) {
      type = "relation";
      id = idOSM.slice(9);
      url += "relation(" + id + ");out center;";
    }
    if (idOSM.startsWith("polyline:")) {
      type = "polyline";
      id = idOSM.slice(9);
      url += "way(" + id + ");out center;";
    }
    if (!type) {
      url += "node(" + id + ");out;way(" + id + ");out center;";
    }
    // console.log("Maps:searchPoiById-url", url);
    this.isVisibleProgressSpinner = true;
    const result = await this.http.get<any>(url).toPromise().catch(event => {
      console.log("Maps:onSearchPoisClick:Error-event", event);
      let result = event.error;
      // console.log("Maps:onSearchPoisClick:Error-result", result);
      return result;
    });
    // console.log("Maps:searchPoiById-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      this.showOverpassRequestError("search poi", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    const elements = result.elements;
    // console.log("Maps:searchPoiById-elements", elements);
    return elements[0];
  }

  // utils - GPX-Route
  private convertORSRouteToGPXRoute_old(ORSRoute) {
    const track = {} as Track;
    track.name = "Trip4You-route";
    if (this.currentRoute.name) { track.name = this.currentRoute.name; }
    track.type = ORSRoute.metadata.query.profile;
    track.author = "Trip4You/maps - anonymous user";
    const feature = ORSRoute.features[0];
    track.trackPoints = new Array<TrackPoint>();
    for (const wp of feature.geometry.coordinates) {
      const tp = {} as TrackPoint;
      tp.order = feature.geometry.coordinates.indexOf(wp);
      tp.lng = wp[0];
      tp.lat = wp[1];
      tp.ele = wp[2];
      track.trackPoints.push(tp);
    }
    return track;
  }

  // utils - navigation/route
  private isCoordAtRouteStartPoint(routePolyline: RoPolyline, coord: number[], maxDist: number) {
    const deltaXStart = Math.abs(routePolyline.points[0].x - coord[0]);
    const deltaYStart = Math.abs(routePolyline.points[0].y - coord[1]);
    if (deltaXStart <= maxDist && deltaYStart <= maxDist) {
      return true;
    } else { return false; }
  }
  private isCoordAtRouteEndPoint(routePolyline: RoPolyline, coord: number[], maxDist: number) {
    const indexEnd = this.routePolyline.points.length - 1;
    const deltaXEnd = Math.abs(routePolyline.points[indexEnd].x - coord[0]);
    const deltaYEnd = Math.abs(routePolyline.points[indexEnd].y - coord[1]);
    if (deltaXEnd <= maxDist && deltaYEnd <= maxDist) {
      return true;
    } else { return false; }
  }
  public calculateDoneRouteDistance(coord: number[], maxDistance: number) {
    // console.log("Maps:calculateDistance-coord", coord);
    const point = { x: coord[0], y: coord[1] } as RoPoint;
    const dist = calculatePolylinePointIntersectionAt(this.routePolyline, point, maxDistance);
    if (isNaN(dist)) {
      // dist =
    }
    if (isNaN(dist) && this.listOffRoutePositions.length < 5) { this.listOffRoutePositions.push(coord); }
    return dist;
  }

  public test1() {
    // console.log("Maps:test1");
    const point = { x: 1712006.1710, y: 5944740.1964 } as RoPoint;
    const dist = calculatePolylinePointIntersectionAt(this.routePolyline, point, this.maxDistanceFromRoute);
    // console.log("Maps:test1-routePolyline", this.routePolyline);
    // console.log("Maps:test1-dist", dist);
  }
  public calculateActualRouteStepResult(doneDistance: number) {
    // console.log("Maps:calculateActualRouteStep-doneDistance", doneDistance);
    const segments = this.geojsonRoute.features[0].properties.segments;

    let currentSegmentIndex = 0;
    const result = { index: -1 } as RouteStepResult;
    let at = 0.0;
    let rest = 0.0;
    let duration = 0.0;
    for (const segment of segments) {
      let currentStepIndex = -1;
      currentSegmentIndex = segments.indexOf(segment);
      // console.log("Maps:calculateActualRouteStep-currentSegmentIndex", currentSegmentIndex);
      for (const step of segment.steps) {
        // console.log("Maps:calculateActualRouteStep-step", step);
        currentStepIndex += 1;
        // console.log("Maps:calculateActualRouteStep-index", currentStepIndex);
        if (at + step.distance <= doneDistance) {
          at += step.distance;
          rest = 0.0;
          duration += step.duration;
        } else {
          rest = at + step.distance - doneDistance;
          result.index = currentStepIndex;
          result.step = step;
          result.rest = rest;
          // result.durationToEnd = this.calculateActualDurationToEnd(result);
          duration += step.duration / step.distance * (step.distance - rest);
          result.durationToEnd = this.routeDuration - duration;
          // console.log("Maps:calculateActualRouteStep-result.durationToEnd", result.durationToEnd);

          if (currentStepIndex !== segment.steps.length - 1) {
            // console.log("Maps:calculateActualRouteStep-currentSegmentIndexA", currentSegmentIndex);
            // console.log("Maps:calculateActualRouteStep-segment", segment);
            result.nextStep = segment.steps[currentStepIndex + 1];
          }
          if (currentStepIndex === segment.steps.length - 1 && currentSegmentIndex !== segments.length - 1) {
            // console.log("Maps:calculateActualRouteStep-currentSegmentIndexB", currentSegmentIndex);
            result.nextStep = segments[currentSegmentIndex + 1].steps[0];
          }
          return result;
        }
      }
    }
    return result;
  }

  public getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public calculateGpxRouteLength() {
    const features = this.gpxRouteSource.getFeatures();
    // console.log("Maps:testPolylineLength3-features", features);
    let length = 0.0;
    for (const feature of features) {
      const geom = feature.getGeometry();
      // console.log("Maps:testPolylineLength3-geom", geom);
      if (geom instanceof Point) {
        // console.log("Maps:testPolylineLength3-Point");
      }
      if (geom instanceof LineString || geom instanceof MultiLineString) {
        // console.log("Maps:testPolylineLength3-LineString");
        length += getLength(geom);
      }
    }
    return length;
  }
  public calculateTrackingRouteLength() {
    const features = this.trackingSource.getFeatures();
    // console.log("Maps:testPolylineLength3-features", features);
    let length = 0.0;
    for (const feature of features) {
      const geom = feature.getGeometry();
      // console.log("Maps:testPolylineLength3-geom", geom);
      if (geom instanceof Point) {
        // console.log("Maps:testPolylineLength3-Point");
      }
      if (geom instanceof LineString || geom instanceof MultiLineString) {
        // console.log("Maps:calculateTrackingRouteLength-LineString", geom);
        length += getLength(geom);
      }
    }
    return length;
  }
  public testPolylineLength1_false(polyline: RoPolyline) {
    const point1 = this.geojsonRoute.features[0].geometry.coordinates[0];
    let coord1 = new Array<number>();
    coord1.push(point1[1]);
    coord1.push(point1[0]);
    // console.log("Maps:testPolylineLength-coord1", coord1);
    let length = 0.0;
    for (let i = 1; i < polyline.points.length; i++) {
      const point2 = this.geojsonRoute.features[0].geometry.coordinates[i];
      const coord2 = new Array<number>();
      coord2.push(point2[1]);
      coord2.push(point2[0]);
      // console.log("Maps:testPolylineLength-coord2", coord2);
      length += getDistance(coord1, coord2, 6378137);
      // console.log("Maps:testPolylineLength-length", length);
      coord1 = coord2;
    }
    return length;
  }
  public testPolylineLength2_false(polyline: RoPolyline) {
    const point1 = polyline.points[0];
    const coord1 = new Array<number>();
    coord1.push(point1.y);
    coord1.push(point1.x);
    // console.log("Maps:testPolylineLength-coord1", coord1);
    let coord1T = transform(coord1, "EPSG:3857", "EPSG:4326");
    // console.log("Maps:testPolylineLength-coord1T", coord1T);
    let length = 0.0;
    for (let i = 1; i < polyline.points.length; i++) {
      const point2 = polyline.points[i];
      const coord2 = new Array<number>();
      coord2.push(point2.y);
      coord2.push(point2.x);
      const coord2T = transform(coord2, "EPSG:3857", "EPSG:4326");
      length += getDistance(coord1T, coord2T, 6378137);
      // console.log("Maps:testPolylineLength-length", length);
      coord1T = coord2T;
    }
    return length;
  }

  public async openIndexedDb() {
    const request = window.indexedDB.open("TileDb", 3);
    // console.log("Maps:openIndexedDb-request", request);

    request.onsuccess = (event) => {
      // console.log("Maps:openIndexedDb-onsuccess-event", event);
      this.indexedDb = (event.target as any).result;
    };

    request.onupgradeneeded = (event) => {
      // console.log("Maps:openIndexedDb-onupgradeneeded-event", event);
      this.indexedDb = (event.target as any).result;
      const version = this.indexedDb.version;
      // console.log("Maps:openIndexedDb-onupgradeneeded-version", version);
      const objectStoreNames = this.indexedDb.objectStoreNames;
      if (!objectStoreNames.contains("MapTiles")) {
        const objectStoreTiles = this.indexedDb.createObjectStore("MapTiles", { keyPath: "key" });
        objectStoreTiles.transaction.oncomplete = () => {
        };
      }
      if (!objectStoreNames.contains("Maps")) {
        const objectStoreMaps = this.indexedDb.createObjectStore("Maps", { keyPath: "key" });
        objectStoreMaps.transaction.oncomplete = () => {
        };
      }
    };

    request.onerror = (event) => {
      console.error("Maps:openIndexedDb-onerror-event", event);
    };
  }

  private createIndexedDbMapSource(indexedDb: IDBDatabase) {
    // console.log("createIndexedDBOsmSource:");
    // indexedDb-osm-source
    const indexedDbOsmSource = new XYZ({
      tileUrlFunction: (tileCoord) => {
        // console.log("------createIndexedDbOsmSource:tileUrlFunction-tileCoord", tileCoord);
        const itemKey = "T_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
        // const itemKey = "X_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
        return itemKey;
      },
      tileLoadFunction: (tile, src) => {
        // console.log("------createIndexedDbOsmSource:tileLoadFunction-tile", tile);
        // console.log("------createIndexedDbOsmSource:tileLoadFunction-src", src);
        const promise = new Promise((resolve, reject) => {
          const transaction = indexedDb.transaction(["MapTiles"], "readonly");
          const objectStore = transaction.objectStore("MapTiles");
          // const countRequest = objectStore.count(src);
          // console.log("createIndexedDbOsmSource:tileLoadFunction:indexedDb-countRequest", countRequest);
          const request = objectStore.get(src);
          // console.log("------createIndexedDBOsmSource:tileLoadFunction-request", request);
          request.onsuccess = (event) => {
            // console.log("------createIndexedDBOsmSource:tileLoadFunction:indexedDb-onsuccess-event", event);
            // console.log("------createIndexedDBOsmSource:tileLoadFunction:indexedDb-onsuccess-request", request);
            const tileObj = request.result;
            if (tileObj) {
              // console.log("+++++++++createIndexedDBOsmSource:tileLoadFunction-onsuccess-tileObj-key", tileObj.key);
              const data = tileObj.data;
              // console.log("------createIndexedDBOsmSource:tileLoadFunction-value", value);
              resolve(data);
            }
            if (!tileObj) {
              // console.log("------createIndexedDBOsmSource:tileLoadFunction-onsuccess-not-found");
              resolve(undefined);
            }
          };
          request.onerror = (event) => {
            // console.log("------createIndexedDBOsmSource:tileLoadFunction:indexedDb-tileLoadFunction-request-onerror", event);
            reject();
          };
        });
        promise.then((result) => {
          // console.log("------createIndexedDBOsmSource:tileLoadFunction-result", result);
          if (result) { (tile as any).getImage().src = result; }
          if (!result) { (tile as any).getImage().src = ""; }
        });
      }
    });
    return indexedDbOsmSource;
  }



  // map&draw-functions  ----------------------------------------------------------------------------------------------------------------
  public async initMap() {
    // console.log("Maps:initMap-start", this.map);
    if (!this.isOnline) { return; }
    if (this.map) { return; }

    // base-map-layer/view/map
    this.basemapLayer = createBaseLayer();
    this.serverMapSource = getOsmMapSource();
    // this.serverOsmSource = getMaptilerOutdoorSource();
    // this.serverOsmSource = getGoogleMapsSource();
    this.tileSource = this.serverMapSource;
    this.basemapLayer.setSource(this.tileSource);

    // test for offline-maps
    // open indexedDb
    this.openIndexedDb();
    setTimeout(() => {
      // console.log("Maps:initMap-indexedDb", this.indexedDb);
      this.indexedDbMapSource = (this.createIndexedDbMapSource(this.indexedDb) as any);
      // this.basemapLayer.setSource(this.indexedDbOsmSource);
    }, 1000);

    // create view & map
    this.view = createView(this.longitude, this.latitude, this.zoom);
    // console.log("Maps:initMap-view", this.view);
    this.map = createMap(this.basemapLayer, this.view);
    this.layers = this.map.getLayers();
    // console.log("Maps:initMap-map.size", this.map.getSize());
    // overlay-layer
    this.ovlmapLayer = createOverlayLayer();
    this.map.addLayer(this.ovlmapLayer);

    // layers
    // layer for geolocation
    this.geoLocationLayer = createGeoLocationLayer();
    this.map.addLayer(this.geoLocationLayer);
    this.geoLocationSource = this.geoLocationLayer.getSource();
    // layer for saved-routes
    this.savedRoutesLocalyLayer = createSavedRoutesLocalyLayer();
    this.map.addLayer(this.savedRoutesLocalyLayer);
    this.savedRoutesLocalySource = this.savedRoutesLocalyLayer.getSource();
    this.savedRoutesServerLayer = createSavedRoutesServerLayer();
    this.map.addLayer(this.savedRoutesServerLayer);
    this.savedRoutesServerSource = this.savedRoutesServerLayer.getSource();
    // layer for public routes
    this.publicRoutesLayer = createPublicRoutesLayer();
    this.map.addLayer(this.publicRoutesLayer);
    this.publicRoutesSource = this.publicRoutesLayer.getSource();

    // layer for saved-places
    this.savedPlacesLocalyLayer = createSavedPlacesLocalyLayer();
    this.map.addLayer(this.savedPlacesLocalyLayer);
    this.savedPlacesLocalySource = this.savedPlacesLocalyLayer.getSource();
    this.savedPlacesServerLayer = createSavedPlacesServerLayer();
    this.map.addLayer(this.savedPlacesServerLayer);
    this.savedPlacesServerSource = this.savedPlacesServerLayer.getSource();
    // layer for planned route
    this.routePlannerRouteLayer = createRouteLayer();
    this.map.addLayer(this.routePlannerRouteLayer);
    // layer for route-arrows
    this.routePlannerRouteArrowLayer = createRouteArrowLayer();
    this.map.addLayer(this.routePlannerRouteArrowLayer);
    this.routePlannerRouteArrowSource = this.routePlannerRouteArrowLayer.getSource();
    // layer for route-pois
    this.routePoisLayer = createRoutePoisLayer();
    this.map.addLayer(this.routePoisLayer);
    this.routePoisSource = this.routePoisLayer.getSource();
    // layer for waypoints
    this.routePlannerWayPointsLayer = createWayPointLayer();
    this.map.addLayer(this.routePlannerWayPointsLayer);
    this.routePlannerWayPointsSource = this.routePlannerWayPointsLayer.getSource();
    // layer for route-pointer
    this.routePointerLayer = createRoutePointerLayer();
    this.map.addLayer(this.routePointerLayer);
    this.routePointerSource = this.routePointerLayer.getSource();
    // layer for search-points
    this.searchPointsLayer = createSearchPointLayer();
    this.map.addLayer(this.searchPointsLayer);
    this.searchPointsSource = this.searchPointsLayer.getSource();
    // layer for markers
    this.markerPointsLayer = createMarkerLayer();
    this.map.addLayer(this.markerPointsLayer);
    this.markerPointsSource = this.markerPointsLayer.getSource();
    // layer for pois
    this.poisLayer = createPoisLayer();
    this.map.addLayer(this.poisLayer);
    this.poisSource = this.poisLayer.getSource();
    this.restoStyle = createRestoStyle();
    this.hotelStyle = createHotelStyle();
    this.supermarketStyle = createSupermarketStyle();
    // layer for gpx-route + waypoints
    this.gpxRouteLayer = createGpxRouteLayer();
    this.gpxRouteSource = this.gpxRouteLayer.getSource();
    this.map.addLayer(this.gpxRouteLayer);
    this.gpxWaypointLayer = createGpxWaypointLayer();
    this.gpxWaypointSource = this.gpxWaypointLayer.getSource();
    this.map.addLayer(this.gpxWaypointLayer);
    // layer for tracking-route
    this.trackingLayer = createTrackingRouteLayer();
    this.map.addLayer(this.trackingLayer);
    this.trackingSource = this.trackingLayer.getSource();
    // layer for tracking-points
    this.trackingLocationLayer = createTrackingPointLayer();
    this.map.addLayer(this.trackingLocationLayer);
    this.trackingLocationSource = this.trackingLocationLayer.getSource();
    // layer for live-tracking
    this.liveTrackingLayer = createLiveTrackingLayer();
    this.map.addLayer(this.liveTrackingLayer);
    this.liveTrackingSource = this.liveTrackingLayer.getSource();
    // layer for map-extents
    this.mapExtentLayer = createMapExtentLayer();
    this.map.addLayer(this.mapExtentLayer);
    this.mapExtentSource = this.mapExtentLayer.getSource();

    // map-controls
    const mapControls = this.map.getControls();
    // console.log("Maps:initMap-mapControls", mapControls);
    // Main-Control
    this.mainControl = new MainControl({ component: this }, this.userLanguage);
    mapControls.push(this.mainControl);
    // pois-Control
    if (!this.hidePoisControl) {
      this.poisControl = new PoisControl({ component: this }, this.userLanguage);
      mapControls.push(this.poisControl);
    }
    // mouse-position-control
    this.MousePositionControl = createMousePositionControl(this.uiType);
    mapControls.push(this.MousePositionControl);
    if (!this.showMousePosition) { this.switchOnOffMousePositionControl(false); }
    // console.log("Maps:initMap-mouseControl", this.MousePositionControl);

    // interactions
    const layers = new Array<VectorLayer<any>>();
    layers.push(this.routePlannerWayPointsLayer);
    // console.log("Maps:initMap-layers", layers);
    this.waypointDragger = new Translate({
      layers,
    });
    this.waypointDragger.on("translatestart", (event: TranslateEvent) => {
      // console.log("Maps:initMap-waypointDragger-Event", event);
      this.onDragWayPointStart(event);
    });
    this.waypointDragger.on("translateend", (event: TranslateEvent) => {
      // console.log("Maps:initMap-waypointDragger-Event", event);
      this.onDragWayPointEnd(event);
    });
    // console.log("Maps:initMap-waypointDragger", this.featureDragger);
    this.map.addInteraction(this.waypointDragger);

    // map-click event
    this.map.on("click", (event) => {
      // console.log("Maps:initMap-clickEvent", event);
      if (this.isScreenLocked) { return; }
      this.onMapClick(event);
      // test geolocation-move
      // this.mapCoordinates[0] = this.mapCoordinates[0] + this.getRandomInt(50.0);
      // this.mapCoordinates[1] = this.mapCoordinates[1] + this.getRandomInt(50.0);
      // this.onPositionChangedTourStatistics()
    });

    // pointer-down event
    // §update pointerdown
    // this.map.on("pointerdown", (event) => {
    //   // this.map.on(olEvents., (event) => {
    //   console.log("Maps:initMap-pointerdownEvent", event);
    //   this.onMapPointerDown(event);
    // });
    // pointer-move event
    this.map.on("pointermove", (event) => {
      // console.log("Maps:initMap-pointermoveEvent", event);
      if (!this.hasPointer) { return; }
      this.onMapPointerMove(event);
    });
    // pointer-up event
    // §update pointerup
    // this.map.on("pointerup", (event) => {
    //   console.log("Maps:initMap-pointerupEvent", event);
    //   this.onMapPointerUp(event);
    // });

    // map-move event
    this.map.on("moveend", (event) => {
      // console.log("Maps:initMap-moveendEvent-mapElement", this.mapElement);
      const zoom = this.view.getZoom();
      if (zoom !== this.zoom) {
        // console.log("Maps:initMap-moveendEvent-zoom", zoom);
        this.zoom = zoom;
        this.drawRouteArrows(this.routePlannerRouteArrowSource);
      }
      // this.updateUrl();
    });
    // change view-rotation event
    this.map.getView().on("change:rotation", (event) => {
      // console.log("Maps:initMap-change:rotation-viewElement", this.view);
      const rotation = this.view.getRotation();
      if (rotation === 0.0) {
        // console.log("Maps:initMap-change:rotation", rotation);
        // this.viewRotation = rotation;
        this.drawRouteArrows(this.routePlannerRouteArrowSource);
      }
    });

    // create-geolocation
    if ("geolocation" in navigator) { this.createGeolocation(); }

    this.isVisibleMap = true;
    if (this.uiType === "L") { this.showMap(); }
    if (this.uiType === "S") { if (this.isActiveDialog) { this.hideMap(); } }
    // console.log("Maps:initMap-map.size", this.map.getSize());
    // console.log("Maps:initMap-end", this.map);

    // create hoverRoutePopup
    this.createHoverRouteMapPopup(undefined);


  }

  // create-geolocation
  private createGeolocation() {
    this.geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });
    this.geolocation.setTracking(true);

    // create-LocationFeature at layer
    this.locationFeature = new Feature();
    this.locationFeature.setStyle(
      new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({
            color: "#3399CC",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
        // image: new Icon({
        //   src: "./assets/icons/location-enter.svg",
        //   rotation: 0,
        // })
      })
    );
    this.geoLocationSource.addFeature(this.locationFeature);
    // create-accuracy-feature
    const accuracyFeature = new Feature();
    this.geoLocationSource.addFeature(accuracyFeature);
    // create-direction-line-feature
    this.directionFeature = new Feature();
    const directionStyle = new Style({
      stroke: new Stroke({
        color: "#3399CC",
        width: 3,
      }),
    });
    this.directionFeature.setStyle(directionStyle);
    this.geoLocationSource.addFeature(this.directionFeature);
    // create-arrow-feature
    this.arrowFeature = new Feature();
    this.arrowFeature.setStyle(directionStyle);
    this.geoLocationSource.addFeature(this.arrowFeature);
    // create change-event
    this.geolocation.on("change:accuracyGeometry", (event) => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
      // this.geolocationText = "Accuracy: " + this.geolocation.getAccuracy() + "[m]" + " Alt: " + this.geolocation.getAltitude() + "[m]"
      //   + " Heading: " + this.geolocation.getHeading() + "[rad]" + " Speed: " + this.geolocation.getSpeed() + "[m/s]";
      // location-arrow
      // const coordinates = this.geolocation.getPosition() as number[];
      const heading = this.geolocation.getHeading();
      // const heading = Math.PI * 0.75;
      // console.log("TestMap1:initMap-heading1", heading);
      let speedOri = this.geolocation.getSpeed();
      // const speedOri = 1.0;
      if (!speedOri) { speedOri = 0.0; }
      let updateArrow = true;
      this.speedCheck = speedOri;
      if (speedOri < 0.5) { updateArrow = false; }
      this.updateArrowCheck = updateArrow;
      if (updateArrow) { this.currentHeading = heading; }
      // heading with device-orientation
      if (this.orientationAbsolute && (!this.isNavigationStarted || !updateArrow)) {
        // this.currentHeading = this.compassHeading * Math.PI / 180.0;
        this.currentHeading = 2.0 * Math.PI - this.orientationAlpha * Math.PI / 180.0;
      }
      // create orientation-arrow
      this.createOrientationArrow(this.directionFeature, this.arrowFeature, this.currentHeading);
    });

    // event geolocation-position-changed
    this.geolocation.on("change:position", (event) => {
      if (this.showLog) { console.log("Maps:change-position-geolocation", this.geolocation); }
      this.mapCoordinates = this.geolocation.getPosition();
      // console.log("Maps:change-position-mapCoordinates", this.mapCoordinates);
      if (!this.mapCoordinatesRotationOld) { this.mapCoordinatesRotationOld = this.mapCoordinates; }
      if (!this.mapCoordinatesNavigationOld) { this.mapCoordinatesNavigationOld = this.mapCoordinates; }
      // if (!this.mapCoordinatesTourStatisticsOld) { this.mapCoordinatesTourStatisticsOld = this.mapCoordinates; }
      // if (!this.geolocationAltiduteOld) { this.geolocationAltiduteOld = this.geolocation.getAltitude(); }
      // if (!this.geolocationLastTime) { this.geolocationLastTime = new Date(Date.now()); }

      // this.mapCoordinates[0] = this.mapCoordinates[0] + this.getRandomInt(500.0);
      // this.mapCoordinates[1] = this.mapCoordinates[1] + this.getRandomInt(500.0);
      // this.mapCoordinates[0] = 1712067.0;
      // this.mapCoordinates[1] = 5944332.0;
      // console.log("Maps:change-position", this.mapCoordinates);
      // console.log("Maps:change-position-heading", this.geolocation.getHeading());
      this.locationFeature.setGeometry(this.mapCoordinates ? new Point(this.mapCoordinates) : null);
      // const heading = this.geolocation.getHeading();
      // if (heading) {
      //   const locationStyle = this.locationFeature.getStyle();
      //   console.log("Maps:change-position-style1", locationStyle);
      //   locationStyle.getImage().rotation_ = heading;
      //   // this.locationFeature.setStyle(locationStyle);
      //   console.log("Maps:change-position-style2", locationStyle);
      // }
      // set current-position
      this.currentPositionMapCoord = {} as LngLat;
      this.currentPositionMapCoord.lng = this.mapCoordinates[0];
      this.currentPositionMapCoord.lat = this.mapCoordinates[1];
      // close missing-locator-popup, if exists
      if (this.dialogRefMissingLocator) { this.dialogRefMissingLocator.close(); }
      // set default zoom
      if (this.zoom === 4) {
        this.longitude = this.mapCoordinates[0];
        this.latitude = this.mapCoordinates[1];
        if (!this.zoomed) { this.zoomToCurrentLocation(14); }
      }
      this.buttonZoomToMyPosition.style.backgroundColor = "";

      // transform postion to lon/lat
      const coord = transform([this.currentPositionMapCoord.lng, this.currentPositionMapCoord.lat], "EPSG:3857", "EPSG:4326");
      // console.log("Maps:change-position-coord", coord);
      this.currentPositionLngLat = {} as LngLat;
      this.currentPositionLngLat.lng = coord[0];
      this.currentPositionLngLat.lat = coord[1];
      // test to set start-waypoint to current position
      if (this.currentRoute.wayPoints && this.currentRoute.wayPoints[0].type === "$current") {
        const x = this.currentRoute.wayPoints[0].coordLon;
        // console.log("Maps:change-position-x", x);
        if (!x) {
          // console.log("Maps:change-position-currentRoute1", this.currentRoute);
          this.currentRoute.wayPoints[0].coordLon = coord[0];
          this.currentRoute.wayPoints[0].coordLat = coord[1];
          this.calculateRoute();
        }
        // console.log("Maps:change-position-currentRoute2", this.currentRoute);
      }
      // if (this.trackingActive) { this.trackingPositionChanged(this.mapCoordinates); }

      // automatic map move center
      if (this.automaticMapMove) {
        const extent = this.view.calculateExtent(this.map.getSize());
        // console.log("Maps:change-mapCoordinates-extent", extent);
        let moveCenter = false;
        const extentX = extent[2] - extent[0];
        if (this.mapCoordinates[0] - extent[0] < extentX * 0.4) { moveCenter = true; }
        if (extent[2] - this.mapCoordinates[0] < extentX * 0.4) { moveCenter = true; }
        const extentY = extent[3] - extent[1];
        if (this.mapCoordinates[1] - extent[1] < extentY * 0.4) { moveCenter = true; }
        if (extent[3] - this.mapCoordinates[1] < extentY * 0.4) { moveCenter = true; }
        if (moveCenter) {
          const lon = this.mapCoordinates[0];
          const lat = this.mapCoordinates[1];
          this.view.setCenter([lon, lat]);
        }
      }
      // automatic map rotation
      if (this.automaticMapRotation) {
        this.deltaX = this.mapCoordinates[0] - this.mapCoordinatesRotationOld[0];
        // console.log("Maps:change-deltaX", this.deltaX);
        this.deltaY = this.mapCoordinates[1] - this.mapCoordinatesRotationOld[1];
        // console.log("Maps:change-deltaY", this.deltaY);
        const rotationIntervall = 25.0;
        if (Math.abs(this.deltaX) > rotationIntervall || Math.abs(this.deltaY) > rotationIntervall) {
          this.atan2 = Math.atan2(this.deltaY, this.deltaX);
          // console.log("Maps:change-atan2", this.atan2);
          this.rotation = Math.PI * 0.5 - this.atan2;
          this.rotation = this.rotation * -1.0;
          // console.log("Maps:change-rotation", this.rotation);
          this.map.getView().setRotation(this.rotation);
          this.drawRouteArrows(this.routePlannerRouteArrowSource);
          this.mapCoordinatesRotationOld = this.mapCoordinates;
        }
      }
      // navigation-geojsonRoute
      if (this.geojsonRoute) {
        if (this.isNavigationStarted) { this.onPositionChangedNavigation(); }
        if (!this.isNavigationStarted) { this.mapCoordinatesNavigationOld = this.mapCoordinates; }
      }
      // live-tracking
      if (this.currentLiveTrackingMe) {
        // console.log("Maps::change-position-lastLiveTrackingMePosition", this.lastLiveTrackingMePosition);
        this.onPositionChangedLiveTracking();
      }
    });

    // handle geolocation error.
    this.geolocation.on("error", (error) => {
      // console.log("Maps:error-error", error);
      // const isGooglebot = /Googlebot/.test(navigator.userAgent);
      // console.log("Maps:isGooglebot", isGooglebot);
      if (!this.presentedRoute) {
        this.geolocationError = (error as any).message;
        this.createMissingLocatorPopup(this.userLanguage, this.geolocationError);
      }
    });
  }

  private onPositionChangedLiveTracking() {
    // this.toDebugLog("onPositionChangedLiveTracking-enter");
    const now = new Date(Date.now());
    let sendPos = true;
    if (this.lastLiveTrackingMePosition) {
      // this.toDebugLog("onPositionChangedLiveTracking-at", this.lastLiveTrackingMePosition.at.toISOString());
      const timeDiff = now.getTime() - this.lastLiveTrackingMePosition.at.getTime();
      // console.log("Maps:change-position-timeDiff", timeDiff);
      // this.toDebugLog("onPositionChangedLiveTracking-timeDiff1", timeDiff);
      if (timeDiff < 60000) { sendPos = false; }
    }
    // console.log("Maps:change-position-sendPos", sendPos);
    // this.toDebugLog("onPositionChangedLiveTracking-sendPos", sendPos);
    if (sendPos) {
      // send tracking-position
      // this.toDebugLog("onPositionChangedLiveTracking-send-now", now);
      const newTrackPos = {} as LiveTrackPosition;
      newTrackPos.trackId = this.currentLiveTrackingMe.trackingId;
      newTrackPos.at = now;
      newTrackPos.coordLon = this.currentPositionLngLat.lng;
      newTrackPos.coordLat = this.currentPositionLngLat.lat;
      this.isOnline = this.commandSenderService.getIsOnline();
      if (this.isOnline) {
        this.globalService.addLiveTrackingPosition(newTrackPos, this.currentLiveTrackingMe.storeOnlyEndPosition);
        this.lastLiveTrackingMePosition = newTrackPos;
      }
    }
    if (now.getTime() > this.currentLiveTrackingMe.endTime.getTime()) {
      // $todo end tracking
      this.toDebugLog("onPositionChangedLiveTracking-exit");
      this.currentLiveTrackingMe = undefined;
      localStoreLiveTrackingMe(this.currentLiveTrackingMe);
      this.lastLiveTrackingMePosition = undefined;
    }
  }

  // create arrow geometry
  private createOrientationArrow(directionFeature: Feature<any>, arrowFeature: Feature<any>, currentHeading: number) {
    const coordinates = this.geolocation.getPosition() as number[];

    const resolution = this.view.getResolution();
    const zoom = this.view.getZoom();
    const length = zoom * resolution;
    const arrowLength = length * 2.0;

    const angle = (Math.PI * 0.5 - currentHeading);
    const posX = coordinates[0];
    const posY = coordinates[1];
    const diffX = Math.cos(angle) * arrowLength;
    const diffY = Math.sin(angle) * arrowLength;
    // console.log("TestMap1:initMap-diffX", diffX);
    // console.log("TestMap1:initMap-diffY", diffY);
    const point1 = new Array();
    point1.push(posX - diffX);
    point1.push(posY - diffY);
    // console.log("TestMap1:initMap-point1", point1);
    const point2 = new Array();
    point2.push(posX + diffX);
    point2.push(posY + diffY);
    // console.log("TestMap1:initMap-point2", point2);
    const points = new Array();
    points.push(point1);
    points.push(point2);
    const line = new LineString(points);
    // console.log("Maps:initMap-line", line);
    directionFeature.setGeometry(line);

    const angle1 = angle - Math.PI * 1.5;
    const points2 = new Array();
    const aL = arrowLength * 0.2;
    let x = -aL * 0.3;
    const y = aL;
    const point3 = new Array();
    let xx = x * Math.cos(angle1) - y * Math.sin(angle1);
    let yy = x * Math.sin(angle1) + y * Math.cos(angle1);
    point3.push(point2[0] + xx);
    point3.push(point2[1] + yy);
    // console.log("TestMap1:initMap-point3", point3);
    const point4 = new Array();
    x = aL * 0.3;
    xx = x * Math.cos(angle1) - y * Math.sin(angle1);
    yy = x * Math.sin(angle1) + y * Math.cos(angle1);
    point4.push(point2[0] + xx);
    point4.push(point2[1] + yy);
    // console.log("TestMap1:initMap-point4", point4);
    points2.push(point3);
    points2.push(point2);
    points2.push(point4);
    const line1 = new LineString(points2);
    arrowFeature.setGeometry(line1);
  }

  // map-click
  public async onMapClick(args) {
    // console.log("Maps:onMapClick-args", args);
    this.lastMapClickPosition = args.coordinate;
    const xLonLat = transform(args.coordinate, "EPSG:3857", "EPSG:4326");
    // console.log("Maps:onMapClick-xLonLat", xLonLat);

    this.longitude = xLonLat[0];
    this.latitude = xLonLat[1];
    // console.log("Maps:onMapClick-longitude", this.longitude);
    // console.log("Maps:onMapClick-latitude", this.latitude);

    this.lonLat = {} as LngLat;
    this.lonLat.lng = this.longitude;
    this.lonLat.lat = this.latitude;

    // identify OSM-data-element
    const saveIsVisibleNewPointPopup = this.isNewPointPopupVisible;
    this.closeAllPopups();
    let layerName = "";
    let type = "";
    let cat = "";
    let name = "";
    let id = -1;
    let idOSM: string;
    let lon: number;
    let lat: number;
    let tags;
    let label: string;
    this.selectedMapItemType = undefined;
    this.selectedMapPoint = undefined;
    this.selectedMapRoute = undefined;
    const features: Feature<any>[] = new Array();
    // const layers: VectorLayer<any>[] = new Array();
    const layers: VectorLayer<any>[] = new Array();
    const options = { hitTolerance: 6 };
    // if (this.hasPointer) { options.hitTolerance = 6; }
    this.map.forEachFeatureAtPixel(args.pixel, (feature, layer) => {
      features.push(feature as Feature<any>);
      layers.push(layer as VectorLayer<any>);
    }, options);
    // console.log("Maps:onMapClick-elements-features", features);
    // console.log("Maps:onMapClick-elements-layers", layers);
    if (features.length > 0) {
      const feature = features[0];
      const layer = layers[0];
      if (this.showLog) { console.log("Maps:onMapClick-elements-feature", feature); }
      if (this.showLog) { console.log("Maps:onMapClick-elements-layer", layer); }

      // layer-name
      layerName = layer.get("name");
      const featureName = feature.get("name");

      // geolocation-point
      if (layerName === "geo-location") {
        // console.log("Maps:onMapClick-geolocation-feature", feature);
        const geom = feature.getGeometry();
        const isPointGeom = geom instanceof Point;
        if (isPointGeom) {
          type = "geolocation";
          lon = this.currentPositionLngLat.lng;
          lat = this.currentPositionLngLat.lat;
          this.selectedMapPoint = {} as MapPlaceL;
          this.selectedMapPoint.layer = layerName;
          this.selectedMapPoint.type = type;
          this.selectedMapPoint.coordLon = lon;
          this.selectedMapPoint.coordLat = lat;
          await this.createGeolocationFeatureMapPopup(args, this.selectedMapPoint);
          return;
        }
      }

      // pois-layer
      if (layerName === "pois") {
        // console.log("Maps:onMapClick-elements-feature", feature);
        // const featureName = feature.get("name");
        type = "poi";
        cat = feature.get("cat"); // poi-category
        id = feature.get("id");
        idOSM = feature.get("idOSM");
        const geometry = feature.getGeometry();
        const coords = geometry.flatCoordinates;
        // console.log("Maps:onMapClick-elements-coords", coords);
        const coordsLonLat = transform(coords, "EPSG:3857", "EPSG:4326");
        // console.log("Maps:onMapClick-elements-coordsLonLat", coordsLonLat);
        lon = coordsLonLat[0];
        lat = coordsLonLat[1];
        name = featureName;
        tags = feature.get("tags");
        // console.log("Maps:onMapClick-elements-name", name);
        // console.log("Maps:onMapClick-elements-tags", tags);
        this.selectedMapPoint = ({} as MapPlaceL);
        this.selectedMapPoint.layer = layerName;
        this.selectedMapPoint.type = type;
        this.selectedMapPoint.cat = cat;
        this.selectedMapPoint.id = id;
        this.selectedMapPoint.idOSM = idOSM;
        this.selectedMapPoint.coordLon = lon;
        this.selectedMapPoint.coordLat = lat;
        this.selectedMapPoint.name = name;
        this.selectedMapPoint.tags = tags;
        // console.log("Maps:onMapClick-selectedMapPoint", this.selectedMapPoint);
        await this.createPOIFeatureMapPopup(args, this.selectedMapPoint);
        return;
      }

      // route-pois
      if (layerName === "route-pois") {
        // console.log("Maps:onMapClick-elements-feature", feature);
        // const featureName = feature.get("name");
        type = "poi";
        cat = feature.get("cat"); // poi-category
        id = feature.get("id");
        idOSM = feature.get("idOSM");
        const geometry = feature.getGeometry();
        const coords = geometry.flatCoordinates;
        // console.log("Maps:onMapClick-elements-coords", coords);
        const coordsLonLat = transform(coords, "EPSG:3857", "EPSG:4326");
        // console.log("Maps:onMapClick-elements-coordsLonLat", coordsLonLat);
        lon = coordsLonLat[0];
        lat = coordsLonLat[1];
        name = featureName;
        tags = feature.get("tags");
        // console.log("Maps:onMapClick-elements-name", name);
        // console.log("Maps:onMapClick-elements-tags", tags);
        this.selectedMapPoint = ({} as MapPlaceL);
        this.selectedMapPoint.layer = layerName;
        this.selectedMapPoint.type = type;
        this.selectedMapPoint.cat = cat;
        this.selectedMapPoint.id = id;
        this.selectedMapPoint.idOSM = idOSM;
        this.selectedMapPoint.coordLon = lon;
        this.selectedMapPoint.coordLat = lat;
        this.selectedMapPoint.name = name;
        this.selectedMapPoint.tags = tags;
        // console.log("Maps:onMapClick-selectedMapPoint", this.selectedMapPoint);
        await this.createRoutePOIFeatureMapPopup(args, this.selectedMapPoint);
        return;
      }

      // way-points-layer
      if (layerName === "way-points") {
        // const featureName = feature.get("name");
        if (featureName) {
          type = "wp";
          id = feature.get("id");
          name = featureName;
          tags = feature.get("tags");
          if (tags) { label = tags.label; }
          // console.log("Maps:onMapClick-waypoint-feature", feature);
          // console.log("Maps:onMapClick-waypoint-name", name);
          this.selectedMapPoint = this.currentRoute.wayPoints[id];
          this.selectedMapPoint.layer = layerName;
          this.selectedMapPoint.type = "wp";
          this.selectedMapPoint.id = id;
          if (tags) { this.selectedMapPoint.label = tags.label; }
          this.canDeleteSelectedWayPoint = true;
          if (id === 0 && this.currentRoute.wayPoints.length === 2) { this.canDeleteSelectedWayPoint = false; }
          if (id === this.currentRoute.wayPoints.length - 1 && !this.currentRoute.backToStart && this.currentRoute.wayPoints.length === 2) {
            this.canDeleteSelectedWayPoint = false;
          }
          // console.log("Maps:onMapClick-canDeleteSelectedWayPoint", this.canDeleteSelectedWayPoint);
          await this.createWPFeatureMapPopup(args, this.selectedMapPoint);
        }
        return;
      }

      // saved-places-layer
      if (layerName === "saved-places-l" || layerName === "saved-places-s") {
        // const featureName = feature.get("name");
        if (featureName) {
          type = "savedplace-l";
          if (layerName === "saved-places-s") { type = "savedplace-s"; }
          cat = feature.get("cat");
          id = feature.get("id");
          idOSM = feature.get("idOSM");
          lon = feature.get("lon");
          lat = feature.get("lat");
          name = featureName;
          tags = feature.get("tags");
          // console.log("Maps:onMapClick-saved-place-feature", feature);
          // console.log("Maps:onMapClick-marker-name", name);
          this.selectedMapPoint = {} as MapPlaceL;
          this.selectedMapPoint.layer = layerName;
          this.selectedMapPoint.type = type;
          this.selectedMapPoint.cat = cat;
          this.selectedMapPoint.id = id;
          this.selectedMapPoint.idOSM = idOSM;
          this.selectedMapPoint.coordLon = lon;
          this.selectedMapPoint.coordLat = lat;
          this.selectedMapPoint.name = name;
          if (tags) { this.selectedMapPoint.label = tags.label; }
          this.selectedMapPoint.tags = tags;
          await this.createSavedPlaceFeatureMapPopup(args, this.selectedMapPoint);
        }
        return;
      }

      // marker-points-layer
      if (layerName === "marker-points") {
        // const featureName = feature.get("name");
        if (featureName) {
          id = feature.get("id");
          this.selectedMapPoint = this.markerPoints[id];
          this.selectedMapPoint.id = id;
          // console.log("Maps:onMapClick-marker-selectedMapPoint", this.selectedMapPoint);
          await this.createMarkerFeatureMapPopup(args, this.selectedMapPoint);
        }
        return;
      }

      // search-points-layer
      if (layerName === "search-points") {
        // const featureName = feature.get("name");
        if (featureName) {
          type = "searchpoint";
          cat = feature.get("cat");
          idOSM = feature.get("idOSM");
          lon = feature.get("lon");
          lat = feature.get("lat");
          name = featureName;
          tags = feature.get("tags");
          if (tags) { label = tags.label; }
          // console.log("Maps:onMapClick-searchpoint-feature", feature);
          // console.log("Maps:onMapClick-searchpoint-name", name);
          this.selectedMapPoint = {} as MapPlaceL;
          this.selectedMapPoint.layer = layerName;
          this.selectedMapPoint.type = type;
          this.selectedMapPoint.cat = cat;
          this.selectedMapPoint.idOSM = idOSM;
          this.selectedMapPoint.coordLon = lon;
          this.selectedMapPoint.coordLat = lat;
          this.selectedMapPoint.name = name;
          if (tags) { this.selectedMapPoint.label = tags.label; }
          this.selectedMapPoint.tags = tags;
          await this.createSearchPointFeatureMapPopup(args, this.selectedMapPoint);
        }
        return;
      }

      // gpxwaypoints-layer
      if (layerName === "gpx-waypoints") {
        // const featureName = feature.get("name");
        type = "gpxwaypoint";
        this.selectedMapItemType = type;
        id = feature.get("id");
        name = featureName;
        tags = {} as any;
        this.selectedMapPoint = {} as MapPlaceL;
        this.selectedMapPoint.layer = layerName;
        this.selectedMapPoint.type = type;
        this.selectedMapPoint.name = name;
        this.selectedMapPoint.tags = tags;
        this.createGPXPointFeatureMapPopup(args, this.selectedMapPoint);
        return;
      }

      // route-layer
      if (layerName === "route") {
        // console.log("Maps:onMapClick-route-feature", feature);
        type = "route";
        cat = feature.get("cat");
        // console.log("Maps:onMapClick-route-cat", cat);
        this.selectedMapItemType = type;
        this.selectedMapRoute = this.currentRoute;
        if (!cat) {
          this.createRouteFeatureMapPopup(args, type, this.selectedMapRoute);
        }
        if (cat === "surface" || cat === "waytype" || cat === "steepness") {
          id = feature.get("id");
          this.createRouteSegmentFeatureMapPopup(args, type, cat, id, this.selectedMapRoute);
        }
      }
      // saved-route-layer-local
      if (layerName === "saved-routes-l") {
        type = "savedroute-l";
        this.selectedMapItemType = type;
        this.selectedSaveType = feature.get("saveType");
        this.selectedMapRoute = this.findSavedRouteLocalyByRouteId(feature.get("routeId"));
        this.createSavedRouteFeatureMapPopup(args, type, this.selectedMapRoute);
        return;
      }
      // saved-route-layer-server
      if (layerName === "saved-routes-s") {
        type = "savedroute-s";
        this.selectedMapItemType = type;
        this.selectedSaveType = feature.get("saveType");
        this.selectedMapRoute = this.findSavedRouteServerByRouteId(feature.get("routeId"));
        this.createSavedRouteFeatureMapPopup(args, type, this.selectedMapRoute);
        return;
      }
      // public-route-layer
      if (layerName === "pub-routes") {
        type = "pubroute";
        id = feature.get("id");
        // console.log("Maps:onMapClick-pubRoute-id", id);
        this.selectedMapItemType = type;
        this.selectedSaveType = feature.get("saveType");
        const pubRoute = this.publicRoutes[id - 1];
        // console.log("Maps:onMapClick-pubRoute-pubRoute", pubRoute);
        const lRoute = convertPubRouteToLRoute(pubRoute);
        lRoute.id = id;
        if (this.userLanguage === "de") { lRoute.name = pubRoute.nameDe; }
        if (this.userLanguage === "en") { lRoute.name = pubRoute.nameEn; }
        this.selectedMapRoute = lRoute;
        this.createPubRouteFeatureMapPopup(args, type, this.selectedMapRoute);
        this.scrolledRouteIndex = id - 1;
        return;
      }

      // live-tracking
      if (layerName === "live-tracking") {
        // console.log("Maps:onMapClick-livetracking-feature", feature);
        // const featureName = feature.get("name");
        type = "live-track";
        cat = feature.get("cat");
        id = feature.get("id");
        name = featureName;
        lon = feature.get("lon");
        lat = feature.get("lat");
        if (cat === "track-route") {
          this.selectedMapItemType = cat;
          this.selectedLiveTracking = this.liveTrackings[id];
          this.createLiveTrackingFeatureMapPopup(args, type, cat, this.selectedLiveTracking);
          return;
        }
        if (cat === "track-end") {
          this.selectedMapPoint = {} as MapPlaceL;
          this.selectedMapPoint.id = id;
          this.selectedMapPoint.layer = layerName;
          this.selectedMapPoint.type = type;
          this.selectedMapPoint.cat = cat;
          this.selectedMapPoint.name = name;
          this.selectedMapPoint.coordLon = lon;
          this.selectedMapPoint.coordLat = lat;
          this.selectedMapPoint.notice = this.liveTrackings[id].drawOnlyEndPoint.toString();
          // console.log("Maps:onMapClick-livetracking-selectedMapPoint", this.selectedMapPoint);
          this.selectedMapItemType = cat;
          this.selectedLiveTracking = this.liveTrackings[id];
          this.createLiveTrackingFeatureMapPopup(args, type, cat, this.selectedLiveTracking);
          return;
        }
      }

      // gpxroute-layer
      if (layerName === "gpx-route") {
        // const featureName = feature.get("name");
        type = "gpxroute";
        this.selectedMapItemType = type;
        name = featureName;
        const desc = feature.get("desc");
        this.selectedMapRoute = {} as PRoute;
        this.selectedMapRoute.name = name;
        this.selectedMapRoute.description = desc;
        this.createGPXRouteFeatureMapPopup(args, type, this.selectedMapRoute);
        return;
      }

      // track-route-layer
      if (layerName === "track-route") {
        type = "track-route";
        this.selectedMapItemType = type;
        name = featureName;
        this.createTrackFeatureMapPopup(args, type, name, null);
        return;
      }
      // track-point-layer
      if (layerName === "track-point") {
        type = "track-point";
        this.selectedMapItemType = type;
        name = featureName;
        const index = Number(name) - 1;
        console.log("Maps:onMapClick-index", index);
        const trackPoint = this.currentTourData.trackPoints[index];
        this.createTrackFeatureMapPopup(args, type, name, trackPoint);
        return;
      }

      // map-extents-layer
      if (layerName === "map-extents") {
        type = "map-extent";
        this.selectedMapItemType = type;
        name = featureName;
        // this.createMapExtentFeatureMapPopup(args, type, name);
        return;
      }
    }

    // click on empty place
    if (type === "") {
      // console.log("Maps:onMapClick-newPoint");

      // new point
      if (this.createNewPointOnMap) {
        this.closeAllPopups();
        if (this.isNavigationStarted) { return; }
        this.isNewPointPopupVisible = !saveIsVisibleNewPointPopup;
        // console.log("Maps:onMapClick-isNewPointPopupVisible", this.isNewPointPopupVisible);
        if (this.isNewPointPopupVisible) { this.createNewPointMapPopup(args); }
        return;
      }
    }
  }

  // drag-waypoint
  public onDragWayPointStart(event: TranslateEvent) {
    // console.log("Maps:onDragWayPointStart-event", event);
    const features = event.features;
    this.dragFeature = features.item(0);
    // console.log("Maps:onDragWayPointStart-dragFeature", this.dragFeature);
    // return true;
  }
  public onDragWayPointEnd(event: TranslateEvent) {
    // console.log("Maps:onDragWayPointStart-event", event);
    const coordLonLat = toLonLat(this.dragFeature.getGeometry().getCoordinates());
    // console.log("Maps:onDragWayPointStart-event", event);
    this.onMapPointerUp(event);
  }
  // onMapPointerUp
  public onMapPointerUp(event) {
    // console.log("Maps:onMapPointerUp-event", event);
    const coord = event.coordinate;
    // console.log("Maps:onPointerUp-coord", coord);
    if (this.dragFeature) {
      const id = this.dragFeature.get("id");
      // console.log("Maps:onPointerUp-id", id);
      // for (const wp of this.currentRoute.wayPoints) {
      //   const index = this.currentRoute.wayPoints.indexOf(wp);
      // console.log("Maps:onPointerUp-wp", wp);
      // if (index === id) {
      const wp = this.currentRoute.wayPoints[id];
      const lonlatCoord = transform(event.coordinate, "EPSG:3857", "EPSG:4326");
      const dx = lonlatCoord[0] - wp.coordLon;
      const dy = lonlatCoord[1] - wp.coordLat;
      // console.log("Maps:onPointerUp-dx", dx);
      // console.log("Maps:onPointerUp-dy", dy);
      if (dx !== 0.0 || dy !== 0.0) {
        this.featureDragged = true;
        wp.coordLon += dx;
        wp.coordLat += dy;
        this.calculateRoute();
        this.updateUrl();
      }
      if (dx === 0.0 && dy === 0.0) {
        this.featureDragged = false;
      }
      // }
      // }
      this.dragFeature = undefined;
    }
  }

  // onMapPointerDown
  public onMapPointerDown_old(event) {
    // console.log("Maps:onMapPointerDown-event", event);
    // if (this.isNavigationStarted) { return; }
    const lonlatCoord = transform(event.coordinate, "EPSG:3857", "EPSG:4326");
    this.longitude = lonlatCoord[0];
    this.latitude = lonlatCoord[1];
    const pixel = this.map.getEventPixel(event.originalEvent);
    const hit = this.map.hasFeatureAtPixel(pixel);
    // const interactions = this.map.getInteractions();
    // console.log("Maps:onMapClick-interactions", interactions);
    if (hit) {
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        const layerName = layer.get("name");
        if (layerName === "way-points") {
          if (this.isScreenLocked) { return; }
          this.startDragingFeature(feature as Feature<any>);
          this.dragFeature = feature as Feature<any>;
          // console.log("Maps:onMapPointerDown-dragFeature", this.dragFeature);
        }
      });
    }
    if (this.dragFeature) { return true; } else { return false; }
  }
  public startDragingFeature(feature: Feature<any>) {
    // console.log("Maps:startDraging-feature", feature);
    const featureCollection = new Collection();
    featureCollection.push(feature);
    // console.log("Maps:startDragingFeature-featureDrager", featureDrager);
    feature.on("change", () => {
      // console.log("-------------Feature Moved To:" + feature.getGeometry().getCoordinates());
      // const index = feature.id;
      const index = feature.get("Id");
      const wp = this.currentRoute.wayPoints[index];
      const coordLonLat = toLonLat(feature.getGeometry().getCoordinates());
      // console.log("Maps:startDragingFeature-coordLonLat", coordLonLat);
    });
  }
  // onMapPointerMove
  public onMapPointerMove(event) {
    // console.log("Maps:onMapPointerMove-event", event);
    const pixel = this.map.getEventPixel(event.originalEvent);
    const options = { hitTolerance: 6 };
    const hit = this.map.hasFeatureAtPixel(pixel, options);
    // console.log("Maps:initMap-pointermoveEvent-mapElement", this.mapElement);
    // console.log("Maps:initMap-pointermoveEvent-hit", hit);
    this.mapElement.nativeElement.style.cursor = hit ? "pointer" : "crosshair";
    if (!hit) {
      this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    }
    if (hit) {
      // if (this.isFeaturePopupRouteVisible) { return; }
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        const layerName = layer.get("name");
        if (layerName === "route") {
          const mapCoordinates = event.coordinate;
          // const xLonLat = transform(mapCoordinates, "EPSG:3857", "EPSG:4326");
          // console.log("Maps:initMap-pointermoveEvent-xLOnLat", xLonLat);
          // console.log("Maps:initMap-pointermoveEvent-currentRoute", this.currentRoute);
          if (this.currentRoute.geojsonRoute) {
            const routeFeature = this.currentRoute.geojsonRoute.features[0];
            const routeDistance = routeFeature.properties.summary.distance;
            // console.log("Maps:initMap-pointermoveEvent-routeDistance", routeDistance);
            const calculatedRoutePolylineLength = calculatePolylineLength(this.routePolyline);
            // console.log("Maps:initMap-pointermoveEvent-calculatedRoutePolylineLength", calculatedRoutePolylineLength);
            const factorRealToPolylineLength = routeDistance / calculatedRoutePolylineLength;
            // console.log("Maps:initMap-pointermoveEvent-factorRealToPolylineLength", factorRealToPolylineLength);
            this.doneHoverRouteDistance = this.calculateDoneRouteDistance(mapCoordinates, this.maxDistanceFromRoute * 3.0);
            // console.log("Maps:initMap-pointermoveEvent-doneHoverRouteDistance", this.doneHoverRouteDistance);
            this.doneHoverRouteDistance *= factorRealToPolylineLength;
            this.doneHoverRouteDistance = Math.round(this.doneHoverRouteDistance * 10) / 10;
            // console.log("Maps:initMap-pointermoveEvent-doneHoverRouteDistance", this.doneHoverRouteDistance);
            this.remainingHoverRouteDistance = routeDistance - this.doneHoverRouteDistance;
            this.remainingHoverRouteDistance = Math.round(this.remainingHoverRouteDistance * 10) / 10;
            // console.log("Maps:initMap-pointermoveEvent-remainingHoverRouteDistance", this.remainingHoverRouteDistance);
            if (!isNaN(this.remainingHoverRouteDistance)) {
              this.hoverRouteOverlay.setPosition(mapCoordinates);
              this.hoverRoutePopupContainerElement.nativeElement.hidden = false;
            } else {
              this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
            }
          } else {
            // this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
          }
        }
        if (layerName !== "route") {
          this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
        }
        if (layerName === "pub-routes") {
          // console.log("Maps:onMapPointerMove-pointermoveEvent-pub-route-feature", feature);
          const id = feature.get("id");
          // console.log("Maps:onMapPointerMove-pointermoveEvent-pub-route-id", id);
          const geom = feature.getGeometry();
          if (geom instanceof Point) {
            const image = feature.getStyle().getImage();
            // console.log("Maps:onMapPointerMove-pointermoveEvent-pub-image", image);
          }
          if (geom instanceof LineString) {
            const style = feature.getStyle();
            const color = style.getStroke().getColor();
            // console.log("Maps:onMapPointerMove-pointermoveEvent-pub-routestyle", style);
            const highlightStyle = new Style({
              stroke: new Stroke({
                color: color,
                width: 6,
              }),
            });
            feature.setStyle(highlightStyle);
          }
          // this.scrolledRouteIndex = id;
        }
      }, options);
    }
  }

  public async newSearchPointOnMap() {
    this.isNewPointPopupVisible = false;
    const searchPoint = {} as MapPlaceL;
    searchPoint.type = "searchpoint";
    searchPoint.id = -1;
    searchPoint.coordLon = this.lonLat.lng;
    searchPoint.coordLat = this.lonLat.lat;
    searchPoint.name = "?";
    this.isVisibleProgressSpinner = true;
    const result = await doORSReverseGeocode(this.http, this.lonLat);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("reverse geocode", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    const foundPoints = result;
    console.log("Maps:onMapClick-searchPoint-foundPoints", foundPoints);
    if (foundPoints && foundPoints.length > 0) {
      const firstPoint = foundPoints[0];
      if (firstPoint.distance < 0.1) {
        searchPoint.id = firstPoint.id;
        searchPoint.idOSM = firstPoint.idOSM;
        searchPoint.name = firstPoint.name;
        searchPoint.label = firstPoint.label;
        searchPoint.coordLon = firstPoint.coordLon;
        searchPoint.coordLat = firstPoint.coordLat;
        searchPoint.tags = firstPoint.tags;
        this.searchText = firstPoint.name;
        this.searchParameter.text = firstPoint.name;
      }
      this.foundRefPlaces = foundPoints;
      this.onMainSearchInputClick();
    }
    this.drawSearchPoint(this.searchPointsSource, searchPoint);
    this.selectedMapPoint = searchPoint;
    this.searchPoint = searchPoint;
    this.zoom = this.view.getZoom();
    this.setCenter();
    this.mapElement.nativeElement.style.cursor = "";

    const info = "rev-geocode:" + this.lonLat.lng.toString() + "," + this.lonLat.lat.toString();
    this.createUsageLog(EnumActionType.call_ors_reversegeocode, info);
  }

  public async newElevationOnMap() {
    this.isNewPointPopupVisible = false;
    const position = {} as LngLat;
    position.lng = this.lonLat.lng;
    position.lat = this.lonLat.lat;
    this.isVisibleProgressSpinner = true;
    const result = await doORSElevation(this.http, position);
    this.isVisibleProgressSpinner = false;
    if (result.type === "http-error") {
      this.showORSRequestError("get elevation", EnumGlobalStatusCode.HttpError, result);
      this.isOnline = false;
      return;
    }
    if (result.type === "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("get elevation", EnumGlobalStatusCode.ORSError, result);
      return;
    }
    this.currentElevation = result;
    // console.log("Maps:newElevationOnMap-currentElevation", this.currentElevation);
    this.createElevationMapPopup(this.lastMapClickPosition);
  }

  private async createObjectNameMapPopup() {
    this.isNamePopupVisible = true;
    // display name-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: true,
        // autoPanAnimation: { duration: 100 }
      });
      const coords = this.view.getCenter();
      overlay.setPosition(coords);
      const typeName = this.objectType;
      const html =
        "<div><b>" + this.textNamePopupTitle + "<b></div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>";
      this.contentElement.nativeElement.innerHTML = html;
      this.map.addOverlay(overlay);
      // console.log("Maps:addOverlay-overlay", overlay);
      // console.log("Maps:addOverlay-map", this.map);
      this.containerElement.nativeElement.hidden = false;
    });
  }

  private async createHoverRouteMapPopup(coords) {
    // console.log("Maps:createHoverRouteMapPopup");
    this.isHoverRoutePopupVisible = true;
    // display hover-route
    setTimeout(() => {
      // if (!this.hoverRoutePopupContainerElement) { return; }
      const element = this.hoverRoutePopupContainerElement.nativeElement;
      this.hoverRouteOverlay = new Overlay({
        element,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      this.hoverRouteOverlay.setPosition(coords);
      this.map.addOverlay(this.hoverRouteOverlay);
      // console.log("Maps:createHoverRouteMapPopup-map", this.map);
    });
  }

  private async createNewPointMapPopup(args) {
    // console.log("Maps:createNewPointMapPopup-args", args);
    this.closeAllPopups();
    this.isNewPointPopupVisible = true;
    // display newPoint-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 },
      });
      overlay.setPosition(args.coordinate);
      const html =
        "<div><b>" + this.textNewPointTitle + "<b></div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>";
      this.contentElement.nativeElement.innerHTML = html;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private async createElevationMapPopup(coords: number[]) {
    console.log("Maps:createElevationMapPopup-coords", coords);
    this.closeAllPopups();
    this.isElevationPopupVisible = true;
    // display newPoint-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 },
      });
      overlay.setPosition(coords);
      const html =
        "<div><b>" + this.textElevationPopupTitle + "<b></div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>";
      this.contentElement.nativeElement.innerHTML = html;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createPointFeatureMapPopup_old(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    // this.isFeaturePopupVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createPointFeaturePopupContent_old(pFeature);
      // console.log("Maps:createPopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createPointFeaturePopupContent_old(pFeature: MapPlaceL) {
    // console.log("Maps:createFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    // way-point
    if (pFeature.type === "wp") {
      const wpNumber = (pFeature.id + 1).toString();
      content = this.textWaypoint + " " + wpNumber + "<br>";
      if (pFeature.name !== "?") { content = content + "<b>" + pFeature.name + "</b><br>"; }
      if (pFeature.name === "?" && this.userLanguage === "en") { content = content + "clicked point"; }
      if (pFeature.name === "?" && this.userLanguage === "de") { content = content + "geklickter Punkt"; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
      // calculate route-km of wps
      let doHr = false;
      this.calculateWaypointDistances();
      // distance = 4000.0;
      const distanceFromStart = this.currentRoute.wayPoints[pFeature.id].distance * this.factorRealToPolylineLength;
      if (!isNaN(distanceFromStart)) {
        const distanceFromStartKm = distanceFromStart / 1000;
        let text = "<span>Distance from start:";
        if (this.userLanguage === "de") { text = "<span>Entfernung vom Start:"; }
        content += text + distanceFromStartKm.toFixed(1) + "km</span><br>";
        doHr = true;
      }
      const distanceToEnd = this.routeDistance - distanceFromStart;
      // console.log("Maps:createFeaturePopupContent-waypoint-distanceToEnd", distanceToEnd);
      if (!isNaN(distanceToEnd)) {
        const distanceToEndKm = distanceToEnd / 1000;
        let text = "<span>Distance to end:";
        if (this.userLanguage === "de") { text = "<span>Entfernung zum Ziel:"; }
        content += text + distanceToEndKm.toFixed(1) + "km</span><br>";
        doHr = true;
      }
      // this.calculatedDonDistance = 0.1;
      // console.log("Maps:onMapClick-waypoint-calculatedDoneDistance", this.calculatedDoneDistance);
      let distanceToWp;
      if (!isNaN(this.calculatedDoneDistance)) { distanceToWp = distanceFromStart - this.calculatedDoneDistance; }
      // console.log("Maps:onMapClick-waypoint-distanceToWp", distanceToWp);
      if (!isNaN(distanceToWp)) {
        let text;
        const distanceToWpKm = distanceToWp / 1000;
        if (distanceToWp >= 0.0) {
          text = "<span>Distance to reach:";
          if (this.userLanguage === "de") { text = "<span>Erreicht in:"; }
        } else {
          distanceToWp *= -1;
          text = "<span>Reached before:";
          if (this.userLanguage === "de") { text = "<span>Erreicht vor:"; }
        }
        content += text + distanceToWpKm.toFixed(1) + "km</span><br>";
        doHr = true;
      }
      if (doHr) { content += "<hr style='margin-top:0;margin-bottom:0.5em'>"; }
    }
    // // geolocation
    // if (pFeature.type === "geolocation") {
    //   content = "Your current location:";
    //   if (this.userLanguage === "de") { content = "Deine aktuelle Position:"; }
    //   content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    // }
    // searchpoint
    if (pFeature.type === "searchpoint" || pFeature.type === "savedplace-l") {
      if (pFeature.cat) {
        const iconSrc = getPoiIconSource(pFeature.cat);
        content = "<img width=24px' src='" + iconSrc + "' alt='map place'>";
      }
      content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>";
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
      content += "<div style='font-size:small'>";
      if (pFeature.tags) {
        // const entries = Object.entries(tags);
        // let tagCount = 0;
        // for (const entry of entries) {
        //   content += "<span><b>" + entry[0] + "</b>: " + entry[1] + "</span>";
        //   if (entry[0] === "wikidata") {
        //     // §todo show-wiki-info
        //     content += "<a target='_blank' href='https://wikidata.org/wiki/" + entry[1] + "'> Wikidata</a>";
        //   }
        //   content += "<br>";
        //   tagCount++;
        //   if (tagCount === 12) { break; }
        // }
        // content += "<hr>";
        // if (tags.street) {
        //   const addrStreet = tags.street;
        //   let addrNr = "";
        //   if (tags.housenumber) { addrNr = tags.housenumber; }
        //   if (addrStreet) { content += "<div>addr: <b>" + addrStreet + " " + addrNr + "</b></div>"; }
        //   const postalcode = tags.postalcode;
        //   const region = tags.region;
        //   if (postalcode) { content += "<div style='margin-left:2.2rem;'><b>" + postalcode + ", " + region + "</b></div>"; }
        //   content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
        // }
        // if (!tags.street) {
        //   if (tags.label) { content += "<div style='margin-left:2.2rem;'><b>" + tags.label + "</b></div>"; }
        //   content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
        // }
      }
      // content += "</div>";
    }
    // gpx-waypoint
    if (pFeature.type === "gpxwaypoint") {
      content = "GPX waypoint:";
      if (this.userLanguage === "de") { content = "GPX-Wegpunkt:"; }
      if (pFeature.name) { content += pFeature.name; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
      content += "<div style='font-size:small'>";
      content += "</div>";
    }
    // live-tracking-end-point
    // if (pFeature.type === "live-track" && pFeature.cat === "track-xend") {
    //   const activeTracking = this.liveTrackings[pFeature.id];
    //   content += "<span>Tracking end-point</span><br>";
    //   const startTime = activeTracking.startTime.toLocaleString();
    //   const endTime = activeTracking.endTime.toLocaleString();
    //   content += "<span>From: " + startTime + "</span><br>";
    //   content += "<span>To</span>" + "<span style='margin:1.3rem;'>: " + endTime + "</span><br>";
    //   const now = new Date(Date.now());
    //   const active = now < activeTracking.endTime;
    //   if (active && !activeTracking.aborted) { content += "<span  style='color:green;>Tracking is still active</span><br>"; }
    //   if (!active) { content += "<span>Tracking is already inactive</span><br>"; }
    //   if (activeTracking.aborted) {
    //     content += "<span style='color:red;'>Tracking aborted by user!</span><br>";
    //   }
    //   const lastPositionAt = activeTracking.trackPositions[activeTracking.trackPositions.length - 1].at;
    //   content += "<span>Last position was from:</span><br>";
    //   content += lastPositionAt.toLocaleString() + "</span><br>";
    //   content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    // }

    // poi
    if (pFeature.type === "poi" || pFeature.type === "marker") {
      if (pFeature.cat) {
        const iconSrc = getPoiIconSource(pFeature.cat);
        if (iconSrc) { content += "<img width=24px' src='" + iconSrc + "' alt='map place'>"; }
      }
      if (!pFeature.name) { content += "<span>Point of interest</span>"; }
      if (pFeature.name) { content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>"; }
      content = content + "<hr style='margin-top:0;margin-bottom:0.5em'>";
      if (pFeature.tags) {
        const entries = Object.entries(pFeature.tags);
        let tagCount = 0;
        for (const entry of entries) {
          const entryContent = "<span><b>" + entry[0] + "</b>: " + entry[1] + "</span><br>";
          if (entry[0] === "street") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "addr:street") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "housenumber") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "addr:housenumber") {
            content += entryContent;
            tagCount++;
          }
          // if (entry[0] === "addr:postcode") {
          //   content += entryContent;
          //   tagCount++;
          // }
          if (entry[0] === "addr:city") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "region") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "opening_hours") {
            content += entryContent;
            tagCount++;
          }
          if (entry[0] === "contact:phone") {
            content += "<span><b>" + entry[0] + "</b>:<a href=tel:" + entry[1] + ">" + entry[1] + "</a><br>";
            tagCount++;
          }
          if (entry[0] === "contact:email") {
            content += "<span><b>" + entry[0] + "</b>:<a href=mailto:" + entry[1] + ">" + entry[1] + "</a><br>";
            tagCount++;
          }
          if (entry[0] === "contact:website") {
            content += "<span><b>" + entry[0] + "</b>: </span><a target='_blank' href= " + entry[1] + ">" + entry[1] + "</a><br>";
            tagCount++;
          }
          if (entry[0] === "wikidata") {
            content += entryContent;
            // §todo show-wiki-info
            content += "<a target='_blank' href='https://wikidata.org/wiki/" + entry[1] + "'> Wikidata</a><br>";
            tagCount++;
          }
          if (tagCount === 12) { break; }
        }
        if (tagCount) { content += "<hr>"; }
      }
      // if (tags) {
      //   const entries = Object.entries(tags);
      //   let tagCount = 0;
      //   for (const entry of entries) {
      //     content += "<span><b>" + entry[0] + "</b>: " + entry[1] + "</span>";
      //     if (entry[0] === "wikidata") {
      //       // §todo show-wiki-info
      //       content += "<a target='_blank' href='https://wikidata.org/wiki/" + entry[1] + "'> Wikidata</a>";
      //     }
      //     content += "<br>";
      //     tagCount++;
      //     if (tagCount === 12) { break; }
      //   }
      //   content += "<hr>";
      // }
    }
    return content;
  }

  private createGeolocationFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupGeolocationVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createGeolocationFeaturePopupContent(pFeature);
      // console.log("Maps:createGeolocationFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createGeolocationFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createGeolocationFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    // geolocation
    if (pFeature.type === "geolocation") {
      content = "Your current location:";
      if (this.userLanguage === "de") { content = "Deine aktuelle Position:"; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    return content;
  }

  private createPOIFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupPOIVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createPOIFeaturePopupContent(pFeature);
      // console.log("Maps:createPOIFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createPOIFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createPOIFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    if (pFeature.cat) {
      const iconSrc = getPoiIconSource(pFeature.cat);
      if (iconSrc) { content += "<img width=24px' src='" + iconSrc + "' alt='map place'>"; }
    }
    if (!pFeature.name) { content += "<span>Point of interest</span>"; }
    if (pFeature.name) { content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>"; }
    content = content + "<hr style='margin-top:0;margin-bottom:0.5em'>";

    return content;
  }


  private createSearchPointFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupSearchPointVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createSearchPointFeaturePopupContent(pFeature);
      //console.log("Maps:createSearchPointFeatureMapPopup-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createSearchPointFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createSearchPointFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>";
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    content += "<div style='font-size:small'>";
    return content;
  }

  private createMarkerFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupMarkerVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createMarkerFeaturePopupContent(pFeature);
      // console.log("Maps:createMarkerFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createMarkerFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createMarkerFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    if (pFeature.cat) {
      const iconSrc = getPoiIconSource(pFeature.cat);
      if (iconSrc) { content += "<img width=24px' src='" + iconSrc + "' alt='map place'>"; }
    }
    if (!pFeature.name) { content += "<span>Point of interest</span>"; }
    if (pFeature.name) { content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>"; }
    content = content + "<hr style='margin-top:0;margin-bottom:0.5em'>";
    return content;
  }

  private createSavedPlaceFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupSavedPlaceVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createSavedPlaceFeaturePopupContent(pFeature);
      // console.log("Maps:createSavedPlaceFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createSavedPlaceFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createSavedPlaceFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    if (pFeature.cat) {
      const iconSrc = getPoiIconSource(pFeature.cat);
      if (iconSrc) { content += "<img width=24px' src='" + iconSrc + "' alt='map place'>"; }
    }
    if (!pFeature.name) { content += "<span>Point of interest</span>"; }
    if (pFeature.name) { content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>"; }
    content = content + "<hr style='margin-top:0;margin-bottom:0.5em'>";
    return content;
  }

  private createWPFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupWPVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createWPFeaturePopupContent(pFeature);
      // console.log("Maps:createWPFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createWPFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createWPFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    const wpNumber = (pFeature.id + 1).toString();
    content = this.textWaypoint + " " + wpNumber + "<br>";
    if (pFeature.name !== "?") { content = content + "<b>" + pFeature.name + "</b><br>"; }
    if (pFeature.name === "?" && this.userLanguage === "en") { content = content + "clicked point"; }
    if (pFeature.name === "?" && this.userLanguage === "de") { content = content + "geklickter Punkt"; }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    // calculate route-km of wps
    let doHr = false;
    this.calculateWaypointDistances();
    // distance = 4000.0;
    const distanceFromStart = this.currentRoute.wayPoints[pFeature.id].distance * this.factorRealToPolylineLength;
    if (!isNaN(distanceFromStart)) {
      const distanceFromStartKm = distanceFromStart / 1000;
      let text = "<span>Distance from start:";
      if (this.userLanguage === "de") { text = "<span>Entfernung vom Start:"; }
      content += text + distanceFromStartKm.toFixed(1) + "km</span><br>";
      doHr = true;
    }
    const distanceToEnd = this.routeDistance - distanceFromStart;
    // console.log("Maps:createFeaturePopupContent-waypoint-distanceToEnd", distanceToEnd);
    if (!isNaN(distanceToEnd)) {
      const distanceToEndKm = distanceToEnd / 1000;
      let text = "<span>Distance to end:";
      if (this.userLanguage === "de") { text = "<span>Entfernung zum Ziel:"; }
      content += text + distanceToEndKm.toFixed(1) + "km</span><br>";
      doHr = true;
    }
    let distanceToWp;
    if (!isNaN(this.calculatedDoneDistance)) { distanceToWp = distanceFromStart - this.calculatedDoneDistance; }
    // console.log("Maps:onMapClick-waypoint-distanceToWp", distanceToWp);
    if (!isNaN(distanceToWp)) {
      let text;
      const distanceToWpKm = distanceToWp / 1000;
      if (distanceToWp >= 0.0) {
        text = "<span>Distance to reach:";
        if (this.userLanguage === "de") { text = "<span>Erreicht in:"; }
      } else {
        distanceToWp *= -1;
        text = "<span>Reached before:";
        if (this.userLanguage === "de") { text = "<span>Erreicht vor:"; }
      }
      content += text + distanceToWpKm.toFixed(1) + "km</span><br>";
      doHr = true;
    }
    if (doHr) { content += "<hr style='margin-top:0;margin-bottom:0.5em'>"; }

    return content;
  }

  private createRoutePOIFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupRoutePOIVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createRoutePOIFeaturePopupContent(pFeature);
      // console.log("Maps:createRoutePOIFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createRoutePOIFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createRoutePOIFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    if (pFeature.cat) {
      const iconSrc = getPoiIconSource(pFeature.cat);
      if (iconSrc) { content += "<img width=24px' src='" + iconSrc + "' alt='map place'>"; }
    }
    if (!pFeature.name) { content += "<span>Point of interest</span>"; }
    if (pFeature.name) { content += "<span style='vertical-align:top;'><b>" + " " + pFeature.name + "</b></span>"; }
    content = content + "<hr style='margin-top:0;margin-bottom:0.5em'>";
    return content;
  }

  private createGPXPointFeatureMapPopup(args, pFeature: MapPlaceL) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupGPXPointVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      this.showTagsInPopups = false;
      const content = this.createGPXPointFeaturePopupContent(pFeature);
      // console.log("Maps:createGPXPointFeaturePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }

  private createGPXPointFeaturePopupContent(pFeature: MapPlaceL) {
    // console.log("Maps:createGPXPointFeaturePopupContent-pFeature", pFeature);
    this.currentFeatureTags = pFeature.tags;
    let content = "";
    content = "GPX waypoint:";
    if (this.userLanguage === "de") { content = "GPX-Wegpunkt:"; }
    if (pFeature.name) { content += pFeature.name; }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    content += "<div style='font-size:small'>";
    content += "</div>";
    return content;
  }



  private createRouteFeatureMapPopup(args, type: string, rFeature: PRoute) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupRouteVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createRouteFeaturePopupContent(type, rFeature);
      // console.log("Maps:createPopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createRouteFeaturePopupContent(type: string, rFeature: PRoute) {
    // console.log("Maps:createFeaturePopupContent-rFeature", rFeature);
    const name = rFeature.name;
    const id = rFeature.id;
    const desc = rFeature.description;
    let content = "";
    // planned-route
    content = "Planned route:";
    if (this.userLanguage === "de") { content = "Geplante Route:"; }
    if (this.currentRoute.name) { content += "<br><b>" + this.currentRoute.name + "<b>"; }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    return content;
  }

  private createRouteSegmentFeatureMapPopup(args, type: string, cat: string, id: number, rFeature: PRoute) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupRouteSegmentVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createRouteSegmentFeaturePopupContent(type, cat, id, rFeature);
      // console.log("Maps:createRouteSegmentPopupFeature-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createRouteSegmentFeaturePopupContent(type: string, cat: string, id: number, rFeature: PRoute) {
    // console.log("Maps:createRouteSegmentFeaturePopupContent-rFeature", rFeature);
    const name = rFeature.name;
    const desc = rFeature.description;
    let content = "";
    // planned-route
    content = "Planned route:";
    if (this.userLanguage === "de") { content = "Geplante Route:"; }
    if (this.currentRoute.name) { content += "<br><b>" + this.currentRoute.name + "<b>"; }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";

    if (cat === "surface") {
      content += "<div><span>" + this.textRoutePopupSurfaceText + ": " + getSurfaceName(id, this.userLanguage) + "</span></div>";
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    if (cat === "waytype") {
      content += "<div><span>" + this.textRoutePopupWaytypeText + ": " + getWaytypeName(id, this.userLanguage) + "</span></div>";
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    if (cat === "steepness") {
      if (id < 0) { content += "<div><span>" + this.textRoutePopupDeclineText + ": " + getSteepnessName(id, this.userLanguage) + "</span></div>"; }
      if (id >= 0) { content += "<div><span>" + this.textRoutePopupInclineText + ": " + getSteepnessName(id, this.userLanguage) + "</span></div>"; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    return content;
  }

  private createSavedRouteFeatureMapPopup(args, type: string, rFeature: PRoute) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupSavedRouteVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createSavedRouteFeaturePopupContent(type, rFeature);
      // console.log("Maps:createSavedRoutePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createSavedRouteFeaturePopupContent(type: string, rFeature: PRoute) {
    // console.log("Maps:createSavedRouteFeaturePopupContent-rFeature", rFeature);
    const name = rFeature.name;
    const id = rFeature.id;
    const desc = rFeature.description;
    let content = "";
    // saved-route-local
    if (type === "savedroute-l") {
      content = "Saved route on device:";
      if (this.userLanguage === "de") { content = "Am Gerät gespeicherte Route:"; }
      if (name) { content += "<br>" + name; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    // saved-route-server
    if (type === "savedroute-s") {
      content = "Saved route on server:";
      if (this.userLanguage === "de") { content = "Auf Server gespeicherte Route:"; }
      if (name) { content += "<br>" + name; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    return content;
  }

  private createPubRouteFeatureMapPopup(args, type: string, rFeature: PRoute) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupPubRouteVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createPubRouteFeaturePopupContent(type, rFeature);
      // console.log("Maps:createPubRoutePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createPubRouteFeaturePopupContent(type: string, rFeature: PRoute) {
    console.log("Maps:createPubRouteFeaturePopupContent-rFeature", rFeature);
    const name = rFeature.name;
    const id = rFeature.id;
    const desc = rFeature.description;
    let content = "";
    // public-route
    if (type === "pubroute") {
      content = "Public route:";
      if (this.userLanguage === "de") { content = "Öffentliche Route:"; }
      if (name) { content += "<br>" + name; }
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    return content;
  }

  private createGPXRouteFeatureMapPopup(args, type: string, rFeature: PRoute) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupGPXRouteVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createGPXRouteFeaturePopupContent(type, rFeature);
      // console.log("Maps:createGPXRoutePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createGPXRouteFeaturePopupContent(type: string, rFeature: PRoute) {
    // console.log("Maps:createGPXRouteFeaturePopupContent-rFeature", rFeature);
    const name = rFeature.name;
    const id = rFeature.id;
    const desc = rFeature.description;
    let content = "";
    // gpx-route
    content = "GPX route:";
    if (this.userLanguage === "de") { content = "GPX-Route:"; }
    if (name) { content += "<br>" + name; }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    // §todo gpx-route-details + functions
    content += "<div style='font-size:small'>";
    if (desc) {
      let text = "Description:";
      if (this.userLanguage === "de") { text = "Beschreibung:"; }
      content += "<div>" + text + "<br><span>" + desc + "</span></div>";
    }
    content += "</div>";
    return content;
  }

  private createTrackFeatureMapPopup(args, type: string, name: string, trackPoint: TrackPoint) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupTrackVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createTrackFeaturePopupContent(type, name, trackPoint);
      // console.log("Maps:createGPXRoutePopupContent-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createTrackFeaturePopupContent(type: string, name: string, trackPoint: TrackPoint) {
    console.log("Maps:createTrackFeaturePopupContent-trackpoint", trackPoint);
    let content = "";
    // track-route
    if (type === "track-route") {
      content = "Track:" + name;
      if (this.userLanguage === "de") { content = "Track:"; }
      if (name) { content += name; }
    }
    if (type === "track-point") {
      content = "Track point:";
      if (this.userLanguage === "de") { content = "Track-Punkt:"; }
      if (name) { content += name; }
    }
    content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    if (type === "track-point") {
      let eleText = "Elevation";
      if (this.userLanguage === "de") eleText = "Seehöhe";
      content += "<div style='font-size:small'>";
      content += "<span>" + eleText + ": " + trackPoint.ele + "m </span><br>";
      const atDate = new Date(trackPoint.timeStamp);
      const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
      const atString = atDate.toLocaleDateString() + " " + atDate.toLocaleTimeString("de-de", options);
      let atText = "Reached at";
      if (this.userLanguage === "de") atText = "Erreicht um";
      content += "<span>" + atText + ": " + atString + "</span><br>"
    }
    content += "</div>";
    return content;
  }


  private createLiveTrackingFeatureMapPopup(args, type: string, cat: string, ltFeature: LiveTracking) {
    this.hoverRoutePopupContainerElement.nativeElement.hidden = true;
    this.closeAllPopups();
    this.isFeaturePopupLiveTrackingVisible = true;
    // display feature-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      overlay.setPosition(args.coordinate);
      const content = this.createLiveTrackingFeaturePopupContent(type, cat, ltFeature);
      // console.log("Maps:createLiveTrackingFeatureMapPopup-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private createLiveTrackingFeaturePopupContent(type: string, cat: string, ltFeature: LiveTracking) {
    // console.log("Maps:createLiveTrackingFeatureMapPopup-ltFeature", ltFeature);
    // live-tracking
    let content = "";
    let title = "Live tracking";
    let fromLbl = "From: ";
    let toLbl = "To <span style='margin-left:1.3rem;'>: </span>";
    const trackingTitle = "Tracking:";
    let inactiveText = "Tracking is already inactive";
    let isabortedText = "Tracking aborted by user!";
    let lastPosText = "Last position is from:";
    if (this.userLanguage === "de") {
      title = "Live-Tracking";
      fromLbl = "Von: </span>";
      toLbl = "Bis: ";
      inactiveText = "Tracking ist bereits inaktiv";
      isabortedText = "Tracking vom Benutzer beendet!";
      lastPosText = "Letzte Position ist vom:";
    }
    if (type === "live-track") {
      const activeTracking = ltFeature;
      content = "<img width='24px' src='./assets/icons/access-point-check.svg' alt='symbol'>";
      content += "<span style='vertical-align:top;'>" + title + " <b>" + activeTracking.name + "</b></span>";
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
      content += "<span>" + trackingTitle + "</span><br>";
      const startTime = activeTracking.startTime.toLocaleString();
      const endTime = activeTracking.endTime.toLocaleString();
      content += "<span>" + fromLbl + startTime + "</span><br>";
      content += "<span>" + toLbl + endTime + "</span><br>";
      const now = new Date(Date.now());
      const active = now < activeTracking.endTime;
      if (active && !activeTracking.aborted) { content += "<span  style='color:green;>Tracking is still active</span><br>"; }
      if (!active) { content += "<span>" + inactiveText + " </span><br>"; }
      if (activeTracking.aborted) {
        content += "<span style='color:red;'>" + isabortedText + "</span><br>";
      }
      const lastPositionAt = activeTracking.trackPositions[activeTracking.trackPositions.length - 1].at;
      content += "<span>" + lastPosText + "</span><br>";
      content += lastPositionAt.toLocaleString() + "</span><br>";
      content += "<hr style='margin-top:0;margin-bottom:0.5em'>";
    }
    return content;
  }

  public async onShowTagsInPopupClick(show: boolean) {
    this.showTagsInPopups = show;
    if (!show) {
      this.contentTagsElement.nativeElement.innerHTML = "";
      return;
    }
    // get featureTags from osm data
    if (!this.currentFeatureTags && this.selectedMapPoint.idOSM) {
      // console.log("Maps:onShowTagsInPopupClick-selectedMapPoint", this.selectedMapPoint);
      // load tags from
      const element = await this.searchPoiById(this.selectedMapPoint.idOSM);
      if (!element) { return; }
      this.selectedMapPoint.tags = element.tags;
      this.currentFeatureTags = element.tags;
    }
    // create content of tags
    if (this.currentFeatureTags) {
      let contentTags = "<div style='font-size:small'>";
      contentTags += "<span style='font-size:large'>OSM-Tags</span><br>";
      const entries = Object.entries(this.currentFeatureTags);
      let tagCount = 0;
      for (const entry of entries) {
        contentTags += "<span><b>" + entry[0] + "</b>: " + entry[1] + "</span>";
        if (entry[0] === "wikidata") {
          // §todo show-wiki-info
          contentTags += "<a target='_blank' href='https://wikidata.org/wiki/" + entry[1] + "'> Wikidata</a>";
        }
        contentTags += "<br>";
        tagCount++;
        if (tagCount === 50) { break; }
      }
      contentTags += "</div>";
      this.contentTagsElement.nativeElement.innerHTML = contentTags;
    }
    // console.log("Maps:createPopupContent-content", content);
  }



  public closeAllPopups() {
    this.isFeaturePopupGPXPointVisible = false;
    this.isFeaturePopupMarkerVisible = false;
    this.isFeaturePopupPOIVisible = false;
    this.isFeaturePopupSavedPlaceVisible = false;
    this.isFeaturePopupSearchPointVisible = false;
    this.isFeaturePopupWPVisible = false;
    this.isFeaturePopupRoutePOIVisible = false;
    this.isFeaturePopupRouteVisible = false;
    this.isFeaturePopupRouteSegmentVisible = false;
    this.isFeaturePopupSavedRouteVisible = false;
    this.isFeaturePopupPubRouteVisible = false;
    this.isFeaturePopupGPXRouteVisible = false;
    this.isFeaturePopupGeolocationVisible = false;
    this.isFeaturePopupLiveTrackingVisible = false;
    this.isFeaturePopupTrackVisible = false;
    this.isFeaturePopupMapExtentVisible = false;

    this.isNewPointPopupVisible = false;
    this.isElevationPopupVisible = false;
    this.isNamePopupVisible = false;
    this.isPoisFilterPopupVisible = false;
    this.isLiveTrackingMePopupVisible = false;
    this.isNeedToLoginPopupVisible = false;
    this.isErrorMessagePopupVisible = false;
  }

  private async createPoisFilterPopup() {
    this.closeAllPopups();
    this.isPoisFilterPopupVisible = true;
    // display pois-filter-popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      const extent = this.view.calculateExtent();
      // console.log("Maps:createPoisFilterPopup-extend", extent);
      const coordX = extent[0] + (extent[2] - extent[0]) * 0.3;
      const coordY = extent[1] + (extent[3] - extent[1]) * 0.2;
      const coords = new Array(coordX, coordY);
      // console.log("Maps:createPoisFilterPopup-coords", coords);
      overlay.setPosition(coords);
      // console.log("Maps:createPoisFilterPopup-overlay", overlay);
      this.map.addOverlay(overlay);
      overlay.panIntoView();
    });
  }

  // set center+zoom
  public setCenter() {
    // console.log("Maps:setCenter-longitude", this.longitude);
    // onsole.log("Maps:setCenter-latitude", this.latitude);
    const view = this.map.getView();
    view.setCenter(fromLonLat([this.longitude, this.latitude]));
    view.setZoom(this.zoom);
  }

  // zoom to current location
  public onZoomToCurrentLocationClick() {
    // console.log("Maps:onZoomToCurrentLocationClick-currentPositionMapCoord", this.currentPositionMapCoord);
    // try to restart geolocation after error
    if (this.geolocationError) {
      this.geolocationError = undefined;
      this.createGeolocation();
      return;
    }
    this.buttonZoomToMyPosition.style.backgroundColor = "lightgreen";
    setTimeout(() => {
      this.buttonZoomToMyPosition.style.backgroundColor = "";
      if (!this.currentPositionMapCoord) {
        this.buttonZoomToMyPosition.style.backgroundColor = "orangered";
      }
    }, 1000);
    this.zoomToCurrentLocation(15);
  }
  public zoomToCurrentLocation(zoom: number) {
    // console.log("Maps:gotoCurrentLocation-userPosition", this.userPosition);
    if (!this.currentPositionMapCoord) { return; }
    const view = this.map.getView();
    view.setCenter(fromLonLat([this.currentPositionMapCoord.lng, this.currentPositionMapCoord.lat], "EPSG:4326"));
    this.zoom = zoom;
    view.setZoom(this.zoom);
  }

  private zoomToMapData(toSavedPlaces?: boolean) {
    this.buttonZoomToMapData.style.backgroundColor = "lightgreen";
    setTimeout(() => {
      this.buttonZoomToMapData.style.backgroundColor = "";
    }, 1000);

    let routeExtent;
    const source = this.routePlannerRouteLayer.getSource();
    // console.log("Maps:zoomToMapData-source", source);
    if (source) {
      routeExtent = source.getExtent();
    }
    let wayPointExtent;
    wayPointExtent = this.routePlannerWayPointsSource.getExtent();
    let searchPlaceExtent;
    if (this.selectedMapPoint) {
      searchPlaceExtent = this.searchPointsSource.getExtent();
    }
    let poisExtent;
    if (this.currentRoute.pois && this.currentRoute.pois.length) {
      poisExtent = this.poisSource.getExtent();
    }
    let markersExtent;
    if (this.markerPoints && this.markerPoints.length) {
      markersExtent = this.markerPointsSource.getExtent();
    }
    let gpxRouteExtent;
    if (this.gpxFileLoaded) {
      gpxRouteExtent = this.gpxRouteSource.getExtent();
      // console.log("Maps:zoomToMapData-gpxRouteSource", this.gpxRouteSource);
      // console.log("Maps:zoomToMapData-gpxRouteExtent", gpxRouteExtent);
    }
    let trackingExtent;
    if (this.currentTourData.isTourStarted && this.currentTourData.showTrack) {
      trackingExtent = this.trackingLocationSource.getExtent();
    }
    let savedPlacesExtent;
    if (this.mySavedPlacesLocaly) {
      savedPlacesExtent = this.savedPlacesLocalySource.getExtent();
    }
    let liveTrackingExtent;
    if (this.liveTrackings && this.liveTrackings.length) {
      liveTrackingExtent = this.liveTrackingSource.getExtent();
    }
    // combine extends and set view extent
    const extent = createEmpty();
    if (routeExtent) { Extend(extent, routeExtent); }
    if (wayPointExtent) { Extend(extent, wayPointExtent); }
    if (poisExtent) { Extend(extent, poisExtent); }
    if (searchPlaceExtent) { Extend(extent, searchPlaceExtent); }
    if (markersExtent) { Extend(extent, markersExtent); }
    if (gpxRouteExtent) { Extend(extent, gpxRouteExtent); }
    if (trackingExtent) { Extend(extent, trackingExtent); }
    if (liveTrackingExtent) { Extend(extent, liveTrackingExtent); }
    if (savedPlacesExtent && toSavedPlaces) { Extend(extent, savedPlacesExtent); }
    // console.log("Maps:zoomToMapData-extent", extent);
    if (isEmpty(extent)) {
      this.zoomToCurrentLocation(15);
      return;
    }
    const pad = this.mapPadding;
    // console.log("Maps:zoomToMapData-pad", pad);
    this.view.fit(extent, { padding: [pad, pad, pad, pad] });
    if (getWidth(extent) < 500.0 && getHeight(extent) < 500) {
      // console.log("Maps:zoomToMapData-with", getWidth(extent));
      // console.log("Maps:zoomToMapData-height", getHeight(extent));u
      this.view.setZoom(18);
    }
    this.zoomed = true;
  }
  private zoomToMapSource(source) {
    // console.log("Maps:zoomToMapSource-source", source);
    const extent = source.getExtent();
    if (extent[0] === Infinity) { return; }
    const view = this.map.getView();
    const padding = this.mapPadding;
    view.fit(extent, { padding: [padding, padding, padding, padding] });
  }


  // convert data to url --------------------------------------------------------------------------------------------------------
  public updateUrl() {
    // console.log("Maps:---------------------------------------updateUrl-navigate");
    const plannerUrl = this.convertRouteToUrl();
    // console.log("Maps:---------------------------------------updateUrl-plannerUrl", plannerUrl);
    const poisUrl = this.convertRoutePoisToUrl();
    const markersUrl = this.convertMarkersToUrl();
    const extentUrl = this.getExtentUrl();
    // url
    let url = "";
    // console.log("Maps:convertDataToUrl-plannerUrl", plannerUrl);
    // console.log("Maps:convertDataToUrl-markersUrl", markersUrl);
    if (plannerUrl) { url += "&" + plannerUrl; }
    if (poisUrl) { url += "&" + poisUrl; }
    if (markersUrl) { url += "&" + markersUrl; }
    // console.log("Maps:updateUrl-url", url);
    // console.log("Maps:---------------------------------------updateUrl-url", url);
    if (url === "") {
      // this.location.go("/param");
      // this.router.navigateByUrl("/param");
      this.router.navigateByUrl("/planner");
      return;
    }

    const versionUrl = "ver=2.0";
    url = this.baseUrl + versionUrl + url;
    if (extentUrl) { url += "&" + extentUrl; }
    // if (navigate) { this.router.navigateByUrl(url); }
    // if (!navigate) { this.location.go(url); }
    this.location.go(url);
  }
  private getExtentUrl() {
    let extentUrl = "";
    const centerMapCoords = this.view.getCenter();
    const centerLonLatCoords = transform(centerMapCoords, "EPSG:3857", "EPSG:4326");
    // console.log("Maps:getExtentUrl-centerLonLatCoords", centerLonLatCoords);
    const zoom = this.view.getZoom();
    extentUrl = "@=" + centerLonLatCoords[0].toString() + "," + centerLonLatCoords[1].toString() + "," + zoom.toString() + "z";
    return extentUrl;
  }
  private convertRouteToUrl() {
    // console.log("Maps:convertRouteToUrl-currentRoute", this.currentRoute);
    let routeOk = true;
    let routeName = "";
    if (this.currentRoute.name) { routeName = "name=" + this.currentRoute.name; }
    let routeId = "";
    if (this.currentRoute.routeId) { routeId = "id=" + this.currentRoute.routeId; }
    const plannerOptions = this.currentRoute.plannerOptions;
    const moveType = plannerOptions.moveType;
    const backToStart = this.currentRoute.backToStart;
    const roundRoute = this.currentRoute.roundRoute;
    const wayPoints = this.currentRoute.wayPoints;
    let startCoord = "";
    let wpCoord = "";
    let endCoord = "";
    let wpMaxIndex = wayPoints.length - 1;
    if (!backToStart) { wpMaxIndex = wayPoints.length - 2; }
    for (const wp of wayPoints) {
      if (wp.coordLon) {
        const index = wayPoints.indexOf(wp);
        if (index === 0) {
          if (!wp.coordLon) { routeOk = false; }
          startCoord = Math.round(wp.coordLon * 1000000) / 1000000 + "|" + Math.round(wp.coordLat * 1000000) / 1000000;
          // let label = wp.name;
          // if (wp.type === "$current") { label = "$current"; }
          // if (label) { startCoord += "|" + label; }
          startCoord += "|" + wp.name;
          if (wp.label && wp.label !== wp.name) { startCoord += "|" + wp.label; }
        }
        if (index > 0 && index <= wpMaxIndex) {
          wpCoord += Math.round(wp.coordLon * 1000000) / 1000000 + "|" + Math.round(wp.coordLat * 1000000) / 1000000;
          // let label = wp.name;
          // if (wp.type === "$current") { label = "$current"; }
          // if (label) { wpCoord += "|" + label; }
          wpCoord += "|" + wp.name;
          if (wp.label && wp.label !== wp.name) { wpCoord += "|" + wp.label; }
          if (wpCoord) { wpCoord += ";"; }
        }
        if (index === wayPoints.length - 1 && !backToStart && !roundRoute) {
          if (!wp.coordLon) { routeOk = false; }
          endCoord = Math.round(wp.coordLon * 1000000) / 1000000 + "|" + Math.round(wp.coordLat * 1000000) / 1000000;
          // let label = wp.name;
          // if (wp.type === "$current") { label = "$current"; }
          // if (label) { endCoord += "|" + label; }
          endCoord += "|" + wp.name;
          if (wp.label && wp.label !== wp.name) { endCoord += "|" + wp.label; }
        }
      }
    }
    // if (startCoord === "" && wpCoord === "" && endCoord === "") { return ""; }
    const routeStyleUrl = "&routestyle=" + this.drawRouteStyle + "|" + this.drawRouteColor;
    const waypointstyleUrl = "&waypointstyle=" + this.drawWaypointStyle + "|" + this.drawWaypointSize.toString();
    if (true) {
      let plannerUrl = "";
      if (routeId) { plannerUrl = routeId + "&"; }
      if (routeName) { plannerUrl += routeName + "&"; }
      if (!roundRoute) {
        plannerUrl += "movetype=" + moveType + "&start=" + startCoord + "&wp=" + wpCoord;
        if (!backToStart) { plannerUrl += "&end=" + endCoord; }
        if (backToStart) { plannerUrl += "&backtostart=true"; }
      }
      if (roundRoute) {
        plannerUrl += "movetype=" + moveType + "&start=" + startCoord;
        plannerUrl += "&roundroute=";
        plannerUrl += plannerOptions.roundRouteLength + "|" + plannerOptions.roundRoutePoints + "|" + plannerOptions.roundRouteSeed;
      }
      plannerUrl += routeStyleUrl;
      plannerUrl += waypointstyleUrl;
      return plannerUrl;
    }
  }

  // share-location
  public onShareLocationClick() {
    // console.log("Maps:onShareLocationClick");
    // show login-needed-popup, when not loogged in
    if (!this.loggedInUser) {
      this.createNeedToLoginMapPopup();
      return;
    }
    let placeName = "";
    let userName = this.loggedInUser.name;
    // if (this.loggedInUser) { userName = this.loggedInUser.name; }
    if (userName.includes("@")) {
      const items = userName.split("@");
      userName = items[0];
    }
    placeName += userName;
    // console.log("Maps:onShareLocationClick-loggedInUser", this.loggedInUser);
    const place = {} as MapPlaceL;
    place.id = this.loggedInUser.id;
    place.type = "marker";
    place.cat = "loc-single";
    place.name = placeName;
    place.coordLon = this.selectedMapPoint.coordLon;
    place.coordLat = this.selectedMapPoint.coordLat;
    this.shareLocation(place);
    this.closeAllPopups();
    const info = ":userId=" + place.id + ":name=" + placeName;
    this.createUsageLog(EnumActionType.send_location, info);
  }
  public shareLocation(place: MapPlaceL) {
    const flagsUrl = this.convertPlaceAsLocationToUrl(place);
    const url = this.baseUrl + "ver=2.0&" + flagsUrl;
    const sharedData: ShareData = { url };
    if (place.cat === "loc-single") {
      sharedData.title = this.shareLocationSubjectText + place.name;
      sharedData.text = this.shareLocationMsg2Text;
      navigator.share(sharedData);
    }
  }
  private convertPlaceAsLocationToUrl(place: MapPlaceL) {
    // console.log("Maps:convertPlaceAsLocationToUrl-place", place);
    const marker = cloneMapPlace(place);
    marker.type = "marker";
    const markersCoord = convertMapPlaceToUrlString(marker);
    const markersUrl = "markers=" + markersCoord;
    // console.log("Maps:convertPlaceAsMarkerToUrl-markersUrl", markersUrl);
    return markersUrl;
  }

  // share-live-tracking-me
  public async onStartLiveTrackingMeClick() {
    // console.log("Maps:onLiveTrackingClick");
    this.closeAllPopups();
    let trackingName = "";
    let userName = this.loggedInUser.name;
    if (userName.includes("@")) {
      const items = userName.split("@");
      userName = items[0];
    }
    trackingName += userName;
    const trackId = uuid.v4();
    const trackTime = 60.0;  // [min]
    this.newLiveTrackingMe = {} as LiveTracking;
    this.newLiveTrackingMe.trackingId = trackId;
    this.newLiveTrackingMe.userId = this.loggedInUser.id;
    this.newLiveTrackingMe.name = trackingName;
    this.newLiveTrackingMe.duration = trackTime;
    this.newLiveTrackingMe.storeOnlyEndPosition = true;
    this.newLiveTrackingMe.aborted = false;
    this.onLiveTrackingMeDurationChange();
    this.createLiveTrackingMeMapPopup("new");
  }
  private async createLiveTrackingMeMapPopup(mode: string) {
    // console.log("Maps:createLiveTrackingMeMapPopup");
    this.isLiveTrackingMePopupVisible = true;
    this.hasLiveTrackingMePopupChanged = false;
    this.newLiveTrackingMeName = this.newLiveTrackingMe.name;
    this.newLiveTrackingMeStoreWholeRoute = !this.newLiveTrackingMe.storeOnlyEndPosition;
    this.currentPopupMode = mode;
    // display popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      let headerText = "Start live tracking";
      if (this.userLanguage === "de") { headerText = "Live-Tracking starten"; }
      if (mode === "show") {
        headerText = "Active live tracking";
        if (this.userLanguage === "de") { headerText = "Aktives Live-Tracking"; }
      }
      const content =
        "<div><b>" + headerText + "<b></div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>";
      // console.log("Maps:createLiveTrackingMeMapPopup-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      const extent = this.view.calculateExtent();
      // console.log("Maps:createPoisFilterPopup-extend", extent);
      const coordX = extent[0] + (extent[2] - extent[0]) * 0.3;
      const coordY = extent[1] + (extent[3] - extent[1]) * 0.2;
      const coords = new Array(coordX, coordY);
      // console.log("Maps:createPoisFilterPopup-coords", coords);
      overlay.setPosition(coords);
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  public onLiveTrackingMeDurationChange() {
    if (this.newLiveTrackingMeHours === undefined) {
      this.newLiveTrackingMeHours = 1;
      this.newLiveTrackingMeMinutes = 0;
    }
    if (this.newLiveTrackingMeHours === 0 && this.newLiveTrackingMeMinutes === 0) { this.newLiveTrackingMeMinutes = 1; }
    this.newLiveTrackingMe.duration = this.newLiveTrackingMeHours * 60 + this.newLiveTrackingMeMinutes;
    this.newLiveTrackingMeDuration = this.newLiveTrackingMeHours + "h " + this.newLiveTrackingMeMinutes + "min";
    // console.log("Maps:onLiveTrackingMeDurationClick-duration", this.newLiveTrackingMe.duration);
  }
  public onShareLiveTrackingMeClick() {
    // console.log("Maps:onShareLiveTrackingMeClick");
    this.shareLiveTracking(this.currentLiveTrackingMe);
  }
  public onShareLiveTrackingMeKeyClick_old() {
    // console.log("Maps:onShareLiveTrackingMeKeyClick");
    this.shareLiveTrackingKey_old(this.currentLiveTrackingMe);
  }
  // show active live-tracking-me
  public onShowLiveTrackingMeClick() {
    // console.log("Maps:onShowLiveTrackingMeClick");
    this.closeAllPopups();
    this.newLiveTrackingMe = this.currentLiveTrackingMe;
    this.createLiveTrackingMeMapPopup("show");
  }
  // suspend active live-tracking-me
  public onAbortLiveTrackingMeClick() {
    // console.log("Maps:onAbortLiveTrackingMeClick");
    this.isLiveTrackingMePopupVisible = false;
    this.currentLiveTrackingMe.aborted = true;
    this.isOnline = this.commandSenderService.getIsOnline();
    if (this.isOnline) {
      this.globalService.updateLiveTracking(this.currentLiveTrackingMe);
    }
    this.currentLiveTrackingMe = undefined;
    localStoreLiveTrackingMe(this.currentLiveTrackingMe);
    this.releaseLiveTrackingWakeLock();
  }
  public onLiveTrackingMePopupStartClick() {
    // console.log("Maps:onLiveTrackingMePopupStartClick");
    this.newLiveTrackingMe.startTime = new Date(Date.now());
    this.newLiveTrackingMe.endTime = new Date(this.newLiveTrackingMe.startTime.getTime() +
      this.newLiveTrackingMe.duration * 60 * 1000);
    this.newLiveTrackingMe.name = this.newLiveTrackingMeName;
    this.newLiveTrackingMe.storeOnlyEndPosition = !this.newLiveTrackingMeStoreWholeRoute;
    this.startLiveTrackingMe();
    this.isLiveTrackingMePopupVisible = false;
  }
  public onLiveTrackingMePopupUpdateClick() {
    //console.log("Maps:onLiveTrackingMePopupUpdateClick");
    this.isLiveTrackingMePopupVisible = false;
    this.currentLiveTrackingMe.name = this.newLiveTrackingMeName;
    this.currentLiveTrackingMe.storeOnlyEndPosition = !this.newLiveTrackingMeStoreWholeRoute;
    this.globalService.updateLiveTracking(this.currentLiveTrackingMe);
  }
  public onLiveTrackingMePopupQuitClick() {
    // console.log("Maps:onLiveTrackingMePopupQuitClick");
    this.isLiveTrackingMePopupVisible = false;
  }
  public onLiveTrackingMePopupChanged() {
    // console.log("Maps:onLiveTrackingMePopupChanged");
    this.hasLiveTrackingMePopupChanged = true;
  }
  private async startLiveTrackingMe() {
    // console.log("Maps:startLiveTrackingMe");
    const result = await this.globalService.addLiveTracking(this.newLiveTrackingMe);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "addLiveTracking");
      this.showApiError(apiErr);
      return;
    }
    const addedTracking = result.addedLiveTracking;
    // console.log("Maps:onLiveTrackingClick-addedTracking", addedTracking);
    this.currentLiveTrackingMe = addedTracking;
    localStoreLiveTrackingMe(this.currentLiveTrackingMe);
    this.activateLiveTrackingWakeLock();
    // create start-position
    const newTrackPos = {} as LiveTrackPosition;
    newTrackPos.trackId = this.currentLiveTrackingMe.trackingId;
    newTrackPos.at = new Date(Date.now());
    newTrackPos.coordLon = this.currentPositionLngLat.lng;
    newTrackPos.coordLat = this.currentPositionLngLat.lat;
    this.globalService.addLiveTrackingPosition(newTrackPos, this.currentLiveTrackingMe.storeOnlyEndPosition);
    this.lastLiveTrackingMePosition = newTrackPos;
    // share tracking-key
    this.shareLiveTracking(addedTracking);
    // add UsageLog
    const info = "trackId=" + addedTracking.trackingId + ":userId=" + addedTracking.userId +
      ":time=" + addedTracking.duration + ":name=" + addedTracking.name;
    this.createUsageLog(EnumActionType.send_livetracker, info);
    // this.onPositionChangedLiveTracking();

  }
  public shareLiveTracking(tracking: LiveTracking) {
    const url = this.baseUrl + "ver=2.0&" + "tracking-key=" + tracking.trackingId;
    const sharedData: ShareData = { url };
    sharedData.title = this.shareLiveTrackingSubjectText;
    sharedData.text = this.shareLiveTrackingMsg2Text;
    sharedData.text += "" + "The tracking is active for " + tracking.duration + "min";
    navigator.share(sharedData);
  }
  public shareLiveTrackingKey_old(tracking: LiveTracking) {
    const data = "Your tracking-key:" + tracking.trackingId;
    const sharedData: ShareData = {};
    sharedData.title = this.shareLiveTrackingKeySubjectText;
    sharedData.text = this.shareLiveTrackingKeyMsg2Text;
    sharedData.text += data;
    navigator.share(sharedData);
  }
  private async activateLiveTrackingWakeLock() {
    if (!this.useWakeLock) { return; };
    let wakeLock: any;
    try {
      const extendedNavigator: any = navigator;
      wakeLock = await extendedNavigator.wakeLock.request("screen");
      // console.log("Maps:activateWakeLock", wakeLock);
    } catch (err) {
      // console.log("Maps:WakeLock-Error", `${err.message}`);
    }
    this.liveTrackingWakeLock = wakeLock;
  }
  private releaseLiveTrackingWakeLock() {
    if (!this.liveTrackingWakeLock) { return; }
    this.liveTrackingWakeLock.release();
    this.liveTrackingWakeLock = undefined;
  }

  // follow live-trackings
  private addLiveTracking(liveTracking: LiveTracking) {
    // console.log("Maps:addLiveTracking-liveTracking", liveTracking);
    if (this.debugLiveTrackingOff) { return; }
    const now = new Date(Date.now());
    let sinceTime = new Date(liveTracking.startTime.getTime() - 1000);
    if (liveTracking.trackPositions.length > 0) {
      const trackPositions = liveTracking.trackPositions;
      const lastTrackPosition = trackPositions[trackPositions.length - 1];
      sinceTime = lastTrackPosition.at;
    }
    if (!this.liveTrackingUpdateInterval) { this.createLiveTrackingUpdateTimer(); }
    this.getLiveTrackingPositions(liveTracking, sinceTime);
    if (!this.liveTrackingInterval) { this.createLiveTrackingTimer(); }
  }

  private async createLiveTrackingTimer() {
    // console.log("Maps:createLiveTrackingTimer");
    this.liveTrackingInterval = setInterval(() => {
      let clear = true;
      const now = new Date(Date.now());
      // console.log("Maps:createLiveTrackingTimer-now", now);
      for (const liveTracking of this.liveTrackings) {
        let sinceTime = new Date(liveTracking.startTime.getTime() - 1000);
        const trackPositions = liveTracking.trackPositions;
        if (trackPositions.length > 0) {
          const lastTrackPosition = trackPositions[trackPositions.length - 1];
          sinceTime = lastTrackPosition.at;
        }
        if (now.getTime() > liveTracking.endTime.getTime()) { continue; }
        // console.log("Maps:createLiveTrackingTimer-sincetime", sinceTime);
        this.getLiveTrackingPositions(liveTracking, sinceTime);
        clear = false;
      }
      if (clear) {
        clearInterval(this.liveTrackingInterval);
        this.liveTrackingInterval = undefined;
        // console.log("Maps:createLiveTrackingTimer-cleared");
      }
    }, 30000); // 30sec
  }

  private async createLiveTrackingUpdateTimer() {
    // console.log("Maps:createLiveTrackingUpdateTimer");
    const now = new Date(Date.now());
    this.liveTrackingUpdateInterval = setInterval(() => {
      // console.log("Maps:createLiveTrackingUpdateTimer-now", now);
      this.isOnline = this.commandSenderService.getIsOnline();
      if (this.isOnline) {
        this.updateLiveTrackings();
        if (this.liveTrackings.length === 0) {
          clearInterval(this.liveTrackingUpdateInterval);
          this.liveTrackingUpdateInterval = undefined;
          // console.log("Maps:createLiveTrackingUpdateTimer-cleared");
        }
      }
    }, 600000);  // 10min
  }

  private async updateLiveTrackings() {
    // console.log("Maps:updateLiveTrackings");
    this.isOnline = this.commandSenderService.getIsOnline();
    if (!this.isOnline) { return; }
    if (!this.liveTrackings) { return; }
    for (const liveTracking of this.liveTrackings) {
      const drawOnlyEndPoint = liveTracking.drawOnlyEndPoint;
      const trackPositions = liveTracking.trackPositions;
      const index = this.liveTrackings.indexOf(liveTracking);
      const result = await this.globalService.getLiveTracking(liveTracking.trackingId);
      if (result.status === EnumGlobalStatusCode.Success) {
        const updatedliveTracking = result.liveTracking;
        updatedliveTracking.trackPositions = trackPositions;
        updatedliveTracking.drawOnlyEndPoint = drawOnlyEndPoint;
        this.liveTrackings[index] = updatedliveTracking;
      }
    }
  }

  private async getLiveTrackingPositions(liveTracking: LiveTracking, sinceTime: Date) {
    this.isOnline = this.commandSenderService.getIsOnline();
    if (!this.isOnline) { return; }
    const result = await this.globalService.getLiveTrackingPositions(liveTracking.trackingId, sinceTime);
    // console.log("Maps:getLiveTrackingPositions-result", result);
    if (result.status === EnumGlobalStatusCode.Success) {
      const trackPositions = result.liveTrackingPositions;
      if (liveTracking.storeOnlyEndPosition) {
        if (trackPositions.length > 0) {
          liveTracking.trackPositions.splice(0);
          liveTracking.trackPositions.push(trackPositions[trackPositions.length - 1]);
        }
      } else {
        for (const item of trackPositions) {
          liveTracking.trackPositions.push(item);
        }
      }
      this.testTrackingPositions = liveTracking.trackPositions;
      this.drawLiveTrackings();
    }
  }
  private drawLiveTrackings() {
    if (!this.liveTrackingSource) { return; }
    this.liveTrackingSource.clear();
    for (const xTracking of this.liveTrackings) {
      // console.log("Maps:drawLiveTracking-xTracking", xTracking);
      this.drawLiveTracking(xTracking);
    }
  }
  private drawLiveTracking(xTracking: LiveTracking) {
    // console.log("Maps:drawLiveTracking-xTracking", xTracking);
    const id = this.liveTrackings.indexOf(xTracking);
    const trackingPositions = xTracking.trackPositions;
    if (!trackingPositions) { return; }
    if (trackingPositions.length === 0) { return; }
    const color = this.getRotationColor(id, 5);
    // get position-points
    const points = new Array();
    for (const xPosition of trackingPositions) {
      // console.log("Maps:drawLiveTracking-xPosition", xPosition);
      const point2 = transform([xPosition.coordLon, xPosition.coordLat], "EPSG:4326", "EPSG:3857");
      points.push(point2);
    }
    // console.log("Maps:drawLiveTracking-points", points);
    // end-point-feature
    const endPosition = trackingPositions[trackingPositions.length - 1];
    const endPoint = points[points.length - 1];
    const endFeature = new Feature({
      geometry: new Point(endPoint),
      type: "live-track",
      cat: "track-end",
      id,
      name: xTracking.name,
      lon: endPosition.coordLon,
      lat: endPosition.coordLat,
    });
    const pointStyle = createLiveTrackingEndPointStyle(color, xTracking.name);
    endFeature.setStyle(pointStyle);
    // console.log("Maps:drawLiveTracking-endFeature", endFeature);
    this.liveTrackingSource.addFeature(endFeature);
    // path-feature
    // console.log("Maps:drawLiveTracking-drawOnlyEndPoint", xTracking.drawOnlyEndPoint);
    if (xTracking.drawOnlyEndPoint) { return; }
    if (points.length <= 2) { return; }
    const pathFeature = new Feature({
      geometry: new LineString(points),
      type: "live-track",
      cat: "track-route",
      id,
      name: xTracking.name,
      // start: xTracking.startTime,
      // end: xTracking.endTime,
    });
    const pathStyle = createLiveTrackingPathStyle(color);
    pathFeature.setStyle(pathStyle);
    // console.log("Maps:drawLiveTracking-lineFeature", lineFeature);
    this.liveTrackingSource.addFeature(pathFeature);
  }

  public onRemoveTrackingClickAtRoute() {
    // console.log("Maps:onRemoveTrackingClickAtRoute-slectedLiveTracking", this.selectedLiveTracking);
    this.closeAllPopups();
    for (const xTracking of this.liveTrackings) {
      // console.log("Maps:onRemoveTrackingClickAtRoute-xTracking1", xTracking);
      if (xTracking.trackingId === this.selectedLiveTracking.trackingId) {
        // console.log("Maps:onRemoveTrackingClickAtRoute-xTracking2", xTracking);
        const index = this.liveTrackings.indexOf(xTracking);
        this.liveTrackings.splice(index, 1);
        this.drawLiveTrackings();
        localStoreLiveTrackings(this.liveTrackings);
        return;
      }
    }
  }
  public onRemoveTrackingClickAtPoint() {
    // console.log("Maps:onRemoveTrackingClickAtPoint-slectedLiveTracking", this.selectedLiveTracking);
    this.closeAllPopups();
    const index = this.selectedMapPoint.id;
    this.liveTrackings.splice(index, 1);
    // console.log("Maps:onRemoveTrackingClick-liveTrackings", this.liveTrackings);
    this.drawLiveTrackings();
    localStoreLiveTrackings(this.liveTrackings);
  }
  public onShowOnlyTrackingEndPositionClick() {
    this.closeAllPopups();
    const trackingId = this.selectedLiveTracking.trackingId;
    // console.log("Maps:onRemoveTrackingClick-trackingId", trackingId);
    for (const xTracking of this.liveTrackings) {
      // console.log("Maps:onShowOnlyTrackingEndPositionClick-xTracking1", xTracking);
      if (xTracking.trackingId === trackingId) {
        // console.log("Maps:onRemoveTrackingClick-xTracking2", xTracking);
        const index = this.liveTrackings.indexOf(xTracking);
        this.liveTrackings[index].drawOnlyEndPoint = true;
        // console.log("Maps:onShowOnlyTrackingEndPositionClick-liveTrackings", this.liveTrackings);
        this.drawLiveTrackings();
        localStoreLiveTrackings(this.liveTrackings);
        return;
      }
    }
  }
  public onShowWholeTrackingRouteClick() {
    // console.log("Maps:onShowWholeTrackingRouteClick-liveTrackings", this.liveTrackings);
    this.closeAllPopups();
    const index = this.selectedMapPoint.id;
    this.liveTrackings[index].drawOnlyEndPoint = false;
    this.drawLiveTrackings();
    localStoreLiveTrackings(this.liveTrackings);
  }

  // login-needed-popup
  private async createNeedToLoginMapPopup() {
    // console.log("Maps:createNeedToLoginMapPopup");
    this.closeAllPopups();
    this.isNeedToLoginPopupVisible = true;
    // display popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      let headerText = "To use this function you have to login first";
      if (this.userLanguage === "de") { headerText = "Du musst dich einloggen um diese Funktion zu nutzen"; }
      const content =
        "<div style='color:red;'>" + headerText + "</div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>";
      // console.log("Maps:createLiveTrackingMeMapPopup-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      const extent = this.view.calculateExtent();
      // console.log("Maps:createPoisFilterPopup-extend", extent);
      const coordX = extent[0] + (extent[2] - extent[0]) * 0.3;
      const coordY = extent[1] + (extent[3] - extent[1]) * 0.2;
      const coords = new Array(coordX, coordY);
      // console.log("Maps:createPoisFilterPopup-coords", coords);
      overlay.setPosition(coords);
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  // error-popup
  private async createErrorMapPopup() {
    // console.log("Maps:createErrorMapPopup");
    this.closeAllPopups();
    this.isErrorMessagePopupVisible = true;
    // display popup
    setTimeout(() => {
      const overlay = new Overlay({
        element: this.containerElement.nativeElement,
        autoPan: false,
        // autoPanAnimation: { duration: 100 }
      });
      // const headerText = this.errorMessageTitle;
      const headerText = this.errorMessage;
      let content =
        "<div style='width:300px'>" +
        "<div style='color:red;'>" + headerText + "</div>" +
        "<hr style='margin-top:0;margin-bottom:0.5em'>" +
        // "<span>" + this.errorMessage + "</span><br>" +
        "<span style='font-size:smaller'>" + this.errorMessageHelp;
      if (this.errorMessageOffline) {
        content += "<img style='height:16px' src='./assets/icons/wifi-alert.svg'"
      }
      content += "</span>"
      content += "</div>";
      // console.log("Maps:createLiveTrackingMeMapPopup-content", content);
      this.contentElement.nativeElement.innerHTML = content;
      const extent = this.view.calculateExtent();
      // console.log("Maps:createPoisFilterPopup-extend", extent);
      const coordX = extent[0] + (extent[2] - extent[0]) * 0.2;
      const coordY = extent[1] + (extent[3] - extent[1]) * 0.2;
      const coords = new Array(coordX, coordY);
      // console.log("Maps:createPoisFilterPopup-coords", coords);
      overlay.setPosition(coords);
      this.map.addOverlay(overlay);
      this.containerElement.nativeElement.hidden = false;
      overlay.panIntoView();
    });
  }
  private async clearErrorMapPopup() {
    // console.log("Maps:clearErrorMapPopup");
    this.isErrorMessagePopupVisible = false;
  }
  private async checkIfNetworkAccessOk() {
    // §todo check for online
    const result = await this.globalService.isApiAvailable();
    if (result.status === EnumGlobalStatusCode.Success) {
      this.isOnline = true;
    } else {
      this.isOnline = false;
    }
    // console.log("Maps:checkIfNetworkAccessOk-isOnline", this.isOnline);
  }

  // login
  public onLoginClick_alt() {
    // console.log("Maps:onLoginClick");
    this.closeAllPopups();
  }



  private convertRoutePoisToUrl() {
    // console.log("Maps:convertRoutePoisToUrl-currentRoute", this.currentRoute);
    if (this.currentRoute.pois.length === 0) { return ""; }
    let poisUrl = "";
    for (const poi of this.currentRoute.pois) {
      if (poisUrl.length > 0) { poisUrl += ","; }
      poisUrl += convertPoiToUrlString(poi);
    }
    poisUrl = "pois=" + poisUrl;
    return poisUrl;
  }
  private convertMarkersToUrl() {
    // console.log("Maps:convertMarkersToUrl-markerPoints", this.markerPoints);
    let markersUrl = "";
    if (this.markerPoints.length) {
      markersUrl = "markers=";
      let markersCoord = "";
      for (const point of this.markerPoints) {
        if (markersCoord) { markersCoord += ";"; }
        markersCoord += convertMapPlaceToUrlString(point);
      }
      markersUrl += markersCoord;
    }
    return markersUrl;
  }

  private convertPlaceAsMarkerToUrl(place: MapPlaceL) {
    // console.log("Maps:convertPlaceAsMarkerToUrl-place", place);
    const marker = cloneMapPlace(place);
    marker.type = "marker";
    const markersCoord = convertMapPlaceToUrlString(marker);
    const markersUrl = "markers=" + markersCoord;
    // console.log("Maps:convertPlaceAsMarkerToUrl-markersUrl", markersUrl);
    return markersUrl;
  }

  private getRotationColor(id: number, mod: number) {
    // console.log("Maps:getRotationColor-id", id);
    // console.log("Maps:getRotationColor-mod", mod);
    if (!this.colorArray) { this.colorArray = createColorArray(); }
    const index = id - Math.floor(id / mod) * mod;
    const color = this.colorArray[index];
    return color;
  }

  private async loadAvailableRegions() {
    // console.log("PublishRoute:getLoadAvailableRegions-loggedInUser", this.loggedInUser);

    let regionIds = getAvailableRegions();
    if (this.loggedInUser && this.loggedInUser.name === "Developer") { regionIds = getAvailableRegionsDevelop(); }
    this.searchRegions = new Array<RefRegion>();
    for (const id of regionIds) {
      const result = await this.globalService.getRefRegion(id, 0, false);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = createApiError(result, "getRefRegion");
        this.showApiError(apiErr);
        return;
      }
      const refRegion = result.refRegion;
      if (refRegion) {
        if (!refRegion.localName) { refRegion.localName = refRegion.name; }
        this.searchRegions.push(refRegion);
      }
    }
    // sort regions
    // this.searchRegions.sort((a, b) => {
    //   if (a.name < b.name) { return -1; }
    //   if (a.name > b.name) { return 1; }
    //   return 0;
    // });
  }

  private async showLocalNotificationsAtStart() {
    const swRegistration = await navigator.serviceWorker.getRegistration();
    // console.log("Maps:showLocalNotificationsAtStart:swRegistration", swRegistration);
    if (!swRegistration) { return; }
    const localStorage = window.localStorage;
    this.notifications = localLoadNotifications(this.userLanguage);
    this.showNotificationShareLocation(swRegistration);
  }
  private showNotificationShareLocation(swRegistration) {
    // share-location
    let doNotifyShareLocation = true;
    if (this.notifications) {
      const statusShareLocation = this.notifications.shareLocation;
      if (statusShareLocation === "done" || statusShareLocation === "ok") { doNotifyShareLocation = false; }
    }
    if (Date.now().valueOf() > (new Date(2023, 12, 23)).valueOf()) { doNotifyShareLocation = false; }  // §todo set realistic date
    if (doNotifyShareLocation) {
      showLocalNotificationShareLocation(this, swRegistration, this.swPush, this.userLanguage);
    }
  }
  public notificationActionShowInfo(action: string) {
    // console.log("Maps:notificationActionShowInfo:action", action);
    if (action === "share-loc") {
      if (!this.notifications) { this.notifications = {} as any; }
      this.notifications.shareLocation = "done";
      localStoreNotifications(this.notifications);
      window.location.href = "/faq/" + this.userLanguage + "/faq-loc";
    }
  }
  public notifcationActionOk(action: string) {
    // console.log("Maps:notifcationActionOk:action", action);
    if (!this.notifications) { this.notifications = {} as any; }
    this.notifications.shareLocation = "ok";
    localStoreNotifications(this.notifications);
  }
  public notifcationActionLater(action: string) {
    // console.log("Maps:notifcationActionLater:action", action);
  }

  public async onOfflineSymbolClicked() {
    await this.checkIfNetworkAccessOk();
    if (!this.isOnline) {
      this.showOfflineError();
    } else {
      this.clearErrorMapPopup();
    }
  }
  private showOfflineError() {
    const apiErr = {} as ApiError;
    apiErr.status = EnumGlobalStatusCode.HttpError;
    this.showApiError(apiErr);
  }
  public showORSSearchError(result: any) {
    console.log("Maps:showORSSearchError:result", result);
    if (result.type == "http-error") {
      this.showORSRequestError("searchautocomplete", EnumGlobalStatusCode.HttpError, result);
    }
    if (result.type == "ors-error") {
      this.orsPostErrorText = result.errorMessage;
      this.showORSRequestError("searchautocomplete", EnumGlobalStatusCode.ORSError, result);
    }
  }
  private showORSRequestError(type: string, status: EnumGlobalStatusCode, result: any) {
    this.errorMessageOffline = false;
    let errText = "Error during";
    let errMessage = "Error requesting OpenRouteService";
    if (this.userLanguage === "de") {
      errText = "Fehler bei"
      errMessage = "Fehler bei Anfrage an OpenRouteService";
    }
    this.errorMessageTitle = errText + " '" + type + "'";
    this.errorMessage = errMessage;
    if (status === EnumGlobalStatusCode.HttpError) {
      this.errorMessageHelp = this.errorMessageHelpOffline;
      this.errorMessageOffline = true;
      this.isOnline = false;
    }
    if (status === EnumGlobalStatusCode.ORSError) {
      this.errorMessageHelp = this.orsPostErrorText;
    }
    // console.log("Maps:showORSRequestError-isOnline", this.isOnline);
    this.createErrorMapPopup();
  }
  private showOverpassRequestError(type: string, status: EnumGlobalStatusCode, result: any) {

    let errText = "Error during";
    let errMessage = "Error requesting OSM-Overpass-Api";
    if (this.userLanguage === "de") {
      errText = "Fehler bei"
      errMessage = "Fehler bei Anfrage an OverPass-Api";
    }
    this.errorMessageTitle = errText + " '" + type + "'";
    this.errorMessage = errMessage;
    if (status === EnumGlobalStatusCode.HttpError) {
      this.errorMessageHelp = this.errorMessageHelpOffline;
      this.errorMessageOffline = true;
      this.isOnline = false;
    }
    this.createErrorMapPopup();
  }
  public showApiError(apiErr: ApiError) {
    const status = apiErr.status;
    this.errorMessageTitle = "";
    this.errorMessage = "";
    this.errorMessageHelp = "";
    // §todo TestUser
    // if (!this.isTestUser) { return; }

    // title
    this.errorMessageTitle = "Error during access to the server'";
    if (this.userLanguage === "de") {
      this.errorMessageTitle = "Fehler beim Zugriff auf Server'";
    }

    // status
    if (status === EnumGlobalStatusCode.HttpError) {
      this.isOnline = false;
      this.socialAuthService.updateUserId();
      this.errorMessage = "No answer returned from Trip4YouMaps-Server";
      if (this.userLanguage === "de") {
        this.errorMessage = "Keine Antwort von Trip4YouMaps-Server'";
      }
      this.errorMessageHelp = this.errorMessageHelpOffline;
      this.errorMessageOffline = true;
      this.createErrorMapPopup();
      return;
    }
    if (status === EnumGlobalStatusCode.UserNotLoggedin) {
      this.loggedInUser = undefined;
      this.socialAuthService.updateUserId();
      this.errorMessage = "Sorry your login time is expired - Please login again";
      this.errorMessageHelp = "To be on the safe side, save route on local device first!";
      if (this.userLanguage === "de") {
        this.errorMessage = "Deine Login-Zeit ist abgelaufen, bitte log dich neu ein";
        this.errorMessageHelp = "Speichere deine Route sicherheithalber auf dem lokalen Gerät!";
      }
      this.createErrorMapPopup();
      return;
    }
    if (status === EnumGlobalStatusCode.NotEnoughPrivileges) {
      this.errorMessage = "You have not enough proviliges for this action";
      this.errorMessageHelp = "";
      if (this.userLanguage === "de") {
        this.errorMessage = "Du hast nicht genügend Rechte für diese Aktion";
        this.errorMessageHelp = "";
      }
      this.createErrorMapPopup();
      return;
    }
    this.errorMessage = "Unknow error during request";
    this.errorMessageHelp = "Check your network connection!";
    if (this.userLanguage === "de") {
      this.errorMessage = "Unbekannter Fehler während der Anfrage";
      this.errorMessageHelp = "Prüfe deine Internetverbindung!";
    }
    this.createErrorMapPopup();
  }

  private async createUsageLog(actionType: EnumActionType, info: string) {
    // update isOnline from api-service
    this.checkIfNetworkAccessOk();
    if (!this.isOnline) { return; }
    this.startedAt = new Date(Date.now());
    // sessionId
    // console.log("Maps:createUsageLog-sessionId1", this.sessionId);
    if (!this.sessionId) {
      this.sessionId = this.globalService.getSessionId();
      // console.log("Maps:createUsageLog-sessionId2", this.sessionId);
    }
    // referrer
    if (!this.referrer) {
      this.referrer = document.referrer;
      // console.log("Maps:ngOnInit-referrer", this.referrer);
    }
    const usageLog = {} as UsageLogMaps;
    usageLog.actionType = actionType;
    usageLog.actionInfo = info;
    if (this.paramPartnerId) { usageLog.partnerId = this.paramPartnerId; }
    usageLog.sessionId = this.sessionId;
    usageLog.userLanguage = this.translate.getBrowserLang();
    usageLog.referrer = this.referrer;
    usageLog.appVer = this.appVersion;
    usageLog.at = this.startedAt;
    const results = await this.globalService.addUsageLogMaps(usageLog);
    if (results.status != EnumGlobalStatusCode.Success) {
      if (results.status === EnumGlobalStatusCode.HttpError) { this.isOnline = false; }
      // const apiErr = createApiError(results, "addUsageLogMaps");
      // this.showApiError(apiErr);
      return;
    }
  }

  public getSurfaceName(id: number) {
    return getSurfaceName(id, this.userLanguage);
  }
  public getSurfaceColor(id: number) {
    return getSurfaceColor(id);
  }


  private toDebugLog(name: string, val?: any) {
    // console.log("Maps:toDebugLog-obj", obj);
    let debugString = name;
    if (val) {
      const objString = val.toString();
      // console.log("Maps:toDebugLog-objString", objString);
      // if (typeof val === "object") {
      //   objString = val.stringify();
      // }
      debugString += ": " + objString;
    }
    this.debugLog.push(debugString);
  }


  private async registerPeriodicSync_save() {

    const permissionDesc = ({ name: "periodic-background-sync" }) as any;
    const status = await navigator.permissions.query(permissionDesc);
    if (status.state === "granted") {
      // Periodic background sync can be used.
      if (this.showLog) { console.log("Test:registerPeriodicSync-status", status); }
    } else {
      // Periodic background sync cannot be used.
      if (this.showLog) { console.log("Test:registerPeriodicSync-status", status); }
    }

    const registration = await navigator.serviceWorker.ready;
    if (this.showLog) { console.log("Test:registerPeriodicSync-registration", registration); }
    try {
      let periodicSync: any;
      if ("periodicSync" in registration) {
        if (this.showLog) { console.log("Periodic Sync available!"); }
        periodicSync = (registration as any).periodicSync
        await periodicSync.register("location-sync3", {
          minInterval: 10, // Mindestintervall von 10 sec
        });
        const tags = await periodicSync.getTags();
        if (!tags.includes("location-sync3")) {
          if (this.showLog) { console.log("Location Sync konnte nicht registriert werden!"); }
        }
        if (tags.includes("location-sync3")) {
          if (this.showLog) { console.log("Location Sync konnte registriert werden - tags", tags); }
        }
      }
    } catch {
      if (this.showLog) { console.log("Periodic Sync konnte nicht registriert werden!"); }
    }

    // add-event-listener
    try {
      await navigator.serviceWorker.addEventListener("location-sync", (event) => {
        if (this.showLog) { console.log("Maps:---------------------------------------->>>>>>location-sync-event", event); }
        this.debugLog.push("location-sync-event");
      }, true);
      await navigator.serviceWorker.addEventListener("location-sync2", (event) => {
        if (this.showLog) { console.log("Maps:---------------------------------------->>>>>>location-sync2-event", event); }
        this.debugLog.push("location-sync2-event");
      }, true);
      await navigator.serviceWorker.addEventListener("location-sync3", (event) => {
        if (this.showLog) { console.log("Maps:---------------------------------------->>>>>>location-sync3-event", event); }
        this.debugLog.push("location-sync3-event");
      }, true);
      await navigator.serviceWorker.addEventListener("periodicsync", (event) => {
        if (this.showLog) { console.log("Maps:---------------------------------------->>>>>>periodicsync-event", event); }
        this.debugLog.push("periodicsync-event");
      }, true);
      await navigator.serviceWorker.addEventListener("fetch", (event) => {
        if (this.showLog) { console.log("Maps:fetch-event", event); }
        // this.debugLog.push("fetch-event");
      }, true);
    } catch (error) {
      console.error("Event-Listener konnte nicht hinzugefügt werden werden:", error);
    }

    const registrations = navigator.serviceWorker.getRegistrations();
    if (this.showLog) { console.log("Test:registerPeriodicSync-registrations", registrations); }

    this.getPeriodicSyncTags();
  }

  private async getPeriodicSyncTags() {
    const registration = await navigator.serviceWorker.ready;
    try {
      let periodicSync: any;
      if ("periodicSync" in registration) {
        periodicSync = (registration as any).periodicSync
        const periodicSyncTags = await periodicSync.getTags();
        if (this.showLog) { console.log("Test: getPeriodicSyncTags - tags", periodicSyncTags); }
      }
    } catch {
      if (this.showLog) { console.log("Test: Periodic Sync nicht verfügbar!"); }
    }
  }

  private createContinueTrackingPopup(language: string) {
    // console.log("Maps:createContinueTrackingPopup");
    let title = "Do you want to continue your tour?";
    if (language === "de") { title = "Möchtest du deine Tour fortsetzen?"; }
    const dialogRef = this.dialog.open(GeneralPopupComponent, {
      closeOnNavigation: true,
      data: {
        language,
        dlgTitle: title,
        dlgText: "",
        dlgText2: "",
        buttonYes: true,
        buttonNo: true,
        defaultButton: "yes",
      },
    });
    dialogRef.afterClosed().subscribe(popupResult => {
      // console.log("Maps:createSavedRoutePopup-popupResult", popupResult);
      if (popupResult === "yes") {
        this.startTourTimer();
      }
      if (popupResult === "no") {
        this.stopTourStatistics();
        this.onShowTrackChanged(false);
      }
    });
  }


}
