import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { EnumRouteType, MapPublicRoute, RefImage, User } from "src/dto.generated/api";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";

import { GlobalService } from "src/app/services/global.service";
import { GeneralPopupComponent } from "src/app/global-components/popup-general/popup-general.component";
import { getDifficultyLevelText, getRouteCategoryName, getRouteTypeName, getMonthName, getRouteDurationCorrectionFactor } from "../maps-utils/utils-route";
import { MapPlaceL } from "src/app/models/mapplace";
// import { Color } from "openlayers";
import { getColorFromIndex } from "../maps-utils/utils-route-categories";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-present-route-component",
  templateUrl: "./present-route.component.html",
  styleUrls: ["./present-route.component.scss"]
})
export class PresentRouteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() loggedInUser: User;
  @Input() route: MapPublicRoute;
  @Input() routeIndex: number;
  @Input() open: boolean;

  @Output() drawPublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() activatePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() removePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();

  // general variables
  public isTestUser: boolean;

  public name: string;
  public description: string
  public moveType: string;
  public difficultyLevel: string;
  public routeType: string;
  public durationHours: number;
  public durationMinutes: number;
  public moreData: boolean;
  public difficultiLevelText: string;
  public months: string[];
  public categories: string[];
  public pois: MapPlaceL[];
  public routeImage: RefImage;
  public refImageBaseUrl: string;
  public indexColor: string;
  public linkTravelPlanner: string;

  public shareRouteSubjectText: string;
  public shareRouteMsgText: string;

  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) { }

  public async ngOnInit() {
    // console.log("PresentRoute:ngOnInit-languageCode", this.languageCode);
    this.translate.use(this.languageCode);

    if (this.loggedInUser && this.loggedInUser.id === 1) { this.isTestUser = true; }

    // name
    this.getRouteNameForLanguage(this.languageCode);
    // console.log("PresentRoute:ngOnInit-name", this.name);
    // description
    this.getRouteDescriptionForLanguage(this.languageCode);
    // console.log("PresentRoute:ngOnInit-description", this.description);
    this.analyseRouteDescription()

    // image
    this.refImageBaseUrl = this.globalService.getRefImageBaseUrl();
    this.indexColor = getColorFromIndex(this.routeIndex);

    // difficulty
    // if(this.route.difficultyLevel === EnumDifficultyLevel.)

    // moveType
    let corrFactor = 1.0;
    if (this.route.plannerOptions) {
      const plannerOptions = JSON.parse(this.route.plannerOptions);
      // console.log("PresentRoute:ngOnInit-plannerOptions", plannerOptions);
      this.moveType = plannerOptions.moveType;
      // console.log("PresentRoute:ngOnInit-moveType", this.moveType);
    }
    // route-type
    this.routeType = getRouteTypeName(this.route.routeType, this.languageCode);
    // duration
    let duration = this.route.duration;
    // correction
    corrFactor = getRouteDurationCorrectionFactor(this.moveType);
    // console.log("PresentRoute:ngOnInit-corrFactor", corrFactor);
    duration = Math.floor(duration * corrFactor / 60);
    this.durationHours = Math.floor(duration / 60);
    this.durationMinutes = duration - this.durationHours * 60;

    // difficulty-level
    this.difficultiLevelText = getDifficultyLevelText(this.route.difficultyLevel, this.languageCode);

    // regions

    // months
    this.months = new Array<string>();
    if (this.route.months) {
      const catIds = this.route.months.split(",");
      for (const catIdString of catIds) {
        const id = Number(catIdString);
        const catName = getMonthName(id, this.languageCode);
        this.months.push(catName);
      }
    }

    // categories
    this.categories = new Array<string>();
    if (this.route.categories) {
      const catIds = this.route.categories.split(",");
      for (const catIdString of catIds) {
        const id = Number(catIdString);
        const catName = getRouteCategoryName(id, this.languageCode);
        this.categories.push(catName);
      }
    }

    // pois
    if (this.route.pois) {
      this.pois = JSON.parse(this.route.pois);
      // console.log("PresentRoute:ngOnInit-pois", this.pois);
    }

    // route-image
    if (this.route.imageIds) {
      const imageId = Number(this.route.imageIds);
      const result = await this.globalService.getRefImage(imageId);
      // if(!result) {
      //   return;
      // }
      this.routeImage = result;
      if (!this.routeImage.width) {
        this.routeImage.width = 4;
        this.routeImage.height = 3;
      }
    }

    // open
    if (this.open) { this.moreData = true; }
  }

  public ngAfterViewInit() {
    // console.log("PresentRoute:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onRouteClick() {
    this.moreData = !this.moreData;
  }

  public onRemoveRouteClick() {
    // console.log("PresentRoute:onRemoveRouteClick-route", this.route);
    this.RemovePublicRoutePopup(this.route, this.languageCode);
  }
  private RemovePublicRoutePopup(route: MapPublicRoute, language: string) {
    // console.log("Maps:removePublicRoutePopup-route", route);
    const title = "Öffentliche Route löschen";
    const dialogRef = this.dialog.open(GeneralPopupComponent, {
      closeOnNavigation: true,
      data: {
        language,
        dlgTitle: title,
        dlgText: "'" + route.name + "' " + "wirklich aus Datenbank löschen?",
        buttonYes: true,
        buttonNo: true,
      },
    });
    dialogRef.afterClosed().subscribe(popupResult => {
      // console.log("Maps:removePublicRoutePopup-popupResult", popupResult);
      if (popupResult === "yes") { this.onRemoveRoute(); }
    });
  }
  public onRemoveRoute() {
    // console.log("PresentRoute:onRemoveRoute-route", this.route);
    this.removePublicRoute.emit(this.route);
  }

  public onActivateRouteClick() {
    // console.log("PresentRoute:onActivateRouteClick-route", this.route);
    this.activatePublicRoute.emit(this.route);
  }

  public onDrawRouteClick() {
    // console.log("PresentRoute:onDrawRouteClick-route", this.route);
    this.drawPublicRoute.emit(this.route);
  }

  public onShareRouteClick() {
    // console.log("PresentRoute:onShareRouteClick-route", this.route);
    this.shareRouteSubjectText = "Shared route: ";
    this.shareRouteMsgText = " Click on the link to display the route."

    const url = "https://maps.trip4you.net/route/" + this.languageCode + "/" + this.route.routeId;
    const sharedData: ShareData = { url };
    sharedData.title = this.shareRouteSubjectText + this.route.name;
    sharedData.text = this.shareRouteMsgText;
    // console.log("PresentRoute:onShareRouteClick-sharedData", sharedData);
    if (!navigator.share) {
      console.error("No share on this device!");
      return;
    };
    // console.error("Share available on this device!");
    navigator.share(sharedData)
      .then(response => {
        // console.log("Share successfull");
      })
      .catch(error => {
        console.error("Error during share of public route:", error);
      });
  }

  public onImageClick(refImage: RefImage) {

  }

  private getRouteNameForLanguage(languageCode: string) {
    // english
    if (languageCode === "en") { this.name = this.route.nameEn; }
    // german
    if (languageCode === "de") { this.name = this.route.nameDe; }
    if (!this.name) { this.name = this.route.nameEn; }
    if (!this.name) { this.name = this.route.nameDe; }
  }

  private getRouteDescriptionForLanguage(languageCode: string) {
    // english
    if (languageCode === "en") { this.description = this.route.descriptionEn; }
    // german
    if (languageCode === "de") { this.description = this.route.descriptionDe; }
    if (!this.description) { this.description = this.route.descriptionEn; }
    if (!this.description) { this.description = this.route.descriptionDe; }
  }

  private analyseRouteDescription() {
    if (!this.description) return;
    const lines = this.description.split("\n");
    // console.log("PresentRoute:analyseRouteDescription-lines", lines);
    for (const line of lines) {
      if (line.startsWith("$linkTP$")) {
        this.linkTravelPlanner = line.slice(8);
        // console.log("PresentRoute:analyseRouteDescription-linkTravelPlanner", this.linkTravelPlanner);
      }
    }
    this.description = "";
    for (const line of lines) {
      if (!line.startsWith("$")) {
        if (this.description.length) { this.description += "\n"; }
        this.description += line;
      }
    }
  }


}
