<!-- present-route-component -->

<!-- route not found -->
<div *ngIf="!route.id">
    <div style="margin:1.0rem; font-size:x-large;">
        <span *ngIf="languageCode==='de'" style="color:red">Route mit der Id '{{route.routeId}}' nicht gefunden!</span>
        <span *ngIf="languageCode!=='de'" style="color:red">Route width id '{{route.routeId}}' not found!</span>
        <span> {{name}}</span> <!-- name is routeId -->
    </div>
</div>

<!-- route found -->
<div *ngIf="route.id" style="margin-top:0.5rem; margin-top:1.0rem; padding:0.5rem; background-color:honeydew;">

    <div>
        <!-- <div style="cursor:pointer;" (click)="onRouteClick()"> -->

        <!-- moveType + route-name -->
        <div style="font-size:x-large;">
            <span *ngIf="routeIndex!=undefined" [style.color]="indexColor">{{routeIndex+1}} : </span>
            <span>{{name}}</span>
        </div>
        <!-- route-type -->
        <div>
            <img *ngIf="moveType==='bike'" src="./assets/icons/bike.svg" title="{{'MAPS.RPL.DLG.BYBIKE_T' | translate}}"
                alt="symbol">
            <img *ngIf="moveType==='ebike'" src="./assets/icons/bicycle-electric.svg"
                title="{{'MAPS.RPL.DLG.BYEBIKE_T' | translate}}" alt="symbol">
            <img *ngIf="moveType==='bike-road'" src="./assets/icons/bike-road.svg"
                title="{{'MAPS.RPL.DLG.BYBIKEROAD_T' | translate}}" alt="symbol">
            <img *ngIf="moveType==='mtb'" src="./assets/icons/bike-mtb.svg"
                title="{{'MAPS.RPL.DLG.BYMTB_T' | translate}}" alt="symbol">
            <img *ngIf="moveType==='walking'" src="./assets/icons/walking.svg"
                title="{{'MAPS.RPL.DLG.WALKING_T' | translate}}" alt="symbol">
            <img *ngIf="moveType==='hiking'" src="./assets/icons/hiking.svg"
                title="{{'MAPS.RPL.DLG.HIKING_T' | translate}}" alt="symbol">
            <img *ngIf="moveType==='car'" src="./assets/icons/car-hatchback.svg"
                title="{{'MAPS.RPL.DLG.BYCAR_T' | translate}}" alt="symbol">
            <span style="margin-left:0.5rem; font-size:medium;">{{routeType}}</span>
        </div>

        <!-- route-image -->
        <div *ngIf="routeImage" style="margin-top:0.5rem;">
            <ng-container *ngIf="routeImage.sourceType===undefined || routeImage.sourceType==1">
                <img *ngIf="routeImage.width>=routeImage.height" style="width:100%; max-width:600px;"
                    [src]="refImageBaseUrl+routeImage.url" title="{{routeImage.title}}"
                    (click)="onImageClick(routeImage)">
                <img *ngIf="routeImage.height>routeImage.width" style="width:100%; max-width:600px; max-height:450px;"
                    [src]="refImageBaseUrl+routeImage.url" title="{{routeImage.title}}"
                    (click)="onImageClick(routeImage)">
            </ng-container>
            <ng-container *ngIf="routeImage.sourceType==2">
                <img style="width:100%; max-width:600px; max-height:450px;" [src]="routeImage.url"
                    title="{{routeImage.title}}" (click)="onImageClick(routeImage)">
            </ng-container>

        </div>

        <!-- route-data -->
        <div style="margin-top:0.5rem;font-size:large;">
            <img style="margin-right:0.0rem;" width=16px src="./assets/icons/clock-outline.svg">
            <span> {{durationHours}}:{{durationMinutes | number:'2.0-0'}} </span>
            <span>&#8596; {{route.routeLength/1000 | number:'1.0-1'}} km </span>
            <span>&#10138; {{route.ascent}} m </span>
            <span>&#10136; {{route.descent}} m </span>
        </div>

        <!-- more-data -->
        <div style="margin-top:0.5rem;font-size:large;cursor:pointer;" (click)="onRouteClick()">
            <span>{{'MAPS.PRESENT-ROUTE.DESCRIPTION_T' | translate}}:
                <img *ngIf="!moreData" style="margin-left:0.5rem; background-color:white"
                    src="./assets/icons/chevron-down.svg" alt="symbol">
                <img *ngIf="moreData" style="margin-left:0.5rem; background-color:white"
                    src="./assets/icons/chevron-up.svg" alt="symbol">
            </span>
        </div>

        <div *ngIf="moreData" style="padding:0.5rem;border:2px solid darkolivegreen; border-radius:5px;">

            <!-- description -->
            <div *ngIf="description">
                <div style="white-space: pre-line;">
                    <span>{{description}}</span>
                </div>
            </div>

            <!-- difficulty-level -->
            <div *ngIf="route.difficultyLevel" style="margin-top:0.5rem;font-size:large;">
                <span>{{'MAPS.PRESENT-ROUTE.DIFFICULTY_T' | translate}}: </span>
                <span class="level" [class.level-easy]="route.difficultyLevel === 1"
                    [class.level-moderate]="route.difficultyLevel === 2"
                    [class.level-difficult]="route.difficultyLevel === 3">{{this.difficultiLevelText}}</span>
            </div>

            <!-- months -->
            <div *ngIf="months.length" style="margin-top:0.5rem;font-size:large;">
                <span>{{'MAPS.PRESENT-ROUTE.MONTHS_T' | translate}}:</span><br>
                <ng-container *ngFor="let cat of months; let l=last">
                    <span style="margin-left:0.5rem;">{{cat}}</span><span *ngIf="!l">, </span>
                </ng-container>
            </div>

            <!-- categories -->
            <div *ngIf="categories.length" style="margin-top:0.5rem;font-size:large;">
                <span>{{'MAPS.PRESENT-ROUTE.CATEGORIES_T' | translate}}:</span>
                <div style="margin-left:0.5rem;">
                    <ng-container *ngFor="let cat of categories; let l=last">
                        <span>{{cat}}</span><span *ngIf="!l"> - </span>
                    </ng-container>
                </div>
            </div>

            <!-- pois -->
            <div *ngIf="pois.length" style="margin-top:0.5rem;font-size:large;">
                <span>{{'MAPS.PRESENT-ROUTE.POIS_T' | translate}}:</span>
                <div style="margin-left:0.5rem;">
                    <ng-container *ngFor="let poi of pois; let i=index">
                        <span>{{i+1}}: </span><span>{{poi.name}}</span><br>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="linkTravelPlanner" style="margin-top:0.5rem;">
                <ng-container *ngIf="languageCode==='de'">
                    <a href="{{linkTravelPlanner}}" target="_blank" title="{{linkTravelPlanner}}">Link zu Route in
                        Trip4You-TravelPlanner</a>
                </ng-container>
                <ng-container *ngIf="languageCode==='en'">
                    <a href="{{linkTravelPlanner}}" target="_blank" title="{{linkTravelPlanner}}">Link to route in
                        Trip4You-TravelPlanner</a>
                </ng-container>
            </div>


        </div>

    </div>

    <!-- buttons -->
    <div *ngIf="route.geojsonRoute" style="margin-top:0.5rem;font-size:large;">
        <button mat-raised-button color="primary" style="margin-left:0.5rem;" (click)="onActivateRouteClick()">
            {{'MAPS.PRESENT-ROUTE.ACTIVATE-ROUTE_BTN_T' | translate}}</button>
        <button mat-raised-button color="primary" style="margin-left:0.5rem;" (click)="onDrawRouteClick()">
            {{'MAPS.PRESENT-ROUTE.DRAW-ROUTE_BTN_T' | translate}}</button>
        <button mat-raised-button color="primary" style="margin-left:0.5rem;" (click)="onShareRouteClick()">
            {{'MAPS.PRESENT-ROUTE.SHARE-ROUTE_BTN_T' | translate}}</button>

        <!-- remove-route -->
        <button *ngIf="loggedInUser && loggedInUser.mapPriv" mat-raised-button color="warn"
            style="margin-top:0.5rem; margin-left:0.5rem;" (click)="onRemoveRouteClick()">Remove</button>

        <!-- not approved -->
        <!-- <div *ngIf="loggedInUser && loggedInUser.mapPriv && !route.approved" -->
        <div style="margin-top:0.5rem; margin-left:1.0rem; font-size:smaller;">
            <span>RouteId: {{route.routeId}}</span>
            <ng-container *ngIf="!route.approved">
                <span *ngIf="languageCode==='de'" sytle="margin-left:1.0rem;"> Route ist nicht geprüft!</span>
                <span *ngIf="languageCode==='en'" sytle="margin-left:1.0rem;"> RRoute is not approved!</span>
            </ng-container>
        </div>
    </div>

</div>